var EntitySchema = require("typeorm").EntitySchema;

module.exports = new EntitySchema({
    name: "coreListPivotItem",
    tableName: "core_list_pivotitem",
    columns: {
        id: {
            primary: true,
            type: "int",
            generated: false
        },
        list_pivot: {
            type: "int",
        },
        list_itema: {
            type: "int"
        },
        list_itemb: {
            type: "int"
        },
        extra_data: {
            type: "varchar",
            nullable: true
        },
        multi_crusher: {
            type: "varchar",
            nullable: true,
            default: ""
        },
        parent_id: {
            type: "int",
            nullable: true
        },
        created: {
            type: "datetime",
            nullable: true
        },
        created_by: {
            type: "int",
            nullable: true
        },
        update: {
            type: "datetime",
            nullable: true
        },
        update_by: {
            type: "int",
            nullable: true
        },
        deleted: {
            type: "datetime",
            nullable: true
        },
        deleted_by: {
            type: "int",
            nullable: true
        },
        published: {
            type: "int",
            default: "1"
        }
    }
})