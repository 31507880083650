import { createQueryBuilder, getRepository, In } from "typeorm";
import { baseApi, isConfigMobile, getCustomerJWT, parseDate, updateReportAuditFields, fixDateObj } from './base'
import axios from 'axios'
import store from "@/store"
import moment from "moment";


/**
 * It is used to get all records from maintenance table.
 * @param {Number} reportId - Report Id.
 * @returns {Array} All records available in the report.
 */
export async function listAllMaintenanceByReport(reportId) {
    const maintenanceRepository = await getRepository("oreReportMaintenance");
    let _items = await maintenanceRepository.find({ report: reportId, deleted: null }).then(response => { return response; }).catch(error => { console.log(error); })
    return _items
}

/**
 * It is used to get all records from maintenance and maintenance details table.
 * @param {Number} reportId - Report Id.
 * @returns {Array} All records available in the report.
 */
export async function listMaintenanceData(reportId) {
    const maintenanceRepository = await getRepository("oreReportMaintenance");
    const maintenanceDetailsRepository = await getRepository(
        "oreReportMaintenanceDetails"
    );
    let _itemsMaintenance = await maintenanceRepository
        .find({ report: reportId, deleted: null })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
    let _itemsMaintenanceDetails = await maintenanceDetailsRepository
        .find({ report: reportId, deleted: null })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
    let _items = {
        maintenance: _itemsMaintenance,
        maintenance_details: _itemsMaintenanceDetails,
    };
    return _items;
}

/**
 * It is used to create or update the maintenance plan for the report.
 * @param {Object} data - Object with data to save maintenance.
 * @returns {Array} - All maintenance plan available in the report.
 */
export async function createUpdateFullMaintenance(data) {
    let isMobile = isConfigMobile()
    if (isMobile) {
        console.log("dataMaintenance",data);
        const maintenanceRepository = await getRepository("oreReportMaintenance");
        const maintenanceDetailsRepository = await getRepository("oreReportMaintenanceDetails");
        let user_id = store.getters["auth/getAuthCustomer"].data.id
        let now = moment().utc().toString(); // To keep format Date to show

        let maintenance_id = data.id;
        let maintenance_data = {
            comments: data.comments,
            equipment: data.equipment,
            report: data.report,
            update: now,
            update_by: user_id,
            created: now,
            created_by: user_id,
        }
        let maintenance_items = data.items;
        let item_db = {}
        let items_maintenance_db = []
        let _itesToSave = []

        if (maintenance_id != null) {
            await maintenanceRepository.update(maintenance_id, {
                comments: data.comments,
                equipment: data.equipment,
                report: data.report,
                update: now,
                update_by: user_id,
                sync: false
            }).then(response => { return response; }).catch(error => { console.log(error); })
            item_db.report = data.report
            item_db.equipment = data.equipment
            item_db.id = data.id
        } else {
            maintenance_data.uuid = data.uuid
            item_db = await maintenanceRepository.save(maintenance_data).then(response => { return response; }).catch(error => { console.log(error); })
        }

        await maintenance_items.forEach(async item_detail => {
            items_maintenance_db.push(item_detail.equipment_maintenance)
            let item_detail_data = {
                equipment_maintenance: item_detail.equipment_maintenance,
                report: item_db.report,
                equipment: item_db.equipment,
                type: item_detail.type,
                value: item_detail.value,
                update: now,
                update_by: user_id,
                created: now,
                created_by: user_id,
            }
            if (item_detail.id != null) {
                await maintenanceDetailsRepository.update(item_detail.id, {
                    equipment_maintenance: item_detail.equipment_maintenance,
                    report: item_db.report,
                    equipment: item_db.equipment,
                    type: item_detail.type,
                    value: item_detail.value,
                    update: now,
                    update_by: user_id,
                    sync: false
                }).then(response => { return response; }).catch(error => { console.log(error); })
            } else {
                item_detail_data.uuid = createUUID();
                _itesToSave.push(item_detail_data)
            }
        });

        // Items to save, it was done like this to control the async
        if (_itesToSave.length > 0) {
            await maintenanceDetailsRepository.save(_itesToSave).then(response => { return response; }).catch(error => { console.log(error); })
        }

        return await sync(data, item_db, items_maintenance_db)
    } else {
        return new Promise(function (resolve, reject) {
            const token = getCustomerJWT()
            axios.post(baseApi() + '1.0/ore/report/maintenance/full-create/', data, { headers: { Authorization: `Bearer ${token}` } })
                .then(response => {
                    store.commit("ore/setUpdateReportsSelected", response.data);
                    resolve(response.data)
                })
                .catch(error => reject(error))
        })
    }
}

/**
 * It is used to synchronize data from maintenance module.
 * It is send from mobile aplication to web aplication
 * @param {Object} data - Object with data to send to web application
 * @param {Object} item_db - Object with data to find the maintenance parent.
 * @returns {Array} All records available in the report.
 */
async function sync(data, item_db, details) {
    store.commit("comun/setSynchronizing", true);
    const reportRepository = getRepository("oreReport");
    const maintenanceRepository = await getRepository("oreReportMaintenance");
    const maintenanceDetailsRepository = await getRepository("oreReportMaintenanceDetails");
    let _data_maintenance = await maintenanceRepository.find({ id: item_db.id })
    let _data_maintenance_details = await maintenanceDetailsRepository.find({ report: data.report, equipment: item_db.equipment, equipment_maintenance: In(details) })
    let data_maintenance = parseDate(_data_maintenance);
    let data_maintenance_details = parseDate(_data_maintenance_details);

    let reportData = await reportRepository.find({ id: data.report }).then(res => { return parseDate(res) })
    await reportRepository.update(reportData[0].id, { sync: false, }).then((res) => {}).catch(error => console.log(error))

    let checkOnLine =  store.getters["comun/getIsOnline"]
    if(checkOnLine){
        let objToSync = {
            reports: [
                {
                    report: fixDateObj([reportData[0]])[0],
                    data: {
                        maintenance: fixDateObj(data_maintenance),
                        maintenance_details: fixDateObj(data_maintenance_details),
                    }
                }
            ],
        }

        const token = getCustomerJWT()
        await axios.post(baseApi() + '1.0/ore/mobile/sync/', objToSync, { headers: { Authorization: `Bearer ${token}` } })
            .then(async function (response) {
                let reportRes = response.data.reports[0].report
                let dataRes = response.data.reports[0].data

                await updateReportAuditFields(reportRes, reportRepository, reportData[0].id)
                if ("maintenance" in dataRes) {
                    let _items = dataRes.maintenance
                    await _items.forEach(async (item) => {
                        //If the sync is rejected, the item is updated with the information that comes from web
                        if (item.action_msg == "reject") {
                            await maintenanceRepository.update(data_maintenance[0].id,
                                {
                                    comments: item.comments,
                                    equipment: item.equipment,
                                    report: item.report,
                                    created: new Date(item.created),
                                    created_by: item.created_by,
                                    update: new Date(item.update),
                                    update_by: item.update_by,
                                    web_id: item.web_id,
                                    update_web: new Date(item.update_web),
                                    sync: true,
                                },
                            ).then((res) => {}).catch(error => console.log(error))
                        } else {
                            //If it is not rejected and the sync was successful, the variables are updated.
                            if (item.action_status) {
                                await maintenanceRepository.update(data_maintenance[0].id,
                                    {
                                        web_id: item.web_id,
                                        update_web: new Date(item.update_web),
                                        sync: true,
                                    },
                                ).then((res) => {}).catch(error => console.log(error))
                            }
                        }
                    })
                }

                if ("maintenance_details" in dataRes) {
                    let _items = dataRes.maintenance_details
                    await _items.forEach(async (item) => {
                        //If the sync is rejected, the item is updated with the information that comes from web.
                        if (item.action_msg == "reject") {
                            await maintenanceDetailsRepository.update(data_maintenance_details[0].id,
                                {
                                    equipment_maintenance: item.equipment_maintenance,
                                    maintenance: item.maintenance_mobile,
                                    type: item.type,
                                    value: item.value,
                                    created: new Date(item.created),
                                    created_by: item.created_by,
                                    update: new Date(item.update),
                                    update_by: item.update_by,
                                    web_id: item.web_id,
                                    update_web: new Date(item.update_web),
                                    sync: true,
                                },
                            ).then((res) => {}).catch(error => console.log(error))
                        } else {
                            //If it is not rejected and the sync was successful, the variables are updated.
                            if (item.action_status) {
                                await maintenanceDetailsRepository.update(item.id_mobile,
                                    {
                                        web_id: item.web_id,
                                        update_web: new Date(item.update_web),
                                        sync: true,
                                    },
                                ).then((res) => {}).catch(error => console.log(error))
                            }
                        }
                    })
                }
                store.commit("comun/setSynchronizing", false);
                return updateStore(reportData[0].id)

            }).catch(error => {store.commit("comun/setSynchronizing", false); console.log(error); return updateStore(reportData[0].id)})
    } else {
        store.commit("comun/setSynchronizing", false);
        return updateStore(reportData[0].id)
    }
}

/**
 * It is used to update the information in the local storage for maintenance plan.
 * @param {Number} report - Report Id.
 */
async function updateStore(report) {
    const maintenance_data = await listMaintenanceData(report)
    store.commit("ore/setUpdateReportsSelected", {
        ...maintenance_data,
    });
}

/**
 * It is used to create a unique string to be able to identify the record when synchronizing.
 * @returns {String} - A unique string to identify the record.
 */
function createUUID() {
    let dt = new Date().getTime()
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (dt + Math.random() * 16) % 16 | 0
        dt = Math.floor(dt / 16)
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16)
    })
    return uuid.toUpperCase()
}