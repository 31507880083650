<template>
  <div>
    <exp-header-bar class="header-dash" @clickLogo="$emit('clickLogo')">
      <template v-slot:row2>
        <div>
          <v-row>
            <v-col cols="10" class="ellipsis-wrapper">
              {{ $t("ore.site.sites") }}:
              <span v-html="labelSelected(itemsSitesSelected, itemsSitesFiltered, true)"></span>
            </v-col>
            <v-col cols="2">
              <v-btn
                style="margin-top: -5px; margin-top: -10px"
                @click="modalSelectSites = true"
              >
                <v-icon dark>mdi-format-list-bulleted-square </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <div>
          <v-row>
            <v-col>
              <div style="float:left">{{ $t("ore.campaign.campaigns") }}:</div>
              <div v-html="labelSelected(itemsCampaignsSelected, itemsCampaigns, true)" class="label-campaign"></div>
            </v-col>
            <v-col cols="2">
              <v-btn
                style="margin-top: -5px; margin-top: -10px"
                @click="openModalCampaigns()"
              >
                <v-icon dark> mdi-format-list-bulleted-square </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </template>
      <template v-slot:row4>
        <div class="icon_calendar"><v-icon>mdi-calendar</v-icon></div>
        <div class="date_calendar">{{ dateNow }}</div>
        <div class="time_calendar">{{ timeNow }}</div>
      </template>
    </exp-header-bar>
    <div class="filters">
      <v-row dense>
        <v-col cols="5">
          <div class="filter-top-row">
            <div class="filter-top-col w-120">
              {{ $t("ore.region.regions") }}
            </div>
            <div class="filter-top-col w-120-">
              <v-text-field
                :value="labelSelected(itemsRegionsSelected, itemsRegions, false)"
                readonly
                outlined
                dense
                background-color="#feec00ff"
                @click="modalSelectRegions = true"
              />
            </div>
          </div>
        </v-col>
        <v-col cols="7">
          <v-row dense>
            <v-col cols="6">
              <div class="filter-top-row">
                <div class="filter-top-col w-120">
                  {{ $t("ore.report.start_date") }}
                </div>
                <div class="filter-top-col w-120-">
                  <exp-date-time
                    :name="$t('ore.report.start_date')"
                    v-model="startDate"
                    :type="'date'"
                    @change="filtersReport()"
                    :max-date="$moment().format('YYYY-MM-DD')"
                    :backgroundColor="'#feec00ff'"
                    :formatDate="this.formatDate"
                  />
                </div>
              </div>
            </v-col>
            <v-col cols="6">
              <div class="filter-top-row">
                <div class="filter-top-col w-120">
                  {{ $t("ore.report.end_date") }}
                </div>
                <div class="filter-top-col w-120-">
                  <exp-date-time
                    :name="$t('ore.report.end_date')"
                    v-model="endDate"
                    :type="'date'"
                    @change="filtersReport()"
                    :min-date="$moment(startDate).format('YYYY-MM-DD')"
                    :max-date="$moment().format('YYYY-MM-DD')"
                    :backgroundColor="'#feec00ff'"
                    :formatDate="this.formatDate"
                  />
                </div>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="filter-status">
          <h2>{{ $t("ore.report.status") }}</h2>
          <v-row>
            <v-col
              cols="4"
              v-for="(item, index) in itemsStatusReports"
              :key="index"
              class="ma-0 pa-0"
            >
              <v-checkbox
                inset
                :color="item.color"
                v-model="itemsSelectedStatusReports[index]"
                :label="$t(item.name)"
                :true-value="item.id"
                false-value=""
                @change="filtersReport()"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!-- Modal to manage the list of regions -->
      <brs-modal
        v-model="modalSelectRegions"
        :width="420"
        :title="$t('ore.region.regions')"
        :textBtnSave="'ore.report.apply_filter'"
        @clickSave="applyFilterRegions($refs.bwlRegions.getItemsCheckedVisibles())"
        @clickClose="$refs.bwlRegions.resetList()"
      >
        <brs-window-list
          ref="bwlRegions"
          :items="itemsRegionsFiltered"
          :itemsSelected="itemsRegionsSelected"
          :selectable="true"
        />
      </brs-modal>
      <!-- Modal to manage the list of sites -->
      <brs-modal
        v-model="modalSelectSites"
        :width="420"
        :title="$t('ore.site.sites')"
        :hasBtnSave="false"
      >
        <brs-window-list
          :items="itemsSitesFiltered"
          @clickItem="applyFilterSites"
        />
      </brs-modal>
      <!-- Modal to manage the list of Campaigns -->
      <brs-modal
        v-model="modalSelectCampaigns"
        :width="1000"
        :title="$t('ore.report.site_crush_camp')"
        :textBtnSave="'ore.report.apply_filter'"
        @clickSave="applyFilterCampaigns($refs.bwlCampaigns.getItemsCheckedVisibles())"
        @clickClose="cancelFilterCampaigns()"
      >
        <brs-window-list
          ref="bwlCampaigns"
          :items="itemsCampaignsFilteredModal"
          :itemsSelected="itemsCampaignsSelected"
          :selectable="true"
        />
      </brs-modal>
    </div>
  </div>
</template>

<script>
import basicMixin from "@/mixins/basic";
import oreMixin from "@/mixins/ore";

import expLoading from "@/components/expLoading";
import expHeaderBar from "@/components/expHeaderBar";
import expDateTime from "@/components/expDateTime.vue";
import brsWindowList from "@/components/brsWindowList.vue";
import brsModal from "@/components/brsModal.vue";

export default {
  auth: false,
  name: "page-index-filters",
  mixins: [basicMixin, oreMixin],
  components: {
    expLoading,
    expHeaderBar,
    expDateTime,
    brsModal,
    brsWindowList
  },
  data() {
    return {
      itemsSelectedStatusReports: this.getDashFilterOption("status"),
      itemsRegionsSelected: this.getDashFilterOption("regions"),
      itemsSitesSelected: this.getDashFilterOption("sites"),
      itemsSitesSelectedAuxiliar: this.getDashFilterOption("sites"),
      itemsCampaignsSelected: this.getDashFilterOption("campaigns"),
      startDate: this.getDashFilterOption("start_date"),
      endDate: this.getDashFilterOption("end_date"),
      modalSelectSites: false,
      modalSelectCampaigns: false,
      modalSelectRegions: false,
      dateNow: null,
      timeNow: null
    };
  },
  props: {
    itemsStatusReports: {
      type: Array,
      default: () => [],
    },
    formatDate: {
      type: String,
      default: "YYYY-MM-DD",
    },
  },
  async mounted() {
    this.dateNow = this.$moment().format(this.formatDate);
    this.timeNow = this.$moment().format("hh:mm:ss A");
    setInterval(() => {
      this.dateNow = this.$moment().format(this.formatDate);
      this.timeNow = this.$moment().format("hh:mm:ss A");
    }, 1000);

    this.isLoading = true;
    setTimeout(() => {
      if (this.itemsSelectedStatusReports.length == 0) {
        this.itemsSelectedStatusReports = this.itemsStatusReports.map((x) => x.id);
      }
      if (this.itemsRegionsSelected.length == 0) {
        this.itemsRegionsSelected = this.itemsRegions.map((x) => x.id);
      }
      if (this.itemsSitesSelected.length == 0) {
        this.itemsSitesSelected = this.itemsSites.map((x) => x.id);
      }
      if (this.itemsCampaignsSelected.length == 0) {
        this.itemsCampaignsSelected = this.itemsCampaigns.map((x) => x.id);
      }
      this.filtersReport();
      this.isLoading = false;
    }, 100);
  },
  methods: {
    getDashFilterOption(option) {
      const filter = this.$store.getters["ore/getDashFilter"];
      return filter[option] != null && filter[option] != undefined
        ? filter[option]
        : [];
    },
    applyFilterRegions(items) {
      this.modalSelectRegions = false;
      this.itemsRegionsSelected = items.map((x) => x.id);
      this.itemsSitesSelectedAuxiliar = [];
      this.itemsSitesSelected.forEach((item) => {
        let itemSite = this.itemsSites.find((x) => x.id == item);
        if (itemSite != undefined && this.itemsRegionsSelected.includes(itemSite.region)) {
          this.itemsSitesSelectedAuxiliar.push(item);
        }
      });
      this.filtersReport();
    },
    applyFilterSites(items) {
      this.modalSelectSites = false;
      this.itemsSitesSelectedAuxiliar = items.map((x) => x.id);
      this.modalSelectCampaigns = true;
    },
    applyFilterCampaigns(items) {
      this.modalSelectCampaigns = false;
      this.itemsCampaignsSelected = items.map((x) => x.id);
      this.itemsSitesSelected = this.itemsSitesSelectedAuxiliar;
      this.filtersReport();
    },
    cancelFilterCampaigns() {
      this.itemsSitesSelectedAuxiliar = this.itemsSitesSelected;
      this.$refs.bwlCampaigns.resetList()
    },
    openModalCampaigns() {
      this.itemsSitesSelectedAuxiliar = this.itemsSitesSelected;
      this.modalSelectCampaigns = true;
    },
    filtersReport() {
      let filters = {
        status: this.itemsSelectedStatusReports,
        state: this.itemsSelectedStatesReports,
        regions: this.$_.filter(this.itemsRegionsSelected, (i) => {
          return this.$_.includes(this.itemsRegionsFiltered.map((x) => x.id), i);
        }),
        sites: this.$_.filter(this.itemsSitesSelected, (i) => {
          return this.$_.includes(this.itemsSitesFiltered.map((x) => x.id), i);
        }),
        campaigns: this.$_.filter(this.itemsCampaignsSelected, (i) => {
          return this.$_.includes(this.itemsCampaignsFiltered.map((x) => x.id), i);
        }),
        dates: [this.startDate, this.endDate]
      }
      this.$emit("filtersReport", filters)
    },
    labelSelected(selected, items, formated = false) {
      try {
        let _selected = this.$_.filter(selected, (i) => {
          return this.$_.includes(items.map((x) => x.id), i);
        });
        let firstName = "";
        if (_selected != undefined && _selected.length > 0)
          firstName = items.find((x) => x.id == _selected[0]).name;
        if (_selected.length == 0) {
          return "";
        } else if (_selected.length == 1) {
          return firstName
        } else {
          if (formated)
            return firstName + " <span class='other_more'>(+" + (_selected.length - 1) + " " + this.$t("core.comun.others") + ")</span>"
          else
            return firstName + " (+" + (_selected.length - 1) + " " + this.$t("core.comun.others") + ")"
        }
      } catch {
        return "";
      }
    },
  },
  computed: {
    itemsRegionsFiltered() {
      return this.itemsRegions;
    },
    itemsSitesFiltered() {
      return this.$_.filter(this.itemsSites, (i) => {
        return this.$_.includes(this.itemsRegionsSelected, i.region);
      });
    },
    itemsCampaignsFiltered() {
      let _itemsCampaigns = this.$_.filter(this.itemsCampaigns, (i) => {
        return this.$_.includes(this.itemsSitesFiltered.map((x) => x.id), i.site);
      });
      if(!this.isset(this.itemsSelectedStatusReports.find(x => x === 100))) {
        _itemsCampaigns = this.$_.filter(_itemsCampaigns, (i) => {
          return i.published == true;
        });
      }
      return _itemsCampaigns;
    },
    itemsCampaignsFilteredModal() {
      let _items = this.$_.filter(this.itemsCampaignsFiltered, (i) => {
        return this.$_.includes(this.itemsSitesSelectedAuxiliar, i.site);
      });
      return _items.map((x) => {
        return {
          ...x,
          name: x.site_name + ' / ' + x.crusher_name + ' / ' + x.name
        }
      });
    },
    itemsSelectedStatesReports() {
      return this.itemsSelectedStatusReports.map(function (item) {
        return parseInt("0" + item);
      })
    }
  },
  watch: {

  },
};
</script>