//i18n-setup.js
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import axios from 'axios'
import store from '../store'

import { loadLanguageAsync } from '../gateway/language'

Vue.use(VueI18n)

export const availableLocales = {
  defaultLocale: 'en-US',
  locales: [
    { code: 'en-US', name: 'English - US' },
    { code: 'en-CA', name: 'English - CA' },
    { code: 'fr-FR', name: 'Français' },
    { code: 'es-CO', name: 'Español' },
    { code: 'tags', name: 'tags' },
  ]
}
let loadedLanguages = []
const messages = { 'last': store.getters['auth/getLastLanguage']['messages'] }
export const i18n = new VueI18n({
  locale: 'last',
  fallbackLocale: 'last',
  messages
})

function _setI18nLanguage(lang) {
  let _userAuth = store.getters['auth/isAuthCustomer']
  i18n.locale = lang
  axios.defaults.headers.common['Accept-Language'] = lang
  document.querySelector('html').setAttribute('lang', lang)
  if (_userAuth) {
    store.commit('auth/setLastLanguage', {
      lang: lang,
      messages: i18n.messages[lang]
    })
  }
  return lang
}

function _loadLanguage(lang, message) {
  i18n.setLocaleMessage(lang, message.data)
  loadedLanguages.push(lang)
  _setI18nLanguage(lang)
}

export function loadLanguage(lang = '') {
  if (lang== "tags") {
    _loadLanguage("tags", {})
    return
  }
  if (lang == '' && store.getters['auth/getLastLanguage']['lang'] != '') {
    lang = store.getters['auth/getLastLanguage']['lang']
  }
  else if (store.getters['auth/getLastLanguage']['lang'] == '') {
    try {
      let userData = JSON.parse(JSON.stringify(store.getters['auth/getAuthCustomer']))
      if (userData['data'] && userData['data']['extra_data']) {
        let extra = JSON.parse(userData['data']['extra_data'])
        if (extra && "language" in extra) {
          lang = extra["language"]
        }
      }
    } catch (error) {
      lang = ''
    }
  }

  lang = (availableLocales.locales.find(x => x.code == lang) != undefined) ? lang : availableLocales.defaultLocale

  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(_setI18nLanguage(lang))
  }

  return new Promise(function (resolve) {
    loadLanguageAsync(lang)
      .then(response => {
        resolve(_loadLanguage(lang, response))
      })
  })
}
