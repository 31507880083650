<template>
  <div class="header-bar">
    <table>
      <tr class="header-main" :style="'background-color:' + headerColor">
        <td class="pair row1">
          <slot name="row1">
            <img :src="imgPath('goback.png')" @click="openRoute('/')" />
          </slot>
        </td>
        <td class="odd row2">
          <slot name="row2">
            <div class="ellipsis-wrapper report-title">{{ $t("ore.site.title") }}: {{ reportSelectedSiteName }}</div>
            <div class="ellipsis-wrapper report-title">{{ $t("ore.crusher.title") }}: {{ reportSelectedCrusherName }}</div>
            <div class="ellipsis-wrapper report-title">{{ $t("ore.campaign.title") }}: {{ reportSelectedCampaignName }}</div>
          </slot>
        </td>
        <td class="pair row3">
          <slot name="row3">
            <img
              :src="imgPath('logo.png')"
              height="50"
              alt="Colas"
              class="logo"
              @click="$emit('clickLogo')"
            />
          </slot>
        </td>
        <td class="odd row4">
          <slot name="row4">
            <div class="icon_calendar"><v-icon>mdi-calendar</v-icon></div>
            <div class="date_calendar">{{ reportDate }}</div>
          </slot>
        </td>
        <td class="pair row5">
          <slot name="row5">
            <template v-if="storeReportSelected.report">
              <v-icon
                large
                color="orange"
                v-if="storeReportSelected.report.shift"
                >mdi-weather-sunny</v-icon
              >
              <v-icon large color="purple" v-else>mdi-weather-night</v-icon>
            </template>
          </slot>
        </td>
        <td class="pair row6">
          <slot name="row6">
            <exp-user
              :admin="false"
              :btnRounded="true"
              v-if="isCustomersAuthenticated"
              :availablesLocales="langAllowed"
              :defaultLocale="langDefault"
              :borderAvatarColor="
                'border-color:' + (isOnline ? 'green' : 'red')
              "
            />
          </slot>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import basicMixin from "@/mixins/basic.js";
import customerMixin from "@/mixins/customer.js";
import oreMixin from "@/mixins/ore";
import mobileMixin from "@/mixins/mobile";

import expLoading from "@/components/expLoading";
import expUser from "@/components/expUser";
import expTranslation from "@/components/expTranslation";
import { mapGetters } from "vuex";

export default {
  name: "report-menu",
  mixins: [basicMixin, customerMixin, oreMixin, mobileMixin],
  components: { expLoading, expUser, expTranslation },
  data() {
    return {};
  },
  props: {},
  methods: {
    imgPath(img) {
      return require("../assets/theme/img/" + img);
    },
  },
  mounted() {},
  computed: {
    ...mapGetters({
      langAllowed: "comun/getLangAllowed",
    }),
    reportDate() {
      return this.$moment(this.reportSelectedDate).format(this.format_date);
    },
    langDefault() {
      return this.$store.getters["getLangDefault"];
    },
    format_date() {
      try {
        return this.crusherFormat.format_date;
      } catch {
        return "YYYY-MM-DD";
      }
    },
    headerColor() {
      let _url = document.URL;
      if (_url.lastIndexOf('https://dv-ore') >= 0) {
        return 'red'
      } else if (_url.lastIndexOf('https://py-ore') >= 0) {
        return 'blue'
      } else if (_url.lastIndexOf('http://local') >= 0) {
        return 'green'
      } else {
        return 'white'
      }
    },
  },
};
</script>
