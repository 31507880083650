import { baseApi, getCustomerJWT } from './base'
import axios from 'axios'

export function getDataTable(source, params, jwt) {
    return new Promise(function (resolve, reject) {
        let endpoint
        switch (source) {
            case "reports":
                endpoint = "1.0/ore/report/dt/"
                break;
        }
        let token = (jwt != "" && jwt != undefined && jwt != null) ? jwt : getCustomerJWT()
        axios.post(baseApi() + endpoint, params, { headers: { Authorization: `Bearer ${token}` } })
            .then(response => resolve(response))
            .catch(error => reject(error))
    })
}