<template>
  <v-card elevation="0" class="pa-0">
    <v-card-text class="pb-0 pt-2">
      <v-row>
        <v-col cols="12" class="mb-0">
          <v-text-field v-model="searchText" :label="$t('ore.report.filter')" class="mb-2" hide-details outlined dense></v-text-field>
          <template v-if="selectable">
            <span style="cursor: pointer" @click="selectAll()">
              <v-icon>
                <template v-if="selectedAll == 1">
                  mdi-checkbox-outline
                </template>
                <template v-else-if="selectedAll == 2">
                  mdi-minus-box
                </template>
                <template v-else> mdi-checkbox-blank-outline </template>
              </v-icon>
              &nbsp;{{ $t("core.comun.select_all") }}
            </span>
          </template>
          <template v-else>
            <span style="cursor: pointer" @click="selectAll()">
              <v-icon>mdi-format-list-bulleted</v-icon>
              &nbsp;{{ $t("ore.site.all_sites") }}
            </span>
          </template>
        </v-col>
      </v-row>
      <v-divider class="mt-2"></v-divider>
    </v-card-text>
    <v-card-text class="ma-0 pa-0" :style="'height: ' + height + 'px; overflow: auto;'">
      <v-list dense>
        <v-list-item-group color="primary">
          <template v-for="(item, index) in items">
            <v-list-item :key="index" v-if="selectable" v-show="isVisible(item.id)" @click="toggleItem(item.id)">
              <v-icon>
                <template v-if="valueItem(item.id)">
                  mdi-checkbox-outline
                </template>
                <template v-else>
                  mdi-checkbox-blank-outline
                </template>
              </v-icon>
              &nbsp;<span :class="item.published == false ? 'grey--text text--lighten-1' : ''">{{ item.name }}</span>
            </v-list-item>
            <v-list-item :key="index" v-else @click="clickItem(item)" v-show="isVisible(item.id)">
              <v-list-item-content>
                <v-list-item-title>
                  <span :class="item.published == false ? 'grey--text text--lighten-1' : ''">{{ item.name }}</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list-item-group>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      searchText: "",
      searchTextDelay: "",
      searchDelay: Object,
      itemsCheckBox: [],
    };
  },
  beforeMount() {
    this.initList();
  },
  props: {
    value: String,
    items: {
      type: Array,
      default: () => [],
    },
    itemsSelected: {
      type: Array,
      default: () => [],
    },
    filterColumn: {
      type: String,
      default: "name",
    },
    allVisible: {
      type: Boolean,
      default: true,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    height: {
      type: Number,
      default: 400,
    },
  },
  methods: {
    initList() {
      this.itemsCheckBox = []
      this.items.forEach((item) => {
        let isChecked = false;
        isChecked = this.itemsSelected.find(value => value == item.id) != undefined ? true : false;
        this.itemsCheckBox.push({
          ...item,
          visible: true,
          checked: isChecked,
        });
      });
    },
    resetList() {
      this.itemsCheckBox.forEach((item) => {
        let isChecked = false;
        isChecked = this.itemsSelected.find(value => value == item.id) != undefined ? true : false;
        item.checked = isChecked;
      });
    },
    toggleItem(itemId) {
      let itemToggle = this.itemsCheckBox.find((x) => x.id == itemId);
      itemToggle.checked = !itemToggle.checked;
      this.clickItem();
    },
    valueItem(itemId) {
      let itemChecked = this.itemsCheckBox.find((x) => x.id == itemId);
      if (itemChecked != undefined) return itemChecked.checked;
      else return false;
    },
    isVisible(itemId) {
      let itemVisible = this.itemsCheckBox.find((x) => x.id == itemId);
      if (itemVisible != undefined) return itemVisible.visible;
      else return false;
    },
    selectAll() {
      if (this.selectable) {
        let visibles = this.getItemsVisibles();
        if (this.selectedAll == 0) {
          visibles.forEach((item) => {
            item.checked = true;
          });
        } else {
          visibles.forEach((item) => {
            item.checked = false;
          });
        }
        this.clickItem();
      } else {
        this.$emit("clickItem", this.getItemsVisibles());
      }
    },
    getItemsChecked() {
      return this.itemsCheckBox.filter((x) => x.checked);
    },
    getItemsVisibles() {
      return this.itemsCheckBox.filter((x) => x.visible);
    },
    getItemsCheckedVisibles() {
      let itemsChecked = this.getItemsChecked();
      let itemsCheckedVisibles = [];
      itemsChecked.forEach((item) => {
        if (item.visible) itemsCheckedVisibles.push(item);
      });
      return itemsCheckedVisibles;
    },
    clickItem(item = null) {
      if (this.selectable) {
        this.$emit("clickItem", this.getItemsCheckedVisibles());
      } else {
        this.$emit("clickItem", [item]);
      }
    },
  },
  computed: {
    selectedAll() {
      let itemsCheckedVisibles = this.getItemsCheckedVisibles();
      if (itemsCheckedVisibles.length == 0) return 0; // Checked none
      else {
        let itemsVisibles = this.getItemsVisibles();
        if (itemsCheckedVisibles.length == itemsVisibles.length)
          return 1; // Checked all
        else return 2; // Checked some
      }
    },
  },
  watch: {
    searchText() {
      clearTimeout(this.searchDelay);
      this.searchDelay = setTimeout(() => {
        this.searchTextDelay = this.searchText;
        this.$emit("input", this.searchTextDelay);
        this.itemsCheckBox.forEach((item) => {
          item.visible =
            item[this.filterColumn]
              .toLowerCase()
              .indexOf(this.searchTextDelay.toLowerCase()) !== -1;
        });
        if (this.selectable) this.clickItem();
      }, 200);
    },
    items: {
      handler() {
        this.initList();
      },
      deep: true,
    },
  },
};
</script>
