var EntitySchema = require("typeorm").EntitySchema;

module.exports = new EntitySchema({
    name: "oreConfigEquipment",
    tableName: "ore_config_equipment",
    columns: {
        id: {
            primary: true,
            type: "int",
            generated: false
        },
        name: {
            type: "varchar"
        },
        description: {
            type: "varchar",
            nullable: true
        },
        crusher: {
            type: "int"
        },
        category: {
            type: "int",
            nullable: true
        },
        zone: {
            type: "int",
            nullable: true
        },
        number: {
            type: "varchar"
        },
        unit: {
            type: "varchar"
        },
        created: {
            type: "datetime",
            nullable: true
        },
        created_by: {
            type: "int",
            nullable: true
        },
        update: {
            type: "datetime",
            nullable: true
        },
        update_by: {
            type: "int",
            nullable: true
        },
        deleted: {
            type: "datetime",
            nullable: true
        },
        deleted_by: {
            type: "int",
            nullable: true
        },
        published: {
            type: "int",
            default: "1"
        }
    }
})