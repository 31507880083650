var EntitySchema = require("typeorm").EntitySchema;

module.exports = new EntitySchema({
    name: "oreConfigProductList",
    tableName: "ore_config_products_list",
    columns: {
        id: {
            primary: true,
            type: "int",
            generated: false
        },
        title: {
            type: "varchar"
        },
        product_id: {
            type: "varchar"
        },
        description: {
            type: "varchar",
            nullable: true
        },
        associated_location: {
            type: "varchar"
        },
        group_id: {
            type: "int"
        },
        extra_data:{
            type: "varchar",
            nullable: true
        },
        multi_site:{
            type: "int",
            default: "1"
        },
        created: {
            type: "datetime",
            nullable: true
        },
        created_by: {
            type: "int",
            nullable: true
        },
        update: {
            type: "datetime",
            nullable: true
        },
        update_by: {
            type: "int",
            nullable: true
        },
        deleted: {
            type: "datetime",
            nullable: true
        },
        deleted_by: {
            type: "int",
            nullable: true
        },
        published: {
            type: "int",
            default: "1"
        }
    }
})