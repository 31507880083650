<template>
  <v-card-actions class="exp-translate">
    <exp-loading
      v-show="isLoading"
    />
    <template>
      <v-speed-dial
        :direction="'bottom'"
        :transition="'slide-y-reverse-transition'"
      >
        <template v-slot:activator>
          <v-avatar>
            <v-img
              :src="imgPath('flags/' + getCodeLang(selectedLocale) + '.png')"
              :alt="getNameLang(selectedLocale)"
              max-height="32"
              max-width="32"
            />
          </v-avatar>
        </template>
        <v-avatar
          v-for="locale in listLocales"
          :key="getCodeLang(locale)"
          @click="switchLanguage(locale.code)"
        >
          <v-img
            :src="imgPath('flags/' + getCodeLang(locale) + '.png')"
            max-height="32"
            max-width="32"
            :alt="getNameLang(locale)"
          />
        </v-avatar>
      </v-speed-dial>
    </template>
  </v-card-actions>
</template>

<script>
import { i18n, loadLanguage, availableLocales } from "../plugins/i18n";
import basicMixins from "../mixins/basic.js";
import configMinins from '../mixins/config.js'

export default {
  mixins: [basicMixins, configMinins],
  data () {
    return {
      isLoading: false
    }
  },
  props: {
    size: {
      type: Number,
      default: 32,
    },
    default: {
      type: String,
      default: "",
    },
  },
  mounted () {
    if (this.isMobile) {
      setTimeout(() => {
        if (this.selectedLocale == null || this.selectedLocale == "") {
          this.switchLanguage(this.listLocales[0].code)
        }
      }, 200)
    }
  },
  methods: {
    async switchLanguage(lang) {
      this.isLoading = true;
      await loadLanguage(lang);
      this.isLoading = false;
    },
    getCodeLang(Locale) {
      if (Locale != undefined) return Locale["code"];
      else return "en-US";
    },
    getNameLang(Locale) {
      if (Locale != undefined) return Locale["name"];
      else return "en-US";
    },
  },
  computed: {
    listLocales() {
      return availableLocales.locales.filter((x) => x.code != "tags");;
    },
    selectedLocale() {
      return this.listLocales.find((x) => x.code == i18n.locale);
    },
    visibleFlag() {
      return this.listLocales.length > 1;
    },
  },
};
</script>
