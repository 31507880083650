<template>
  <v-card elevation="0" :color="'transparent'">
    <reject-form ref="rejectForm" @change="reportStatusSave" />
    <exp-loading v-show="isLoading" />
    <v-card-text class="px-0 py-1">
      <v-row
        no-gutters
        class="d-flex align-center justify-center sub-report-title"
      >
        <v-col cols="12">
          <span :style="`font-size: ${moduleCode == 'MPD' ? languageSelected != 'en-US' ? 2.0 + 'rem' : '' : ''}`">{{ $t(pageTitle) }}</span>
          <div class="btns-module-action">
            <template v-if="moduleCode == 'MSM'">
              <v-btn
                v-if="!reportOnlyRead(reportStatus) && (moduleCode == 'MSM' && (reportStatus == 3 || reportStatus == 2))"
                rounded
                @click="reportStatusSave"
                x-large
                dark
                color="blue"
                :disabled="disabledBtnReport"
                :style="`color: ${
                  colorText != undefined && colorText != ''
                    ? colorText
                    : 'black'
                };`"
              >
                <p class="ma-0 pa-0 text-h5">
                  {{ $t("ore.report.submit") }}
                </p>
              </v-btn>
              <v-btn
                v-if="!reportOnlyRead(reportStatus) && (moduleCode == 'MSM' && reportStatus == 4)"
                rounded
                @click="reportStatusReject"
                x-large
                dark
                color="red"
                :disabled="disabledBtnReport"
                style="float: left !important"
                :style="`color: ${
                  colorText != undefined && colorText != ''
                    ? colorText
                    : 'black'
                };`"
              >
                <v-icon left>mdi-close-circle-outline</v-icon>
                <p class="ma-0 pa-0 text-h5">
                  {{ $t("core.comun.reject") }}
                </p>
              </v-btn>
              <v-btn
                v-if="!reportOnlyRead(reportStatus) && (moduleCode == 'MSM' && reportStatus == 4)"
                rounded
                @click="reportStatusSave(1)"
                x-large
                dark
                color="green"
                :disabled="disabledBtnReport"
                :style="`color: ${
                  colorText != undefined && colorText != ''
                    ? colorText
                    : 'black'
                };`"
              >
                <v-icon left>mdi-check-circle-outline</v-icon>
                <p class="ma-0 pa-0 text-h5">
                  {{ $t("core.comun.accept") }}
                </p>
              </v-btn>
            </template>
            <template v-else>
              <v-btn
                :color="color || 'primary'"
                class="ml-5"
                rounded
                @click="moduleStatusSave"
                x-large
                v-if="moduleCode !== 'MSL'"
                :disabled="btnDisabled || disabledBtnReport"
                :style="`color: ${
                  colorText != undefined && colorText != ''
                    ? colorText
                    : 'black'
                };`"
              >
                <v-icon left>mdi-content-save-outline</v-icon>
                <p class="ma-0 pa-0 text-h5">
                  {{ $t("core.comun.save") }}
                </p>
              </v-btn>
            </template>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { saveUpdateReportSatus, saveUpdateModuleSatus } from "@/gateway/report";
import rejectForm from "@/views/reports/summary/form";
import basicMixin from "@/mixins/basic";

//import basicMixins from "@/mixins/basic";
//import customerMixin from "@/mixins/customer";

export default {
  mixins: [basicMixin],
  components: { rejectForm },
  data() {
    return {
      route: {},
      pageTitle: "",
      moduleCode: "",
      isLoading: false,
      isValidForm: false
    };
  },
  props: {
    color: {
      type: String,
      default: "",
    },
    colorText: {
      type: String,
      default: "",
    },
    btnDisabled: {
      type: Boolean,
      default: false,
    },
    disabledBtnReport: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    async moduleStatusSave() {
      if (this.isValidForm) {
        if (!this.isLoading) {
          this.isLoading = true;
          try {
            await saveUpdateModuleSatus(this.moduleCode)
          } catch (error) {
            this.logError(error)
          }
          this.isLoading = false;
        }
      } else {
        this.$emit("onSave");
      }
    },
    async reportStatusSave(status = null) {
      this.isLoading = true;
      try {
        let newStatus =
          this.reportStatus == 3 ? 4 : this.reportStatus == 2 ? 4 : status;
        await saveUpdateReportSatus({ status: newStatus });
      } catch (error) {
        this.logError(error)
      }
      this.isLoading = false;
    },
    reportStatusReject() {
      this.$refs.rejectForm.showDialog = true;
    },
  },
  mounted() {
    this.route = this.$router.history.current;
  },
  computed: {
    reportStatus() {
      let report = this.$store.getters["ore/getReportSelected"];
      if (
        typeof report.report != "undefined" &&
        typeof report.report.status != "undefined"
      )
        return report.report.status;
      else return 0;
    },
    languageSelected() {
      return this.$store.getters["auth/getLastLanguage"].lang
    },
  },
  watch: {
    route: {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal === oldVal) {
          return;
        }
        this.pageTitle = this.route.meta.pageTitle;
        this.moduleCode = this.route.meta.moduleCode;
      },
    },
    async isValidForm(value){
      if(value){
        await this.moduleStatusSave()
        this.isValidForm = false;
      }
    }
  },
};
</script>
