import { createQueryBuilder, getRepository } from "typeorm";
import { baseApi, isConfigMobile, getCustomerJWT, GetIdAccessRoles } from './base'
import axios from 'axios'
import store from "@/store"

/**
 * It is used to get all records from the campaign table.
 * @returns {Array} - An array with the records from campaign table.
 */
export function getCampaigns() {
    const self = this
    let itemsCampaigns = store.getters['ore/getListCampaigns']

    let isMobile = isConfigMobile()
    if (isMobile) {
        return new Promise(async function (resolve) {
            if (typeof (itemsCampaigns) != 'undefined' && itemsCampaigns.length != 0) {
                resolve(itemsCampaigns)
            } else {
                let user_id = store.getters["auth/getAuthCustomer"].data.id
                let _idAccessRoles = await GetIdAccessRoles(user_id);
                let _idsCrushers = _idAccessRoles.crushers
                let _idsSites = _idAccessRoles.sites

                const campaigns = await getRepository("oreConfigCampaign")
                    .createQueryBuilder("campaign")
                    .select(["campaign.*","site.name", "crusher.name"])
                    .leftJoin("oreConfigSite", "site", "site.id = campaign.site")
                    .leftJoin("oreConfigCrusher", "crusher", "crusher.id = campaign.crusher")
                    .where("campaign.deleted IS NULL")
                if (_idsCrushers != null) {
                    campaigns.andWhere("crusher.id IN (:...idscrushers)", { idscrushers: _idsCrushers })
                }
                if (_idsSites != null) {
                    campaigns.andWhere("site.id IN (:...idsSites)", { idsSites: _idsSites })
                }
                let _campaigns = await campaigns.getRawMany()
                    .then(res => { return res})
                    .catch(e => console.log(e))

                store.commit('ore/setListCampaigns', _campaigns)
                resolve(_campaigns)
            }
        })
    } else {
        return new Promise(function (resolve, reject) {
            if (typeof (itemsCampaigns) != 'undefined' && itemsCampaigns.length != 0) {
                resolve(itemsCampaigns)
            } else {
                const token = getCustomerJWT()
                axios.get(baseApi() + '1.0/ore/crushing/campaign/lts/', { headers: { Authorization: `Bearer ${token}` } })
                    .then(response => {
                        store.commit('ore/setListCampaigns', response.data)
                        resolve(response.data)
                    })
                    .catch(error => reject(error))
            }
        })
    }
}

