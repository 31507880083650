import { createQueryBuilder, getRepository } from "typeorm";
import { baseApi, isConfigMobile, getCustomerJWT } from "./base";
import axios from "axios";
import store from "@/store";

/**
 * It is used to get all records from the campaign target table.
 * @returns {Array} - An array with the records from campaign target table.
 */
export function getLtsCampaignTarget() {
    const self = this;
    let itemsCampaignTarget = store.getters["ore/getListCampaignTarget"];
    let isMobile = isConfigMobile()
    return new Promise(async function (resolve) {
        if (
            typeof itemsCampaignTarget != "undefined" &&
            itemsCampaignTarget.length > 0
        ) {
            resolve(itemsCampaignTarget);
        }
        let _campaignTarget = [];
        let error = false;
        if (isMobile) {
            const campaignTargetRepository = await getRepository(
                "oreConfigCampaignTarget"
            );
            _campaignTarget = await campaignTargetRepository
                .find({ deleted: null, published: true })
                .catch((e) => {
                    _campaignTarget = [];
                    console.log("error-m getLtsCampaignTarget", e);
                });
        } else {
            const token = getCustomerJWT();
            _campaignTarget = await axios
                .get(baseApi() + "1.0/ore/crushing/campaign_target/lts/", {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((r) => {
                    return r.data
                })
                .catch((e) => {
                    _campaignTarget = [];
                    console.log("error getLtsCampaignTarget", e);
                });
        }
        store.commit("ore/setListCampaignTarget", _campaignTarget);
        resolve(_campaignTarget);
    });
}
