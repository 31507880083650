import { getDataTable } from '../gateway/datatable'

const datatableMixin = {
    data() {
        return {
            draw: 1,
            loading: true,
            items: {},
            jwt: '',
            prevent_options: true,
            options: {
                search: '',
                page: 1,
                itemsPerPage: -1,
                recordsFiltered: 0
            },
            footerProps: {
                'items-per-page-text': this.$t('core.datatable.rows_page')
            }
        }
    },
    watch: {
        options() {
            if (!this.prevent_options)
                this.getData(this.jwt)
        },
        reportSelected() {
            this.extrafilters.reportId = this.reportSelected.id
        }
    },
    methods: {
        async getData(jwt = "") {
            this.prevent_options = true
            const self = this
            const parameters = {}
            self.loading = true
            self.jwt = jwt
            parameters.format = 'datatables'
            parameters.draw = self.draw++
            parameters.keep = 'id'
            self.headers.forEach((colum, index) => {
                if (!self.isset(colum.iscolum)) {
                    parameters['columns[' + index + '][data]'] = colum.value
                    parameters['columns[' + index + '][name]'] = ''
                    parameters['columns[' + index + '][searchable]'] = (typeof (colum.filterable) !== 'undefined' ? colum.filterable : 'true')
                    parameters['columns[' + index + '][orderable]'] = (typeof (colum.sortable) !== 'undefined' ? colum.sortable : 'true')
                    parameters['columns[' + index + '][search][value]'] = ''
                    parameters['columns[' + index + '][search][regex]'] = false
                }
                if (self.isset(self.options.sortBy)) {
                    if (self.options.sortBy[0] === colum.value) {
                        parameters['order[0][column]'] = index
                    }
                }
            })
            if (self.isset(self.options.sortDesc)) {
                parameters['order[0][dir]'] = (self.options.sortDesc[0] === true ? 'desc' : 'asc')
            } else {
                parameters['order[0][column]'] = 0
                parameters['order[0][dir]'] = 'desc'
            }

            parameters.start = (self.options.page - 1) * self.options.itemsPerPage
            parameters.length = self.options.itemsPerPage
            if (self.options.search !== '') { parameters['search[value]'] = self.options.search; parameters['search[regex]'] = false }

            let retItems
            this.$store.commit("ore/setLoadingReport", {state: true, msg: 'Loadind dashboard'})
            await getDataTable(self.endpoint, { params: parameters, extra: self.extrafilters }, jwt)
                .then(response => {
                    retItems = response.data
                    this.$store.commit("ore/setLoadingReport", {state: false, msg: ''})
                }).catch(e => {
                    this.$store.commit("ore/setLoadingReport", {state: true, msg: 'Error loadind dashboard'})
                })

            retItems.recordsTotal = parseInt(retItems.recordsTotal)
            retItems.recordsFiltered = parseInt(retItems.recordsFiltered)
            retItems.draw = parseInt(retItems.draw)
            self.items = retItems
            self.loading = false
            this.prevent_options = false
        }
    }
}
export default datatableMixin
