<template>
  <div>
    <exp-alert-confirm
      ref="modalSaveChanges"
      message="ore.report.msg_data_no_save"
      :labelOk="'core.comun.yes'"
      :colorOk="'green'"
      :iconOk="'mdi-check-circle'"
      :fnOk="saveMaterialCrushingActivities"
      :labelCancel="'core.comun.no'"
      :colorCancel="'red'"
      :iconCancel="'mdi-close-circle'"
      :fnCancel="noSaveChanges"
    />
    <exp-ore-modal
      ref="materialSummaryModal"
      :openModal="showDialog"
      @onCloseModal="closeModal"
      @onAcceptModal="saveMaterialCrushingActivities"
      width="400px"
      :modalTitle="$t('ore.report.products')"
      :actionsConfig="modalActionsConfig"
    >
      <template v-slot:body>
        <ValidationObserver v-slot="{ dirty }" ref="observerMaterialActivities">
          <form class="form-material" @submit.prevent="">
            <v-row dense>
              <v-col cols="12" class="mb-2">
                <ValidationProvider
                  v-slot="{ errors }"
                  :name="$t('ore.report.products')"
                  rules="required"
                >
                  <v-select
                    v-model="data.product"
                    :items="materialsSummaryProductList"
                    item-value="id"
                    item-text="product"
                    outlined
                    dense
                    hide-details
                    :label="$t('ore.report.products')"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="8">
                <ValidationProvider
                  v-slot="{ errors }"
                  :name="$t('ore.report.activities')"
                  rules="required"
                >
                  <v-select
                    v-model="data.activity"
                    :items="materialsSummaryActivitiesList"
                    item-value="id"
                    item-text="name"
                    outlined
                    dense
                    :label="$t('ore.report.activities')"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="4">
                <ValidationProvider
                  v-slot="{ errors }"
                  :name="$t('ore.report.quantity')"
                >
                  <exp-mask
                    v-model="data.qty"
                    :label="$t('ore.report.quantity')"
                    class="input-number"
                    outlined
                    dense
                    :error-messages="errors"
                    :mask="maskDecimalNegativeNumber"
                  />
                </ValidationProvider>
              </v-col>
            </v-row>
          </form>
        </ValidationObserver>
      </template>
    </exp-ore-modal>
  </div>
</template>

<script>
import basicMixin from "@/mixins/basic";
import oreMixin from "@/mixins/ore";

import expOreModal from "@/components/expOreModal";
import { ValidationObserver, ValidationProvider } from "vee-validate";

import {
  updateMaterialCrushingActivities,
  createMaterialCrushingActivities,
} from "@/gateway/material_crushing_activities";

export default {
  name: "material-form-products",
  mixins: [basicMixin, oreMixin],
  components: { ValidationObserver, ValidationProvider, expOreModal },
  data() {
    return {
      // Modal vars
      loading: false,
      showDialog: false,
      originalData: {},
      // Custom vars
      data: {},
      default: {
        id: undefined,
        qty: "",
      },
    };
  },
  props: {
    configForms: {
      type: Object,
      default: () => {},
    },
    parentData: {
      type: Object,
      default: () => {},
    },
    materialsSummaryActivitiesList: {
      type: Array,
      default: () => [],
    },
    materialsSummaryProductList: {
      type: Array,
      default: () => [],
    },
    disabledBtnReport: {
      type: Boolean,
      default: false
    }
  },
  async mounted() {},
  methods: {
    // Modal methods
    initModal() {
      if ("observerMaterialActivities" in this.$refs) {
        this.$refs.observerMaterialActivities.reset();
      }
      this.data = this.$_.merge({ ...{}, ...this.default }, this.data);
      this.originalData = this.$_.cloneDeep(this.data);
      try {
        if ("qty" in this.data && (this.data["qty"] || this.data["qty"] === 0))
          this.originalData["qty"] = String(this.data.qty);
      } catch (error) {}
    },
    noSaveChanges() {
      this.showDialog = false;
      this.clearModal();
    },
    clearModal() {
      this.data = {};
      this.originalData = {};
    },
    closeModal() {
      if (this.isDirty && this.storeOnlyReadReport == false) {
        this.$refs.modalSaveChanges.showDialog = true;
      } else {
        this.showDialog = false;
        this.clearModal();
      }
    },
    // Custom methods
    async saveMaterialCrushingActivities() {
      if (this.loading) return;
      let _material_crush_uuid = this.fastValidValue(
        "uuid",
        "",
        this.parentData
      );
      if(!this.isset(this.data.qty)) this.data.qty = 0;
      let frmValid = await this.$refs.observerMaterialActivities.validate();
      this.$refs.modalSaveChanges.showDialog = false;
      if (frmValid) {
        if (_material_crush_uuid === "") return;
        this.data["material_crush_uuid"] = _material_crush_uuid;
        this.loading = true;
        let _data = undefined;
        try {
          if ("id" in this.data && this.data["id"]) {
            _data = await updateMaterialCrushingActivities(
              this.data.id,
              this.data
            );
          } else {
            this.data["scenario"] = this.fastValidValue(
              "report.scenario",
              null,
              this.storeReportSelected
            );
            this.data["campaign"] = this.fastValidValue(
              "report.campaign",
              null,
              this.storeReportSelected
            );
            _data = await createMaterialCrushingActivities(this.data);
          }
          this.$emit("parentActions", "_set_materials_summary_activities", _data);
          this.showDialog = false;
          this.clearModal();
        } catch (error) {
          this.logError(error)
        }
        this.loading = false;
      }
    },
  },
  computed: {
    // Modal computed
    isDirty() {
      // retorna true si la info ha cambiado
      // returns true if the info has changed
      return !this.$_.isEqual(this.data, this.originalData);
    },
    modalActionsConfig() {
      return {
        disabled_content: this.loading,
        action_accept_config: {
          loading: this.loading,
          disabled: this.storeOnlyReadReport
        },
        action_close_config: {
          disabled: this.loading,
          onlyEmmit: true,
        },
      };
    },
  },
  watch: {
    // Modal watch
    showDialog(value) {
      if (value) {
        this.initModal();
      } else {
        this.$emit("parentActions", "_clean_includes_id", {});
        this.clearModal();
      }
    },
  },
};
</script>