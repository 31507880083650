<template>
  <div>
    <exp-alert-confirm
        ref="modalSaveChanges"
        :message="msg_modal"
        :labelOk="'core.comun.yes'"
        :colorOk="'green'"
        :iconOk="'mdi-check-circle'"
        :fnOk="saveReportSatus"
        :labelCancel="'core.comun.no'"
        :colorCancel="'red'"
        :iconCancel="'mdi-close-circle'"
        :fnCancel="dontSaveChange"
    />
    <v-dialog v-model="showDialog" persistent max-width="700px">
      <exp-loading v-show="isLoading" />
      <v-card :disabled="saving">
        <v-card-title class="headline lighten-2 pb-0">
          {{ $t("ore.report.add_comment") }}
        </v-card-title>
        <ValidationObserver v-slot="{ dirty }" ref="observerComments">
          <form class="form-inventory" @submit.prevent="saveReportSatus()">
            <v-card-text>
              <v-row justify="space-between">
                <v-col cols="12" class="py-0">
                  <ValidationProvider
                    v-slot="{ errors }"
                    :name="$t('ore.report.comments')"
                    rules="required"
                  >
                    <v-textarea
                      rows="3"
                      outlined
                      :error-messages="errors"
                      v-model="data.comment"
                      dense
                      :label="$t('ore.report.comments')"
                      value=""
                    ></v-textarea>
                  </ValidationProvider>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn rounded :disabled="!dirty || storeOnlyReadReport" type="submit" color="green">
                <v-icon left v-if="!saving">mdi-content-save</v-icon>
                <v-progress-circular
                  v-else
                  indeterminate
                  size="24"
                  class="ore-progress-circular-loading"
                ></v-progress-circular>
                {{ $t("core.comun.save") }}&nbsp;
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn rounded @click="cancelBtnForm()" color="red">
                <v-icon left>mdi-close-circle</v-icon>
                {{ $t("core.comun.cancel") }}&nbsp;
              </v-btn>
            </v-card-actions>
          </form>
        </ValidationObserver>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import oreMixin from "@/mixins/ore";
import basicMixin from "@/mixins/basic";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { saveUpdateReportSatus } from "@/gateway/report";
export default {
  name: "modal-add-comments",
  mixins: [basicMixin, oreMixin],
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      showDialog: false,
      saving: false,
      msg_modal: "",
      listInventoryType: [],
      data: {
        comments: "",
      }
    };
  },
  methods: {
    async saveReportSatus() {
      const self = this;
      let valid = await self.$refs.observerComments.validate();
      if (valid) {
        self.saving = true;
        self.data.report = self.reportSelectedId;
        self.data.status = 2
        try {
          await saveUpdateReportSatus(self.data)
        } catch (error) {
          self.logError(error)
        }
        self.showDialog = false;
        self.saving = false;
      }
    },
    async cancelBtnForm() {
      const self = this
      if(self.isDirty){
        self.msg_modal = "ore.report.msg_data_no_save"
        self.$refs.modalSaveChanges.showDialog = true
      } else {
        self.showDialog = false;
      }
    },
    async dontSaveChange() {
      this.$refs.modalSaveChanges.showDialog = false
      this.showDialog = false;
    }
  },
  computed: {
    isDirty() {
      return this.$refs.observerComments.ctx.dirty
    },
  },
  watch: {
    showDialog(value) {
      const self = this
      if (!value) {
        setTimeout(function () {
          self.$refs.observerComments.reset();
        }, 100);
        self.data = {
          comments: '',
          report: null,
        };
      }
    },
  }
};
</script>
