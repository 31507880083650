<template>
  <div>
    <v-snackbar style="z-index:9999;" top right :timeout="timeout" :color="color" v-model="localShow" absolute shaped>
      <span>
        {{ message }}
      </span>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: "exp-snack-bar",
  mixins: [],
  data() {
    return {
      localShow: false,
      message: "",
      timeout: 2000,
      color: "red"
    };
  },
};
</script>

