<template>
  <div>
    <exp-loading v-show="isLoading" />
    <startStopForm
      ref="startstopForm"
      :configTable="configTableEvents"
      :configFormsEvents="configFormsEvents"
      :configForms="configForms"
      :disabledBtnreport="storeOnlyReadReport"
    />
    <exp-alert-confirm
      ref="modalSaveChanges"
      :message="msg_modal"
      :labelOk="'core.comun.yes'"
      :colorOk="'green'"
      :iconOk="'mdi-check-circle'"
      :fnOk="saveStartStop"
      :labelCancel="'core.comun.no'"
      :colorCancel="'red'"
      :iconCancel="'mdi-close-circle'"
      :fnCancel="dontSaveChange"
    />
    <exp-alert-confirm
      ref="modalMessage"
      :message="msg_modal"
      :optionsButtons="{ confirm: false, cancel: true }"
      :labelCancel="'core.comun.close'"
      :colorCancel="'green'"
      :iconCancel="'mdi-check-circle'"
      :fnCancel="closeModalMessage"
    />
    <v-card elevation="0" :color="'transparent'">
      <exp-report-header
        ref="expHeader"
        :color="color"
        :code="''"
        :btnDisabled="storeOnlyReadReport || !isDirty"
        :colorText="colorText"
        :disabledBtnReport="storeOnlyReadReport"
        @onSave="saveStartStop()"
      />
      <ValidationObserver v-if="moduleLoaded" ref="observerStartStop">
        <v-card-text class="table_module">
          <v-card
            class="my-2"
            flat
            style="height: calc(100% - 15px); overflow-y: auto"
          >
            <v-card-text>
              <form class="form-startstop white">
                <v-row>
                  <v-col cols="3">
                    <ValidationProvider
                      v-show="configForms.shift_start.visible"
                      :name="$t('ore.report.shift_start')"
                      :rules="
                        data.production_no
                          ? ''
                          : configForms.shift_start.visible
                          ? 'required'
                          : ''
                      "
                      v-slot="{ errors }"
                    >
                      <exp-date-time
                        name="ore.report.shift_start"
                        v-model="data.shift_start"
                        :type="'datetime'"
                        :label="$t('ore.report.shift_start')"
                        :formatDateTime="crusherFormat.format_date + ' HH:mm'"
                        :error-messages="errors"
                      />
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="3">
                    <ValidationProvider
                      v-if="configForms.production_start.visible"
                      :name="$t('ore.report.production_start')"
                      :rules="data.production_no ? '' : 'required'"
                      v-slot="{ errors }"
                    >
                      <exp-date-time
                        name="ore.report.production_start"
                        :disabled="data.production_no"
                        v-model="data.production_start"
                        :type="'datetime'"
                        :label="$t('ore.report.production_start')"
                        :formatDateTime="crusherFormat.format_date + ' HH:mm'"
                        :error-messages="errors"
                        :hiddeTextDisabled="true"
                      />
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="3">
                    <ValidationProvider
                      v-if="configForms.production_end.visible"
                      :name="$t('ore.report.production_end')"
                      :rules="data.production_no ? '' : 'required'"
                      v-slot="{ errors }"
                    >
                      <exp-date-time
                        name="ore.report.production_end"
                        v-model="data.production_end"
                        :disabled="data.production_no"
                        :type="'datetime'"
                        :label="$t('ore.report.production_end')"
                        :formatDateTime="crusherFormat.format_date + ' HH:mm'"
                        :errorMessages="errors"
                        :hiddeTextDisabled="true"
                      />
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="3">
                    <ValidationProvider
                      v-show="configForms.shift_end.visible"
                      :name="$t('ore.report.shift_end')"
                      :rules="
                        data.production_no
                          ? ''
                          : configForms.shift_end.visible
                          ? 'required'
                          : ''
                      "
                      v-slot="{ errors }"
                    >
                      <exp-date-time
                        name="ore.report.shift_end"
                        v-model="data.shift_end"
                        :type="'datetime'"
                        :label="$t('ore.report.shift_end')"
                        :formatDateTime="crusherFormat.format_date + ' HH:mm'"
                        :error-messages="errors"
                      />
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="3">
                    <v-row>
                      <v-col cols="12">
                        <ValidationProvider
                          v-if="configForms.production_hours.visible"
                          :name="$t('ore.report.production_hours')"
                          rules=""
                          v-slot="{ errors }"
                        >
                          <v-text-field
                            :error="errors.length > 0"
                            :error-messages="errors"
                            class="readonly"
                            :value="
                              startStopProductionHours(data) | secondsToHours
                            "
                            :label="$t('ore.report.production_hours')"
                            outlined
                            dense
                            readonly
                          />
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12">
                        <ValidationProvider
                          v-if="configForms.planned_down_time.visible"
                          :name="$t('ore.report.planned_down_time')"
                          :rules="''"
                          v-slot="{ errors }"
                        >
                          <exp-date-time
                            v-if="configForms.type_planned_down_time.visible"
                            name="ore.report.planned_down_time"
                            v-model="data.planned_down_time"
                            formatTime="H[h] m[m]"
                            :type="'time'"
                            :label="$t('ore.report.planned_down_time')"
                            :error-messages="errors"
                            :hiddeTextDisabled="true"
                          />
                          <v-text-field
                            v-else
                            class="readonly"
                            :value="$moment.duration(data.planned_down_time).as('seconds') | secondsToHours"
                            :label="$t('ore.report.planned_down_time')"
                            outlined
                            dense
                            readonly
                          />
                        </ValidationProvider>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="6">
                    <div v-if="configForms.production_no_razon.visible">
                      <v-btn
                        class="mb-3"
                        depressed
                        block
                        :color="color"
                        @click="toggleProduction()"
                      >
                        <span
                          :style="`color: ${
                            colorText != undefined && colorText != ''
                              ? colorText
                              : 'black'
                          };`"
                        >
                          {{
                            data.production_no
                              ? $t("ore.report.production")
                              : $t("ore.report.no_production")
                          }}
                        </span>
                      </v-btn>
                      <ValidationProvider
                        :name="$t('ore.report.production_no_reason')"
                        :rules="data.production_no ? 'required' : ''"
                        v-slot="{ errors }"
                      >
                        <v-select
                          :error-messages="errors"
                          v-if="data.production_no"
                          :disabled="!data.production_no"
                          v-model="data.production_no_razon"
                          :items="reasons"
                          item-value="id"
                          item-text="name"
                          outlined
                          clearable
                          dense
                          :label="$t('ore.report.production_no_reason')"
                        />
                        <v-card
                          v-else
                          flat
                          color="transparent"
                          height="44px"
                        ></v-card>
                      </ValidationProvider>
                    </div>
                  </v-col>
                  <v-col cols="3">
                    <v-row no-gutters>
                      <v-col
                        :cols="showFlagProductionRunningTime ? '11' : '12'"
                        v-if="
                          configForms.running_time.visible &&
                          configForms.type_running_time.manual
                        "
                      >
                        <ValidationProvider
                          :name="$t('ore.report.running_time')"
                          :rules="data.production_no ? '' : 'required'"
                          v-slot="{ errors }"
                        >
                          <exp-date-time
                            name="ore.report.running_time"
                            v-model="data.running_time"
                            :disabled="data.production_no"
                            formatTime="H[h] m[m]"
                            :type="'time'"
                            :label="$t('ore.report.running_time')"
                            :error-messages="errors"
                            :hiddeTextDisabled="true"
                          />
                        </ValidationProvider>
                      </v-col>
                      <v-col
                        :cols="showFlagProductionRunningTime ? '11' : '12'"
                        v-else-if="
                          configForms.running_time.visible &&
                          !configForms.type_running_time.manual
                        "
                      >
                        <ValidationProvider
                          :name="$t('ore.report.running_time')"
                          :rules="''"
                          v-slot="{ errors }"
                        >
                          <v-text-field
                            class="readonly"
                            :value="running_time_computed | secondsToHours"
                            :label="$t('ore.report.running_time')"
                            outlined
                            dense
                            readonly
                          />
                        </ValidationProvider>
                      </v-col>
                      <v-col
                        class="d-flex align-center"
                        cols="1"
                        v-if="showFlagProductionRunningTime"
                      >
                        <v-icon color="red"> mdi-flag </v-icon>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </form>
            </v-card-text>
            <v-card-text class="px-15">
              <v-divider
                style="border: 2px solid rgb(38, 122, 212)"
              ></v-divider>
            </v-card-text>
            <v-card-text class="py-0">
              <v-row class="d-flex justify-center ma-5">
                <v-col cols="6" class="d-flex justify-center">
                  <v-card
                    color="rgb(255 198 91)"
                    @click="clkFunction('_modal_events', 0)"
                    width="250px"
                    height="120px"
                    class="rounded-lg white--text"
                    flat
                  >
                    <v-row class="pt-3">
                      <v-spacer></v-spacer>
                      <v-card-title class="text-h3 pa-0 py-2 px-2">{{
                        totalEvents
                      }}</v-card-title>
                      <v-card-title class="text-h3 pa-0 py-2 px-2"
                        >+</v-card-title
                      >
                      <v-spacer></v-spacer>
                    </v-row>
                    <v-row>
                      <v-card-title
                        class="font-weight-bold text-h6 pa-0 ml-5"
                        >{{ $t("ore.report.total_events") }}</v-card-title
                      >
                    </v-row>
                    <v-card
                      flat
                      class="ma-0 rounded-t-0"
                      style="position: absolute; width: 100%; bottom: 0px"
                    >
                      <v-divider
                        style="border: 8px solid rgb(189 144 60)"
                      ></v-divider>
                    </v-card>
                  </v-card>
                </v-col>
                <v-col cols="6" class="d-flex justify-center">
                  <v-card
                    color="rgb(15 118 223)"
                    width="250px"
                    height="120px"
                    class="rounded-lg white--text"
                    flat
                    v-if="this.configForms.production_stops.visible"
                  >
                    <v-row class="pt-5 px-5 production-stops-text-field-custom">
                      <ValidationProvider>
                        <exp-mask
                          v-model="data.production_stops"
                          class="input-number num_stops"
                          outlined
                          dense
                          :mask="maskIntegerNumber"
                        />
                      </ValidationProvider>
                    </v-row>
                    <v-row>
                      <v-card-title
                        class="font-weight-bold text-h6 pa-0 ml-5"
                        >{{ $t("ore.report.production_stops") }}</v-card-title
                      >
                    </v-row>
                    <v-card
                      flat
                      class="ma-0 rounded-t-0"
                      style="position: absolute; width: 100%; bottom: 0px"
                    >
                      <v-divider
                        style="border: 8px solid rgb(0 78 161)"
                      ></v-divider>
                    </v-card>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-row class="my-0">
            <v-col class="py-0">
              <expOreCalculationBar
                :items="itemsCalculationBar"
                :color="color"
                :colorText="colorText"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </ValidationObserver>
    </v-card>
  </div>
</template>

<script>
import basicMixin from "@/mixins/basic";
import customerMixin from "@/mixins/customer";
import oreMixin from "@/mixins/ore";
import startStopForm from "./form";
import expDateTime from "@/components/expDateTime.vue";

import { ValidationObserver, ValidationProvider } from "vee-validate";
import {
  getStartStopReport,
  createStartStop,
  updateStartStop,
} from "@/gateway/start_stop";
import { getListItemsByCode } from "@/gateway/lists";

export default {
  name: "page-reports-startstop",
  layout: "colas",
  mixins: [basicMixin, customerMixin, oreMixin],
  components: {
    startStopForm,
    expDateTime,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      isLoading: false,
      saving: false,
      moduleLoaded: false,
      data: {
        production_no: false,
        production_no_razon: null,
        production_start: "",
        production_end: "",
        production_time: "",
        shift_start: "",
        shift_end: "",
        running_time: "",
        production_stops: 0,
      },
      originalData: {},
      isDirty: false,
      color: "blue accent-3",
      colorText: "blue accent-3",
      nextPage: "",
      msg_modal: "",
      reasons: [],
      configFormsEvents: {
        stop_time: { visible: true, disabled: false },
        unresolved: { visible: true, disabled: false },
        restart_time: { visible: true, disabled: false },
        razon: { visible: true, disabled: false },
        category: { visible: false, disabled: false },
        equipment: { visible: false, disabled: false },
        equipment_id:{ visible: false, disabled: false},
        comments: { visible: true, disabled: false },
      },
      configForms: {
        production_end: { visible: true, disabled: false },
        production_hours: { visible: true, disabled: false },
        production_no_razon: { visible: true, disabled: false },
        production_start: { visible: true, disabled: false },
        running_time: { visible: true, disabled: false },
        shift_end: { visible: true, disabled: false },
        shift_start: { visible: true, disabled: false },
        type_running_time: { visible: true, manual: true },
        planned_down_time: { visible: true, disabled: false },
        type_planned_down_time: { visible: true, manual: true },
        production_stops: { visible: true, disabled: false },
      },
      configTableEvents: {
        direction: "v",
        headers: [],
      },
    };
  },
  beforeRouteLeave(to, from, next) {
    const self = this;
    if (!self.isDirty) {
      next();
    } else if (this.storeOnlyReadReport) {
      next();
    } else {
      self.nextPage = to.path;
      next(false);
      self.msg_modal = "ore.report.msg_data_no_save";
      self.$refs.modalSaveChanges.showDialog = true;
    }
  },
  async mounted() {
    const self = this;
    this.moduleLoaded = false;
    let storeConfigModule = this.pageModuleConfig.settingsConfig;
    let localConfigFormEvents = this.$_.clone(this.configFormsEvents);
    let localConfigForm = this.$_.clone(this.configForms);
    let localConfigTableEvents = this.$_.clone(this.configTableEvents);
    this.configFormsEvents = {
      ...localConfigFormEvents,
      ...storeConfigModule.formReception,
    };
    this.configTableEvents = {
      ...localConfigTableEvents,
      ...storeConfigModule.tableReception,
    };
    this.configForms = {
      ...localConfigForm,
      ...storeConfigModule.form,
    };
    self.isLoading = true;
    await self.getLists();
    this.color = this.pageModuleConfig.color || "primary";
    this.colorText = this.pageModuleConfig.color_text || "primary";
    if (self.isCustomersAuthenticated && self.reportSelectedId > 0)
      await self.getAllData();
    this.moduleLoaded = true;
  },
  methods: {
    async getAllData(data = null) {
      const self = this;
      this.isLoading = true;
      let reportSelected = this.$store.getters["ore/getReportSelected"];
      let _data = data != null ? data : reportSelected.start_stop;
      this.originalData = {};
      try {
        if (!this.isset(_data)) {
          _data = await getStartStopReport(this.reportSelectedId);
        }
        this.$store.commit("ore/setUpdateReportsSelected", {
          start_stop: _data,
        });
        if (this.isset(_data)) {
          this.data = this.$_.clone(_data);
          this.originalData = this.$_.cloneDeep(_data);
        }
      } catch (error) {
        this.logError(error);
      }
      this.isLoading = false;
      setTimeout(() => {
        self.$refs.observerStartStop.reset();
        self.isDirty = false;
      }, 10);
    },
    toggleProduction() {
      this.data.production_no = !this.data.production_no;
      if(this.data.production_no == false) {
        if (!this.isset(this.data.production_start) || this.data.production_start == undefined)
          this.data.production_start = this.data.shift_start

          if (!this.isset(this.data.production_end) || this.data.production_end == undefined)
          this.data.production_end = this.data.shift_end
      }
    },
    async saveStartStop() {
      let valid = await this.$refs.observerStartStop.validate();
      if (valid) {
        if (!this.data.production_no) {
          if (
            this.$moment(this.production_end).isBefore(this.production_start)
          ) {
            this.msg_modal = "ore.report.msg_start_stop_1"; //"Production End must be greater than the Production Start""La fin de la production doit étre supérieure au début de la production"
            this.$refs.modalMessage.showDialog = true;
            return;
          } else if (
            this.visibleStartStopShifts &&
            this.$moment(this.shift_end).isBefore(this.production_end)
          ) {
            this.msg_modal = "ore.report.msg_start_stop_2"; //"Shift End must be greater than Production End"  "La fin de l'équipe doit étre supérieure à la fin de la production"
            this.$refs.modalMessage.showDialog = true;
            return;
          } else if (
            this.visibleStartStopShifts &&
            this.$moment(this.production_start).isBefore(this.shift_start)
          ) {
            this.msg_modal = "ore.report.msg_start_stop_3"; //"Production start must be greater than the shift start"  "Le début de la production doit étre supérieur au début de l'équipe"
            this.$refs.modalMessage.showDialog = true;
            return;
          } else if (this.visibleStartStopShifts && this.maximumWorkShift) {
            /* ("diferencia entre shift debe estar dentro de 24H");
              ("difference between shift must be within 24H"); */
            this.msg_modal = "ore.report.msg_start_stop_4"; //"Maximum work shift to be 24 hours"  "Quart de travail maximum ded 24 heures"
            this.$refs.modalMessage.showDialog = true;
            return;
          } else if (
            this.visibleStartStopShifts &&
            this.$moment(this.shift_end).isBefore(this.shift_start)
          ) {
            /* ("shift start debe ser menor que shif end");
              ("shift start must be less than shif end"); */
            this.msg_modal = "ore.report.msg_start_stop_5"; //"End Shift must be greater than the Start Shift"  "Le décalage de fin doit étre supérieur au décalage de début"
            this.$refs.modalMessage.showDialog = true;
            return;
          } else if (
            this.visibleStartStopShifts &&
            this.$moment(this.maxShiftStart).isBefore(
              this.$moment(this.shift_start, this.crusherFormat.format_date)
            )
          ) {
            this.msg_modal = "ore.report.msg_start_stop_6"; //Maximum shift_start must be today
            this.$refs.modalMessage.showDialog = true;
            return;
          }
        } else {
          if (
            this.visibleStartStopShifts &&
            this.$moment(this.maxShiftStart).isBefore(
              this.$moment(this.shift_start, this.crusherFormat.format_date)
            )
          ) {
            this.msg_modal = "ore.report.msg_start_stop_6"; //Maximum shift_start must be today
            this.$refs.modalMessage.showDialog = true;
            return;
          } else if (
            this.visibleStartStopShifts &&
            this.$moment(this.shift_end).isBefore(this.shift_start)
          ) {
            /* ("shif start debe ser menor que shif end");

                ("shift start must be less than shift end");
            */
            this.msg_modal = "ore.report.msg_start_stop_5"; //"End Shift must be greater than the Start Shift"  "Le décalage de fin doit étre supérieur au décalage de début"
            this.$refs.modalMessage.showDialog = true;
            return;
          }
        }
        if (this.data.production_no) {
          this.data.production_start = this.$moment().format(
            this.crusherFormat.format_date + " HH:mm"
          );
          this.data.production_end = this.$moment().format(
            this.crusherFormat.format_date + " HH:mm"
          );
        }
        this.isLoading = true;
        this.saving = true;
        this.data.report = this.reportSelectedId;
        this.data.events_total = this.$moment.utc(this.startStopEventsTotal() * 1000).format("HH:mm");
        if (!this.configForms.type_running_time.manual) {
          if(this.running_time_computed <= 0) {
            this.data.running_time = '00:00'
          }
        }
        this.$store.commit("ore/setUpdateReportsSelected", {
          start_stop: this.data,
        });
        let _data;
        try {
          if (this.data.id) {
            _data = await updateStartStop(this.data.id, this.data);
            this.$refs.observerStartStop.reset();
            this.$refs.modalSaveChanges.showDialog = false;
            this.isDirty = false;
          } else {
            _data = await createStartStop(this.data);
            await this.$refs.observerStartStop.reset();
            this.$refs.modalSaveChanges.showDialog = false;
            this.isDirty = false;
          }
          this.getAllData(_data);
          this.$refs.expHeader.isValidForm = true;
          await this.$refs.expHeader.moduleStatusSave();
          if (this.isset(this.nextPage)) this.openRoute(this.nextPage);
        } catch (error) {
          console.log("catchUpdate2");
          this.logError(error);
        }
      }
      this.saving = false;
      this.isLoading = false;
    },
    async clkFunction(accion) {
      const self = this;
      this.$store.commit("ore/setUpdateReportsSelected", {
        start_stop: this.data,
      });
      switch (accion) {
        case "_modal_events":
          let _startStopTimes = {
            _production_start: self.data.production_no
              ? self.$moment().format("YYYY-MM-DD HH:mm:ss")
              : self
                  .$moment(self.data.production_start)
                  .format("YYYY-MM-DD HH:mm:ss"),
            _production_end: self.data.production_no
              ? null
              : self
                  .$moment(self.data.production_end)
                  .format("YYYY-MM-DD HH:mm:ss"),
            _shift_start: self
              .$moment(self.data.shift_start)
              .format("YYYY-MM-DD HH:mm:ss"),
            _shift_end: self
              .$moment(self.data.shift_end)
              .format("YYYY-MM-DD HH:mm:ss"),
          };
          self.$refs.startstopForm.startStopTimes = _startStopTimes;
          self.$refs.startstopForm.viewStartStopEvents = "list";
          self.$refs.startstopForm.showDialog = true;
          break;
          break;
        default:
          break;
      }
    },
    async dontSaveChange() {
      const self = this;
      this.$store.commit("ore/setUpdateReportsSelected", {
        start_stop: this.originalData,
      });
      await self.$refs.observerStartStop.reset();
      self.$refs.modalSaveChanges.showDialog = false;
      this.isDirty = false;
      self.openRoute(self.nextPage);
    },
    closeModalMessage() {
      this.$refs.modalMessage.showDialog = false;
    },
    async getLists() {
      try {
        this.reasons = this.listTranslate(
          await getListItemsByCode("NPR")
        ).filter((x) => x.deleted == null);
      } catch (error) {
        this.logError(error);
      }
    },
  },
  computed: {
    visibleStartStopShifts() {
      return (
        this.configForms.shift_start.visible &&
        this.configForms.shift_end.visible
      );
    },
    showFlagProductionRunningTime() {
      return this.startStopRunningTime() < this.startStopProductionHours();
    },
    maxShiftStart() {
      return this.$moment(this.$moment().add(1, "days").format("YYYY-MM-DD"))
        .add(-1, "seconds")
        .format("YYYY-MM-DD HH:mm:ss");
    },
    maxShiftEnd() {
      return this.$moment(this.data.shift_start)
        .add(1, "days")
        .format("YYYY-MM-DD HH:mm:ss");
    },
    totalEvents() {
      return this.$store.getters["ore/getReportSelected"].start_stop_events
        .length;
    },
    maximumWorkShift() {
      let _shift_start = this.$moment(this.shift_start);
      let _shift_end = this.$moment(this.shift_end);
      let WorkShiftSeconds = this.$moment
        .duration(_shift_end.diff(_shift_start))
        .as("seconds");
      return WorkShiftSeconds > 86400;
    },
    production_start() {
      return this.$moment(this.data.production_start).format(
        this.crusherFormat.format_date + " HH:mm"
      );
    },
    production_end() {
      return this.$moment(this.data.production_end).format(
        this.crusherFormat.format_date + " HH:mm"
      );
    },
    shift_start() {
      return this.$moment(this.data.shift_start).format(
        this.crusherFormat.format_date + " HH:mm"
      );
    },
    shift_end() {
      return this.$moment(this.data.shift_end).format(
        this.crusherFormat.format_date + " HH:mm"
      );
    },
    itemsCalculationBar() {
      let itemsSummary = this.$_.cloneDeep(
        this.$store.getters["ore/getSummaryReport"](
          this.pageModuleConfig.code
        )[0]
      );
      if (itemsSummary && "summaryBar" in itemsSummary)
        itemsSummary = itemsSummary.summaryBar;
      /* TEMP - Sobreescribir calculos para pasar la data actual y que cambie en tiempo real

          TEMP - Override calculations to pass current data and have it change in real time
      */
      if (itemsSummary) {
        itemsSummary = itemsSummary.map((item) => {
          if (item.name === "Downtime") {
            item.value =
              this.numberToPercent(this.startStopDownTime(this.data)) + "%";
          }
          if (item.name === "Uptime") {
            item.value =
              this.numberToPercent(this.startStopUpTime(this.data)) + "%";
          }
          return item;
        });
        return itemsSummary;
      } else return [];
    },
    onToggleNoproduction(state) {
      //console.log(state);
    },
    running_time_computed() {
      return this.startStopRunningTime(this.data);
    },
    isDirted() {
      // retorna true si la info ha cambiado
      // return true if the info has changed
      return !this.$_.isEqual(this.data, this.originalData);
    },
  },
  watch: {
    data: {
      deep: true,
      handler(newValue, oldValue) {
        try {
          if (
            this.$refs.observerStartStop &&
            this.$refs.observerStartStop.ctx.dirty
          ) {
            this.isDirty = true;
          }
        } catch (e) {
          this.isDirty = false;
        }
      },
    },
    reportSelectedId() {
      this.getAllData();
    },
    "storeLang.lang"(value) {
      if (value) {
        this.getLists();
      }
    },
    running_time_computed(value, old) {
      if (!this.storeGlobalSettingsgModules.start_stop_running_time_manual) {
        if (value !== old) {
          this.data["running_time"] = this.$moment
            .utc(this.running_time_computed * 1000)
            .format("HH:mm:ss");
        }
      }
    },
  },
};
</script>

<style>
.production-stops-text-field-custom
  .v-text-field.v-text-field--enclosed
  .v-text-field__details {
  display: none;
}

.num_stops label.v-label  {
  display: none;
}

.num_stops {
  background: rgb(204 231 240);
}

.date-time-picker .field-label {
  background: white !important;
  top: -10px !important;
  padding: 0px 5px;
}

.date-time-picker .field-input.no-clear-button {
  padding-top: 0 !important;
}
</style>
