import { createQueryBuilder, getRepository } from "typeorm";
import { baseApi, isConfigMobile, getCustomerJWT } from './base'
import axios from 'axios'
import store from "@/store"

/**
 * It is used to get all available equipment maintenance available.
 * @returns {Array} All equipment maintenance available.
 */
export async function getAllEquipmentMaintenance() {
    let isMobile = isConfigMobile()
    if (isMobile) {
        let itemsEquipment = await store.getters['ore/getListEquipmentMaintenance']
        return new Promise(async function (resolve) {
            if (itemsEquipment.length > 0) resolve(itemsEquipment)
            else {
                const equipmentMaintenanceRepository = await getRepository("oreConfigEquipmentMaintenance");
                let _equipmentMaintenance = await equipmentMaintenanceRepository.find({ deleted: null }).then(response => { return response; }).catch(error => { console.log(error); })
                store.commit('ore/setListEquipmentMaintenance', _equipmentMaintenance)
                resolve(_equipmentMaintenance)
            }
        })
    } else {
        let itemsEquipment = await store.getters['ore/getListEquipmentMaintenance']
        return new Promise(function (resolve) {
            if (itemsEquipment.length > 0) resolve(itemsEquipment)
            else {
                const token = getCustomerJWT()
                axios.get(baseApi() + '1.0/ore/equipmentmaintenance/all/', { headers: { Authorization: `Bearer ${token}` } })
                    .then(response => {
                        store.commit('ore/setListEquipmentMaintenance', response.data)
                        resolve(response.data)
                    })
                    .catch(error => reject(error))
            }
        })
    }
}
