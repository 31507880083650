import { mapGetters } from "vuex";
import { filterListItemByCrusher } from "@/gateway/lists";

const oreListsMixin = {
    data() {
        return {
            fullListItem: [],
            groups_list: {
                RAP: ["RCM", "RAP"],
                BIN: ["AGG"],
                AGG: ["AGG"],
                FILLER: ["FILLER", "ADD"],
                GPBT: ["LAC", "BCM"],
            },
        };
    },
    mounted() {
        this.setAllItems();
    },
    computed: {
        ...mapGetters({
            storeReportSelected: "ore/getReportSelected",
            storeComunLists: "comun/getLists",
            storeListSource: "ore/getListSource",
            storeListScenario: "ore/getListScenario",
            storeListCampaign: "ore/getListCampaigns",
            storeListProduct: "ore/getListProducts",
        }),
        campaignReport() {
            let idCampaign = this.fastValidValue(
                "report.campaign",
                undefined,
                this.storeReportSelected
            );
            let _obj = this.storeListCampaign.find((_x) => _x.id === idCampaign);
            return _obj;
        },
    },
    methods: {
        async oreGetGroupList(codeGroup = null, listParentCode = null) {
            let _list_items = [];
            let _codes_group = this.groups_list[codeGroup];
            let listParent = listParentCode
                ? this.storeComunLists.filter((x) => x.code === listParentCode)
                : this.storeComunLists;
            if (!codeGroup || !listParent) return [];
            listParent.forEach((_list) => {
                if (_list && "items" in _list && _list.items) {
                    _list.items.forEach((_list_item) => {
                        if (
                            "extra_data" in _list_item &&
                            _list_item.extra_data &&
                            _list_item.extra_data != "" &&
                            _list_item.extra_data != "{}"
                        ) {
                            let _extra_data = {};
                            try {
                                _extra_data = this.JSONparse(_list_item.extra_data);
                            } catch (error) {
                                _extra_data = {};
                            }
                            if (_extra_data && "group" in _extra_data && _extra_data.group) {
                                let _in_group = _codes_group.find(
                                    (x) => x === _extra_data.group
                                );
                                if (_in_group) _list_items.push(_list_item);
                            }
                        }
                    });
                }
            });
            _list_items = await filterListItemByCrusher(_list_items);
            return _list_items;
        },
        oreGetTranslationListItem(id) {
            let _list_item = this.fullListItem.find((x) => x.id === id);
            let _name_list_item =
                _list_item && "name" in _list_item ? _list_item.name : undefined;
            let translation = _name_list_item
                ? this.$t("core.list_item." + _name_list_item)
                : "";
            return translation;
        },
        setAllItems() {
            let fullItems = [];
            this.storeComunLists.forEach((x) => {
                if(x.items.length != undefined)
                    fullItems = [...fullItems, ...x.items];
            });
            this.fullListItem = fullItems;
        },
        oreGetSourceListByCampaign(campaign = undefined) {
            let _campaign_id = campaign;
            let _list = [];
            if (!_campaign_id) {
                _campaign_id = this.campaignReport ? this.campaignReport.id : undefined;
            }
            if (_campaign_id) {
                _list = this.storeListSource.filter(
                    (_x) => this.fastValidValue("campaign", false, _x) === _campaign_id || _x.campaign === 0
                );
            }
            return _list;
        },
        oreGetProductListByCampaign(campaign = undefined) {
            let _campaign_id = campaign;
            let _list = [];
            if (!_campaign_id) {
                _campaign_id = this.campaignReport ? this.campaignReport.id : undefined;
            }
            if (_campaign_id) {
                _list = this.storeListProduct.filter(
                    (_x) => this.fastValidValue("campaign", false, _x) === _campaign_id
                );
            }
            return _list;
        },
    },
};
export default oreListsMixin;
