<template>
  <div>
    <exp-alert-confirm
      ref="modalSaveChanges"
      :message="msg_modal"
      :labelOk="'core.comun.yes'"
      :colorOk="'green'"
      :iconOk="'mdi-check-circle'"
      :fnOk="saveEnergy"
      :labelCancel="'core.comun.no'"
      :colorCancel="'red'"
      :iconCancel="'mdi-close-circle'"
      :fnCancel="dontSaveChange"
    />
    <v-dialog v-model="showDialog" persistent max-width="700px">
      <v-card :disabled="disabledForm">
        <v-card-title class="headline lighten-2">{{
          $t("ore.report.energy")
        }}</v-card-title>
        <hr class="modal-title-hr" />
        <ValidationObserver v-slot="{ dirty }" ref="observerEnergy">
          <form class="form-list-type" @submit.prevent="saveEnergy()">
            <v-card-text>
              <v-row justify="space-between">
                <v-col class="py-0" cols="8">
                  <v-text-field
                    v-if="isEditing"
                    class="readonly"
                    :value="energyName"
                    :label="$t('ore.report.energy_asigned')"
                    outlined
                    dense
                    readonly
                  ></v-text-field>
                  <ValidationProvider
                    v-else
                    v-slot="{ errors }"
                    :name="$t('ore.report.energy_asigned')"
                    rules="required"
                  >
                    <v-select
                      v-model="valEnergyAssigned"
                      :items="listPivoteEnergyAssigned"
                      item-value="id"
                      item-text="name"
                      :label="$t('ore.report.energy_asigned')"
                      outlined
                      dense
                      :error-messages="errors"
                      @change="setEnergyValues()"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col class="py-0" cols="4" v-if="configForms.units.visible">
                  <v-text-field
                    class="readonly"
                    :value="unitName"
                    :label="$t('ore.report.units')"
                    outlined
                    dense
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col
                  class="py-0"
                  cols="4"
                  v-if="configForms.meter_start.visible"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    :name="$t('ore.report.meter_start')"
                    rules="required"
                  >
                    <exp-mask
                      v-model="data.started"
                      :label="$t('ore.report.meter_start')"
                      class="input-number"
                      outlined
                      dense
                      :error-messages="errors"
                      :mask="maskDecimalNumber"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col
                  class="py-0"
                  cols="4"
                  v-if="configForms.received.visible"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    :name="$t('ore.report.received')"
                    rules=""
                  >
                    <exp-mask
                      v-model="data.received"
                      :label="$t('ore.report.received')"
                      class="input-number"
                      outlined
                      dense
                      :error-messages="errors"
                      :mask="maskDecimalNumber"
                      :readonly="
                        configForms.received.disabled || data.qty_received === 1
                      "
                    />
                  </ValidationProvider>
                </v-col>
                <v-col
                  class="py-0"
                  cols="4"
                  v-if="configForms.meter_end.visible"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    :name="$t('ore.report.meter_end')"
                    rules="required"
                  >
                    <exp-mask
                      v-model="data.remaining"
                      :label="$t('ore.report.meter_end')"
                      class="input-number"
                      outlined
                      dense
                      :error-messages="errors"
                      :mask="maskDecimalNumber"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col
                  class="py-0"
                  cols="4"
                  v-if="configForms.difference.visible"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    :name="$t('ore.report.difference')"
                    rules=""
                  >
                    <exp-mask
                      :value="data.difference"
                      :label="$t('ore.report.difference')"
                      class="input-number"
                      outlined
                      dense
                      readonly
                      :error-messages="errors"
                      :mask="maskDecimalNumber"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col class="py-0" cols="4" v-if="configForms.factor.visible">
                  <exp-mask
                    v-model="data.factor"
                    :label="
                      $t('ore.report.factor') + ' (' + $t(factorName) + ')'
                    "
                    class="input-number"
                    outlined
                    dense
                    readonly
                    :mask="maskDecimalNumber"
                  />
                </v-col>
                <v-col class="py-0" cols="4" v-if="configForms.kwh.visible">
                  <ValidationProvider
                    v-slot="{ errors }"
                    :name="$t('ore.report.kwh')"
                    rules=""
                  >
                    <exp-mask
                      :value="data.kwh"
                      :label="$t('ore.report.kwh')"
                      class="input-number"
                      outlined
                      dense
                      readonly
                      :error-messages="errors"
                      :mask="maskDecimalNumber"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col class="py-0" cols="4" v-if="configForms.btu.visible">
                  <ValidationProvider
                    v-slot="{ errors }"
                    :name="$t('ore.report.btu')"
                    rules=""
                  >
                    <exp-mask
                      :value="data.btu"
                      :label="$t('ore.report.btu')"
                      class="input-number"
                      outlined
                      dense
                      readonly
                      :error-messages="errors"
                      :mask="maskDecimalNumber"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col
                  class="py-0"
                  cols="4"
                  v-if="configForms.start_time.visible"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    :name="$t('ore.report.start_time')"
                    rules="required"
                  >
                    <exp-date-time
                      v-model="data.start_time"
                      :type="'time'"
                      :label="$t('ore.report.start_time')"
                      :error-messages="errors"
                      :hiddeTextDisabled="true"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col
                  class="py-0"
                  cols="4"
                  v-if="configForms.end_time.visible"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    :name="$t('ore.report.end_time')"
                    rules="required"
                  >
                    <exp-date-time
                      v-model="data.end_time"
                      :type="'time'"
                      :label="$t('ore.report.end_time')"
                      :error-messages="errors"
                      :hiddeTextDisabled="true"
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn
                rounded
                :disabled="!dirty || canSaveItem"
                color="green"
                type="submit"
              >
                <v-icon left v-if="!saving">mdi-content-save</v-icon>
                <v-progress-circular
                  v-else
                  indeterminate
                  size="24"
                  class="ore-progress-circular-loading"
                ></v-progress-circular>
                {{ $t("core.comun.save") }}
              </v-btn>
              <span class="red--text" v-if="errorCreateItem != ''"
                >&nbsp; {{ $t("ore.comun." + errorCreateItem) }}</span
              >
              <v-spacer></v-spacer>
              <v-btn
                rounded
                color="red"
                :disabled="saving"
                @click="cancelBtnForm()"
              >
                <v-icon left>mdi-close-circle</v-icon>
                {{ $t("core.comun.cancel") }}
              </v-btn>
            </v-card-actions>
          </form>
        </ValidationObserver>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import basicMixin from "@/mixins/basic";
import oreMixin from "@/mixins/ore";
import customersMixin from "@/mixins/customer";
import expDateTime from "@/components/expDateTime.vue";
import { getListPivotByCode, getListItemsByCode } from "@/gateway/lists";
import { createEnergy, getAllEnergy, updateEnergy } from "@/gateway/energy";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  name: "page-ore-energy-form",
  mixins: [basicMixin, customersMixin, oreMixin],
  components: {
    expDateTime,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      showDialog: false,
      saving: false,
      dataForm: false,
      disabledForm: false,
      disabledField: false,
      allData: [],
      msg_modal: "",
      listEnergyUse: [], // Energy Use
      listEnergySource: [], // Energy Type
      listUnits: [],
      listPivoteEnergyAssigned: [],
      valEnergyAssigned: 0,
      data: {},
    };
  },
  props: {
    configForms: {
      type: Object,
      default: () => {},
    },
    disabledBtnReport: {
      type: Boolean,
      default: false,
    },
    energyItems: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    async getAllData(data = null) {
      const self = this;
      try {
        this.allData = data ? data : await getAllEnergy(this.reportSelectedId);
        this.$store.commit("ore/setUpdateReportsSelected", {
          energy: self.allData,
        });
      } catch (error) {
        this.logError(error)
      }
    },
    async saveEnergy() {
      const self = this;
      let frmValid = await self.$refs.observerEnergy.validate();
      if (frmValid && !self.canSaveItem) {
        self.saving = true;
        self.disabledForm = true;
        self.data.report = self.reportSelectedId;
        self.data.start_time =
          self.reportSelectedDate + " " + self.data.start_time;
        self.data.end_time = self.reportSelectedDate + " " + self.data.end_time;
        let _data;
        try {
          if (self.isset(self.data.id)) {
            _data = await updateEnergy(self.data.id, self.data);
          } else {
            self.data.uuid = self.createUUID();
            _data = await createEnergy(self.data);
          }
          await self.getAllData(_data);
          self.dataForm = true;
          self.showDialog = false;
        } catch (error) {
          self.logError(error)
        }
        self.saving = false;
        self.disabledForm = false;
      }
    },
    async cancelBtnForm() {
      const self = this;
      if (self.isDirty && !self.disabledBtnReport) {
        self.msg_modal = "ore.report.msg_data_no_save";
        self.$refs.modalSaveChanges.showDialog = true;
      } else {
        self.showDialog = false;
      }
    },
    async dontSaveChange() {
      this.$refs.modalSaveChanges.showDialog = false;
      this.showDialog = false;
    },
    setEnergyValues() {
      let _energyAssigned = this.listPivoteEnergyAssigned.find(
        (x) => x.id == this.valEnergyAssigned
      );
      if (_energyAssigned.parent_id != null) {
        let _energyPivoteMaster = this.listPivoteEnergyAssigned.find(
          (x) => x.id == _energyAssigned.parent_id
        );
        let _energyMaster = this.energyItems.find(
          (x) =>
            x.energy_type == _energyPivoteMaster.list_itemb &&
            x.energy_use == _energyPivoteMaster.list_itema
        );
        this.data.master_uuid =
          _energyMaster != undefined ? _energyMaster.uuid : null;
        this.data.is_master = false;
      } else {
        this.data.master_uuid = null;
        this.data.is_master = true;
      }
      this.data.energy_use = _energyAssigned.list_itema;
      this.data.energy_type = _energyAssigned.list_itemb;
    },
    async getLists() {
      let _itema;
      let _itemb;
      let _listPivoteEnergyAssigned = [];
      try {
        this.listEnergyUse = await getListItemsByCode("EUS");
        this.listEnergySource = await getListItemsByCode("ETY"); // Energy Type
        this.listUnits = await getListItemsByCode("EUN");
        _listPivoteEnergyAssigned = await getListPivotByCode("EAS");
      } catch (error) {
        this.logError(error)
      }

      this.listPivoteEnergyAssigned = [];
      _listPivoteEnergyAssigned.forEach((master) => {
        if (master.parent_id == null) {
          _itema = this.listEnergyUse.find((x) => x.id == master.list_itema);
          _itemb = this.listEnergySource.find((x) => x.id == master.list_itemb);
          this.listPivoteEnergyAssigned.push({
            id: master.id,
            name:
              this.$t(
                "core.list_item." + (_itema != undefined ? _itema.name : "")
              ) +
              " - " +
              this.$t(
                "core.list_item." + (_itemb != undefined ? _itemb.name : "")
              ),
            list_itema: master.list_itema,
            list_itemb: master.list_itemb,
            parent_id: master.parent_id,
          });
          _listPivoteEnergyAssigned
            .filter((x) => x.parent_id == master.id)
            .forEach((sub) => {
              _itema = this.listEnergyUse.find((x) => x.id == sub.list_itema);
              _itemb = this.listEnergySource.find(
                (x) => x.id == sub.list_itemb
              );
              this.listPivoteEnergyAssigned.push({
                id: sub.id,
                name:
                  "» » » " +
                  this.$t(
                    "core.list_item." + (_itema != undefined ? _itema.name : "")
                  ) +
                  " - " +
                  this.$t(
                    "core.list_item." + (_itemb != undefined ? _itemb.name : "")
                  ),
                list_itema: sub.list_itema,
                list_itemb: sub.list_itemb,
                parent_id: sub.parent_id,
              });
            });
        }
      });
    },
  },
  async mounted() {
    this.getLists();
  },
  computed: {
    isEditing() {
      let value = false;
      if ("id" in this.data && this.data["id"] != null && this.data[""] != "")
        value = true;
      return value;
    },
    unitName() {
      let unitName = "";
      if (this.data.energy_type > 0) {
        let energySource = this.listEnergySource.find(
          (energySource) => energySource.id == this.data.energy_type
        );
        if (energySource != undefined) {
          try {
            let _extraData = this.JSONparse(energySource.extra_data);
            this.data.factor = _extraData.conversion_factor;
            this.data.factor_type = _extraData.conversion_to;
            this.data.qty_received = _extraData.qty_received;
          } catch {
            this.data.factor = 0;
          }
          let unitItem = this.listUnits.find(
            (unit) => unit.id == energySource.parent_id
          );
          if (unitItem != undefined) {
            unitName =
              this.JSONparse(unitItem.extra_data).short_name || "No-unit";
          } else {
            unitName = "No-unit";
          }
        } else {
          unitName = "No-unit";
        }
      }
      return unitName;
    },
    factorName() {
      let _factorTo = "";
      if (this.data.factor_type == 0) {
        _factorTo = "ore.report.to_but";
      } else {
        _factorTo = "ore.report.to_kwh";
      }
      return _factorTo;
    },
    energyName() {
      let _itema;
      let _itemb;
      let _energyName = "";
      if (this.data.energy_use > 0) {
        _itema = this.listEnergyUse.find((x) => x.id == this.data.energy_use);
        if (_itema != undefined) {
          _energyName += this.$t(
            "core.list_item." + (_itema != undefined ? _itema.name : "")
          );
        }
      }
      _energyName += " - ";
      if (this.data.energy_type > 0) {
        _itemb = this.listEnergySource.find(
          (x) => x.id == this.data.energy_type
        );
        if (_itemb != undefined) {
          _energyName += this.$t(
            "core.list_item." + (_itemb != undefined ? _itemb.name : "")
          );
        }
      }
      return _energyName;
    },
    canSaveItem() {
      return (
        this.disabledBtnReport ||
        this.errorCreateItem != "" ||
        (this.valEnergyAssigned <= 0 && this.data.id == 0)
      );
    },
    errorCreateItem() {
      let msg_not_create = "";
      if (this.data.id > 0) {
      } else {
        let energy_use =
          this.data.energy_use != null ? this.data.energy_use : 0;
        let energy_type =
          this.data.energy_type != null ? this.data.energy_type : 0;
        let master_uuid = this.data.master_uuid;
        if (energy_use > 0 && energy_type > 0) {
          let _item = this.energyItems.find(
              (item) =>
                item.energy_use === energy_use &&
                item.energy_type === energy_type &&
                item.master_uuid === master_uuid
            )
          if (_item != undefined) {
            msg_not_create = "item_already_exists";
          } else {
            if (this.data.is_master == false && this.data.master_uuid == null) {
              msg_not_create = "no_item_master";
            } else {
            }
          }
        } else {
          msg_not_create = "no_use_type_selected";
        }
      }
      return msg_not_create;
    },
    isDirty() {
      return this.$refs.observerEnergy.ctx.dirty;
    },
  },
  watch: {
    async showDialog(isOpen) {
      const self = this;
      if (isOpen === true) {
        self.dataForm = false;
        self.getLists();
        this.valEnergyAssigned = 0;
        this.data.start_time =
          this.$moment(this.data.start_time).format("HH:mm").length > 5
            ? "06:00"
            : this.$moment(this.data.start_time).format("HH:mm");
        this.data.end_time =
          this.$moment(this.data.end_time).format("HH:mm").length > 5
            ? "18:00"
            : this.$moment(this.data.end_time).format("HH:mm");
        setTimeout(function () {
          self.$refs.observerEnergy.reset();
        }, 100);
      } else {
        this.disabledField = false;
        if (self.dataForm) {
          this.$emit("change", self.allData);
        }
      }
    },
    data: {
      handler(data) {
        data.difference = Math.abs(
          parseFloat(data.started) +
            parseFloat(data.received) -
            parseFloat(data.remaining)
        );
        let _calEnergy = parseFloat(data.difference) * parseFloat(data.factor);
        if (data.factor_type == 0) {
          data.btu = _calEnergy;
          data.kwh = _calEnergy * 0.000293071;
        } else {
          data.btu = _calEnergy / 0.000293071;
          data.kwh = _calEnergy;
        }
      },
      deep: true,
    },
  },
};
</script>