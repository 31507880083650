import { createQueryBuilder, getRepository } from "typeorm";
import { baseApi, isConfigMobile, getCustomerJWT, parseDate, updateReportAuditFields } from "./base";
import axios from "axios";
import store from "@/store";
import moment from "moment";

/**
 * It is used to get the record from production hours table
 * @param {Number} reportId - Report Id.
 * @returns {Object} The respective record.
 */
export function getProductionByReport(reportId) {
  let isMobile = isConfigMobile();
  if (isMobile) {
    return new Promise(function (resolve) {
      const production = getRepository("oreReportProductionHours")
        .findOne({ report: reportId, delete: null })
        .then((response) => {
          if (typeof response == "undefined") response = {};
          resolve(response);
        })
        .catch((error) => console.log(error));
    });
  } else {
    return new Promise(function (resolve, reject) {
      const token = getCustomerJWT();
      axios
        .get(baseApi() + "1.0/ore/report/production/" + reportId + "/", {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  }
}

/**
 * It creates a new record in the production hours table
 * @param {Object} data - Object with data to save
 * @returns {Object} The respective production hours record.
 */
export async function createProduction(data) {
  let isMobile = isConfigMobile();
  if (isMobile) {
    const production = getRepository("oreReportProductionHours");
    let now = new Date();
    let user_id = store.getters["auth/getAuthCustomer"].data.id;
    data.update = now;
    data.update_by = user_id;
    data.created = now;
    data.created_by = user_id;
    await production
      .save(data)
      .then((response) => {
        return sync([response]);
      })
      .catch((error) => console.log(error));
  } else {
    return new Promise(function (resolve, reject) {
      const token = getCustomerJWT();
      axios
        .post(baseApi() + "1.0/ore/report/production/", data, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  }
}

/**
 * It updates a record in the production hours table
 * @param {Number} id - Production hours Id.
 * @param {Object} data - Object with data to update
 * @returns {Object} The respective production hours record.
 */
export async function updateProduction(id, data) {
  let isMobile = isConfigMobile();
  if (isMobile) {
    const production = await getRepository("oreReportProductionHours");
    let user_id = store.getters["auth/getAuthCustomer"].data.id;
    await production.update(id, {
      production_hours: data.production_hours,
      reprocessing_hours: data.reprocessing_hours,
      running_time: data.running_time,
      update: new Date(),
      update_by: user_id,
      sync: false,
    });
    await production.find({ id: id }).then((res) => { return sync(res); });
  } else {
    return new Promise(function (resolve, reject) {
      const token = getCustomerJWT();
      axios
        .put(baseApi() + "1.0/ore/report/production/" + id + "/", data, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  }
}

/**
 * It is used to synchronize data from production hours module.
 * It is send from mobile aplication to web aplication
 * @param {Object} data - Object with data to send to web application
 * @returns {Object} The respective production hours record.
 */
async function sync(data) {
  store.commit("comun/setSynchronizing", true);
  const reportRepository = getRepository("oreReport");
  const production = await getRepository("oreReportProductionHours");
  let data_production = parseDate(data);

  let reportData = await reportRepository
    .find({ id: data_production[0].report })
    .then((res) => {
      return parseDate(res);
    });
  await reportRepository
    .update(reportData[0].id, { sync: false })
    .then((res) => {})
    .catch((error) => console.log(error));

  let checkOnLine = store.getters["comun/getIsOnline"];
  if (checkOnLine) {
    let objToSync = {
      reports: [
        {
          report: reportData[0],
          data: { production_hours: data_production[0] },
        },
      ],
    };

    const token = getCustomerJWT();
    await axios
      .post(baseApi() + "1.0/ore/mobile/sync/", objToSync, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(async function (response) {
        console.log("secondAwaitFNResponse", response);
        let id = data_production[0].id;
        let reportRes = response.data.reports[0].report;
        let dataRes = response.data.reports[0].data;

        await updateReportAuditFields(
          reportRes,
          reportRepository,
          reportData[0].id
        );
        if ("production_hours" in dataRes) {
          let _items = [dataRes.production_hours];
          _items.forEach(async (item) => {
            //If the sync is rejected, the item is updated with the information that comes from web.
            if (item.action_msg == "reject") {
              await production
                .update(id, {
                  production_hours: item.production_hours,
                  reprocessing_hours: item.reprocessing_hours,
                  running_time: item.running_time,
                  created: new Date(item.created),
                  created_by: item.created_by,
                  update: new Date(item.update),
                  update_by: item.update_by,
                  web_id: item.web_id,
                  update_web: new Date(item.update_web),
                  sync: true,
                })
                .then((res) => {})
                .catch((error) => console.log(error));
            } else {
              //If it is not rejected and the sync was successful, the variables are updated.
              if (item.action_status) {
                await production
                  .update(id, {
                    web_id: item.web_id,
                    update_web: new Date(item.update_web),
                    sync: true,
                  })
                  .then((res) => {})
                  .catch((error) => console.log(error));
              }
            }
          });
        }

        store.commit("comun/setSynchronizing", false);
        return new Promise(async function (resolve) {
          await production
            .findOne({ id: data_production[0].id })
            .then((response) => {
              resolve(response);
            });
        });
      })
      .catch((error) => {
        store.commit("comun/setSynchronizing", false);
        console.log(error);
        return new Promise(async function (resolve) {
          await production
            .findOne({ id: data_production[0].id })
            .then((response) => {
              resolve(response);
            });
        });
      });
  } else {
    store.commit("comun/setSynchronizing", false);
    return new Promise(async function (resolve) {
      await production
        .findOne({ id: data_production[0].id })
        .then((response) => {
          resolve(response);
        });
    });
  }
}
