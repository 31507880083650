import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import msal from 'vue-msal';

import axios from './plugins/axios';
import { i18n, loadLanguage } from './plugins/i18n';
import lodash from './plugins/lodash';
import moment from './plugins/moment';
import vuetify from './plugins/vuetify';
import toasted from './plugins/toasted';

import "./filters/ore"
import './assets/theme/scss/theme.scss';
import './assets/theme/font/font.css';

// global components
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import expLoading from "@/components/expLoading";
import expAlertConfirm from "@/components/expAlertConfirm";
import expOreCalculationBar from "@/components/expOreCalculationBar";
import expReportHeader from "@/components/expReportHeader";
import expMask from "@/components/expMask";
import configMixin from "@/mixins/config";
import syncbgMixin from "@/mixins/syncbg";


Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker);
Vue.component('expLoading', expLoading);
Vue.component('expAlertConfirm', expAlertConfirm);
Vue.component('expReportHeader', expReportHeader);
Vue.component('expOreCalculationBar', expOreCalculationBar);
Vue.component('ExpMask', expMask);

Vue.use(msal, {
  auth: {
    clientId: 'd9e68db6-9646-40dd-b398-c99f78c658a2'
  }
});

const vm = new Vue({
  axios,
  lodash,
  moment,
  vuetify,
  router,
  store,
  i18n,
  toasted,
  mixins: [configMixin, syncbgMixin],
  beforeCreate() {
    loadLanguage();
  },
  mounted() {
    if (this.isMobile) {
      store.commit('comun/setIsMobile', true);
      store.commit('comun/setIsOnline', true);
      document.addEventListener("offline", () => { store.commit('comun/setIsOnline', false); }, false);
      document.addEventListener("online", () => { store.commit('comun/setIsOnline', true); }, false);

      setInterval(() => {
        this.syncBgFn("SetInterval APP");
      }, 1000 * 60);// * 60 * 5);

    } else {
      store.commit('comun/setIsMobile', false);
      store.commit('comun/setIsOnline', true);
    }
  },
  render: h => h(App)
});

vm.$mount('#app');
