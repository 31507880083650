<template>
  <div class="ore-loading">
    <v-progress-circular
      :size="30"
      :width="4"
      color="blue"
      indeterminate
      class="ore-loading-progress"
    />
    <div class="ore-loading-title">
      <span>
        <slot name="tile">Loading</slot>
      </span>
    </div>
    <div class="ore-loading-message">
      <slot name="message"></slot>
    </div>
  </div>
</template>

<script>
import configMinins from '../mixins/config.js'
import store from "../store";
import { i18n, loadLanguage, availableLocales } from "../plugins/i18n";

export default {
  mixins: [configMinins],
  data() {
    return {};
  },
  mounted () {
    if (this.isMobile) {
      setTimeout(async () => {
        if(this.selectedLocale == 'last') {
          let lastLanguage = store.getters["comun/getLastLanguage"];
          await loadLanguage(lastLanguage ? lastLanguage : availableLocales.locales[0].code);
        }
      }, 200)
    }
  },
  methods: {
    title() {
      let title = this.$t("core.app.loading");
      return title;
    },
  },
  computed: {
    selectedLocale() {
      return i18n.locale;
    }
  },
};
</script>