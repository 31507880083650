<template>
  <v-dialog v-model="showDialog" persistent max-width="700px">
    <exp-loading v-show="isLoading" />
    <exp-alert-confirm ref="delStartStopEvent" :colorCancel="'green'" :message="msg_delete"
      :fnOk="deleteStartStopEventFntModal" />
    <exp-alert-confirm ref="modalSaveChanges" :message="msg_modal" :labelOk="'core.comun.yes'" :colorOk="'green'"
      :iconOk="'mdi-check-circle'" :fnOk="saveStartStopEvent" :labelCancel="'core.comun.no'" :colorCancel="'red'"
      :iconCancel="'mdi-close-circle'" :fnCancel="dontSaveChange" />
    <exp-alert-confirm ref="modalMessage" :message="msg_modal" :optionsButtons="{ confirm: false, cancel: true }"
      :labelCancel="'core.comun.close'" :colorCancel="'green'" :iconCancel="'mdi-check-circle'"
      :fnCancel="closeModalMessage" />
    <v-card class="card-bitumenreception" :disabled="isLoading">
      <v-card-title class="headline lighten-2 pb-0">
        {{ $t("ore.report.event_list") }}
        <v-spacer></v-spacer>
      </v-card-title>
      <hr class="modal-title-hr" />
      <div v-show="viewStartStopEvents == 'list'">
        <v-card-text class="mt-0 pt-0" style="overflow-y: auto">
          <exp-report-table :direction="configTable.direction" :headers="headersTable" :items="items"
            :tableHeight="'300px'" :funcEdit="editStartStopEvent" :funcDelete="deleteStartStopEventFntDT"
            :readOnly="storeOnlyReadReport" :optionsButtons="{ edit: true, delete: !disabledBtnreport }" />
        </v-card-text>
      </div>
      <div v-show="viewStartStopEvents == 'form'">
        <ValidationObserver ref="observerFormStarStop" name="observerFormStarStop" v-slot="{ dirty }">
          <form class="form-material" @submit.prevent="saveStartStopEvent()">
            <v-card-text>
              <v-row class="d-flex align-center">
                <v-col cols="4" class="py-0" v-if="configFormsEvents.stop_time.visible">
                  <ValidationProvider v-slot="{ errors }" :name="$t('ore.report.stop_time')" rules="required">
                    <exp-date-time v-model="data.stop_time" :type="'datetime'" :label="$t('ore.report.stop_time')"
                      :formatDateTime="crusherFormat.format_date + ' HH:mm'" :error-messages="errors"
                      :hiddeTextDisabled="true" />
                  </ValidationProvider>
                </v-col>
                <v-col cols="3" class="py-0" v-if="configFormsEvents.unresolved.visible">
                  <ValidationProvider :name="$t('ore.report.unresolved')" rules="required">
                    <v-switch v-model="data.unresolved" inset :label="data.unresolved
      ? $t('ore.report.unresolved')
      : $t('ore.report.resolved')
    "></v-switch>
                  </ValidationProvider>
                </v-col>
                <v-col cols="4" class="py-0" v-if="configFormsEvents.restart_time.visible">
                  <ValidationProvider v-slot="{ errors }" :name="$t('ore.report.restart_time')"
                    :rules="data.unresolved ? '' : 'required'">
                    <exp-date-time v-model="data.restart_time" :disabled="data.unresolved" :type="'datetime'"
                      :label="$t('ore.report.restart_time')" :formatDateTime="crusherFormat.format_date + ' HH:mm'"
                      :error-messages="errors" :hiddeTextDisabled="hiddenValueRestartTime" />
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" class="py-0" v-if="configFormsEvents.razon.visible">
                  <ValidationProvider v-slot="{ errors }" :name="$t('ore.report.reason')" rules="required">
                    <v-select v-model="data.razon" @change="onChangedRazon" :items="listReasons" item-value="id"
                      item-text="name" outlined dense :label="$t('ore.report.reason')" :error-messages="errors" />
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" class="py-0" v-if="configFormsEvents.category.visible && !isDisabledChooseEquipment
    ">
                  <ValidationProvider v-slot="{ errors }" :name="$t('ore.comun.category')" rules="">
                    <v-select v-model="data.category" :items="listEquipmentCategory" item-value="id" item-text="name"
                      outlined dense :label="$t('ore.comun.category')" :error-messages="errors"
                      @change="getListEquipmentsFiltered" />
                  </ValidationProvider>
                </v-col>

                <v-col cols="12" class="py-0" v-if="configFormsEvents.equipment.visible && !isDisabledChooseEquipment
    ">
                  <ValidationProvider  v-slot="{ errors }" :name="$t('ore.list.equipment')" rules="">
                    <v-select v-if="configFormsEvents.equipment_id.visible" v-model="data.equipment" :items="listEquipmentsFiltered" item-value="id"
                      :item-text="item => `${item.number} - ${item.name}`" outlined dense
                      :label="$t('ore.list.equipment')" :error-messages="errors" />
                    <v-select v-if="!configFormsEvents.equipment_id.visible" v-model="data.equipment" :items="listEquipmentsFiltered" item-value="id" 
                      item-text="name" outlined dense :label="$t('ore.list.equipment')" :error-messages="errors" />
                  </ValidationProvider>
                </v-col>

                <v-col cols="12" class="py-0" v-if="configFormsEvents.comments.visible">
                  <ValidationProvider v-slot="{ errors }" :name="$t('ore.report.comments')" rules="">
                    <v-textarea v-model="data.comments" :error-messages="errors" :rows="3" outlined dense
                      :label="$t('ore.report.comments')" />
                  </ValidationProvider>
                </v-col>
              </v-row>
            </v-card-text>
          </form>
        </ValidationObserver>
      </div>
      <v-card-actions>
        <v-btn rounded color="blue" v-if="viewStartStopEvents == 'list'" @click="clkFunction('_new_event', 0)"
          class="mb-1" :disabled="storeOnlyReadReport">
          <v-icon left>mdi-plus</v-icon>
          {{ $t("ore.report.add_new") }}&nbsp;
        </v-btn>
        <v-btn @click="saveStartStopEvent" rounded color="green" v-if="viewStartStopEvents == 'form'" class="mb-1"
          :disabled="!isDirty || disabledBtnreport">
          <v-icon left v-if="!isLoading">mdi-content-save</v-icon>
          <v-progress-circular v-else indeterminate size="18"
            class="ore-progress-circular-loading"></v-progress-circular>
          {{ $t("core.comun.save") }}&nbsp;
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn rounded @click="showDialog = false" color="red" v-show="viewStartStopEvents == 'list'" class="mb-1">
          <v-icon left>mdi-close-circle</v-icon>
          {{ $t("core.comun.close") }}&nbsp;
        </v-btn>
        <v-btn rounded @click="cancelBtnForm" color="red" v-show="viewStartStopEvents == 'form'" class="mb-1">
          <v-icon left>mdi-close-circle</v-icon>
          {{ $t("core.comun.cancel") }}&nbsp;
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import basicMixin from "@/mixins/basic";
import customerMixin from "@/mixins/customer";
import oreMixin from "@/mixins/ore";
import expDateTime from "@/components/expDateTime.vue";

import { ValidationObserver, ValidationProvider } from "vee-validate";
import { getListItemsByCode } from "@/gateway/lists";
import {
  updateStartStopEvent,
  createStartStopEvent,
  getStartStopEvent,
  deleteStartStopEvent,
  getAllEventsLists,
} from "@/gateway/start_stop_events";
import {
  updateStartStop,
} from "@/gateway/start_stop";

import expReportTable from "@/components/expReportTable";
export default {
  name: "page-reports-startstop-form",
  mixins: [basicMixin, customerMixin, oreMixin],
  components: {
    expDateTime,
    ValidationObserver,
    ValidationProvider,
    expReportTable,
  },
  data() {
    return {
      viewStartStopEvents: "list",
      isLoading: false,
      showDialog: false,
      data: {
        report: this.$store.getters["ore/getReportSelected"].id,
        stop_time: "12:00:00",
        restart_time: "12:00:00",
        unresolved: true,
        razon: 11,
        equipment: 2,
        comments: "Comment",
      },
      dataBack: {},
      hiddenValueRestartTime: true,
      msg_delete: "",
      msg_modal: "",
      isDisabledChooseEquipment: true,
      listEquipmentCategory: [],
      listEquipmentsFiltered: [],
      listReasonsArray: [],
      items: [],
      listTemp: [
        {
          id: 1,
          name: "Razon",
        },
        {
          id: 5,
          name: "Equipment",
        },
      ],
      headers: [
        {
          text: "ore.report.stop_time",
          value: "stop_time",
          width: "80px",
          alignh: "text-center",
          alignb: "text-center",
        },
        {
          text: "ore.report.restart_time",
          value: "restart_time",
          width: "80px",
          alignh: "text-center",
          alignb: "text-center",
        },
        {
          text: "ore.report.down_time",
          value: "down_time",
          width: "80px",
          alignh: "text-center",
          alignb: "text-center",
        },
        {
          text: "ore.report.reason",
          value: "razon",
          alignh: "text-center",
          alignb: "text-center",
        },
        {
          text: "core.comun.options",
          value: "options",
          width: "85px",
          alignh: "text-center",
          alignb: "text-center",
        },
      ],
      startStopTimes: {},
      dataStartStop: null
    };
  },
  props: {
    configForms: {
      type: Object,
      default: () => { },
    },
    configFormsEvents: {
      type: Object,
      default: () => { },
    },
    configTable: {
      type: Object,
      default: () => { },
    },
    disabledBtnreport: {
      type: Boolean,
      default: false,
    },
  },
  async mounted() {
    this.getLists();
    this.dataStartStop = await this.$store.getters["ore/getReportSelected"].start_stop;
  },
  methods: {
    getDifTime(time = null, time2 = null) {
      let y = this.$moment(time, "YYYY-MM-DD HH:mm:ss")
      let x = this.$moment(time2, "YYYY-MM-DD HH:mm:ss")
      let _secconds = this.$moment.duration(x.diff(y)).as('seconds')
      let difFormmat = this.$moment(this.$moment(), "YYYY-MM-DD")
        .startOf("day")
        .seconds(_secconds)
        .format("HH:mm");
      return difFormmat
    },
    async getAllData(data = null) {
      this.isLoading = true;
      let allData = data
        ? data
        : this.$store.getters["ore/getReportSelected"].start_stop_events;
      try {
        if (allData === null) {
          allData = await getAllEventsLists(this.reportSelectedId);
        }
        this.$store.commit("ore/setUpdateReportsSelected", {
          start_stop_events: this.currentData,
        });
        let _data = [];
        this.$_.forEach(allData, (item) => {
          let _razon =
            this.listReasonsArray[
            this.listReasonsArray.findIndex((x) => x.id === item.razon)
            ];
          _data.push({
            id: item.id,
            stop_time: this.$moment(item.stop_time, "YYYY-MM-DDTHH:mm:ss").format(this.crusherFormat.format_date + ' HH:mm'),
            restart_time: item.unresolved ? " - - - " : this.$moment(item.restart_time, "YYYY-MM-DDTHH:mm:ss").format(this.crusherFormat.format_date + ' HH:mm'),
            down_time: this.getDifTime(item.stop_time, item.unresolved ? this.startStopTimes._production_end : item.restart_time),
            razon: _razon ? _razon.name : this.$t("ore.report.no_reason"),
            unresolved: item.unresolved,
          });
        });
        this.items = _data;
        this.$store.commit("ore/setUpdateReportsSelected", {
          start_stop_events: allData,
        });
      } catch (error) {
        this.logError(error)
      }
      setTimeout(() => {
        this.$refs.observerFormStarStop.reset();
      }, 10);
      this.isLoading = false;
    },
    async clkFunction(accion, id) {
      const self = this;
      switch (accion) {
        case "_new_event":
          self.data = {
            stop_time: self.startStopTimes._production_start,
            restart_time: self.startStopTimes._production_end,
            unresolved: true,
            razon: null,
            equipment: null,
            comments: "",
          };
          setTimeout(() => {
            this.$refs.observerFormStarStop.reset();
            this.onChangedRazon(this.data.razon);
          }, 10);
          self.viewStartStopEvents = "form";
          break;
        default:
          break;
      }
    },
    async saveStartStopEvent() {
      const self = this;
      let valid = await self.$refs.observerFormStarStop.validate();
      let _clonedData = this.$_.clone(this.data);
      if (valid) {
        if (this.data.unresolved) {
          _clonedData.restart_time = null
          if (this.configForms.shift_start.visible) {
            if (this.$moment(this.data.stop_time).isBefore(this.startStopTimes._shift_start)) {
              this.msg_modal = this.$t("ore.report.msg_events_1"); //Le STOP TIME doit étre superieur au START SHIFT - The STOP TIME must be greater than the START SHIFT
              this.$refs.modalMessage.showDialog = true;
              return;
            }
          }
        } else {
          if (this.$moment(this.data.stop_time).isBefore(this.startStopTimes._shift_start)) {
            if (this.configForms.shift_start.visible) {
              this.msg_modal = this.$t("ore.report.msg_events_1"); //Le STOP TIME doit étre superieur au START SHIFT - The STOP TIME must be greater than the START SHIFT
              this.$refs.modalMessage.showDialog = true;
              return;
            }
          } else if (this.$moment(this.data.restart_time).isBefore(this.data.stop_time)) {
            this.msg_modal = this.$t("ore.report.msg_events_2"); // L'heure de redémarrage doit étre supérieure à l'heure de l'arrét - The restart time must be greater than the stop time
            this.$refs.modalMessage.showDialog = true;
            return;
          } else if (this.$moment(this.data.restart_time).isAfter(this.startStopTimes._shift_end)) {
            if (this.configForms.shift_end.visible) {
              this.msg_modal = this.$t("ore.report.msg_events_3"); // Le SHIFT END doit étre supérieur au RESTART TIME - The SHIFT END must be greater than the RESTART TIME
              this.$refs.modalMessage.showDialog = true;
              return;
            }
          }
        }
        self.isLoading = true;
        _clonedData["report"] = self.reportSelectedId;
        //_clonedData["running_time"] =
        _clonedData["total_event_time"] = this.startStopEventsTotal();
        let _data;
        try {
          if (_clonedData.id) {
            _data = await updateStartStopEvent(_clonedData.id, _clonedData);
          } else {
            _clonedData.uuid = self.createUUID();
            _data = await createStartStopEvent(_clonedData);
          }
          await self.getAllData(_data);
          self.viewStartStopEvents = "list";

          if (this.dataStartStop != null && this.dataStartStop != undefined) {
            let _running_time = this.$moment.utc(this.startStopRunningTime() * 1000).format("HH:mm");
            let _events_total = this.$moment.utc(this.startStopEventsTotal() * 1000).format("HH:mm");
            this.dataStartStop["running_time"] = _running_time
            this.dataStartStop["events_total"] = _events_total
            await updateStartStop(this.dataStartStop.id, this.dataStartStop);
          }

        } catch (error) {
          this.logError(error)
        }
      }
      self.isLoading = false;
    },
    async editStartStopEvent(id) {
      this.isLoading = true;
      try {
        let data = await getStartStopEvent(id);
        this.data = data;
        this.viewStartStopEvents = "form";
        this.onChangedRazon(data.razon);
        this.getListEquipmentsFiltered(data.category)
        let equipmentData = this.$_.find(this.storeListEquipment, {
          id: data.equipment,
        });
        if (equipmentData) this.data.category = equipmentData.category;
        this.dataBack = this.$_.cloneDeep(data)
        this.hiddenValueRestartTime = this.data.unresolved === true
        this.initRestartTime()
        setTimeout(() => {
          this.$refs.observerFormStarStop.reset();
        }, 10);
      } catch (error) {
        this.logError(error)
      }
      this.isLoading = false;
    },
    deleteStartStopEventFntDT(id) {
      this.msg_delete = "ore.report.confirm_delete_event";
      this.$refs.delStartStopEvent.id = id;
      this.$refs.delStartStopEvent.showDialog = true;
    },
    async deleteStartStopEventFntModal(id) {
      this.isLoading = true;
      try {
        let _data = await deleteStartStopEvent(id);
        await this.getAllData(_data);
        this.dataForm = true;
      } catch (error) {
        this.logError(error)
      }
      this.isLoading = false;
    },
    closeModalMessage() {
      this.$refs.modalMessage.showDialog = false;
    },
    cancelBtnForm() {
      if (this.isDirty && !this.disabledBtnreport) {
        this.msg_modal = "ore.report.msg_data_no_save";
        this.$refs.modalSaveChanges.showDialog = true;
      } else {
        this.viewStartStopEvents = "list";
      }
    },
    async dontSaveChange() {
      this.$refs.modalSaveChanges.showDialog = false;
      this.viewStartStopEvents = "list";
    },
    async getLists() {
      try {
        this.listReasonsArray = await this.listTranslate(
          await getListItemsByCode("REL")
        );
        this.listEquipmentCategory = await this.listTranslate(
          await getListItemsByCode("EQC")
        );
      } catch (error) {
        this.logError(error)
      }
    },
    onChangedRazon(id) {
      let codR = this.$_.find(this.listReasons, { id: id });
      let _isDisabledChooseEquipment = true
      try {
        let _item = JSON.parse(codR.extra_data.replaceAll("'", "\""))
        _isDisabledChooseEquipment = !(_item.assigning_equipment)
      } catch { }
      if (id && codR && _isDisabledChooseEquipment) {
        this.isDisabledChooseEquipment = true;
        this.data.category = null;
        this.data.equipment = null;
      } else {
        this.isDisabledChooseEquipment = false;
      }
    },
    resetForm() {
      this.data.category = null;
      this.isDisabledChooseEquipment = true;
      this.data.razon = null;
      this.hiddenValueRestartTime = true
    },
    initRestartTime() {
      if (!this.$moment(this.data.restart_time).isValid() || this.$moment(this.data.restart_time).isBefore(this.$moment(this.data.stop_time)))
        if ("restart_time" in this.dataBack) {
          if (!this.$moment(this.data.restart_time).isValid()) {
            this.data.restart_time = this.startStopTimes._production_end
          } else {
            this.data.restart_time = this.dataBack.restart_time
          }
        } else {
          this.data.restart_time = this.startStopTimes._production_end
        }
    },
    getListEquipmentsFiltered() {
      let items = this.$_.cloneDeep(this.storeListEquipment);

      let listEquitments = this.$_.filter(items, {
        category: this.data.category,
        crusher: this.storeCrusherId,
      });

      if (listEquitments.find(x => x.id == this.data.equipment) == undefined)
        this.data.equipment = null
      
        this.listEquipmentsFiltered =  listEquitments;
    },
  },
  computed: {
    isDirty() {
      return this.$refs.observerFormStarStop.ctx.dirty;
    },
    listReasons() {
      let _listItems = this.listReasonsArray.filter(x => x.deleted == null && x.published == true);
      if (
        _listItems.length > 0 &&
        !(!this.$_.isNil(this.data.razon) && this.data.razon > 0)
      ) {
        this.data.razon = this.$_.first(_listItems)["id"];
      }
      return _listItems;
    },
    headersTable() {
      let headersConfig = this.configTable.headers;
      let returnHeaders = [];
      for (let item in headersConfig) {
        if (headersConfig[item].visible && item !== "options") {
          let searchWord = item;
          let x = this.$_.find(this.headers, (x) => x.value === searchWord);
          if (x) returnHeaders.push(x);
        }
      }
      let x = this.$_.find(this.headers, (x) => x.value === "options");
      if (x) returnHeaders.push(x);
      return returnHeaders;
    },
  },
  watch: {
    async showDialog(isOpen) {
      const self = this;
      if (isOpen === true) {
        self.isLoading = true;
        await self.getLists();
        await self.getAllData();
        self.isLoading = false;
      } else {
        self.resetForm();
      }
    },
    "data.unresolved"(value) {
      if (value) this.data.restart_time = null;
      this.hiddenValueRestartTime = value
      this.initRestartTime()
    },
    viewStartStopEvents(value) {
      if (value === "list") {
        this.dataBack = {}
        this.resetForm();
      }
    },
  },
};
</script>
