import { createQueryBuilder, getRepository, IsNull } from "typeorm";
import { baseApi, isConfigMobile, getCustomerJWT, parseDate, updateReportAuditFields } from './base'
import axios from 'axios'
import store from "@/store"

/**
 * It is used to upload images to server.
 * @param {File} image - Image to upload.
 * @returns {String} The name of the uploaded file.
 */
export function updloadImage(image) {
    return new Promise(function (resolve) {
        const token = getCustomerJWT()
        axios.post(baseApi() + '1.0/images/upload/', image, { headers: { "Authorization": `Bearer ${token}`, "Content-Type": "multipart/form-data" } })
            .then(response => resolve(response))
            .catch(error => console.log(error))
    })
}


/**
 * It is used to save the image data after uploading.
 * @param {Object} image - Object with the image data to save in the database.
 * @returns {Object} Object with the information of the saved image.
 */
export function saveImage(image) {
    let isMobile = isConfigMobile()
    if (isMobile) {
        return new Promise( async function (resolve) {
            const fileRepository = await getRepository("coreFile");
            let imageSaved = await fileRepository.save(image).then( (response) => { return response }).catch(e => console.log(e))
            resolve(imageSaved)
        })
    } else {
        return new Promise(function (resolve) {
            const token = getCustomerJWT()
            axios.post(baseApi() + '1.0/ore/report/images/', image, { headers: { Authorization: `Bearer ${token}` } })
                .then(response => resolve(response))
                .catch(error => reject(error))
        })
    }
}

/**
 *
 * @param {String} type - Name of the module where the image comes from.
 * @param {String} uuid - It can be the UUID of the report (for the materials module)
 *                        or the UUID of the bitumen reception record (for the bitumen reception module).
 * @returns {Object} Object with the he information of the image.
 */
export function getImages(type, uuid) {
    let isMobile = isConfigMobile()
    if (isMobile) {
        return new Promise( async function (resolve) {
            const fileRepository = await getRepository("coreFile");
            let allImages = await fileRepository.find({ attachment_uuid: uuid, attachment_type: type, deleted: IsNull() }).then(response => { /*console.log("AllimgSave",response);*/ return { data: response } }).catch(e => console.log(e))
            resolve(allImages)
        })
    } else {
        return new Promise(function (resolve) {
            const token = getCustomerJWT()
            axios.get(baseApi() + '1.0/ore/report/images/' + type + "/" + uuid + "/", { headers: { Authorization: `Bearer ${token}` } })
                .then(response => resolve(response))
                .catch(error => console.log(error))
        })
    }
}

/**
 * It makes a soft delete of a record from file table
 * @param {Number} id - Image Id.
 * @returns All images available.
 */
export async function deleteImage(id) {
    let isMobile = isConfigMobile()
    if (isMobile) {
        let user_id = store.getters["auth/getAuthCustomer"].data.id
        const fileRepository = await getRepository("coreFile");
        // it Validate if the item is synchronized (web_id != null)
        let _fileSync = await fileRepository.findOne({ id: id, deleted: null }).then(response => { return response }).catch(e => console.log(e))
        if( _fileSync.web_id == null ){
            await fileRepository.delete(id).then(response => {}).catch(e => console.log(e))
        } else {
            await fileRepository.update(id,{
                update: new Date(),
                update_by: user_id,
                deleted: new Date(),
                deleted_by: user_id,
                sync: false,
            }).then(response => { return response }).catch(e => console.log(e))
            await fileRepository.find({ id: id }).then((res) => { return sync(res); });
        }

    } else {
        return new Promise(function (resolve) {
            const token = getCustomerJWT()
            axios.delete(baseApi() + '1.0/ore/report/images/' + id + "/", { headers: { Authorization: `Bearer ${token}` } })
                .then(response => resolve(response))
                .catch(error => console.log(error))
        })
    }
}

/**
 * It is used to download the image from the web application to the mobile application.
 * @param {String} image - Name of the image
 * @param {String} attachment_type - Name of the module where the image comes from.
 * @returns {String} The path of the downloaded image.
 */
export async function syncImage(image, attachment_type){
    return new Promise( async function (resolve) {
        let fileurl = baseApi() + "1.0/image/" + image + "/";
        let blob = null;
        let xhr = new XMLHttpRequest();
        xhr.open("GET", fileurl);
        xhr.responseType = "blob";//force the HTTP response, response-type header to be blob
        xhr.onload = function()
        {
            blob = xhr.response;
            let filename = image;
            let DataBlob = blob;
            window.resolveLocalFileSystemURL(cordova.file.dataDirectory,
                function(dirEntry) {
                    dirEntry.filesystem.root.getDirectory(attachment_type + "/", {create: true, exclusive: false },
                        function(dir){
                            dir.getFile(filename, { create: true }, function(file) {
                                file.createWriter(function(fileWriter) {
                                    fileWriter.write(DataBlob);
                                    resolve(file.toURL())
                                }, function(err){
                                    console.log(err);
                                });
                            });
                        }, function(e){ console.log("error_create_folder",e); }
                    )
                }, function(e){ console.log("folder_not_found",e); }
            );
        }
        xhr.send();
    })
}

/**
 * It is used to send the data of an image from the mobile application to the web application.
 * @param {Object} image - Object with the image data.
 * @returns {Object} Object with the saved image data.
 */
export async function syncImageToWeb(image){
    return new Promise(function (resolve) {
        const token = getCustomerJWT()
        axios.post(baseApi() + '1.0/ore/report/images/', image, { headers: { Authorization: `Bearer ${token}` } })
            .then(async (response) => {
                let user_id = store.getters["auth/getAuthCustomer"].data.id
                const fileRepository = await getRepository("coreFile");
                await fileRepository.update({ file_name: response.data.data.file_name },{
                    update: new Date(),
                    update_by: user_id,
                    sync: true,
                }).then(response => { return response }).catch(e => console.log(e))
                resolve(response)
            })
            .catch(error => console.log(error))
    })
}

/**
 * It is used to synchronize data of images when an image record is deleted on mobile application.
 * It is send from mobile aplication to web aplication
 * @param {Object} data - Object with data to send to web application.
 * @returns {Array} All records available in the report.
 */
async function sync(data) {
    store.commit("comun/setSynchronizing", true);
    let reportId = store.getters['ore/getReportId']
    const reportRepository = getRepository("oreReport");
    const fileRepository = await getRepository("coreFile");
    let data_file = parseDate(data);

    let reportData = await reportRepository.find({ id: reportId }).then(res => { return parseDate(res) })
    await reportRepository.update(reportId, { sync: false, }).then((res) => {}).catch(error => console.log(error))

    let checkOnLine =  store.getters["comun/getIsOnline"]
    if(checkOnLine){
        let objToSync = {
            reports: [
                {
                    report: reportData[0],
                    data: { core_file: data_file }
                }
            ],
        }
        const token = getCustomerJWT()
        await axios.post(baseApi() + '1.0/ore/mobile/sync/', objToSync, { headers: { Authorization: `Bearer ${token}` } })
            .then(async function (response) {
                let id = data_file[0].id
                let reportRes = response.data.reports[0].report
                let dataRes = response.data.reports[0].data

                await updateReportAuditFields(reportRes, reportRepository, reportId)
                if ("core_file" in dataRes) {
                    let _items = dataRes.core_file
                    _items.forEach(async (item) => {
                        if (item.action_msg != "reject") {
                            //If the sync process is not rejected and the sync was successful, the variables are updated.
                            if (item.action_status) {
                                await fileRepository.update(id,
                                    {
                                        web_id: item.web_id,
                                        update_web: new Date(item.update_web),
                                        sync: true,
                                    },
                                ).then((res) => { }).catch(error => console.log(error))
                            }
                        }
                    })
                }
                return new Promise( function (resolve) {
                    store.commit("comun/setSynchronizing", false);
                    resolve(true)
                })

            }).catch(error => {
                console.log(error);
                return new Promise( function (resolve) {
                    store.commit("comun/setSynchronizing", false);
                    resolve(true)
                })
            })
    } else {
        return new Promise( function (resolve) {
            store.commit("comun/setSynchronizing", false);
            resolve(true)
        })
    }
}