<template>
  <v-dialog v-model="value" :width="width" persistent>
    <v-card>
      <v-card-title><strong>{{ title }}</strong></v-card-title>
      <v-divider></v-divider>
      <v-card-text class="px-2 pb-2 mt-2">
        <slot></slot>
      </v-card-text>
      <v-card-text class="px-2 pb-0">
        <slot name="actions">
          <v-row class="ma-0 pb-2">
            <v-col class="pa-1 text-left" v-if="hasBtnSave">
              <v-btn rounded color="green" @click="clickSave">
                {{ $t(textBtnSave) }}
              </v-btn>
            </v-col>
            <v-col class="pa-1 text-right" v-if="hasBtnCancel">
              <v-btn rounded color="red" @click="clickClose">
                {{ $t(textBtnCancel) }}
              </v-btn>
            </v-col>
          </v-row>
        </slot>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {

    };
  },
  props: {
    value: {
      type: Boolean,
      required: false,
    },
    title: {
      type: String,
      default: "",
    },
    width: {
      type: [String, Number],
      default: "800",
    },
    hasBtnSave: {
      type: Boolean,
      default: true,
    },
    textBtnSave: {
      type: String,
      default: "core.comun.save",
    },
    hasBtnCancel: {
      type: Boolean,
      default: true,
    },
    textBtnCancel: {
      type: String,
      default: "core.comun.cancel",
    },
  },
  methods: {
    clickSave() {
      this.$emit("clickSave");
    },
    clickClose() {
      this.$emit('input', false);
      this.$emit("clickClose");
    },
  }
}
</script>