import { getSyncReport, getReportsLen } from "@/gateway/sync";
import { createQueryBuilder, getRepository } from "typeorm";
import moment from "moment";
import store from "@/store";

const syncReportsMixin = {
    data() {
        return {
            index: 0,
            total_options_saved: 0,
            total_reports: 0,
            total_reports_saved: 0,
            date_sync_manual: ""
        }
    },
    methods: {
        /**
         * It is used to get information from the web application about all the reports and sub-reports tables.
         * If the response is valid, the synchronization date is updated and the data is processed and saved.
         * @returns true
         */
        async syncReportsFn(){
            this.$store.commit("comun/setSynchronizing", true);
            const self = this;
            return new Promise( async function(resolve){
                if (self.checkOnLine())  {
                    if (self.isOnline == true) {

                        const sync = await getRepository("syncDate");
                        let lastSync = await sync.find().then(response => { console.log("DATE",response); return response; }).catch(error => { console.log("error", error) })
                        let _user_id = self.$store.getters["auth/getAuthCustomer"].data.id
                        let _dateSyncSaved = lastSync.filter(x => x.user_id == _user_id)
                        store.commit("comun/setLastSync",moment(_dateSyncSaved[0].date_lists).format("DD/MM/YYYY HH:mm"));
                        let _lastSync = self.date_sync_manual == "" ? lastSync.filter(x => x.user_id == _user_id) : [{ date_report: self.date_sync_manual}]

                        if (_lastSync.length > 0) {
                            self.total_reports = await getReportsLen(_lastSync[0].date_report)
                        } else {
                            self.total_reports = await getReportsLen()
                        }
                        let numPerPage = 300
                        let numSteps = Math.ceil(self.total_reports.reports_len / numPerPage);
                        let i = 0;

                        for await (let i of Array(numSteps).keys()) {
                            let _reports = await getSyncReport(_lastSync[0].date_report,self.index)
                            if(Object.entries(_reports).length > 0 && _reports.data.length > 0){
                                self.index += 1
                                self.total_reports_saved += _reports.data.length
                                await self.saveReportsFn(_reports)
                                let _currentSync = lastSync.filter(x => x.user_id == _user_id)
                                await sync.update(_currentSync[0].id, { date_report: _reports.sync_date, user_id: _user_id, index_sync_report: i});
                            }
                            if(i == numSteps -1) {
                                console.log("NO_MORE_REPORTS")
                                self.index = 0
                                self.date_sync_manual = ""
                                if(Object.entries(_reports).length > 0){
                                    _lastSync = lastSync.filter(x => x.user_id == _user_id)
                                    await sync.update(_lastSync[0].id, { date_report: _reports.sync_date, user_id: _user_id, index_sync_report: 0});
                                    store.commit("comun/setLastSync",moment(_reports.sync_date).format("DD/MM/YYYY HH:mm"));
                                }
                                break;
                            }
                        }

                    }
                }
                self.$store.commit("comun/setSynchronizing", false);
                resolve(true)
            })
        },

        /**
         * It is used to capture a date entered by the user and perform the synchronization process.
         * @param {String} dateSyncManual - Date to synchronizing manually.
         */
        async syncReportsManualFn(dateSyncManual = null){
            this.date_sync_manual = dateSyncManual
            await this.syncReportsFn()
        },

        /**
         * It is used to process the incoming data about reports and sub-reports.
         * This Validates if the data is to insert or update.
         * @param {Object} newDataToSync - Object with the information to process.
         * @returns true
         */
        async saveReportsFn(newDataToSync = {}) {
            const self = this;
            return new Promise(async function (resolve) {
                if (self.checkOnLine())  {
                    if (self.isOnline == true) {

                        const reportRepository = await getRepository("oreReport")
                        const startStopRepository = await getRepository("oreReportStartStop");
                        const startStopEventsRepository = await getRepository("oreReportStartStopEvents");
                        const productionHoursRepository = await getRepository("oreReportProductionHours");
                        const productionManHoursRepository = await getRepository("oreReportProductionManHours");
                        const materialRepository = await getRepository("oreReportMaterialCrushing");
                        const materialActivitiesRepository = await getRepository("oreReportMaterialCrushingActivities");
                        const energyRepository = await getRepository("oreReportEnergy");
                        const inventoryRepository = await getRepository("oreReportInventory");
                        const commentsRepository = await getRepository("oreReportComments");
                        const maintenanceRepository = await getRepository("oreReportMaintenance");
                        const maintenanceDetailsRepository = await getRepository("oreReportMaintenanceDetails");
                        const reportStatusRepository = await getRepository("oreReportStatus");
                        const moduleStatusRepository = await getRepository("oreReportModuleStatus");

                        if(typeof(newDataToSync.data) != 'undefined') {
                            self.messageLoading = "Downloading " + self.total_reports_saved + "/" + self.total_reports.reports_len + " reports";

                            await newDataToSync.data.forEach(async (element) => {
                                let _report = element.report
                                let _modules = element.sub_reports
                                let _reportUpdate = await reportRepository.query(`SELECT * FROM ore_report WHERE uuid = "${_report.uuid}"`)//.find({uuid: _report.uuid}).then(res => { /*console.log(res);*/ return res}).catch(e => console.log(e))
                                //If the incoming report does not have a uuid, it will not be saved.
                                if(_report.uuid != null){
                                    _report.created = _report.created ? moment(_report.created).toISOString() : null
                                    _report.update = moment(_report.update).toISOString()
                                    if(_reportUpdate.length > 0){
                                        await reportRepository.update({uuid: _report.uuid}, {status: _report.status, sync: true, deleted: _report.deleted}).then(res => console.log("upReport",res)).catch(e => console.log(e))
                                        if ("start_stop" in _modules) {
                                            await self.updateModule(_modules.start_stop, startStopRepository,_reportUpdate);
                                        }

                                        if("start_stop_events" in _modules){
                                            await self.updateModule(_modules.start_stop_events,startStopEventsRepository,_reportUpdate);
                                        }

                                        if("production_hours" in _modules){
                                            await self.updateModule(_modules.production_hours,productionHoursRepository,_reportUpdate);
                                        }

                                        if("production_man_hours" in _modules){
                                            await self.updateModule(_modules.production_man_hours,productionManHoursRepository,_reportUpdate);
                                        }

                                        if("material_crushing" in _modules){
                                            await self.updateModule(_modules.material_crushing,materialRepository,_reportUpdate);
                                        }

                                        if("material_crushing_activities" in _modules){
                                            await self.updateModule(_modules.material_crushing_activities,materialActivitiesRepository,_reportUpdate);
                                        }

                                        if("energy" in _modules){
                                            await self.updateModule(_modules.energy,energyRepository,_reportUpdate);
                                        }

                                        if("inventory" in _modules){
                                            await self.updateModule(_modules.inventory,inventoryRepository,_reportUpdate);
                                        }

                                        if("comments" in _modules){
                                            await self.updateModule(_modules.comments,commentsRepository,_reportUpdate);
                                        }

                                        if("maintenance" in _modules){
                                            await self.updateModule(_modules.maintenance,maintenanceRepository,_reportUpdate);
                                        }

                                        if("maintenance_details" in _modules){
                                            await self.updateModule(_modules.maintenance_details,maintenanceDetailsRepository,_reportUpdate);
                                        }

                                        if("report_status" in _modules){
                                            await self.updateModule(_modules.report_status,reportStatusRepository,_reportUpdate);
                                        }

                                        if("module_status" in _modules){
                                            await self.updateModule(_modules.module_status,moduleStatusRepository,_reportUpdate);
                                        }
                                    } else {
                                        _report.web_id = _report.id
                                        _report.date = _report.date.slice(0,10)
                                        _report.sync = true
                                        //let _newReport = await reportRepository.save(_report).then(res => {console.log("saveReport",res); return res }).catch(e => console.log(e))
                                        let _query = await self.createInsertSqlReport(reportRepository,[_report]);
                                        await reportRepository.query(_query)
                                        let _newReport = await reportRepository.find({uuid: _report.uuid}).then(res => { /*console.log("saveReport",res[0]);*/ return res[0]}).catch(e => console.log(e))

                                        if ("start_stop" in _modules) {
                                            await self.saveModule(_modules.start_stop, startStopRepository,_newReport);
                                        }

                                        if("start_stop_events" in _modules){
                                            await self.saveModule(_modules.start_stop_events,startStopEventsRepository,_newReport);
                                        }

                                        if("production_hours" in _modules){
                                            await self.saveModule(_modules.production_hours,productionHoursRepository,_newReport);
                                        }

                                        if("production_man_hours" in _modules){
                                            await self.saveModule(_modules.production_man_hours,productionManHoursRepository,_newReport);
                                        }

                                        if("material_crushing" in _modules){
                                            await self.saveModule(_modules.material_crushing,materialRepository,_newReport);
                                        }

                                        if("material_crushing_activities" in _modules){
                                            await self.saveModule(_modules.material_crushing_activities,materialActivitiesRepository,_newReport);
                                        }

                                        if("energy" in _modules){
                                            await self.saveModule(_modules.energy,energyRepository,_newReport);
                                        }

                                        if("inventory" in _modules){
                                            await self.saveModule(_modules.inventory,inventoryRepository,_newReport);
                                        }


                                        if("comments" in _modules){
                                            await self.saveModule(_modules.comments,commentsRepository,_newReport);
                                        }

                                        if("maintenance" in _modules){
                                            await self.saveModule(_modules.maintenance,maintenanceRepository,_newReport);
                                        }

                                        if("maintenance_details" in _modules){
                                            await self.saveModule(_modules.maintenance_details,maintenanceDetailsRepository,_newReport);
                                        }

                                        if("report_status" in _modules){
                                            await self.saveModule(_modules.report_status,reportStatusRepository,_newReport);
                                        }

                                        if("module_status" in _modules){
                                            await self.saveModule(_modules.module_status,moduleStatusRepository,_newReport);
                                        }
                                    }
                                }
                            });
                        }
                    }
                }
                setTimeout(() => {
                    resolve(true)
                }, 5000);
            })
        },

        /**
         * It is used to process the data to be insert.
         * @param {Array} data - Data to be parsed and saved.
         * @param {Class} repository - Entity to manipulate the model (entitie).
         * @param {Object} newReport - The new report respective.
         */
        async saveModule(data, repository, newReport){
            let _items = data
            let _itemsTosave = []
            await _items.forEach(async (item) => {
                item.report = newReport.id
                item.web_id = item.id
                item.sync = true
                // To avoid problems in safari (IOS)
                if (("created" in item) && item.created != null)
                    item.created = moment.utc(item.created).local().toISOString()

                if (("update" in item) && item.update != null )
                    item.update = moment.utc(item.update).local().toISOString()

                if (("date_time" in item) && item.date_time != null)
                    item.date_time = moment(item.date_time).toISOString();

                if (("production_start" in item) && item.production_start != null)
                    item.production_start = moment(item.production_start).toISOString();

                if (("production_end" in item) && item.production_end != null)
                    item.production_end = moment(item.production_end).toISOString();

                if (("shift_start" in item) && item.shift_start != null)
                    item.shift_start = moment(item.shift_start).toISOString();

                if (("shift_end" in item) && item.shift_end != null)
                    item.shift_end = moment(item.shift_end).toISOString();

                if (("stop_time" in item) && item.stop_time != null)
                    item.stop_time = moment(item.stop_time).toISOString();

                if (("restart_time" in item) && item.restart_time != null)
                    item.restart_time = moment(item.restart_time).toISOString();

                if (("start_time" in item) && item.start_time != null)
                    item.start_time = moment(item.start_time).toISOString();

                if (("end_time" in item) && item.end_time != null)
                    item.end_time = moment(item.end_time).toISOString();

                if(item.uuid != null)
                    _itemsTosave.push(item)
            })
            if(_itemsTosave.length > 0){
                let _query = await this.createInsertSqlReport(repository,_itemsTosave);
                await repository.query(_query)
                this.total_records_saved = _itemsTosave.length
            }
        },

        /**
         * It is used to process the data to be update.
         * @param {Array} data - Data to be parsed and saved.
         * @param {Class} repository - Entity to manipulate the model (entitie).
         * @param {Array} report - The array with the report respective.
         */
        async updateModule(data, repository, report){
            let _items = data
            let _itemsTosave = []
            let _uuidArray = []
            await _items.forEach(async (item) => {
                item.report = report[0].id
                item.web_id = item.id
                item.sync = true
                if(item.uuid != null)
                    _uuidArray.push(item.uuid)
                // To avoid problems in safari (IOS)
                if (("created" in item) && item.created != null)
                    item.created = moment.utc(item.created).local().toISOString()

                if (("update" in item) && item.update != null )
                    item.update = moment.utc(item.update).local().toISOString()

                if (("date_time" in item) && item.date_time != null)
                    item.date_time = moment(item.date_time).toISOString();

                if (("production_start" in item) && item.production_start != null)
                    item.production_start = moment(item.production_start).toISOString();

                if (("production_end" in item) && item.production_end != null)
                    item.production_end = moment(item.production_end).toISOString();

                if (("shift_start" in item) && item.shift_start != null)
                    item.shift_start = moment(item.shift_start).toISOString();

                if (("shift_end" in item) && item.shift_end != null)
                    item.shift_end = moment(item.shift_end).toISOString();

                if (("stop_time" in item) && item.stop_time != null)
                    item.stop_time = moment(item.stop_time).toISOString();

                if (("restart_time" in item) && item.restart_time != null)
                    item.restart_time = moment(item.restart_time).toISOString();

                if (("start_time" in item) && item.start_time != null)
                    item.start_time = moment(item.start_time).toISOString();

                if (("end_time" in item) && item.end_time != null)
                    item.end_time = moment(item.end_time).toISOString();

                if(item.uuid != null)
                    _itemsTosave.push(item)
            })

            if(_uuidArray.length > 0){
                await repository
                .createQueryBuilder()
                .delete()
                .from(repository.metadata.givenTableName)
                .where(`${repository.metadata.givenTableName}.uuid IN (:...uuid)`, { uuid: _uuidArray })
                .execute()
                .then((res) => { console.log("delete"); }).catch(error => console.log(error));;
            }
            if(_itemsTosave.length > 0){
                let _query = await this.createInsertSqlReport(repository,_itemsTosave);
                await repository.query(_query)
                this.total_records_saved = _itemsTosave.length
            }
        },

        /**
         * It is used to create the query to insert data into the mobile application,
         * taking the attributes of the models (entities) and the incoming values.
         * @param {Class} entity - Entity to manipulate the report model.
         * @param {Array} dataToInsert - Data to be saved in the mobile application.
         * @returns {String} - Query to be executed.
         */
        createInsertSqlReport(entity, dataToInsert) {
            let strQuery = "INSERT INTO '" + entity.metadata.givenTableName + "' (";
            for (const [key, value] of Object.entries(entity.metadata.propertiesMap)) {
                if(key != "id")
                    strQuery += "'" + key + "',"
            }
            strQuery = strQuery.substr(0, strQuery.length - 1)
            strQuery += ') VALUES '
            for (let item of dataToInsert) {
                strQuery += "(";
                for (const [key, value] of Object.entries(entity.metadata.propertiesMap)) {
                    if(key != "id"){

                        if ((key == "created") && item[key] != null){
                            item[key] = item[key].slice(0,-1)
                        }

                        if ((key == "update") && item[key] != null){
                            item[key] = item[key].slice(0,-1)
                        }

                        if ((key == "date_time") && item[key] != null){
                            item[key] = item[key].slice(0,-1)
                        }

                        if ((key == "production_start") && item[key] != null){
                            item[key] = item[key].slice(0,-1)
                        }

                        if ((key == "production_end") && item[key] != null){
                            item[key] = item[key].slice(0,-1)
                        }

                        if ((key == "shift_start") && item[key] != null){
                            item[key] = item[key].slice(0,-1)
                        }

                        if ((key == "shift_end") && item[key] != null){
                            item[key] = item[key].slice(0,-1)
                        }

                        if ((key == "stop_time") && item[key] != null){
                            item[key] = item[key].slice(0,-1)
                        }

                        if ((key == "restart_time") && item[key] != null){
                            item[key] = item[key].slice(0,-1)
                        }

                        if ((key == "start_time") && item[key] != null){
                            item[key] = item[key].slice(0,-1)
                        }

                        if ((key == "end_time") && item[key] != null){
                            item[key] = item[key].slice(0,-1)
                        }

                        strQuery += this.fixValue(item[key], key, entity.metadata.ownColumns) + ","
                    }
                }
                strQuery = strQuery.substr(0, strQuery.length - 1)
                strQuery += "),";
            }
            strQuery = strQuery.substr(0, strQuery.length - 1)
            if(entity.metadata.givenTableName == "ore_report_energy"){
                //console.log(strQuery);
            }
            return strQuery;
        },

        /**
         * It is used to fix values and prevent error in the application mobile.
         * @param {Any} _val - The value to be fixed.
         * @param {String} key - The name of the column of the model (entitie).
         * @param {Array} columns - Array of the columns of the model (entitie).
         * @returns The value fixed to be saved in the mobile application.
         */
        fixValue(_val, key, columns) {
            let valReturn = null
            if (!(_val == null || _val == undefined)) {
                if (typeof (_val) == "number" || typeof (_val) == "boolean") {
                    valReturn = _val
                } else {
                    var regex = new RegExp("\"", "g");
                    try {
                        _val = _val.replace(regex, "\'")
                    } catch {
                        console.log(_val)
                    }
                    valReturn = `"${_val}"`
                }
            }

            if (valReturn == null) {
                let _default = columns.find(x => x.databaseName == key)["default"]
                if (_default != undefined) {
                    let _type = columns.find(x => x.databaseName == key)["type"]
                    if (_type == "int") {
                        return parseInt(_default)
                    }
                    else if (_type == "varchar") {
                        return `"${_default}"`
                    } else {
                        return _default
                    }
                } else {
                    return valReturn
                }
            } else {
                return valReturn
            }
        }
    }
}
export default syncReportsMixin