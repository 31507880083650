var EntitySchema = require("typeorm").EntitySchema;

module.exports = new EntitySchema({
    name: "coreFile",
    tableName: "core_file",
    columns: {
        id: {
            primary: true,
            type: "int",
            generated: true
        },
        uuid: {
            type: "varchar",
            length: 50,
            nullable: true
        },
        web_id: {
            type: "int",
            nullable: true
        },
        disk_name: {
            type: "varchar",
            nullable: true
        },
        file_name: {
            type: "varchar",
            nullable: true
        },
        file_size: {
            type: "int",
            nullable: true
        },
        content_type: {
            type: "varchar",
            nullable: true
        },
        title: {
            type: "varchar",
            nullable: true
        },
        description: {
            type: "varchar",
            nullable: true
        },
        field: {
            type: "varchar",
            nullable: true
        },
        attachment_uuid: {
            type: "varchar",
            length: 50,
            nullable: true
        },
        attachment_id: {
            type: "int",
            nullable: true
        },
        attachment_type: {
            type: "varchar",
            nullable: true
        },
        sort_order: {
            type: "int",
            nullable: true
        },
        sync: {
            type: "int",
            default: "0"
        },
        update_web: {
            type: "datetime",
            nullable: true
        },
        created: {
            type: "datetime",
            nullable: true
        },
        created_by: {
            type: "int",
            nullable: true
        },
        update: {
            type: "datetime",
            nullable: true
        },
        update_by: {
            type: "int",
            nullable: true
        },
        deleted: {
            type: "datetime",
            nullable: true
        },
        deleted_by: {
            type: "int",
            nullable: true
        },
        published: {
            type: "int",
            default: "1"
        }
    }
})