<template>
  <div>
    <v-dialog persistent v-model="showDialog" width="900">
      <v-card :height="'600px'" class="d-flex flex-column">
        <v-card :disabled="isSavingData" flat style="overflow: auto">
          <v-card-text class="px-2">
            <v-card
              flat
              height="200px"
              class="my-2"
              style="overflow: auto; border: 1px solid"
            >
              <v-row no-gutters>
                <v-col cols="5" class="text-center">
                  <span class="font-weight-bold">
                    {{ $t("ore.report.things_check") }}
                  </span>
                  <template>
                    <v-btn
                      v-if="locked"
                      icon
                      color="pink"
                      @click="unlockItems('all', 1)"
                    >
                      <v-icon v-if="isAllUnlocked[1]">mdi-lock</v-icon>
                      <v-icon v-else>mdi-lock-open</v-icon>
                    </v-btn>
                  </template>
                  <template>
                    <v-btn
                      v-if="!locked"
                      icon
                      color="pink"
                      @click="unlockItems('all', 1)"
                    >
                      <v-icon v-if="!isAllUnlocked[1]">mdi-eye-off</v-icon>
                      <v-icon v-else>mdi-eye</v-icon>
                    </v-btn>
                  </template>
                </v-col>
                <v-col cols="5"></v-col>
                <v-col cols="2" class="text-center">
                  <span class="font-weight-bold">
                    {{ $t("ore.report.last_date") }}
                  </span>
                </v-col>
              </v-row>
              <v-card flat height="150px" style="overflow: auto">
                <v-card-text class="pa-0">
                  <template v-for="itemVerify in itemsVerify">
                    <v-list-item
                      v-if="locked || !itemVerify.local_var_item_status"
                      :disabled="itemVerify.local_var_item_status || storeOnlyReadReport"
                      :key="itemVerify.id"
                    >
                      <v-row
                        no-gutters
                        :key="itemVerify.id"
                        class="d-flex align-center"
                      >
                        <v-col cols="7">
                          <span class="font-weight-bold text-truncate">{{
                            getItemFrecuency(itemVerify.frecuency).name[0]
                          }}</span>
                          <span>
                            {{ getListItemMaintenance(itemVerify.item).name }}
                          </span>
                        </v-col>
                        <v-col cols="4">
                          <template v-for="itemAction in actionsVerifyType">
                            <v-btn
                              small
                              :key="itemAction.id"
                              :depressed="itemVerify.local_var_item_status"
                              :color="
                                getColorVerifyItem(
                                  itemVerify._value === itemAction.id
                                    ? itemAction.id
                                    : null
                                ).color
                              "
                              class="mx-1"
                              @click="
                                saveMaintenanceDetails(
                                  itemVerify,
                                  itemAction.id
                                )
                              "
                            >
                              {{ itemAction.name }}
                            </v-btn>
                          </template>
                        </v-col>
                        <v-col cols="1" class="text-center text-caption">
                          <span>{{
                            getDateTimeFormated(itemVerify._last_date)
                          }}</span>
                        </v-col>
                      </v-row>
                    </v-list-item>
                  </template>
                </v-card-text>
              </v-card>
            </v-card>
            <v-card
              flat
              height="200px"
              class="my-2"
              style="overflow: auto; border: 1px solid"
            >
              <v-row no-gutters>
                <v-col cols="6" class="text-center">
                  <span class="font-weight-bold">
                    {{ $t("ore.report.item_measure") }}
                  </span>
                  <template>
                    <v-btn
                      v-if="locked"
                      icon
                      color="pink"
                      @click="unlockItems('all', 2)"
                    >
                      <v-icon v-if="isAllUnlocked[2]">mdi-lock</v-icon>
                      <v-icon v-else>mdi-lock-open</v-icon>
                    </v-btn>
                  </template>
                  <template>
                    <v-btn
                      v-if="!locked"
                      icon
                      color="pink"
                      @click="unlockItems('all', 2)"
                    >
                      <v-icon v-if="!isAllUnlocked[2]">mdi-eye-off</v-icon>
                      <v-icon v-else>mdi-eye</v-icon>
                    </v-btn>
                  </template>
                </v-col>
                <v-col cols="6" class="text-center">
                  <v-row no-gutters class="d-flex justify-space-around">
                    <v-col cols="2" class="text-center">
                      <span class="font-weight-bold">{{
                        $t("ore.report.units")
                      }}</span>
                    </v-col>
                    <v-col cols="5" class="text-center">
                      <span class="font-weight-bold">{{
                        $t("ore.report.value")
                      }}</span>
                    </v-col>
                    <v-col cols="5" class="text-center">
                      <span class="font-weight-bold">
                        {{ $t("ore.report.last_date") }}
                      </span>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-card flat height="150px" style="overflow: auto">
                <v-card-text class="pa-0">
                  <template v-for="itemMeasure in itemsMeasure">
                    <v-list-item
                      v-if="locked || !itemMeasure.local_var_item_status"
                      :disabled="itemMeasure.local_var_item_status || storeOnlyReadReport"
                      :key="itemMeasure.id"
                    >
                      <v-row class="d-flex align-center">
                        <v-col cols="6">
                          <span class="font-weight-bold">{{
                            getItemFrecuency(itemMeasure.frecuency).name[0]
                          }}</span>
                          {{ getListItemMaintenance(itemMeasure.item).name }}
                        </v-col>
                        <v-col cols="6" class="text-center">
                          <v-row
                            class="d-flex justify-space-around align-center"
                          >
                            <v-col cols="2">
                              <span>
                                {{ getItemUnit(itemMeasure.unit).name }}
                              </span>
                            </v-col>
                            <v-col
                              cols="5"
                              class="production-stops-text-field-custom"
                            >
                              <v-text-field
                                v-model="itemMeasure._value"
                                outlined
                                dense
                                @focus="
                                  focusFieldValueMaintenance(
                                    itemMeasure,
                                    itemMeasure._value
                                  )
                                "
                                @change="
                                  onChangeTextFieldValue(
                                    itemMeasure,
                                    itemMeasure._value
                                  )
                                "
                              ></v-text-field>
                            </v-col>
                            <v-col
                              v-if="!itemMeasure.local_var_editing_value"
                              cols="3"
                              class="text-center text-caption px-0"
                            >
                              {{ getDateTimeFormated(itemMeasure._last_date) }}
                            </v-col>
                            <v-col v-else cols="5" class="text-center px-0">
                              <v-btn
                                @click="
                                  saveMaintenanceDetails(
                                    itemMeasure,
                                    itemMeasure._value
                                  )
                                "
                                large
                                class="mx-1"
                                text
                                icon
                                color="green"
                              >
                                <v-icon>mdi-content-save</v-icon>
                              </v-btn>
                              <v-btn
                                @click="txtFieldValueClose(itemMeasure)"
                                large
                                class="mx-1"
                                text
                                icon
                                color="red"
                              >
                                <v-icon>mdi-close-circle</v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-list-item>
                  </template>
                </v-card-text>
              </v-card>
            </v-card>
            <v-card
              flat
              height="200px"
              class="my-2"
              style="overflow: auto; border: 1px solid"
            >
              <v-row no-gutters>
                <v-col cols="6" class="text-center">
                  <span class="font-weight-bold">
                    {{ $t("ore.report.item_entry") }}
                  </span>
                  <template>
                    <v-btn
                      v-if="locked"
                      icon
                      color="pink"
                      @click="unlockItems('all', 3)"
                    >
                      <v-icon v-if="isAllUnlocked[3]">mdi-lock</v-icon>
                      <v-icon v-else>mdi-lock-open</v-icon>
                    </v-btn>
                  </template>
                  <template>
                    <v-btn
                      v-if="!locked"
                      icon
                      color="pink"
                      @click="unlockItems('all', 3)"
                    >
                      <v-icon v-if="!isAllUnlocked[3]">mdi-eye-off</v-icon>
                      <v-icon v-else>mdi-eye</v-icon>
                    </v-btn>
                  </template>
                </v-col>
                <v-col cols="6" class="text-center">
                  <v-row no-gutters class="d-flex justify-space-around">
                    <v-col cols="2" class="text-center">
                      <span class="font-weight-bold">{{
                        $t("ore.report.units")
                      }}</span>
                    </v-col>
                    <v-col cols="5" class="text-center">
                      <span class="font-weight-bold">{{
                        $t("ore.report.value")
                      }}</span>
                    </v-col>
                    <v-col cols="5" class="text-center">
                      <span class="font-weight-bold">
                        {{ $t("ore.report.last_date") }}
                      </span>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-card flat height="150px" style="overflow: auto">
                <v-card-text class="pa-0">
                  <template v-for="itemEntry in itemsEntry">
                    <v-list-item
                      v-if="locked || !itemEntry.local_var_item_status"
                      :disabled="itemEntry.local_var_item_status || storeOnlyReadReport"
                      :key="itemEntry.id"
                    >
                      <v-row class="d-flex align-center">
                        <v-col cols="6">
                          <span class="font-weight-bold">{{
                            getItemFrecuency(itemEntry.frecuency).name[0]
                          }}</span>
                          {{ getListItemMaintenance(itemEntry.item).name }}
                        </v-col>
                        <v-col cols="6" class="text-center">
                          <v-row
                            class="d-flex justify-space-around align-center"
                          >
                            <v-col cols="3">
                              <span>
                                {{ getItemUnit(itemEntry.unit).name }}
                              </span>
                            </v-col>
                            <v-col
                              cols="4"
                              class="production-stops-text-field-custom"
                            >
                              <v-text-field
                                v-model="itemEntry._value"
                                outlined
                                dense
                                @focus="
                                  focusFieldValueMaintenance(
                                    itemEntry,
                                    itemEntry._value
                                  )
                                "
                                @change="
                                  onChangeTextFieldValue(
                                    itemEntry,
                                    itemEntry._value
                                  )
                                "
                              ></v-text-field>
                            </v-col>
                            <v-col
                              v-if="!itemEntry.local_var_editing_value"
                              cols="3"
                              class="text-center text-caption px-0"
                            >
                              {{ getDateTimeFormated(itemEntry._last_date) }}
                            </v-col>
                            <v-col v-else cols="5" class="text-center px-0">
                              <v-btn
                                @click="
                                  saveMaintenanceDetails(
                                    itemEntry,
                                    itemEntry._value
                                  )
                                "
                                large
                                class="mx-1"
                                text
                                icon
                                color="green"
                              >
                                <v-icon>mdi-content-save</v-icon>
                              </v-btn>
                              <v-btn
                                @click="txtFieldValueClose(itemEntry)"
                                large
                                class="mx-1"
                                text
                                icon
                                color="red"
                              >
                                <v-icon>mdi-close-circle</v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-list-item>
                  </template>
                </v-card-text>
              </v-card>
            </v-card>
          </v-card-text>
        </v-card>
        <v-spacer></v-spacer>
        <v-divider></v-divider>
        <v-row no-gutters>
          <v-col cols="12">
            <div class="production-stops-text-field-custom">
              <v-textarea
                :disabled="isSavingData"
                v-model="maintenanceData.comments"
                class="pa-2"
                rows="2"
                outlined
                dense
                :label="$t('ore.report.comments')"
              ></v-textarea>
            </div>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-btn
            rounded
            :disabled="disabledBtnReport"
            color="green"
            @click="saveFullMaintenance()"
          >
            <v-icon left v-if="!isSavingData">mdi-content-save</v-icon>
            <v-progress-circular
              v-else
              indeterminate
              size="24"
              class="ore-progress-circular-loading"
            ></v-progress-circular>
            {{ $t("core.comun.save") }}&nbsp;
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="isSavingData"
            rounded
            @click="showDialog = false"
            color="red"
          >
            <v-icon left>mdi-close-circle</v-icon>
            {{ $t("core.comun.cancel") }}&nbsp;
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import oreMixin from "@/mixins/ore";
import basicMixin from "@/mixins/basic";
import expReportTable from "@/components/expReportTable";
import { getListItemsByCode } from "@/gateway/lists";
import { createUpdateFullMaintenance } from "@/gateway/maintenance";

export default {
  name: "modal-verifiy-maintenance",
  mixins: [basicMixin, oreMixin],
  components: {
    expReportTable,
  },
  data() {
    return {
      showDialog: false,
      isSavingData: false,
      isAllUnlocked: {},
      defaultStateDisabledHidden: true,
      colorTabs: "",
      tabsModel: 0,
      typeItem: 1,
      lastValueItem: {},
      realValueItem: {},
      maintenanceData: {
        comments: "",
        equipment: 0,
        remote_id: 0,
        report: 0,
      },
      itemsVerify: [],
      itemsMeasure: [],
      itemsEntry: [],
      units: [],
      listItemsMaintenance: [],
    };
  },
  props: {
    equipmentSelected: {
      type: Object,
      default: () => ({
        equipment_maintenance: [],
        _report_maintenance: {},
      }),
    },
    idReport: {
      type: Number,
      default: 0,
    },
    idCrusher: {
      type: Number,
      default: 0,
    },
    disabledBtnReport: {
      type: Boolean,
      default: false,
    },
    locked: {
      // true: locked, false: hidden
      type: Boolean,
      default: true,
    },
  },
  watch: {
    showDialog(value) {
      if (!value) {
        this.resetModal();
      } else {
        this.getLists();
        this.initData();
      }
    },
  },
  async mounted() {
    this.getLists();
  },
  computed: {
    heightTableHor() {
      return this.isMobile ? "100%" : "calc(100% - 15px)";
    },
    listFrecuency() {
      return [
        { id: 1, name: this.$t("ore.maintenance.daily") },
        { id: 2, name: this.$t("ore.maintenance.weekly") },
        { id: 3, name: this.$t("ore.maintenance.biweekly") },
        { id: 4, name: this.$t("ore.maintenance.monthly") },
        { id: 5, name: this.$t("ore.maintenance.annually") },
      ];
    },
    actionsVerifyType() {
      return [
        { id: 1, name: this.$t("ore.report.ok"), color: "green" },
        { id: 2, name: this.$t("ore.report.monitor"), color: "yellow" },
        { id: 3, name: this.$t("ore.report.repair"), color: "red" },
      ];
    },
    listType() {
      return [
        { id: 1, name: this.$t("ore.maintenance.verify") },
        { id: 3, name: this.$t("ore.maintenance.entry") },
        { id: 2, name: this.$t("ore.maintenance.measure") },
      ];
    },
  },
  methods: {
    getItemFrecuency(id) {
      let data = this.$_.find(this.listFrecuency, (x) => x.id === id);
      if (data) return data;
      else return { name: "" };
    },
    getListItemMaintenance(id) {
      let data = this.$_.find(this.listItemsMaintenance, (x) => x.id === id);
      if (data) {
        data = this.$_.cloneDeep(data);
        data["name"] = this.$t("core.list_item." + data.name);
        return data;
      } else return { name: "" };
    },
    getItemUnit(id) {
      let data = this.$_.find(this.units, (x) => x.id === id);
      if (data) {
        data = this.$_.cloneDeep(data);
        data["name"] = this.$t("core.list_item." + data.name);
        return data;
      } else return { name: "" };
    },
    getColorVerifyItem(type) {
      let actionVerify = this.$_.find(this.actionsVerifyType, (x) => x.id === type);
      if (actionVerify) return actionVerify;
      else return { color: "" };
    },
    initData() {
      this.resetModal();
      let equipmentSelected = this.$_.cloneDeep(this.equipmentSelected);
      if (this.isset(equipmentSelected._report_maintenance.id)) {
        this.maintenanceData = equipmentSelected._report_maintenance;
      } else {
        this.maintenanceData = {
          ...this.maintenanceData,
          equipment: equipmentSelected.id,
          report: this.idReport,
        };
      }
      equipmentSelected.equipment_maintenance.forEach((item) => {
        let itemEq = this.$_.cloneDeep(item);
        // set default status any item
        // locked - hidden: if items are hidden, it is shown by default
        itemEq["local_var_item_status"] = true; //this.locked === false; // Important for hidden items
        // if _remainning_time < 0 It has not met frequency
        // disable the element if _remainning_time < 0
        if (itemEq._remainning_time < 0) {
          // set default state
          //itemEq["local_var_item_status"] = this.$_.clone(this.isAllUnlocked[itemEq.type]);
        }
        // enable the element if it is from this report
        if (!itemEq._is_other_report) itemEq["local_var_item_status"] = false;
        if (item._value > 0) this.realValueItem[itemEq.id] = item._value;
        itemEq["local_var_editing_value"] = false;
        if (itemEq.type === 1) this.itemsVerify.push(itemEq);
        if (itemEq.type === 2) this.itemsMeasure.push(itemEq);
        if (itemEq.type === 3) this.itemsEntry.push(itemEq);
      });
    },
    resetModal() {
      this.isSavingData = false;
      // manual state
      this.defaultStateDisabledHidden = this.locked;
      this.isAllUnlocked[1] = this.$_.clone(this.defaultStateDisabledHidden);
      this.isAllUnlocked[2] = this.$_.clone(this.defaultStateDisabledHidden);
      this.isAllUnlocked[3] = this.$_.clone(this.defaultStateDisabledHidden);
      this.tabsModel = 0;
      this.typeItem = 1;
      this.lastValueItem = {};
      this.realValueItem = {};
      (this.maintenanceData = {
        comments: "",
        equipment: 0,
        remote_id: 0,
        report: 0,
      }),
        (this.itemsVerify = []);
      this.itemsMeasure = [];
      this.itemsEntry = [];
    },
    focusFieldValueMaintenance(item, actualValue) {
      if (
        !item.local_var_editing_value &&
        !this.isset(this.lastValueItem[item.id])
      ) {
        this.lastValueItem[item.id] = actualValue;
      }
      item.local_var_editing_value = true;
    },
    onChangeTextFieldValue(item, lastData) {
      if (!this.isset(this.lastValueItem[item.id])) {
        this.lastValueItem[item.id] = lastData;
      }
    },
    txtFieldValueClose(item) {
      item.local_var_editing_value = false;
      item._value = this.lastValueItem[item.id];
      delete this.lastValueItem[item.id];
    },
    unlockItems(id, type) {
      let data = [];
      this.isAllUnlocked[type] =
        id === "all" && this.isAllUnlocked[type]
          ? !this.isAllUnlocked[type]
          : true;
      if (type === 1) data = this.itemsVerify;
      if (type === 2) data = this.itemsMeasure;
      if (type === 3) data = this.itemsEntry;
      data = data.map((item) => {
        if (item.id === id || id === "all") {
          if (item._remainning_time < 0 && item._is_other_report) {
            if (
              !item.local_var_item_status &&
              this.isset(this.realValueItem[item.id])
            ) {
              item.local_var_editing_value = false;
              // restore actual value
              item._value = this.realValueItem[item.id];
              // restore edit value
              delete this.lastValueItem[item.id];
            }
            // change state
            item.local_var_item_status = !item.local_var_item_status;
          }
        }
      });
    },
    async saveFullMaintenance() {
      if (this.isSavingData) return true;
      this.isSavingData = true;
      let itemsMaintenance = [
        ...this.itemsMeasure,
        ...this.itemsEntry,
        ...this.itemsVerify,
      ];
      let itemsSend = [];
      itemsMaintenance.forEach((item) => {
        let objSend = {
          id: item._maintenance_detail_id,
          // maintenance: null,
          equipment_maintenance: item.id,
          type: item.type,
          value: item._value,
          report: this.idReport,
          equipment: item._equipment,
          uuid: this.createUUID(),
        };
        if (item._value > 0) {
          // If it is from another report, delete the id so that the backend creates it
          if (item._is_other_report) {
            objSend["id"] = null;
          }
          // verify that there are changes
          if (
            this.isset(this.realValueItem[item.id]) &&
            this.realValueItem[item.id] !== item._value
          ) {
            itemsSend.push(objSend);
          } else if (!this.isset(this.realValueItem[item.id])) {
            itemsSend.push(objSend);
          }
        }
      });
      let dataMaintenance = {
        id: this.isset(this.maintenanceData.id)
          ? this.maintenanceData.id
          : null,
        comments: this.maintenanceData.comments,
        report: this.idReport,
        equipment: this.maintenanceData.equipment,
        items: itemsSend,
        _crusher_id: this.idCrusher,
        uuid: this.createUUID(),
      };
      try {
        await createUpdateFullMaintenance(dataMaintenance)
          .then((r) => {
            this.onSavedData();
            this.showDialog = false;
          })
          .catch((e) => {
            this.logError(e)
            console.log(e);
          });
      } catch (error) {
        this.logError(error)
      }
      this.isSavingData = false;
    },
    onSavedData() {
      this.$emit("onSavedData");
    },
    async saveMaintenanceDetails(item, data) {
      // 1 ok, 2 monitor, 3 repair
      if (item.type === 1) this.lastValueItem[item.id] = item._value;
      else if (this.isset(this.lastValueItem[item.id]))
        delete this.lastValueItem[item.id];
      item._value = data;
      item.local_var_editing_value = false;
    },
    async getLists() {
      this.listItemsMaintenance = await getListItemsByCode("MIT");
      this.units = await getListItemsByCode("UNIT");
    },
  },
};
</script>

<style>
.production-stops-text-field-custom
  .v-text-field.v-text-field--enclosed
  .v-text-field__details {
  display: none;
}

.production-stops-text-field-custom .v-input__control .v-input__slot {
  margin-bottom: 0px !important;
}
.v-list-item--disabled {
  pointer-events: none;
  background: #c5c5c5;
}
</style>
