var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"report-table"},[(_vm.direction == 'v')?_c('div',{staticClass:"table-vertical white mt-2",staticStyle:{"display":"flex","height":"calc(100% - 15px)","overflow":"auto","width":"100%"}},[_c('v-simple-table',{attrs:{"fixed-header":"","height":'100%'}},[_c('thead',[_c('tr',_vm._l((_vm.headers),function(header,i){return _c('th',{key:i,staticClass:"pa-1",class:header.alignh,style:('width: ' +
              header.width +
              ';background-color:' +
              (header.value == 'options' ? '#feec00ff' : ''))},[_vm._v(" "+_vm._s(_vm.$t(header.text))+" "),(header.unit != '')?_c('span',[_vm._v(_vm._s(header.unit))]):_vm._e()])}),0)]),_c('tbody',_vm._l((_vm.items),function(item,j){return _c('tr',{key:j},_vm._l((_vm.headers),function(header,k){return _c('td',{key:k,staticClass:"pa-1",class:header.alignb,style:(`width: ${header.width}; background-color: ${
              header.value == 'options' ? '#feec00ff' : ''
            }; cursor: ${
              header.value == 'options'
                ? ''
                : _vm.clickableItem
                ? 'pointer !important;'
                : ''
            }`)},[_vm._t([header.value],function(){return [(header.value == 'options')?[(item.id === _vm.idItemLoading)?_c('v-progress-circular',{staticClass:"ore-progress-circular-loading",attrs:{"indeterminate":""}}):[(_vm.optionsButtons.edit)?_c('v-icon',{staticClass:"icon-option",attrs:{"disabled":_vm.disableIcon(item, 'edit')},on:{"click":function($event){return _vm.funcEdit(item.id)}}},[_vm._v(_vm._s(_vm.readOnly == false ? "mdi-pencil" : "mdi-eye"))]):_vm._e(),(_vm.optionsButtons.delete)?_c('v-icon',{staticClass:"icon-option",attrs:{"disabled":_vm.disableIcon(item, 'delete')},on:{"click":function($event){return _vm.funcDelete(item.id)}}},[_vm._v("mdi-delete")]):_vm._e()]]:[_c('div',{on:{"click":function($event){return _vm.onClickItemDT(item)}}},[_c('span',{domProps:{"innerHTML":_vm._s(item[header.value] != '' ? item[header.value] : '&nbsp;')}})])]]},null,item)],2)}),0)}),0)])],1):_c('div',{staticClass:"table-horizontal white mt-2",staticStyle:{"height":"calc(100% - 15px)"}},[_c('div',{staticClass:"tableh-header",staticStyle:{"height":"100%"}},[_c('div',{staticClass:"v-data-table table-body v-data-table--dense theme--light",staticStyle:{"height":"100%"}},[_c('div',{staticClass:"v-data-table__wrapper",staticStyle:{"height":"100%","overflow-x":"hidden"}},[_c('table',{style:('height: ' + _vm.heightTableHor)},[_c('tbody',_vm._l((_vm.headers),function(header,i){return _c('tr',{key:i,style:((header.value == 'options' ? 'height: 36px' : '') +
                  ' !important; background-color:' +
                  _vm.colorBg +
                  ' !important')},[_c('th',{staticStyle:{"text-align":"left !important"},style:('width: 100%; height: auto !important;color:' +
                    _vm.colorText +
                    ' !important;')},[(header.value != 'options')?[_vm._v(" "+_vm._s(_vm.$t(header.text))+" "),(header.unit != '')?_c('span',[_vm._v(_vm._s(header.unit))]):_vm._e()]:[_vm._m(0,true)]],2)])}),0)])])])]),_c('div',{staticClass:"tableh-body",staticStyle:{"height":"100%"}},[_c('div',{staticClass:"v-data-table table-body v-data-table--dense theme--light",staticStyle:{"height":"100%"}},[_c('div',{staticClass:"v-data-table__wrapper",staticStyle:{"height":"100%"}},[_c('table',{style:('width: calc(150px * ' +
              _vm.items.length +
              ') !important; height: 100%')},[_c('tbody',_vm._l((_vm.headers),function(header,i){return _c('tr',{key:i,class:header.alignh,style:(header.value == 'options' ? 'height: 36px' : '')},_vm._l((_vm.items),function(item,j){return _c('td',{key:j,style:(`width: 150px; height: auto !important;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; cursor: ${
                    header.value == 'options'
                      ? ''
                      : _vm.clickableItem
                      ? 'pointer !important;'
                      : ''
                  };`)},[(header.value == 'options')?[_c('div',{staticClass:"options-h",style:('background-color:' +
                        (header.value == 'options' ? '#feec00ff' : ''))},[(_vm.optionsButtons.edit)?_c('v-icon',{staticClass:"icon-option",attrs:{"disabled":_vm.disableIcon(item, 'edit')},on:{"click":function($event){return _vm.funcEdit(item.id)}}},[_vm._v(_vm._s(_vm.readOnly == false ? "mdi-pencil" : "mdi-eye"))]):_vm._e(),(_vm.optionsButtons.delete)?_c('v-icon',{staticClass:"icon-option",attrs:{"disabled":_vm.disableIcon(item, 'delete')},on:{"click":function($event){return _vm.funcDelete(item.id)}}},[_vm._v("mdi-delete")]):_vm._e()],1)]:[_c('div',{on:{"click":function($event){return _vm.onClickItemDT(item)}}},[_vm._v(" "+_vm._s(item[header.value] != "" ? item[header.value] : " ")+" ")])]],2)}),0)}),0)])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"options-h"},[_c('div',{staticStyle:{"height":"34px"}},[_vm._v(" ")])])
}]

export { render, staticRenderFns }