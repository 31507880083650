import { createQueryBuilder, getRepository } from "typeorm";
import { baseApi, isConfigMobile, getCustomerJWT, parseDate, updateReportAuditFields } from "./base";
import axios from "axios";
import store from "@/store";
import moment from "moment";

/**
 * It is used to get all records from production man hours activities table.
 * @param {Number} reportId - Report Id.
 * @returns {Array} All records available in the report.
 */
export async function getAllProductionItems(reportId) {
  let isMobile = isConfigMobile();
  if (isMobile) {
    return new Promise(async function (resolve) {
      const productionHoursItems = await getRepository(
        "oreReportProductionManHours"
      )
        .find({ report: reportId, deleted: null })
        .then((response) => {
          store.commit("ore/setUpdateReportsSelected", {
            production_man_hours: response,
          });
          resolve(response);
        })
        .catch((error) => console.log(error));
    });
  } else {
    return new Promise(function (resolve, reject) {
      const token = getCustomerJWT();
      axios
        .get(
          baseApi() + "1.0/ore/report/production_items/all/" + reportId + "/",
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  }
}


/**
 * It is used to get the record from production man hours table
 * @param {Number} reportId - Report Id.
 * @returns {Object} The respective record.
 */
export function getProductionItem(id) {
  let isMobile = isConfigMobile();
  if (isMobile) {
    return new Promise(function (resolve) {
      const material = getRepository("oreReportProductionManHours")
        .findOne({ id: id, deleted: null })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => console.log(error));
    });
  } else {
    return new Promise(function (resolve, reject) {
      const token = getCustomerJWT();
      axios
        .get(baseApi() + "1.0/ore/report/production_items/" + id + "/", {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  }
}

/**
 * It makes a soft delete of a record from production man hours activities table
 * @param {Number} id - Production man hours Id.
 * @returns {Array} All records available in the report.
 */
export async function deleteProductionItem(id) {
  let isMobile = isConfigMobile();
  if (isMobile) {
    const productionHoursItems = getRepository("oreReportProductionManHours");
    let user_id = store.getters["auth/getAuthCustomer"].data.id;

    await productionHoursItems
      .update(id, {
        update: new Date(),
        update_by: user_id,
        deleted: new Date(),
        deleted_by: user_id,
        sync: false,
      })
      .then(() => {})
      .catch((error) => console.log(error));
    await productionHoursItems.find({ id: id }).then((res) => {
      console.log("secondAwait");
      return sync(res);
    });
  } else {
    return new Promise(function (resolve, reject) {
      const token = getCustomerJWT();
      axios
        .delete(baseApi() + "1.0/ore/report/production_items/" + id + "/", {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  }
}

/**
 * It creates a new record in the production man hours table
 * @param {Object} data - Object with data to save
 * @returns {Array} All records available in the report.
 */
export async function createProductionItem(data) {
  let isMobile = isConfigMobile();
  if (isMobile) {
    const productionHoursItems = getRepository("oreReportProductionManHours");
    let now = new Date();
    let user_id = store.getters["auth/getAuthCustomer"].data.id;
    data.update = now;
    data.update_by = user_id;
    data.created = now;
    data.created_by = user_id;
    await productionHoursItems
      .save(data)
      .then((response) => {
        return sync([response]);
      })
      .catch((error) => console.log(error));
  } else {
    return new Promise(function (resolve, reject) {
      const token = getCustomerJWT();
      axios
        .post(baseApi() + "1.0/ore/report/production_items/", data, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  }
}

/**
 * It updates a record in the production man hours table
 * @param {Number} id - Production man hours Id.
 * @param {Object} data - Object with data to update
 * @returns {Array} All records available in the report.
 */
export async function updateProductionItem(id, data) {
  let isMobile = isConfigMobile();
  if (isMobile) {
    const productionManHourItems = getRepository("oreReportProductionManHours");
    let user_id = store.getters["auth/getAuthCustomer"].data.id;
    await productionManHourItems.update(id, {
      number_of_men: data.number_of_men,
      hours_worked: data.hours_worked,
      update: new Date(),
      update_by: user_id,
      sync: false,
    });
    await productionManHourItems.find({ id: id, deleted: null }).then((res) => {
      return sync(res);
    });
  } else {
    return new Promise(function (resolve, reject) {
      const token = getCustomerJWT();
      axios
        .put(baseApi() + "1.0/ore/report/production_items/" + id + "/", data, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  }
}

/**
 * It is used to synchronize data from production module.
 * It is send from mobile aplication to web aplication
 * @param {Object} data - Object with data to send to web application
 * @returns {Object} The respective production record.
 */
async function sync(data) {
  store.commit("comun/setSynchronizing", true);
  const reportRepository = getRepository("oreReport");
  const production = await getRepository("oreReportProductionManHours");
  let data_production = parseDate(data);

  let reportData = await reportRepository
    .find({ id: data_production[0].report })
    .then((res) => {
      return parseDate(res);
    });
  await reportRepository
    .update(reportData[0].id, { sync: false })
    .then((res) => {})
    .catch((error) => console.log(error));

  let checkOnLine = store.getters["comun/getIsOnline"];
  if (checkOnLine) {
    let objToSync = {
      reports: [
        {
          report: reportData[0],
          data: { production_man_hours: data_production },
        },
      ],
    };

    const token = getCustomerJWT();
    await axios
      .post(baseApi() + "1.0/ore/mobile/sync/", objToSync, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(async function (response) {
        let id = data_production[0].id;
        let reportRes = response.data.reports[0].report;
        let dataRes = response.data.reports[0].data;

        await updateReportAuditFields(
          reportRes,
          reportRepository,
          reportData[0].id
        );
        if ("production_man_hours" in dataRes) {
          let _items = [dataRes.production_man_hours];
          _items.forEach(async (item) => {
            //If the sync is rejected, the item is updated with the information that comes from web.
            if (item.action_msg == "reject") {
              await production
                .update(id, {
                  number_of_men: 0,
                  hours_worked: 0,
                  created: new Date(item.created),
                  created_by: item.created_by,
                  update: new Date(item.update),
                  update_by: item.update_by,
                  web_id: item.web_id,
                  update_web: new Date(item.update_web),
                  sync: true,
                })
                .then((res) => {
                  console.log("updateProductionItemReject");
                })
                .catch((error) => console.log(error));
            } else {
              //If it is not rejected and the sync was successful, the variables are updated.
              if (item.action_status) {
                await production
                  .update(id, {
                    web_id: item.web_id,
                    update_web: new Date(item.update_web),
                    sync: true,
                  })
                  .then((res) => {
                    console.log("updateProductionItemSync");
                  })
                  .catch((error) => console.log(error));
              }
            }
          });
        }

        store.commit("comun/setSynchronizing", false);
        return getAllProductionItems(data_production[0].report);
      })
      .catch((error) => {
        store.commit("comun/setSynchronizing", false);
        console.log(error);
        return getAllProductionItems(data_production[0].report);
      });
  } else {
    store.commit("comun/setSynchronizing", false);
    return getAllProductionItems(data_production[0].report);
  }
}
