var EntitySchema = require("typeorm").EntitySchema;

module.exports = new EntitySchema({
    name: "syncDate",
    tableName: "sync_date",
    columns: {
        id: {
            primary: true,
            type: "int",
            generated: true
        },
        user_id: {
            type: "int",
            nullable: true
        },
        date_lists: {
            type: "varchar",
            nullable: true
        },
        date_report: {
            type: "varchar",
            nullable: true
        },
        index_sync_report:{
            type: "int",
            nullable: true
        },
        date_files: {
            type: "varchar",
            nullable: true
        },
    }
})