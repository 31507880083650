var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"elevation":"0","color":'transparent'}},[_c('reject-form',{ref:"rejectForm",on:{"change":_vm.reportStatusSave}}),_c('exp-loading',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoading),expression:"isLoading"}]}),_c('v-card-text',{staticClass:"px-0 py-1"},[_c('v-row',{staticClass:"d-flex align-center justify-center sub-report-title",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{style:(`font-size: ${_vm.moduleCode == 'MPD' ? _vm.languageSelected != 'en-US' ? 2.0 + 'rem' : '' : ''}`)},[_vm._v(_vm._s(_vm.$t(_vm.pageTitle)))]),_c('div',{staticClass:"btns-module-action"},[(_vm.moduleCode == 'MSM')?[(!_vm.reportOnlyRead(_vm.reportStatus) && (_vm.moduleCode == 'MSM' && (_vm.reportStatus == 3 || _vm.reportStatus == 2)))?_c('v-btn',{style:(`color: ${
                _vm.colorText != undefined && _vm.colorText != ''
                  ? _vm.colorText
                  : 'black'
              };`),attrs:{"rounded":"","x-large":"","dark":"","color":"blue","disabled":_vm.disabledBtnReport},on:{"click":_vm.reportStatusSave}},[_c('p',{staticClass:"ma-0 pa-0 text-h5"},[_vm._v(" "+_vm._s(_vm.$t("ore.report.submit"))+" ")])]):_vm._e(),(!_vm.reportOnlyRead(_vm.reportStatus) && (_vm.moduleCode == 'MSM' && _vm.reportStatus == 4))?_c('v-btn',{staticStyle:{"float":"left !important"},style:(`color: ${
                _vm.colorText != undefined && _vm.colorText != ''
                  ? _vm.colorText
                  : 'black'
              };`),attrs:{"rounded":"","x-large":"","dark":"","color":"red","disabled":_vm.disabledBtnReport},on:{"click":_vm.reportStatusReject}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-close-circle-outline")]),_c('p',{staticClass:"ma-0 pa-0 text-h5"},[_vm._v(" "+_vm._s(_vm.$t("core.comun.reject"))+" ")])],1):_vm._e(),(!_vm.reportOnlyRead(_vm.reportStatus) && (_vm.moduleCode == 'MSM' && _vm.reportStatus == 4))?_c('v-btn',{style:(`color: ${
                _vm.colorText != undefined && _vm.colorText != ''
                  ? _vm.colorText
                  : 'black'
              };`),attrs:{"rounded":"","x-large":"","dark":"","color":"green","disabled":_vm.disabledBtnReport},on:{"click":function($event){return _vm.reportStatusSave(1)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-check-circle-outline")]),_c('p',{staticClass:"ma-0 pa-0 text-h5"},[_vm._v(" "+_vm._s(_vm.$t("core.comun.accept"))+" ")])],1):_vm._e()]:[(_vm.moduleCode !== 'MSL')?_c('v-btn',{staticClass:"ml-5",style:(`color: ${
                _vm.colorText != undefined && _vm.colorText != ''
                  ? _vm.colorText
                  : 'black'
              };`),attrs:{"color":_vm.color || 'primary',"rounded":"","x-large":"","disabled":_vm.btnDisabled || _vm.disabledBtnReport},on:{"click":_vm.moduleStatusSave}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-content-save-outline")]),_c('p',{staticClass:"ma-0 pa-0 text-h5"},[_vm._v(" "+_vm._s(_vm.$t("core.comun.save"))+" ")])],1):_vm._e()]],2)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }