<template>
  <v-app class="ore-index">
    <exp-loading v-show="isLoading" />
    <modal-status ref="modalStatus" @change="filterReport" />
    <modal-info-log ref="modalInfoLog" />
    <modal-images ref="modalImages" />
    <div fluid>
      <dash-filters
        :itemsStatusReports="itemsStatusReports"
        :formatDate="formatDate"
        @filtersReport="filtersReport"
        @clickLogo="clickLogo"
      />
      <dash-table
        :itemsStatusReports="itemsStatusReports"
        :filtes="filters"
        :formatDate="formatDate"
        :itemsTable="itemsTable"
        :infoPagination="infoPagination"
        :optionsPagination="options"
        :showId="showId"
        @filtersReport="pageReport"
        @editReport="editReport"
        @openModalStatus="openModalStatus"
        @openModalImages="openModalImages"
        @openModalStatusLog="openModalStatusLog"
        @confirmDeleteReport="confirmDeleteReport"
      >
        <v-col cols="3" class="pl-5 text-right">
          <v-btn
            color="#feec00ff"
            class="ml-4 mt-2"
            @click="openModalNewReport"
            :disabled="filters.regions.length == 0 || reportOnlyRead(0)"
          >
            <v-icon left>mdi-plus</v-icon>
            {{ $t("ore.report.add_new") }}
          </v-btn>
        </v-col>
        <v-col cols="1" class="pl-5 text-right">
          <v-btn class="mt-2 mr-3" fab dark small @click="openDocumentation()">
            <v-icon blue darken-4>
              mdi-exclamation-thick
            </v-icon>
          </v-btn>
        </v-col>
      </dash-table>
    </div>
    <exp-alert-confirm
      ref="delReport"
      :colorCancel="'green'"
      :message="'ore.report.confirm_delete_report'"
      :fnOk="deleteReport"
    />
    <v-dialog v-model="modalNewReport" persistent max-width="300px">
      <v-card class="card-newreport">
        <v-card-title class="headline lighten-2">{{
          $t("ore.report.create_new")
        }}</v-card-title>
        <ValidationObserver ref="observerCreateReport">
          <form action="" method="post" class="form-list-type">
            <v-card-text>
              <v-row justify="space-between">
                <v-col class="py-1">
                  <ValidationProvider
                    v-slot="{ errors }"
                    :name="$t('ore.site.sites')"
                    rules="required"
                  >
                    <v-autocomplete
                      v-model="newReport.site"
                      :items="itemsSelectedSitesNames"
                      item-value="id"
                      item-text="name"
                      :label="$t('ore.site.sites')"
                      :error-messages="errors"
                      :disabled="itemsSelectedSitesNames.length <= 1"
                      hide-details="true"
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row justify="space-between">
                <v-col class="py-1">
                  <ValidationProvider
                    v-slot="{ errors }"
                    :name="$t('ore.report.campaign_crusher')"
                    rules="required"
                  >
                    <v-autocomplete
                      v-model="newReport.campaign"
                      :items="itemsSeletedCampaignsNames"
                      item-value="id"
                      item-text="name"
                      :label="$t('ore.report.campaign_crusher')"
                      :error-messages="errors"
                      :disabled="itemsSeletedCampaignsNames.length <= 1"
                      hide-details="true"
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row justify="space-between">
                <v-col class="py-1 pb-2">
                  <ValidationProvider
                    v-slot="{ errors }"
                    :name="$t('ore.report.scenario')"
                    rules="required"
                  >
                    <v-autocomplete
                      v-model="newReport.scenario"
                      :items="itemsSeletedScenariosNames"
                      item-value="id"
                      item-text="name"
                      :label="$t('ore.report.scenario')"
                      :error-messages="errors"
                      :disabled="itemsSeletedScenariosNames.length <= 0"
                      @change="checkExistingReport()"
                      hide-details="true"
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row justify="space-between">
                <v-col cols="7">
                  <exp-date-time
                    :name="$t('ore.report.date')"
                    v-model="newReport.date"
                    :type="'date'"
                    :label="$t('ore.report.date')"
                    @change="checkExistingReport()"
                    :max-date="$moment().format('YYYY-MM-DD')"
                    :formatDate="this.formatDate"
                    :backgroundColor="'#feec00ff'"
                  />
                </v-col>
                <v-col cols="5">
                  <v-switch
                    v-model="newReport.shift"
                    inset
                    color="dafault"
                    :label="$t('ore.report.shift')"
                    @change="checkExistingReport()"
                    class="switch-shift"
                  >
                    <template v-slot:label>
                      <v-icon large color="orange" v-if="newReport.shift"
                        >mdi-weather-sunny</v-icon
                      >
                      <v-icon large color="purple" v-else
                        >mdi-weather-night</v-icon
                      >
                    </template>
                  </v-switch>
                </v-col>
              </v-row>
              <v-row
                v-if="msg_state_report && msg_state_report !== ''"
                no-gutters
                class="d-flex text-center"
              >
                <span class="red--text text--lighten-1">{{
                  msg_state_report
                }}</span>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn
                rounded
                color="green"
                :disabled="!(newReport.state && canCreateReport)"
                @click="createReport()"
              >
                <v-icon left v-if="!creatingReport">mdi-content-save</v-icon>
                <v-progress-circular
                  v-else
                  indeterminate
                  size="24"
                  class="ore-progress-circular-loading"
                ></v-progress-circular>
                {{ $t("core.comun.create") }}
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn rounded color="red" @click="modalNewReport = false">
                <v-icon left>mdi-close-circle</v-icon>
                {{ $t("core.comun.cancel") }}
              </v-btn>
            </v-card-actions>
          </form>
        </ValidationObserver>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import basicMixin from "@/mixins/basic";
import customersMixin from "@/mixins/customer";
import datatableMixin from "@/mixins/datatable";
import oreMixin from "@/mixins/ore";

import expLoading from "@/components/expLoading";
import expDateTime from "@/components/expDateTime.vue";
import dashFilters from "./filters.vue";
import dashTable from "./table.vue";
import modalStatus from "./modal_status";
import modalInfoLog from "./modal_info_log";
import modalImages from "./modal_images";

import {
  checkReport,
  newReport,
  getReportStatus,
  getAllReports,
  getReportById,
  deleteFullReport,
} from "../../gateway/report";
import { getImagesScenario } from "../../gateway/scenario"
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  auth: false,
  name: "page-index",
  layout: "colas",
  mixins: [basicMixin, customersMixin, datatableMixin, oreMixin],
  components: {
    expLoading,
    expDateTime,
    ValidationObserver,
    ValidationProvider,
    modalStatus,
    modalInfoLog,
    modalImages,
    dashFilters,
    dashTable
  },
  data() {
    return {
      endpoint: "reports",
      formatDate: "YYYY-MM-DD",
      modalNewReport: false,
      creatingReport: false,
      newReport: {
        site: 0,
        campaign: null,
        scenario: null,
        shift: true,
        date: this.$moment(new Date()).format("YYYY-MM-DD"),
        state: false,
      },
      filters: {
        regions: [],
        sites: [],
        campaigns: [],
        status: [],
        start_date: "",
        end_date: "",
        page: this.getDashFilterOption("page"),
      },
      options: { search: "", length: 7 },
      itemsTable: [],
      infoPagination: {},
      itemsStatusReports: [
        { id: 1, name: "ore.report.status_approved", color: "#727c0e" },
        { id: 2, name: "ore.report.status_reject", color: "#dd681a" },
        { id: 3, name: "ore.report.status_pending", color: "#577796" },
        { id: 4, name: "ore.report.status_waiting", color: "#6d5482" },
        { id: 100, name: "ore.report.inactive_campaigns", color: "#7a6463" },
        { id: 101, name: "ore.report.flowsheet", color: "#5a9e9a" }
      ],
      first: true,
      msg_state_report: "",
      showId: false
    };
  },
  async mounted() {
    this.isLoading = true;
    this.$store.commit("ore/setReportId", 0);
    this.$store.commit("ore/setCrusherId", 0);
    this.$store.commit("ore/setReportSelected", {});
    this.$store.commit("ore/setModulesReport", []);

    try {
      this.formatDate = JSON.parse(this.$store.getters["auth/getAuthCustomer"].data.extra_data).format_date;
      if(typeof(this.formatDate) == 'undefined')
        this.formatDate = "YYYY-MM-DD";
    } catch (error) {
      this.formatDate = "YYYY-MM-DD";
    }

    if(this.isset(this.$route.params.id)) {
      await getReportById(this.$route.params.id)
        .then((response) => {
          this.editReport(response);
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
        });
    }
    this.isLoading = false;
  },
  methods: {
    getDashFilterOption(option) {
      const filter = this.$store.getters["ore/getDashFilter"];
      return filter[option] != null && filter[option] != undefined
        ? filter[option]
        : [];
    },
    openModalNewReport() {
      this.modalNewReport = true
    },
    async openModalStatus(id) {
      this.isLoading = true;
      let data = await getReportStatus(id);
      data.forEach((element) => {
        element.created = this.$moment(element.created).format(
          this.formatDate + " HH:mm:ss"
        );
      });
      this.$refs.modalStatus.reportId = id;
      this.$refs.modalStatus.tab = 0;
      this.$refs.modalStatus.currentStatus = data[data.length - 1].status;
      this.$refs.modalStatus.items = this.$_.filter(data, (x) => {
        return x.status == 2 || x.status == 96;
      });
      this.$refs.modalStatus.showDialog = true;
      this.isLoading = false;
    },
    async openModalStatusLog(id) {
      /*
      this.isLoading = true;
      let data = await getReportStatus(id);
      if (data.length > 0) {
        data.forEach((element) => {
          element.created = this.$moment(element.created).format(
            this.formatDate + " HH:mm:ss"
          );
        });
        this.$refs.modalInfoLog.items = data;
        this.$refs.modalInfoLog.showDialog = true;
      }
      this.isLoading = false;
      */
    },
    async openModalImages(id){
      this.isLoading = true;
      let data = await getImagesScenario(id);
      this.$refs.modalImages.items = data;
      this.$refs.modalImages.model = 0;
      this.$refs.modalImages.scenarioId = id;
      this.$refs.modalImages.showDialog = true;
      this.isLoading = false;
    },
    async checkExistingReport() {
      this.newReport.state = false
      if (!this.canCreateReport) return false;

      this.msg_state_report = "";
      this.isLoading = true;
      try {
        let _crusher = this.itemsCampaigns.filter(x => x.id == this.newReport.campaign)
        if(_crusher.length > 0)
          this.newReport.crusher = _crusher[0].crusher;

        let _checkReport = await checkReport(this.newReport);
        if (_checkReport.state == false && _checkReport.id == 0) {
          this.newReport.state = true;
        } else {
          this.msg_state_report = _checkReport.state_report && _checkReport.state_report >= 0
            ? this.$t("ore.report.msg_exist_report")
            : "";
        }
        this.isLoading = false;
      } catch {
        this.isLoading = false;
      }
    },
    async createReport() {
      let frmValid = await this.$refs.observerCreateReport.validate();
      try {
        if (frmValid) {
          this.isLoading = true;
          let _newReport = await newReport(this.newReport);
          this.newReport.state = false;
          this.modalNewReport = false;
          this.editReport(_newReport);
          this.isLoading = false;
        }
      } catch {
        this.isLoading = false;
      }
    },
    async editReport(item) {
      await this.$store.commit("ore/setReportId", item.id);
      await this.$store.commit("ore/setCrusherId", item.crusher_id);
      await this.$store.commit("ore/setLoadingData", {
        code: "report",
        state: false,
      });
      this.openRoute("/report/summary");
    },
    async confirmDeleteReport(id) {
      this.$refs.delReport.id = id;
      this.$refs.delReport.showDialog = true;
    },
    async deleteReport(id) {
      this.isLoading = true;
      await deleteFullReport(id, this.extrafilters, this.options);
      this.filterReport();
    },
    filtersReport(filters) {
      this.filters = {
        ...filters,
        page: this.first ? this.getDashFilterOption("page") : 1,
      };
      this.filterReport();
      this.first = false;
    },
    pageReport(page) {
      this.filters = {
        ...this.filters,
        page: page,
      };
      this.filterReport();
    },
    async filterReport() {
      this.isLoading = true;
      let allData = await getAllReports(
        this.filters,
        this.filters.page,
        this.options.length
      );
      this.infoPagination = allData.find((x) => x.id == undefined);
      this.itemsTable = []
      this.$_.forEach(allData, (item) => {
          let _user = this.infoPagination.users.find((x) => x.id == item.created_by);
          if (item.id != undefined) {
            this.itemsTable.push({
              id: item.id,
              site_crush_camp: [item.site_name, item.crusher_name, item.campaign_name],
              scenario: item.scenario_name,
              scenario_id: item.scenario,
              flowsheet: item.flowsheet,
              crusher_id: item.crusher,
              date: item.date,
              shift: item.shift,
              status: item.status,
              jws: item.jws_qty > 0 ? item.jws_qty : item.jws_qty_calc,
              responsible: typeof _user != "undefined" ? _user.email : "---",
            });
          }
        });
      this.$store.commit("ore/setDashFilter", {
        ...this.filters,
        start_date: this.filters.dates[0],
        end_date: this.filters.dates[1],
      });
      this.isLoading = false;
    },
    clickLogo() {
      this.showId = !this.showId;
    },
    openDocumentation() {
      window.open('https://colase4.sharepoint.com/sites/ORE');
    },
  },
  computed: {
    isReloadDashboard() {
      return this.$store.getters["ore/getReloadDashboard"];
    },
    canCreateReport() {
      if(this.newReport.site == null || this.newReport.site == 0 || this.newReport.site == "") return false;
      if(this.newReport.campaign == null || this.newReport.campaign == 0 || this.newReport.campaign == "") return false;
      if(this.newReport.scenario == null || this.newReport.scenario == 0 || this.newReport.scenario == "") return false;
      return true;
    },
    itemsSelectedSitesNames() {
      this.newReport.site = null
      let _itemsSelected = this.$_.filter(this.itemsSites, (i) => {
        return this.$_.includes(this.filters.sites, i.id);
      });
      if(_itemsSelected.length == 1) this.newReport.site = _itemsSelected[0].id
      return _itemsSelected;
    },
    itemsSeletedCampaignsNames() {
      this.newReport.campaign = null
      if(this.newReport.site == 0 || !this.isset(this.newReport.site))
        return []
      let _itemsSelected = this.$_.filter(this.itemsCampaigns, (i) => {
        return this.$_.includes(this.filters.campaigns, i.id);
      });
      if( !this.inactiveCampaign ) {
        _itemsSelected = this.$_.filter(_itemsSelected, (i) => {
          return i.published == true;
        });
      }
      _itemsSelected = this.$_.filter(_itemsSelected, (i) => { return i.site == this.newReport.site; });
      if(this.userType >= 5)
        _itemsSelected = _itemsSelected.filter(x => x.published == true)
      if(_itemsSelected.length == 1) this.newReport.campaign = _itemsSelected[0].id
      return _itemsSelected;
    },
    itemsSeletedScenariosNames () {
      this.newReport.scenario = null
      if(this.newReport.campaign == 0 || !this.isset(this.newReport.campaign))
        return []
      let _itemsSelected = this.$_.filter(this.itemsScenarios, (i) => { return i.campaign == this.newReport.campaign; });
      return _itemsSelected;
    },
  },
  watch: {
    isCustomersAuthenticated(new_value) {
      if (new_value) this.filterReport();
    },
    modalNewReport(state) {
      this.msg_state_report = "";
    },
    isReloadDashboard(state) {
      if (state) this.filterReport();
      this.$store.commit("ore/setReloadDashboard", false);
    },
  },
};
</script>

<style lang="scss">
.header-dash {
  .header-main {
    .row1 {
      display: none !important;
    }
  }
}
.colas-centercontainer {
  tr.color_bg {
    td {
      div {
        font-weight: bold;
      }
    }
  }
}
.label-campaign {
  float: left;
  max-width: 305px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-left: 5px;
}
</style>
