<template>
  <div class="exp-login">
    <div class="exp-login-main">
      <v-row align="center" justify="center" v-show="!isLoading && !msal.isAuthenticated">
        <v-card class="elevation-20 login-wrap">
          <v-card-text class="login-card">
            <v-row>
              <v-col class="login-carrusel pb-0" cols="6">
                <template v-if="images_gallery && images_gallery.length > 0">
                  <v-carousel
                    height="425"
                    cycle
                    hide-delimiter-background
                    :show-arrows="false"
                    :continuous="true"
                  >
                    <v-carousel-item
                      v-for="(image, i) in images_gallery"
                      :key="i"
                      :src="imgPath('login/' + image)"
                    />
                  </v-carousel>
                </template>
                <template v-else>
                  <div style="min-height: 425px"></div>
                </template>
              </v-col>
              <v-col class="login-form pb-0" cols="12" sm="6">
                <v-card-title>
                  <exp-translation
                    class="translation_login"
                    :size="24"
                    :default="langDefault"
                  />
                  <h2 class="center">
                    <span v-if="selectedLocale != 'last'">{{ $t("core.user.login") }}</span>
                    <span v-else>Login</span>
                  </h2>
                </v-card-title>
                <v-row v-if="typeLogin">
                  <v-col cols="12 pb-0">
                    <v-form @submit.prevent="login()">
                      <v-text-field
                        v-model="username"
                        append-icon="mdi-account"
                        type="text"
                        :label="$t('core.user.username')"
                        :disabled="isLoading"
                      />
                      <v-text-field
                        v-model="password"
                        :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show_password ? 'text' : 'password'"
                        :label="$t('core.user.password')"
                        :disabled="isLoading"
                        @click:append="show_password = !show_password"
                      />
                    </v-form>
                    <v-card-actions>
                      <v-btn
                        rounded
                        dark
                        large
                        block
                        :disabled="!btn_login"
                        :class="colorPrimary"
                        :style="'color:' + textPrimary"
                        @click="login()"
                      >
                        <v-icon>mdi-lock</v-icon>&nbsp;{{
                          $t("core.user.login")
                        }}
                      </v-btn>
                    </v-card-actions>
                    <div class="switch_login">
                      <a @click="typeLogin = !typeLogin" v-show="selectedLocale != 'last'">{{ $t("core.user.login_using_msal") }}</a>
                    </div>
                  </v-col>
                </v-row>
                <v-row v-else>
                  <v-col cols="12 pb-0">
                    <v-card-actions>
                      <div v-if="!msal.isAuthenticated">
                        <div @click="btnLoginMsal()" class="btn_msal">
                          <img class="img-responsive" :src="imgPath('logo.png')" />
                          <div v-if="selectedLocale != 'last'">{{ $t("core.user.login_using_msal_description") }}</div>
                          <div v-else>Colas Login</div>
                        </div>
                      </div>
                    </v-card-actions>
                    <div  class="switch_login">
                      <a @click="typeLogin = !typeLogin" v-show="selectedLocale != 'last'">{{ $t("core.user.login_using_local") }}</a>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-row>
    </div>
  </div>
</template>

<script>
import basicMixins from "../mixins/basic.js";
import configMinins from '../mixins/config.js'
import expTranslation from "./expTranslation.vue";
import store from "../store";
import { mapGetters } from "vuex";
import { msalMixin } from "vue-msal";
import { i18n } from "../plugins/i18n";

export default {
  name: "exp-login",
  mixins: [basicMixins, configMinins, msalMixin],
  components: { expTranslation },
  data() {
    return {
      username: "",
      password: "",
      code: "",
      images_gallery: ['login1.jpg', 'login2.jpg', 'login3.jpg'],
      show_password: false,
      btn_login: true,
      typeLogin: false
    };
  },
  props: {
    loginBackend: {
      typeof: Boolean,
      default: true,
    },
    haveTranslations: {
      typeof: Boolean,
      default: true,
    },
    funcLogin: {
      type: Function,
      default: (value) => {
        return value;
      },
    },
    funcLoginMsal: {
      type: Function,
      default: (value) => {
        return value;
      },
    },
    colorPrimary: {
      type: String,
      default: "green",
    },
    textPrimary: {
      type: String,
      default: "white",
    },
  },
  mounted() {
    if (this.$msal.isAuthenticated()) {
      this.loginMsal()
    }
  },
  computed: {
    ...mapGetters({
    }),
    isCustomersAuthenticated() {
      return this.$store.getters['auth/isAuthCustomer']
    },
    langDefault() {
      return store.getters["comun/getLangDefault"];
    },
    userMsal() {
      let user = null;
      if (this.msal.isAuthenticated) {
        user = {
          ...this.msal.user,
          profile: {},
        };
        if (this.msal.graph && this.msal.graph.profile) {
          user.profile = this.msal.graph.profile;
        }
      }
      if(user != null)
        user.token = localStorage.getItem('msal.' + user.idToken.aud + '.idtoken')
      return user;
    },
    selectedLocale() {
      return i18n.locale;
    }
  },
  watch: {
    userMsal: {
      handler() {
        this.loginMsal()
      },
      deep: true
    }
  },
  methods: {
    async login() {
      this.isLoading = true;
      if (this.username.length > 0 && this.password.length > 0) {
        this.isLoading = true;
        await this.funcLogin({
          username: this.username,
          password: this.password,
          config: false,
        });
        this.isLoading = false;
      } else {
        this.isLoading = false;
        this.msgError(this.$t("core.user.incorrect_username_password"));
        setTimeout(() => {
          this.btn_login = true;
        }, 1500);
      }
    },
    btnLoginMsal() {
      if (this.isMobile) {
        var ref = cordova.InAppBrowser.open(
          "https://dv-ore-crushing-na.colasiss.com/loginmobile",
          "_blank",
          "location=no"
        );
        ref.addEventListener("message", async (params) => {
          await this.funcLoginMsal(
            {
              token: params.data.token,
              config: false,
            },
            this.$msal
          );
          window.location.reload();
          ref.close();
        });
      } else {
        this.$msal.signIn();
      }
    },
    async loginMsal() {
      if (this.isCustomersAuthenticated) return;

      if (!this.isMobile) {
        await this.funcLoginMsal(
          {
            token: this.userMsal.token,
            config: false,
          },
          this.$msal
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .btn_msal {
    border: 1px solid;
    border-radius: 10px;
    padding: 10px;
    margin: auto;
    margin-bottom: 15px;
    cursor: pointer;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    max-width: 275px;
    div {
      font-size: 25px;
      text-align: center;
      font-weight: bold;
      line-height: 35px;
      margin-top: 15px;
    }
  }
  .btn_msal img {
    width: 100%;
  }
  .switch_login {
    position: absolute;
    bottom: 10px;
  }
</style>
