<template>
  <v-card-actions>
    <exp-loading
      v-show="isLoading"
      :message="'core.translates.loading_dictionary'"
    />
    <exp-ore-loading v-if="isSyncManual">
      <template v-slot:title>{{ titleLoading }}</template>
      <template v-slot:message>{{ messageLoading }}</template>
    </exp-ore-loading>
    <exp-alert-confirm
      ref="reloadApp"
      :colorCancel="'green'"
      :message="msg_delete"
      :fnOk="reloadApp"
    />
    <exp-alert-confirm
      ref="reloadAppAferSync"
      :colorOk="'green'"
      :message="msg_delete"
      :fnOk="reloadApp"
      :iconOk="'mdi-check-circle'"
      :optionsButtons="{ confirm: true, cancel: false }"
    />
    <v-menu offset-y left :close-on-content-click="false">
      <template v-slot:activator="{ on, attrs }">
        <v-avatar :style="'border-width: 2px;' + borderAvatarColor">
          <v-img
            :src="
              auth_user.picture != null
                ? staticBase + '/media/' + auth_user.picture
                : imgPath('nouser.png')
            "
            :alt="fullName"
            v-bind="attrs"
            v-on="on"
          />
        </v-avatar>
      </template>
      <v-list dense>
        <v-list-item>
          <v-list-item-icon>
            <v-img
              :src="
                auth_user.picture != null
                  ? staticBase + '/media/' + auth_user.picture
                  : imgPath('nouser.png')
              "
              :alt="fullName"
              :max-height="24"
              :max-width="24"
              class="ml-2"
              style="border: 1px solid #ccc; border-radius: 50%"
            />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ fullName }}</v-list-item-title>
            <v-list-item-title>{{ fullRole }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item class="listLocales">
          <v-list-item-icon>
            <v-img
              v-if="selectedLocale && selectedLocale.code != 'tags'"
              :src="imgPath('flags/' + getCodeLang(selectedLocale) + '.png')"
              :alt="getNameLang(selectedLocale)"
              :max-height="24"
              :max-width="24"
              class="ml-2"
            />
            <v-icon size="28" class="ml-2" v-else>mdi-earth</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-select
              v-model="locale_code"
              :items="listLocales"
              item-value="code"
              item-text="name"
              dense
              @change="switchLanguage()"
            />
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-icon>
            <v-img
              :src="imgPath('icons/version.png')"
              :max-height="24"
              :max-width="24"
              class="ml-2"
            />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              >{{ $t("core.comun.version") }}:
              <span v-if="isMobile">{{ app_version }}</span>
              <span v-else>{{ app_version }}</span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <exp-user-restore-password
          v-if="!isMobile"
          ref="expUserRestorePassword"
          @onChangePassword="simplePasswordChange"
          :loading="isLoading"
        ></exp-user-restore-password>
        <v-list-item link @click="openModalConfirm">
          <v-list-item-icon>
            <v-img
              :src="imgPath('icons/synchronize.png')"
              :max-height="24"
              :max-width="24"
              class="ml-2"
            />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t("ore.report.last_synchronized") }}
              <span v-if="isMobile">{{ last_sync }}</span>
              <span v-else>{{ last_sync }}</span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item  v-if="isMobile && isOnline" link @click="syncDialog = true">
          <v-list-item-icon>
            <v-img
              :src="imgPath('icons/synchronize.png')"
              :max-height="24"
              :max-width="24"
              class="ml-2"
            />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t("ore.report.manual_synchronize") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item link @click="exitDialog = true">
          <v-list-item-icon>
            <v-img
              :src="imgPath('icons/shutdown.png')"
              :alt="getNameLang(selectedLocale)"
              :max-height="24"
              :max-width="24"
              class="ml-2"
            />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t("core.user.logout") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-dialog v-model="exitDialog" persistent max-width="350">
      <v-card>
        <v-card-title class="headline">{{
          $t("core.user.logout")
        }}</v-card-title>
        <v-card-text>{{ $t("core.user.confirm_logout") }}</v-card-text>
        <v-card-actions>
          <v-btn
            :rounded="btnRounded"
            color="red"
            dark
            @click="
              exitDialog = false;
              exit();
            "
          >
            <v-icon right>mdi-exit-to-app</v-icon>
            {{ $t("core.comun.yes") }}&nbsp;&nbsp;&nbsp;
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            :rounded="btnRounded"
            color="green"
            dark
            @click="exitDialog = false"
          >
            <v-icon right>mdi-check-circle-outline</v-icon>
            {{ $t("core.comun.no") }}&nbsp;&nbsp;&nbsp;
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="syncDialog" persistent max-width="350">
      <v-card>
        <v-card-title class="headline">{{
          $t("ore.report.date_sync")
        }}</v-card-title>
        <v-card-text>
          <exp-date-time
            name="ore.report.date_sync"
            v-model="dateSyncManual"
            :type="'date'"
            :label="$t('ore.report.date_sync')"
            :formatDate="'YYYY-MM-DD'"
            :maxDate="$moment()"
          />
          <v-radio-group v-model="optionSynManual" row mandatory>
            <v-radio
              :label="$t('core.comun.config_lists')"
              value="1"
            ></v-radio>
            <v-radio
              :label="$t('core.comun.reports')"
              value="2"
            ></v-radio>
          </v-radio-group>
        </v-card-text>
        <v-card-actions>
          <v-btn
            :rounded="btnRounded"
            color="green"
            dark
            @click="syncDialog = false; syncManual();"
          >
            <v-icon right>mdi-check-circle-outline</v-icon>
            {{ $t("ore.report.start_sync") }}&nbsp;&nbsp;&nbsp;
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            :rounded="btnRounded"
            color="red"
            dark
            @click="syncDialog = false;"
          >
            <v-icon right>mdi-close-circle</v-icon>
            {{ $t("core.comun.cancel") }}&nbsp;&nbsp;&nbsp;
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card-actions>
</template>

<script>
import { i18n, loadLanguage, availableLocales } from "../plugins/i18n";
import expUserRestorePassword from "./expUserRestorePassword";
import expDateTime from "./expDateTime.vue";
import expOreLoading from "./expOreLoading";
import basicMixins from "@/mixins/basic";
import customerMixin from "@/mixins/customer";
import oreMixin from "@/mixins/ore";
import mobileMixin from "@/mixins/mobile";
import syncListsMixin from "@/mixins/sync_lists"
import syncReportsMixin from "@/mixins/sync_reports"
import { simplePasswordChange } from "@/gateway/customer";
import { msalMixin } from "vue-msal";

export default {
  mixins: [
    basicMixins,
    customerMixin,
    oreMixin,
    mobileMixin,
    syncListsMixin,
    syncReportsMixin,
    msalMixin
  ],
  components: { expUserRestorePassword, expDateTime, expOreLoading },
  data() {
    return {
      exitDialog: false,
      isLoading: false,
      locale_code: "",
      auth_user: {},
      borderIsOnline: "color: green",
      last_sync: "",
      app_version: "",
      syncDialog: false,
      dateSyncManual: "",
      optionSynManual: null,
      isSyncManual: false,
      messageLoading: "",
      titleLoading: "",
      msg_delete: ""
    };
  },
  props: {
    admin: {
      type: Boolean,
      default: true,
    },
    btnRounded: {
      type: Boolean,
      default: false,
    },
    availablesLocales: {
      type: Array,
      default: (value) => {
        return [];
      },
    },
    defaultLocale: {
      type: String,
      default: "",
    },
    borderAvatarColor: {
      type: String,
      default: "color:red",
    },
  },
  mounted() {
    this.locale_code = this.selectedLocale ? this.selectedLocale.code : "";
    let _auth_user = this.$store.getters["auth/getAuthCustomer"];
    this.auth_user = _auth_user ? _auth_user.data : {};
    this.app_version = this.$store.getters["comun/getAppVersion"];
    this.last_sync = this.$store.getters["comun/getLastSync"];
    this.dateSyncManual = this.$moment().format("YYYY-MM-DD")
  },
  computed: {
    fullName() {
      const _userData = this.auth_user;
      let _fullName = "";
      if (this.isset(_userData.first_name) && _userData.first_name.length > 0) {
        _fullName += _userData.first_name.trim();
      }
      _fullName += " ";
      if (this.isset(_userData.last_name) && _userData.last_name.length > 0) {
        _fullName += _userData.last_name.trim();
      }
      return _fullName;
    },
    fullRole() {
      const _userData = this.auth_user;
      if (this.isset(_userData.user_type)) {
        return "(" + this.labelUserType(_userData.user_type) + ")";
      } else return "";
    },
    listLocales() {
      let _listLocales = [];
      let _locales = availableLocales.locales;
      _listLocales = _locales
      if (this.auth_user.user_type == 1) {
        _listLocales.push({
          code: "tags",
          name: "Tags" // this.$t("core.comun.tags")
        })
      }
      return _listLocales;
    },
    selectedLocale() {
      return this.listLocales.find((x) => x.code == i18n.locale);
    },
    visibleFlag() {
      return this.listLocales.length > 1;
    },
  },
  methods: {
    labelUserType(type) {
      let _name;
      switch (type) {
        case 1:
          _name = "core.user_type.developer";
          break;
        case 2:
          _name = "core.user_type.super_admin";
          break;
        case 3:
          _name = "core.user_type.administrator";
          break;
        case 4:
          _name = "core.user_type.supervisor";
          break;
        case 5:
          _name = "core.user_type.operator";
          break;
        case 6:
          _name = "core.user_type.auditor";
          break;
      }
      return this.$t(_name);
    },
    async exit() {
      const self = this;
      if (this.$msal.isAuthenticated()) {
        self.customersLogout();
        this.$msal.signOut()
      } else {
        self.customersLogout();
      }
    },
    async switchLanguage() {
      this.isLoading = true;
      await loadLanguage(this.locale_code);
      this.generateSummaryModules();
      this.isLoading = false;
    },
    getCodeLang(Locale) {
      if (Locale != undefined) return Locale["code"];
      else return "en-US";
    },
    getNameLang(Locale) {
      if (Locale != undefined) return Locale["name"];
      else return "en-US";
    },
    async simplePasswordChange(data) {
      this.isLoading = true
      await simplePasswordChange(data).then(r => {
        this.isLoading = false
        this.$refs.expUserRestorePassword.closeModal()
      }).catch(e => {
        console.log(e);
        this.isLoading = false
      })
    },
    async syncManual() {
      this.$store.commit("comun/setSynchronizing", true);
      this.isSyncManual = true
      let _dateToSync = this.dateSyncManual;
      if(this.optionSynManual == 1){
        await this.syncListsFn(_dateToSync);
        this.msg_delete = "ore.report.app_reload_msg";
        this.$refs.reloadAppAferSync.showDialog = true;
      } else {
        await this.syncReportsManualFn(_dateToSync);
      }
      this.isSyncManual = false
      this.messageLoading = ""
      this.$store.commit("comun/setSynchronizing", false);
      this.$store.commit("ore/setReloadDashboard",true);
    },
    openModalConfirm(){
      this.msg_delete = "ore.report.confirm_sync_reload";
      this.$refs.reloadApp.showDialog = true;
    },
    async reloadApp(){
      window.location.reload()
    },
  },
  watch: {
    selectedLocale() {
      if (this.locale_code == "") {
        this.locale_code = this.selectedLocale ? this.selectedLocale.code : "";
        this.switchLanguage();
      }
    },
  },
};
</script>
