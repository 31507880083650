import configMixin from "./config";
import syncbgMixin from "./syncbg";

const mobileMixin = {
    mixins: [configMixin, syncbgMixin],
    data() {
        return {
            isOnline: false,
        }
    },
    mounted() {
        this.checkOnLine();
    },
    methods: {
        async checkOnLine() {
            try {
                if (this.isMobile) {
                    var networkState = navigator.connection.type;
                    if (networkState == Connection.NONE) {
                        this.$store.commit('comun/setIsOnline', false);
                    } else {
                        this.$store.commit('comun/setIsOnline', true);
                    }
                } else {
                    this.$store.commit('comun/setIsOnline', true);
                }
            } catch {
                this.$store.commit('comun/setIsOnline', false);
            }
            this.isOnline = this.$store.getters["comun/getIsOnline"];
            return this.isOnline;
        }
    },
    computed: {
        compuIsOnline() {
            return this.$store.getters["comun/getIsOnline"];
        }
    },
    watch: {
        compuIsOnline(newValue, oldValue) {
            if (newValue == oldValue) return;
            this.checkOnLine();
            if (this.isMobile && (newValue == true && oldValue == false) && this.isOnline) {
                //this.syncBgFn("Offline to Online SYNC");
            }
        },
    }
}
export default mobileMixin