var EntitySchema = require("typeorm").EntitySchema;

module.exports = new EntitySchema({
    name: "oreConfigEquipmentMaintenance",
    tableName: "ore_config_equipment_maintenance",
    columns: {
        id: {
            primary: true,
            type: "int",
            generated: false
        },
        equipment: {
            type: "int"
        },
        type: {
            type: "int"
        },
        frecuency: {
            type: "int"
        },
        item: {
            type: "int"
        },
        unit: {
            type: "int",
            nullable: true
        },
        min: {
            type: "float",
            nullable: true
        },
        max: {
            type: "float",
            nullable: true
        },
        created: {
            type: "datetime",
            nullable: true
        },
        created_by: {
            type: "int",
            nullable: true
        },
        update: {
            type: "datetime",
            nullable: true
        },
        update_by: {
            type: "int",
            nullable: true
        },
        deleted: {
            type: "datetime",
            nullable: true
        },
        deleted_by: {
            type: "int",
            nullable: true
        },
        published: {
            type: "int",
            default: "1"
        }
    }
})