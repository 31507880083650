const calculationsMixin = {
  data() {
    return {
      formats: {},
    }
  },
  methods: {
    getRunningTime () {
      if(this.calcActiveStartStopModule) {
        return this.startStopRunningTime()
      } else {
        return this.productionRunningTime()
      }
    },
    getProductionHours () {
      if(this.calcActiveStartStopModule) {
        return this.startStopProductionHours()
      } else {
        return this.productionHours()
      }
    },
    getDownTime () {
      if(this.calcActiveStartStopModule) {
        try {
          return this.$moment.duration(this.storeReportSelected.start_stop.planned_down_time).as('seconds')
        } catch (error) {
          return 0
        }
      } else {
        return this.productionDownTime()
      }
    },
    // FormatersForCalculations //
    secondsToHours(value, separator = ':') {
      if (!value && !(value >= 0)) return "0h:0m";
      value = Number(value);
      return Math.floor(value / 3600).toString() + 'h' + separator + Math.floor(value % 3600 / 60) + 'm'
    },
    numberToPercent(value) {
      let zeros = "0";
      value = Number(value);
      if (this.crusherFormat != null)
        zeros.repeat(this.crusherFormat.decimal_percent)

      let numberReturn = "";
      if (!value && !(value >= 0))
        numberReturn = "0." + zeros
      else
        numberReturn = Number(value).toFixed(this.crusherFormat.decimal_percent)
      return this.numberToFormatNumeral(numberReturn, true)
    },
    numberToFormatNumeral(value, percent = false) {
      let _decimals = percent ? this.crusherFormat.decimal_percent : this.crusherFormat.decimal_number
      let zero = "0";
      let zeros = zero.repeat(_decimals)
      let _totalZeros = zeros.length
      let separator_thousand = ","
      let separator_decimals = "."
      // period
      if (this.crusherFormat.separator_for_thousand == 1) {
        separator_thousand = "."
        separator_decimals = ","
      }
      // comma
      if (this.crusherFormat.separator_for_thousand == 2) {
        separator_thousand = ","
        separator_decimals = "."
      }
      // space
      if (this.crusherFormat.separator_for_thousand == 3) {
        separator_thousand = " "
        separator_decimals = ","
      }
      let number_will_not_decimals = this.crusherFormat.number_will_not_decimals
      let _number = 0
      let _integerPart = 0
      let _integerPartString = "0"
      let _decimalPart = 0
      let _decimalPartString = "0"
      let _stringNumber = `${_integerPartString}${separator_decimals}${zeros}`
      try {
        // Validates number
        if (Number(value) !== NaN && Number(value) !== "NaN" && !isNaN(value)) {
          _number = Number(value).toFixed(_totalZeros)
          // Get integer part
          _integerPart = Math.trunc(_number)
          // Get decimal part if not integer
          if (!(Number.isInteger(_number))) {
            _decimalPartString = _number.toString().split('.')[1]
            _decimalPart = Number(_decimalPartString)
          }
        }
        // Convert the integer part to string and apply the separator
        _integerPartString = _integerPart.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator_thousand)
        // Validate decimal part
        if (_decimalPart > 0 && _totalZeros > 0) {
          // remove numbers from the decimal part to equal _totalZeros
          if (_decimalPartString.length > _totalZeros) {
            _decimalPartString = _decimalPartString.slice(0, _totalZeros)
          }
          // add zeros to the decimal part, if missing to equal _totalZeros
          if (_decimalPartString.length < _totalZeros) {
            let _missingZeros = _totalZeros - _decimalPartString.length
            _decimalPartString = _decimalPartString +  zero.repeat(_missingZeros)
          }
        } else {
          _decimalPartString = zeros
        }
        // validate response according to number_will_not_decimals
        if (_number < parseInt(number_will_not_decimals) && _totalZeros > 0) {
          _stringNumber = `${_integerPartString}${separator_decimals}${_decimalPartString}`
        } else {
          _stringNumber = `${_integerPartString}`
        }
      } catch (error) {
        console.log(error);
      }
      return _stringNumber
    },
    parseFloatCustom(number) {
      if (Number(number) !== NaN && Number(number) !== "NaN" && !isNaN(number)) {
        return  Math.round(parseFloat(Number(number)) * 1000) / 1000
      } else { return 0 }
    },
    // END FormatersForCalculations //
    recievedQtyItemInventory(inventoryItem) {
      let data = this.$_.filter(this.$store.getters['ore/getReportSelected'].inventory_reception, itemRecption => itemRecption.inventory_item === inventoryItem)
      return this.parseFloatCustom(this.$_.sumBy(data, (item => item.received)))
    },
    startStopRunningTime(data = {}) {
      try {
        let config_running_time = this.$store.getters['ore/getSettingsModules'].start_stop_running_time_manual
        if (!this.isset(data)) {
          data = this.$store.getters['ore/getReportSelected'].start_stop
        }
        if (data.production_no) return 0
        if (config_running_time) return this.$moment.duration(data.running_time, 'HH:mm:ss').asSeconds()
        else {
          let production_hours_seconds = this.startStopProductionHours(data)
          let total_event_time_seconds = this.startStopEventsTotal()
          let running_time_auto = production_hours_seconds - total_event_time_seconds
          if (running_time_auto < 0) running_time_auto = 0
          return running_time_auto
        }
      } catch (e) {
        console.log(e);
        return 0
      }
    },
    startStopProductionHours(data = {}) {
      try {
        if (!this.isset(data)) {
          data = this.$store.getters['ore/getReportSelected'].start_stop
        }
        let startTime = this.$moment(data.production_start, 'YYYY-MM-DD HH:mm:ss')
        let endTime = this.$moment(data.production_end, 'YYYY-MM-DD HH:mm:ss')
        let start_end_time_diff_seconds = this.$moment.duration(endTime.diff(startTime)).as('seconds')
        let planned_down_time = this.$moment.duration(data.planned_down_time).as('seconds')
        if(this.$store.getters['ore/getSettingsModules'].start_stop_planned_down_time_visible)
          return Math.abs(Number(start_end_time_diff_seconds - planned_down_time))
        else
          return Math.abs(Number(start_end_time_diff_seconds))
      } catch {
        return 0
      }
    },
    startStopDownTime(data = {}) {
      try {
        if (!this.isset(data)) {
          data = this.$store.getters['ore/getReportSelected'].start_stop
        }
        if (data.production_no) return 100
        let runing_time_seconds = this.startStopRunningTime(data)
        let production_hours = this.startStopProductionHours(data)
        if (production_hours > 0) return Math.abs((100 - (runing_time_seconds / production_hours) * 100))
        else return 0
      } catch {
        return 0
      }
    },
    startStopUpTime(data = {}) {
      try {
        if (!this.isset(data)) {
          data = this.$store.getters['ore/getReportSelected'].start_stop
        }
        let runing_time_seconds = this.startStopRunningTime(data)
        let production_hours = this.startStopProductionHours(data)
        if (production_hours > 0) return (runing_time_seconds / production_hours) * 100
        else return 0
      } catch {
        return 0
      }
    },
    startStopEventsTotal(data = []) {
      try {
        if (!this.isset(data)) {
          data = this.$store.getters['ore/getReportSelected'].start_stop_events
        }
        let _isMobile = this.$store.getters['comun/getIsMobile']
        let start_stop_data = this.$store.getters['ore/getReportSelected'].start_stop
        let prod_start = _isMobile ?
          this.$moment(start_stop_data.production_start).format('YYYY-MM-DD HH:mm:ss') :
          this.$moment(start_stop_data.production_start, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')
        let prod_start_time = this.$moment(prod_start, 'YYYY-MM-DD HH:mm:ss')
        let prod_end = _isMobile ?
          this.$moment(start_stop_data.production_end).format('YYYY-MM-DD HH:mm:ss') :
          this.$moment(start_stop_data.production_end, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')
        let prod_end_time = this.$moment(prod_end, 'YYYY-MM-DD HH:mm:ss')
        let orderedEvents = this.$_.cloneDeep(data).map(_itemEvent => {
          _itemEvent["_stop_time"] = this.$moment(_itemEvent["stop_time"], 'YYYY-MM-DD HH:mm:ss')
          return _itemEvent
        })
        orderedEvents = orderedEvents.sort((a, b) => {
          return this.$moment(a._stop_time).diff(b._stop_time);
        });
        let eventsList = []

        this.$_.forEach(orderedEvents, ((event, index) => {
          let e_stop_time = this.$moment(event.stop_time, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD HH:mm:ss')
          e_stop_time = this.$moment(e_stop_time, 'YYYY-MM-DD HH:mm:ss')
          let e_restart_time = this.$moment(event.restart_time, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD HH:mm:ss')
          e_restart_time = this.$moment(e_restart_time, 'YYYY-MM-DD HH:mm:ss')

          if (this.$moment(e_stop_time).isBefore(prod_start_time))
            e_stop_time = prod_start_time

          if (this.$moment(e_restart_time).isSameOrAfter(prod_end_time))
            e_restart_time = prod_end_time

          if (event.unresolved) {
            if (this.$moment(e_stop_time).isSameOrAfter(prod_start_time)) {
              e_restart_time = prod_end_time
            } else return;
          }

          if (index === 0 || eventsList.length === 0) {
            if (this.$moment(e_stop_time).isBefore(prod_start_time)) e_stop_time = prod_start_time
          }
          else {
            let lastEvent = eventsList[eventsList.length - 1]
            if (this.$moment(e_stop_time).isSameOrAfter(lastEvent.e_stop_time) && this.$moment(e_stop_time).isSameOrBefore(lastEvent.e_restart_time)) {
              if (this.$moment(e_restart_time).isAfter(lastEvent.e_restart_time)) {
                lastEvent.e_restart_time = e_restart_time
              }
              return;
            }
            else {
              if (this.$moment(e_restart_time).isAfter(prod_end_time)) e_restart_time = prod_end_time
            }
          }
          if (this.$moment(e_restart_time).isBefore(e_stop_time)) return;
          eventsList.push({ e_stop_time, e_restart_time })
        }))
        let total_time = 0
        this.$_.forEach(eventsList, (event => {
          total_time += this.$moment.duration(event.e_restart_time.diff(event.e_stop_time)).as('seconds')
        }))
        return total_time < 0 ? 0 : total_time
      } catch (e) {
        return 0
      }
    },
    energyTotalBtu(data = [], idsUse = []) {
      try {
        let totalBtu = 0;
        if (!this.isset(data)) {
          data = this.$store.getters['ore/getReportSelected'].energy
          data.forEach(master => {
            let skipItem = false;
            if (idsUse.length > 0) {
              if (idsUse.find(x => x.id == master.energy_use) == undefined) {
                skipItem = true
              }
            }
            if (!skipItem) {
              let reduceBtu = 0;
              data
                .filter((x) => x.master_uuid == master.uuid)
                .forEach((sub) => {
                  reduceBtu +=
                    (Math.abs(sub.started + sub.received - sub.remaining) *
                      sub.factor) /
                    (sub.factor_type == 0 ? 1 : 0.000293071);
                });
              let btu =
                (Math.abs(master.started + master.received - master.remaining) *
                  master.factor) /
                (master.factor_type == 0 ? 1 : 0.000293071) -
                reduceBtu;
              if (btu < 0) btu = 0;
              totalBtu += btu;
            }
          });
        }
        return totalBtu;
      } catch {
        return 0
      }
    },
    energyTotalKwh(data = []) {
      try {
        return this.energyTotalBtu(data) * 0.000293071
      } catch {
        return 0
      }
    },
    energyTotalBtu_T(data = []) {
      return this.productionTotalProduced() > 0 ? Math.abs(this.energyTotalBtu() / this.productionTotalProduced()) : 0
    },
    energyTotalKwh_T(data = []) {
      return this.productionTotalProduced() > 0 ? Math.abs(this.energyTotalKwh() / this.productionTotalProduced()) : 0
    },
    energyElectricityBtu_T() {
      let use_for = ['electricity']
      return this.productionTotalProduced() > 0 ? Math.abs(this.energyByUseType(use_for) / this.productionTotalProduced()) : 0
    },
    energyGeneratorBtu_T() {
      let use_for = ['generator']
      return this.productionTotalProduced() > 0 ? Math.abs(this.energyByUseType(use_for) / this.productionTotalProduced()) : 0
    },
    energyByUseType(use_for) {
      let idsUse = [{ id: 0 }]
      let itemsList = this.$store.getters['comun/getLists'].find(x => x.code == "EUS").items
      itemsList.forEach(item => {
        try {
          let _extra_data = item.extra_data
          if (_extra_data != undefined && _extra_data != "") {
            _extra_data = JSON.parse(_extra_data.replaceAll("'", "\""));
            if(_extra_data["use_for"] == use_for) {
              idsUse.push({ id: item.id })
            }
          }
        } catch {}
      });
      return this.energyTotalBtu(null, idsUse)
    },
    OEEAvailability() {
      try {
        let _availability = 0
        let _productionHoursModule = this.storeReportSelected.production_hours
        let _startStopModule = this.storeReportSelected.start_stop
        let formulation = 1
        try {
          let configModule = this.JSONparse(this.storeCrusherSelected.settings.modules.find(x => x.code == "MOE").settings)
          formulation = configModule.summary.formulation + 1
        } catch {
          formulation = 1
        }

        switch (formulation) {
          case 1:
            _availability = (this.$moment.duration(_productionHoursModule.running_time).as('seconds') / 3600 ) /
              (this.$moment.duration(_productionHoursModule.production_hours).as('seconds') / 3600 ) * 100
            break;
          case 2:
            let _calc1 = (this.startStopRunningTime() / 3600) * 100
            let _calc2 = (this.startStopProductionHours() / 3600) //- (this.$moment.duration(_startStopModule.planned_down_time).as('seconds') / 3600)
            if(_calc2 > 0){
              _availability = _calc1 / _calc2
            }
        }
        return this.parseFloatCustom(_availability)
      } catch {
        return 0
      }
    },
    OEEPerfomance() {
      try {
        let _performance = 0
        let _scenarioSelected = this.getScenarioSelected()
        let _runningTime = this.getRunningTime() / 3600
        if(_scenarioSelected && _scenarioSelected.theoretical_tph > 0) {
          _performance = (( this.materialActivitiesTotalProducedPositive() / _runningTime ) / _scenarioSelected.theoretical_tph) * 100
        }
        return _performance
      } catch (e) {
        console.log(e)
        return 0
      }
    },
    OEEQuality() {
      try {
        let _quality = 0
        if(this.materialActivitiesTotalProducedPositive() > 0){
          _quality = 100 - this.wasteSummaryAll() / this.materialActivitiesTotalProducedPositive() * 100
        }
        return _quality
      } catch {
        return 0
      }
    },
    OEE_OEE() {
      try {
        return ((this.OEEAvailability() * this.OEEPerfomance() * this.OEEQuality()) * 0.0001)
      } catch {
        return 0
      }
    },
    productionRunningTime () {
      let _production = this.$store.getters['ore/getReportSelected'].production_hours
      let _running_time = 0
      if (_production) {
        _running_time = this.$moment.duration(_production.running_time, 'HH:mm:ss').asSeconds()
      }
      return _running_time
    },
    productionHours () {
      let _production = this.$store.getters['ore/getReportSelected'].production_hours
      let _production_hours = 0
      if (_production) {
        _production_hours = this.$moment.duration(_production.production_hours, 'HH:mm:ss').asSeconds()
      }
      return _production_hours
    },
    productionDownTime () {
      let _campaignTargetData = this.$store.getters["ore/getReportCampaignTarget"]
      try {
        return this.$moment.duration(_campaignTargetData.day_report.planned_downtime).as('seconds')
      } catch (error) {
        return 0
      }
    },
    // CRUSHING
    getScenarioSelected(){
      let _scenarios = this.$store.getters["ore/getListScenario"]
      let _scenarioSelected = _scenarios.find(x => x.id == this.storeReportSelected.report.scenario)
      return _scenarioSelected
    },
    productionTotalOfMen(data = []){
      try {
        if(data.length == 0){
          data = this.$store.getters['ore/getReportSelected'].production_man_hours
        }
        let _total_of_men = this.parseFloatCustom(this.$_.sumBy(data, (item) => item.number_of_men));
        return _total_of_men
      } catch {
        return 0
      }
    },
    productionSumTotal(data = []){
      try {
        if(data.length == 0){
          data = this.$store.getters['ore/getReportSelected'].production_man_hours
        }
        let _sum_total = this.parseFloatCustom(this.$_.sumBy(data, (item) => item.number_of_men * item.hours_worked  ));
        return _sum_total
      } catch {
        return 0
      }
    },
    productionNumManHours(){
      try {
        let _man_hours = 0
        if(this.productionTotalOfMen() > 0){
          _man_hours = this.parseFloatCustom(this.productionSumTotal() / this.productionTotalOfMen());
        }
        return _man_hours
      } catch {
        return 0
      }
    },
    efficiency(){
      try {
        let _efficiency = 0
        let _running_time = this.startStopRunningTime() / 3600
        if(this.productionNumManHours() > 0){
          _efficiency = (_running_time / this.productionNumManHours()) * 100
        }
        return _efficiency
      } catch {
        return 0
      }
    },
    wasteSummaryAll(){
      try {
        let _listItems = this.$store.getters['comun/getLists'].find(x => x.code == "ACT")
        let _wasteItem = _listItems != undefined ?_listItems.items.find(x => x.code == "waste") : 0
        let _data = this.$store.getters['ore/getReportSelected'].material_crushing_activities
        let _wasteActivities = _data.filter(x => x.activity == (_wasteItem ? _wasteItem.id : 0))

        let _sum = this.parseFloatCustom(this.$_.sumBy(_wasteActivities, (item) => item.qty ));
        return _sum
      } catch {
        return 0
      }
    },
    tphTheoretical(formula = 0){
      try {
        let _scenarios = this.$store.getters["ore/getListScenario"]
        let _scenarioSelected = _scenarios.filter(x => x.id == this.storeReportSelected.report.scenario)
        let _thpTheoretical = _scenarioSelected.length > 0 ? _scenarioSelected[0].theoretical_tph : 0
        return this.parseFloatCustom(_thpTheoretical)
      } catch {
        return 0
      }
    },
    tphPlanned(){
      let _scenarios = this.$store.getters["ore/getListScenario"]
      let _scenarioSelected = _scenarios.filter(x => x.id == this.storeReportSelected.report.scenario)
      let _target_tph = _scenarioSelected.length > 0 ? _scenarioSelected[0].target_tph : 0
      let _thpPlanned = 0
      let formulation = 1
      try {
        let configModuleAll = this.JSONparse(this.storeCrusherSelected.settings.modules.find(x => x.code == "MAL").settings)
        formulation = configModuleAll.general.formula_tph_planned + 1
      } catch {
        formulation = 1
      }

      switch (formulation) {
        case 1:
          _thpPlanned = _target_tph ? _target_tph : 0
          break;
        case 2:
          let _production_hours = this.getProductionHours() / 3600
          let _plannedDownTime = this.getDownTime() / 3600
          let _calc1 = this.materialActivitiesTotalProduced() / (_production_hours - _plannedDownTime)
          if(this.parseFloatCustom(_calc1) == 0){
            _thpPlanned = _scenarioSelected.length > 0 ? _scenarioSelected[0].theoretical_tph : 0
          } else {
            _thpPlanned = this.parseFloatCustom(_calc1)
          }
          break;
      }
      return _thpPlanned
    },
    thpReal(){
      try {
        let _tphReal = 0
        let _running_time = this.getRunningTime() / 3600
        if(_running_time > 0){
          _tphReal = this.materialActivitiesTotalProduced() / _running_time
        }
        return this.parseFloatCustom(_tphReal)
      } catch {
        return 0
      }
    },
    thpProducedReal(){
      try {
        let _thpProducedReal = 0
        let _running_time = this.getRunningTime() / 3600
        if(_running_time > 0){
          _thpProducedReal = this.materialActivitiesTotalProducedPositive() / _running_time
        }
        return this.parseFloatCustom(_thpProducedReal)
      } catch {
        return 0
      }
    },
    thpRealNoWaste(){
      try {
        let _tphRealNoWaste = 0
        let _running_time = this.getRunningTime() / 3600
        if(_running_time && _running_time > 0){
          _tphRealNoWaste = ( this.materialActivitiesTotalProduced() - this.wasteSummaryAll() ) / _running_time
        }
        return this.parseFloatCustom(_tphRealNoWaste)
      } catch {
        return 0
      }
    },
    productionManHoursSummary(){
      try {
        let _calc = 0
        if(this.productionSumTotal() > 0){
          _calc = this.materialActivitiesTotalProduced() / this.productionSumTotal()
        }
        return this.parseFloatCustom(_calc)
      } catch {
        return 0
      }
    },
    tonsMenCrushing(){
      try {
        let _calc = 0
        if(this.productionTotalOfMen() > 0){
          _calc =  this.materialActivitiesTotalProduced() / this.productionTotalOfMen()
        }
        return _calc
      } catch {
        return 0
      }
    },
    // For production module
    materialSumPrimaryCrushingQty(){
      try{
        let data = this.$store.getters['ore/getReportSelected'].material_crushing
        let _sum = this.parseFloatCustom(this.$_.sumBy(data, (item) => item.primary_crushing_qty ));
        return _sum
      } catch {
        return 0
      }
    },
    materialActivitiesTotalProduced(parentData = undefined, productData=undefined) {
      try {
        let self = this
        let _materialsActivities = self.$store.getters['ore/getReportSelected'].material_crushing_activities
        if (parentData) {
          _materialsActivities = _materialsActivities.filter(x => x.material_crush_uuid === parentData.uuid);
        }
        if (productData) {
          _materialsActivities = _materialsActivities.filter(x => x.product === productData.id);
        }
        let _total_produced = self.parseFloatCustom(self.$_.sumBy(_materialsActivities, function (o) { return self.parseFloatCustom(o.qty) }))
        return _total_produced
      } catch (e) {
        return 0
      }
    },
    materialActivitiesTotalProducedPositive(parentData = undefined, productData=undefined) {
      try {
        let self = this
        let _materialsActivities = self.$store.getters['ore/getReportSelected'].material_crushing_activities
        if (parentData) {
          _materialsActivities = _materialsActivities.filter(x => x.material_crush_uuid === parentData.uuid);
        }
        if (productData) {
          _materialsActivities = _materialsActivities.filter(x => x.product === productData.id);
        }
        let _total_produced = self.parseFloatCustom(self.$_.sumBy(_materialsActivities, function (o) { if (self.parseFloatCustom(o.qty) > 0) return self.parseFloatCustom(o.qty) }))
        return _total_produced
      } catch (e) {
        return 0
      }
    },
    materialActivitiesTotalProducedBalance(materialData = undefined) {
      try {
        let self = this
        let materialActivitiesTotalProduced = self.materialActivitiesTotalProduced(materialData)
        let _material = self.$store.getters['ore/getReportSelected'].material_crushing.find(_x => _x.id === materialData.id)
        let primary_crushing_qty = _material ? _material.primary_crushing_qty : 0
        let _total_produced_balance = 0
        if (primary_crushing_qty > 0) {
          _total_produced_balance = self.parseFloatCustom(materialActivitiesTotalProduced) / self.parseFloatCustom(primary_crushing_qty)
        }
        return _total_produced_balance * 100
      } catch (e) {
        return 0
      }
    },
    materialActivitiesProportion(data = undefined, parentData=undefined) {
      try {
        let self = this
        let _totalQty = 0
        let _materialsActivities = self.$store.getters['ore/getReportSelected'].material_crushing_activities
        if (parentData) {
          _materialsActivities = _materialsActivities.filter(x => x.material_crush_uuid === parentData.uuid);
        }
        if (data) {
          _materialsActivities = _materialsActivities.filter(x => x.material_crush_uuid === data.material_crush_uuid);
          _totalQty = self.parseFloatCustom(data.qty)
        } else {
        _materialsActivities.forEach(_item => {
          _totalQty = _totalQty + self.parseFloatCustom(_item.qty)
        })
        }
        let _total_produced = self.parseFloatCustom(self.$_.sumBy(_materialsActivities, function (o) { return self.parseFloatCustom(o.qty) }))
        if( _total_produced == 0)
          return 0
        else
          return self.parseFloatCustom(self.parseFloatCustom(_totalQty) / _total_produced * 100)
      } catch (e) {
        console.log(e)
        return 0
      }
    },
    materialActivitiesAllProportion(data = undefined, parentData=undefined) {
      try {
        let self = this
        let _totalQty = 0
        let _materialsActivities = self.$store.getters['ore/getReportSelected'].material_crushing_activities
        if (parentData) {
          _materialsActivities = _materialsActivities.filter(x => x.material_crush_uuid === parentData.uuid);
        }
        if (data) {
          //_materialsActivities = _materialsActivities.filter(x => x.material_crush_uuid === data.material_crush_uuid);
          _totalQty = self.parseFloatCustom(data.qty)
        } else {
        _materialsActivities.forEach(_item => {
          _totalQty = _totalQty + self.parseFloatCustom(_item.qty)
        })
        }
        let _total_produced = self.parseFloatCustom(self.$_.sumBy(_materialsActivities, function (o) { return self.parseFloatCustom(o.qty) }))
        if( _total_produced == 0)
          return 0
        else
          return self.parseFloatCustom(self.parseFloatCustom(_totalQty) / _total_produced * 100)
      } catch (e) {
        console.log(e)
        return 0
      }
    },
    materialSummaryProportion (data=undefined, productData=undefined) {
      try {
        let self = this
        let _materialsActivities = self.$store.getters['ore/getReportSelected'].material_crushing_activities
        let _data
        if (data) {
          _data = _materialsActivities.filter(x => x.material_crush_uuid === data.uuid);
        }
        if (productData) {
          _data = _materialsActivities.filter(x => x.product === productData.id);
        }
        let _total_qty_child = self.parseFloatCustom(self.$_.sumBy(_data, function (o) { return self.parseFloatCustom(o.qty) }))
        let _total_qty_all = self.parseFloatCustom(self.$_.sumBy(_materialsActivities, function (o) { return self.parseFloatCustom(o.qty) }))

        if(_total_qty_all == 0)
          return 0
        else
          return (_total_qty_child / _total_qty_all * 100)
      } catch (e) {
        return 0
      }
    },
    materialSummaryProportionPositive (data=undefined, productData=undefined) {
      try {
        let self = this
        let _materialsActivities = self.$store.getters['ore/getReportSelected'].material_crushing_activities
        let _data
        if (data) {
          _data = _materialsActivities.filter(x => x.material_crush_uuid === data.uuid);
        }
        if (productData) {
          _data = _materialsActivities.filter(x => x.product === productData.product_id);
        }
        let _total_qty_child = self.parseFloatCustom(self.$_.sumBy(_data, function (o) { if (self.parseFloatCustom(o.qty) > 0) return self.parseFloatCustom(o.qty) }))
        let _total_qty_all = self.parseFloatCustom(self.$_.sumBy(_materialsActivities, function (o) { if (self.parseFloatCustom(o.qty) > 0) return self.parseFloatCustom(o.qty) }))

        return (_total_qty_child / _total_qty_all * 100)
      } catch (e) {
        return 100
      }
    },
    productionTotalProduced(data = {}) {
      try {
        let self = this
        return self.materialActivitiesTotalProducedPositive()
      } catch (e) {
        console.log(e)
        return 0
      }
    },
    summaryProductsTotalQTY() {
      try {
        let self = this
        return self.materialActivitiesTotalProduced()
      } catch (e) {
        console.log(e)
        return 0
      }
    },
    summaryProductsTotalProportion() {
      try {
        let self = this
        return self.materialSummaryProportion()
      } catch (e) {
        return 0
      }
    },
    summaryActivitiesTotalQTY() {
      try {
        let self = this
        return self.materialActivitiesTotalProduced()
      } catch (e) {
        console.log(e)
        return 0
      }
    },
    summaryActivitiesTotalQTYPositive() {
      try {
        let self = this
        return self.materialActivitiesTotalProducedPositive()
      } catch (e) {
        console.log(e)
        return 0
      }
    },
    summaryActivitiesTotalProportion() {
      try {
        let self = this
        return self.materialActivitiesProportion()
      } catch (e) {
        console.log(e)
        return 0
      }
    },
    summaryActivitiesTotalCrusherProductsPrimaryQty(materialUUID=undefined) {
      try {
        let self = this
        let _materials = self.$store.getters['ore/getReportSelected'].material_crushing
        let _materialsActivities = self.$store.getters['ore/getReportSelected'].material_crushing_activities
        if (materialUUID) {
          _materials = _materials.filter(_x => _x.uuid === materialUUID)
          _materialsActivities = _materialsActivities.filter(_x => _x.material_crush_uuid === materialUUID)
        }
        let totalPrimaryCrushing = self.parseFloatCustom(self.$_.sumBy(_materials, function (o) { return self.parseFloatCustom(o.primary_crushing_qty) }))
        let totalQtySummaryActivities = self.parseFloatCustom(self.$_.sumBy(_materialsActivities, function (o) { return self.parseFloatCustom(o.qty) }))
        let _total = totalPrimaryCrushing - totalQtySummaryActivities
        return _total
      } catch (e) {
        console.log(e)
        return 0
      }
    },
    summaryActivitiesPercentTotalCrusherProductsPrimaryQty(materialUUID=undefined) {
      try {
        let self = this
        let _materials = self.$store.getters['ore/getReportSelected'].material_crushing
        let _materialsActivities = self.$store.getters['ore/getReportSelected'].material_crushing_activities
        let _crushing_qty = 0
        if (materialUUID) {
          let _materialUUID = _materials.find(_x => _x.uuid === materialUUID)
          if (_materialUUID) {
            _crushing_qty = _materialUUID.primary_crushing_qty
          }
          _materialsActivities = _materialsActivities.filter(_x => _x.material_crush_uuid === materialUUID)
        }
        let totalQtySummaryActivities = self.parseFloatCustom(self.$_.sumBy(_materialsActivities, function (o) { return self.parseFloatCustom(o.qty) }))
        let _total = _crushing_qty > 0 ? totalQtySummaryActivities / _crushing_qty * 100 : 0
        return _total
      } catch (e) {
        console.log(e)
        return 0
      }
    },
  },
  computed: {
    calcActiveStartStopModule () {
      return this.$store.getters['ore/getSettingsModules'].active_start_stop_module
    },
  }
}
export default calculationsMixin