<template>
  <v-overlay class="exp-loading">
    <v-container class="row align-center justify-center">
      <v-progress-circular
        :size="100"
        :width="10"
        color="blue"
        indeterminate
      ></v-progress-circular>
      <v-container class="row align-center justify-center">
        <h1>
          <span v-if="selectedLocale != 'last'">{{ $t(message) }}...</span>
          <span v-else>Loading...</span>
        </h1>
      </v-container>
    </v-container>
  </v-overlay>
</template>

<script>
import { i18n } from "../plugins/i18n";

export default {
  props: {
    message: {
      type: String,
      default: "core.app.loading",
    },
  },
  computed: {
    selectedLocale() {
      return i18n.locale;
    }
  },
};
</script>

<style lang="scss" scoped>
h1 {
  font-size: 25px;
}
.exp-loading {
  z-index: 99999999 !important;
  position: fixed;
}
</style>
