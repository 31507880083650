var EntitySchema = require("typeorm").EntitySchema;

module.exports = new EntitySchema({
    name: "oreConfigCampaignTarget",
    tableName: "ore_config_campaign_target",
    columns: {
        id: {
            primary: true,
            type: "int",
            generated: false
        },
        campaign: {
            type: "int"
        },
        ton_hour_day: {
            type: "float",
            nullable: true
        },
        ton_hour_night: {
            type: "float",
            nullable: true
        },
        ton_work_shift_day: {
            type: "float",
            nullable: true
        },
        ton_work_shift_night: {
            type: "float",
            nullable: true
        },
        number_men_day: {
            type: "float",
            nullable: true
        },
        number_men_night: {
            type: "float",
            nullable: true
        },
        ton_hour_man_day: {
            type: "float",
            nullable: true
        },
        ton_hour_man_night: {
            type: "float",
            nullable: true
        },
        efficiency_day: {
            type: "float",
            nullable: true
        },
        efficiency_night: {
            type: "float",
            nullable: true
        },
        kwh_t_day: {
            type: "float",
            nullable: true
        },
        kwh_t_night: {
            type: "float",
            nullable: true
        },
        schedule_day: {
            type: "varchar",
            nullable: true
        },
        schedule_night: {
            type: "varchar",
            nullable: true
        },
        created: {
            type: "datetime",
            nullable: true
        },
        created_by: {
            type: "int",
            nullable: true
        },
        update: {
            type: "datetime",
            nullable: true
        },
        update_by: {
            type: "int",
            nullable: true
        },
        deleted: {
            type: "datetime",
            nullable: true
        },
        deleted_by: {
            type: "int",
            nullable: true
        },
        published: {
            type: "int",
            default: "1"
        }
    }
})