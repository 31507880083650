var EntitySchema = require("typeorm").EntitySchema;

module.exports = new EntitySchema({
    name: "coreUsersRoles",
    tableName: "core_users_roles",
    columns: {
        id: {
            primary: true,
            type: "int",
            generated: false
        },
        type: {
            type: "int",
            default: "0",
            nullable: true
        },
        option: {
            type: "int",
            default: "0",
            nullable: true
        },
        user: {
            type: "int",
            nullable: true
        },
        region: {
            type: "int",
            nullable: true
        },
        crusher: {
            type: "int",
            nullable: true
        },
        site: {
            type: "int",
            nullable: true
        },
        supervisor: {
            type: "int",
            nullable: true
        },
        created: {
            type: "datetime",
            nullable: true
        },
        created_by: {
            type: "int",
            nullable: true
        },
        update: {
            type: "datetime",
            nullable: true
        },
        update_by: {
            type: "int",
            nullable: true
        },
        deleted: {
            type: "datetime",
            nullable: true
        },
        deleted_by: {
            type: "int",
            nullable: true
        },
        published: {
            type: "int",
            default: "1"
        }
    }
})