<template>
  <div>
    <exp-loading v-show="isLoading" />
    <v-dialog v-model="showDialog" persistent max-width="700px">
        <v-card>
            <v-card-text>
                <v-carousel v-model="model" hide-delimiters>
                    <v-carousel-item
                        v-for="(item, i) in items"
                        :key="i"
                    >
                        <v-sheet height="100%" tile>
                            <exp-image
                              :data="item"
                              :fnView="viewImage"
                              :fnDownLoadImage="downLoadImage"
                              :showBtnDelete="false"
                              :imgHeight="'430'"
                              :imgWidth="'auto'"
                            />
                        </v-sheet>
                    </v-carousel-item>
                </v-carousel>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-btn rounded @click="showDialog = false" color="red">
                    <v-icon left>mdi-close-circle</v-icon>
                    {{ $t("core.comun.close") }}&nbsp;
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <div
      v-if="showViewImage"
      class="view-image pa-5"
      @click="showViewImage = false"
    >
      <v-img
        contain
        max-height="100vh"
        max-width="100%"
        :src="urlViewImage"
        @click="showViewImage = false"
      ></v-img>
    </div>
  </div>
</template>

<script>
import oreMixin from "@/mixins/ore";
import basicMixin from "@/mixins/basic";
import configMixin from "@/mixins/config";

import { syncImage } from "@/gateway/images";
import { getImagesScenario } from "../../gateway/scenario"

import expImage from "../reports/images/image.vue"
import expLoading from "@/components/expLoading";

export default {
  name: "modal-info-log",
  mixins: [basicMixin, oreMixin, configMixin],
  components: { expImage, expLoading },
  data() {
    return {
      isLoading: false,
      showDialog: false,
      model: 0,
      scenarioId: 0,
      items: [],
      showViewImage: false,
      urlViewImage: ""
    };
  },
  methods: {
    viewImage(id) {
      if (this.isMobile) {
        const self = this;
        const img = this.items.find((x) => x.id == id);
        window.resolveLocalFileSystemURL( cordova.file.dataDirectory + img.attachment_type,
          function(fileSystem){
            fileSystem.getFile(img.title, { created: false },
              function(fileEntry) {
                fileEntry.file( function(file){
                  const reader = new FileReader();
                  reader.readAsDataURL(file);
                  reader.onload = () => {
                    self.showViewImage = true;
                    self.urlViewImage = reader.result;
                  };
                  reader.onerror = (error) => console.log(error);
                });
              },
              function (e) {
                console.log("file_not_found", e);
              }
            );
          },
          function (e) {
            console.log("error", e);
          }
        );
      } else {
        let img = this.items.find((x) => x.id == id);
        this.showViewImage = true;
        this.urlViewImage = this.baseApi + "1.0/image/" + img.file_name;
      }
    },
    async downLoadImage(file_name, attachment_type){
      this.isLoading = true
      await syncImage(file_name,attachment_type);
      let _data = await getImagesScenario(this.scenarioId);
      this.items = _data;
      this.isLoading = false
    }
  },
  computed: {
    noImage() {
      return this.imgPath("nopicture.jpg");
    },
    isOnline() {
      return this.$store.getters["comun/getIsOnline"];
    },
  }
};
</script>
<style scoped>
.view-image {
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.9);
    top: 0;
    left: 0;
    z-index: calc(100 * 100) !important;
    display: flex;
    margin: auto;
    cursor: pointer;
    background-color: white;
}
</style>