export const expSnackBarStore = {
  namespaced: true,
  state: {
    snackMsg: ''
  },
  mutations: {
    setSnackBar (state, data) {
      state.snackMsg = data.msg
    }
  },
  actions: {
    showSnack({ commit }, payload) {
      commit("setSnackBar", payload);
    },
  },
}