<template>
  <div>
    <exp-loading v-show="isLoading" />
    <energy-form
      ref="energyForm"
      @change="getAllData"
      :configForms="configForms"
      :disabledBtnReport="storeOnlyReadReport"
      :energyItems="itemsOriginal"
    />
    <exp-alert-confirm
      ref="delEnergy"
      :colorCancel="'green'"
      :message="msg_delete"
      :fnOk="deleteEnergyFntModal"
    />
    <v-card elevation="0" :color="'transparent'">
      <exp-report-header
        :color="color"
        :code="''"
        :colorText="colorText"
        :disabledBtnReport="storeOnlyReadReport"
        ref="expHeader"
        @onSave="saveModuleStatus()"
      />
      <v-card-text class="table_module energy_module">
        <v-row>
          <v-col>
            <v-btn
              rounded
              :color="color"
              @click="clkFunction('_new', 0)"
              :style="`color: ${
                colorText != undefined && colorText != '' ? colorText : 'black'
              };`"
              large
              :disabled="storeOnlyReadReport"
            >
              <v-icon left>mdi-plus</v-icon>
              {{ $t("ore.report.add_new") }}
            </v-btn>
          </v-col>
        </v-row>
        <exp-report-table
          :direction="configTable.direction"
          :headers="headersTable"
          :items="items"
          :tableHeight="'calc(100% - 15px)'"
          :funcEdit="editEnergy"
          :funcDelete="deleteEnergyFntDT"
          :readOnly="storeOnlyReadReport"
          :optionsButtons="{ edit: true, delete: !storeOnlyReadReport }"
          :colorBg="color"
          :colorText="colorText"
        />
        <expOreCalculationBar
          :items="itemsCalculationBar"
          :color="color"
          :colorText="colorText"
        />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import basicMixin from "@/mixins/basic";
import customerMixin from "@/mixins/customer";
import datatableMixin from "@/mixins/datatable";
import oreMixin from "@/mixins/ore";
import expReportTable from "@/components/expReportTable";
import energyForm from "./form";
import { getListItemsByCode } from "@/gateway/lists";
import { getAllEnergy, getEnergy, deleteEnergy } from "@/gateway/energy";
export default {
  name: "page-reports-energy",
  layout: "ore",
  mixins: [basicMixin, customerMixin, datatableMixin, oreMixin],
  components: {
    expReportTable,
    energyForm,
  },
  data() {
    return {
      isLoading: false,
      color: "#0F9992",
      colorText: "",
      endpoint: "energy",
      msg_delete: "",
      headers: [
        {
          text: "ore.report.master_sub",
          value: "master_sub",
          width: "120px",
          alignh: "text-center",
          alignb: "text-left",
        },
        {
          text: "ore.report.energy_use",
          value: "energy_use",
          width: "120px",
          alignh: "text-center",
          alignb: "text-left",
        },
        {
          text: "ore.report.energy_source",
          value: "energy_type",
          width: "120px",
          alignh: "text-center",
          alignb: "text-left",
        },
        {
          text: "ore.report.units",
          value: "units",
          alignh: "text-center",
          alignb: "text-center",
        },
        {
          text: "ore.report.meter_start",
          value: "started",
          alignh: "text-center",
          alignb: "text-right",
        },
        {
          text: "ore.report.received_qty",
          value: "received",
          alignh: "text-center",
          alignb: "text-right",
        },
        {
          text: "ore.report.meter_end",
          value: "remaining",
          alignh: "text-center",
          alignb: "text-right",
        },
        {
          text: "ore.report.difference",
          value: "difference",
          alignh: "text-center",
          alignb: "text-right",
        },
        {
          text: "ore.report.factor",
          value: "factor",
          alignh: "text-center",
          alignb: "text-right",
        },
        {
          text: "ore.report.btu",
          value: "btu",
          alignh: "text-center",
          alignb: "text-right",
        },
        {
          text: "ore.report.btu_t",
          value: "btu_t",
          alignh: "text-center",
          alignb: "text-right",
        },
        {
          text: "ore.report.kwh",
          value: "kwh",
          alignh: "text-center",
          alignb: "text-right",
        },
        {
          text: "ore.report.kwh_t",
          value: "kwh_t",
          alignh: "text-center",
          alignb: "text-right",
        },
        {
          text: "ore.report.start_time",
          value: "start_time",
          alignh: "text-center",
          alignb: "text-right",
        },
        {
          text: "ore.report.end_time",
          value: "end_time",
          alignh: "text-center",
          alignb: "text-right",
        },
        {
          text: "ore.report.btu_reduce",
          value: "btu_reduce",
          alignh: "text-center",
          alignb: "text-right",
        },
        {
          text: "ore.report.kwh_reduce",
          value: "kwh_reduce",
          alignh: "text-center",
          alignb: "text-right",
        },
        {
          text: "core.comun.options",
          value: "options",
          width: "85px",
          alignh: "text-center",
          alignb: "text-center",
        },
      ],
      items: [],
      itemsOriginal: [],
      listUnits: [],
      listEnergyUse: [],
      listEnergySource: [],
      configForms: {
        units: {
          visible: true,
          disabled: false,
        },
        energy_use: {
          visible: true,
          disabled: false,
        },
        energy_type: {
          visible: true,
          disabled: false,
        },
        meter_start: {
          visible: true,
          disabled: false,
        },
        received: {
          visible: true,
          disabled: false,
        },
        meter_end: {
          visible: true,
          disabled: false,
        },
        difference: {
          visible: true,
          disabled: false,
        },
        factor: {
          visible: true,
          disabled: false,
        },
        kwh: {
          visible: true,
          disabled: false,
        },
        btu: {
          visible: true,
          disabled: false,
        },
        start_time: {
          visible: true,
          disabled: false,
        },
        end_time: {
          visible: true,
          disabled: false,
        },
      },
      configTable: {
        direction: "v",
        headers: [],
      },
    };
  },
  async mounted() {
    this.isLoading = true;
    let storeConfigModule = this.pageModuleConfig.settingsConfig;
    let localConfigForm = this.$_.clone(this.configForms);
    this.configForms = { ...localConfigForm, ...storeConfigModule.form };
    this.configTable = { ...localConfigForm, ...storeConfigModule.table };
    this.color = this.pageModuleConfig.color || "primary";
    this.colorText = this.pageModuleConfig.color_text || "black";
    await this.getLists();
    if (this.isCustomersAuthenticated && this.reportSelectedId > 0)
      this.getAllData();
  },
  methods: {
    async getAllData(data = null) {
      this.isLoading = true;
      let allData = data
        ? data
        : this.$store.getters["ore/getReportSelected"].energy;
      try {
        if (!this.isset(allData)) {
          allData = await getAllEnergy(this.reportSelectedId);
        }
      } catch (error) {
        this.logError(error)
        allData = []
      }

      this.$store.commit("ore/setUpdateReportsSelected", { energy: allData });
      let _data = [];
      let _orderAllData = [];
      this.$_.forEach(allData, (master) => {
        if (master.master_uuid == null || !("master_uuid" in master)) {
          master.master_uuid = null
          _orderAllData.push(master);
          allData
            .filter(
              (x) => x.master_uuid != null && x.master_uuid == master.uuid
            )
            .forEach((sub) => {
              _orderAllData.push(sub);
            });
        }
      });
      allData = _orderAllData;
      this.itemsOriginal = [] = allData;
      this.$_.forEach(allData, (item) => {
        let production = this.productionTotalProduced();
        let diff = Math.abs(item.started + item.received - item.remaining);
        let reduceBtu = 0;
        allData
          .filter((x) => x.master_uuid == item.uuid)
          .forEach((sub) => {
            reduceBtu +=
              (Math.abs(sub.started + sub.received - sub.remaining) *
                sub.factor) /
              (sub.factor_type == 0 ? 1 : 0.000293071);
          });
        let reduceKwh = reduceBtu * 0.000293071;
        let btu =
          (diff * item.factor) / (item.factor_type == 0 ? 1 : 0.000293071) -
          reduceBtu;
        if (btu < 0) btu = 0;
        let kwh = btu * 0.000293071;
        let kwht = production > 0 ? kwh / production : 0;
        let btut = production > 0 ? btu / production : 0;
        _data.push({
          id: item.id,
          master_sub:
            item.master_uuid == null
              ? this.$t("ore.report.master")
              : this.$t("ore.report.sub"),
          master_uuid: item.master_uuid,
          energy_use: this.getTranslation(item.energy_use, this.listEnergyUse),
          energy_type: this.getTranslation(
            item.energy_type,
            this.listEnergySource
          ),
          difference: this.numberToFormatNumeral(diff),
          btu: this.numberToFormatNumeral(btu),
          btu_t: this.numberToFormatNumeral(btut),
          kwh: this.numberToFormatNumeral(kwh),
          kwh_t: this.numberToFormatNumeral(kwht),
          factor: this.numberToFormatNumeral(item.factor),
          started: this.numberToFormatNumeral(item.started),
          received: this.numberToFormatNumeral(item.received),
          remaining: this.numberToFormatNumeral(item.remaining),
          btu_reduce: this.numberToFormatNumeral(reduceBtu),
          kwh_reduce: this.numberToFormatNumeral(reduceKwh),
          start_time:
            this.$moment(item.start_time).format("HH:mm").length > 5
              ? "06:00"
              : this.$moment(item.start_time).format("HH:mm"),
          end_time:
            this.$moment(item.end_time).format("HH:mm").length > 5
              ? "18:00"
              : this.$moment(item.end_time).format("HH:mm"),
          units: this.unitEnergyType(item),
          options: {
            edit: true,
            delete:
              !allData.filter((x) => x.master_uuid == item.uuid).length > 0,
          },
        });
      });
      this.items = _data;
      this.isLoading = false;
    },
    unitEnergyType(item) {
      try {
        let energySource = this.$_.filter(
          this.listEnergySource,
          (energySource) => energySource.id == item.energy_type
        );
        let unitItem = this.$_.filter(
          this.listUnits,
          (unit) => unit.id == energySource[0].parent_id
        )[0];
        let unitName = this.JSONparse(unitItem.extra_data);
        return unitName.short_name || "No-unit";
      } catch (error) {
        return "No-unit";
      }
    },
    editEnergy(id) {
      const self = this;
      self.clkFunction("_edit", id);
    },
    deleteEnergyFntDT(id) {
      const self = this;
      self.clkFunction("_delete", id);
    },
    async clkFunction(accion, id) {
      const self = this;
      switch (accion) {
        case "_edit":
          self.isLoading = true;
          try {
            self.$refs.energyForm.data = await getEnergy(id);
            self.$refs.energyForm.disabledField = true;
            self.$refs.energyForm.showDialog = true;
          } catch (error) {
            self.logError(error)
          }
          self.isLoading = false;
          break;
        case "_new":
          self.isLoading = true;
          self.$refs.energyForm.data = {
            started: 0,
            received: 0,
            remaining: 0,
            difference: 0,
            factor: 0,
            factor_type: 0,
            kwh: 0,
            btu: 0,
            energy_use: 0,
            energy_type: 0,
            qty_received: 0,
            start_time: "06:00",
            end_time: "18:00",
          };
          self.$refs.energyForm.showDialog = true;
          self.isLoading = false;
          break;
        case "_delete":
          this.msg_delete = "ore.report.confirm_delete_energy";
          this.$refs.delEnergy.id = id;
          this.$refs.delEnergy.showDialog = true;
          break;
        default:
          break;
      }
    },
    async deleteEnergyFntModal(id) {
      const self = this;
      self.isLoading = true;
      try {
        let _data = await deleteEnergy(id);
        await self.getAllData(_data);
      } catch (error) {
        self.logError(error)
      }
      self.isLoading = false;
    },
    async getLists() {
      try {
        this.listEnergyUse = await getListItemsByCode("EUS");
        this.listEnergySource = await getListItemsByCode("ETY");
        this.listUnits = await getListItemsByCode("EUN");
      } catch (error) {
        this.logError(error)
      }
    },
    saveModuleStatus() {
      this.$refs.expHeader.isValidForm = true;
    },
  },
  computed: {
    tableHeight() {
      return this.heightScreen > 768 ? 452 : this.heightScreen - 357;
    },
    headersTable() {
      let headersConfig = this.configTable.headers;
      let headersTable = this.headers;
      let returnHeaders = [];
      for (let item in headersTable) {
        if (
          headersConfig[headersTable[item].value] &&
          headersConfig[headersTable[item].value].visible &&
          headersTable[item].value !== "options"
        ) {
          returnHeaders.push(headersTable[item]);
        }
      }
      let x = this.$_.find(headersTable, (x) => x.value === "options");
      if (x) returnHeaders.push(x);
      return returnHeaders;
    },
    itemsCalculationBar() {
      return this.$store.getters["ore/getSummaryReport"](
        this.pageModuleConfig.code
      )[0].summaryBar;
    },
  },
  watch: {
    reportSelectedId() {
      this.getAllData();
    },
    "storeLang.lang"(value) {
      if (value) {
        this.getAllData();
        this.getLists();
      }
    },
  },
};
</script>

<style lang="scss">
.energy_module {
  .v-card__text.table_module > div:nth-child(1) {
    margin-top: -85px;
    margin-bottom: 0px;
  }
  .table-horizontal {
    .tableh-header {
      width: 220px;
      float: left;
      table {
      }
    }
    .tableh-body {
      float: left;
      width: calc(100% - 220px);
      table {
      }
    }
  }
}
</style>