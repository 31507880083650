<template>
  <div>
    <exp-loading v-show="isLoading" />
    <exp-alert-confirm
      ref="modalSaveChanges"
      :message="msg_modal"
      :labelOk="'core.comun.yes'"
      :colorOk="'green'"
      :iconOk="'mdi-check-circle'"
      :labelCancel="'core.comun.no'"
      :colorCancel="'red'"
      :iconCancel="'mdi-close-circle'"
      :fnOk="saveProduction"
      :fnCancel="dontSaveChange"
    />
    <production-form
      ref="productionForm"
      @change="getAllTableItems"
      @parentActions="clkFunction"
      :configForms="configForms"
      :disabledBtnReport="storeOnlyReadReport"
    />
    <exp-alert-confirm
      ref="deleteProdItem"
      :colorCancel="'green'"
      :message="msg_delete"
      :fnOk="deleteProductionFntModal"
    />
    <v-card elevation="0" :color="'transparent'">
      <exp-report-header
        ref="expHeader"
        :color="color"
        :code="''"
        :btnDisabled="storeOnlyReadReport || !isDirty"
        @onSave="saveProduction()"
        :colorText="colorText"
        :disabledBtnReport="storeOnlyReadReport"
      />
      <ValidationObserver ref="observerProduction">
        <form class="form-production" @submit.prevent="saveProduction()">
          <v-card-text class="table_module">
            <v-row dense>
              <v-col cols="4" v-if="configForms.production_hours.visible">
                <!-- DISABLED if activeStartStopModule -->
                <template v-if="!activeStartStopModule">
                  <ValidationProvider
                    v-slot="{ errors }"
                    :name="$t('ore.report.production_hours')"
                    rules=""
                  >
                    <exp-date-time
                      name="ore.report.production_hours"
                      v-model="data.production_hours"
                      formatTime="H[h] m[m]"
                      :type="'time'"
                      :label="$t('ore.report.production_hours')"
                      :error-messages="errors"
                      :hiddeTextDisabled="true"
                    />
                  </ValidationProvider>
                </template>
                <v-text-field
                  v-else
                  class="readonly"
                  :value="startStopProductionHours() | secondsToHours"
                  :label="$t('ore.report.production_hours')"
                  outlined
                  dense
                  readonly
                />
              </v-col>
              <v-col cols="4" v-if="configForms.reprocessing_hours.visible">
                <ValidationProvider
                  v-slot="{ errors }"
                  :name="$t('ore.report.reprocessing_hours')"
                  rules=""
                >
                  <exp-date-time
                    name="ore.report.reprocessing_hours"
                    v-model="data.reprocessing_hours"
                    :type="'time'"
                    :label="$t('ore.report.reprocessing_hours')"
                    :formatDateTime="crusherFormat.format_date + ' HH:mm'"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="4" v-if="configForms.primary_crushing_qty.visible">
                <ValidationProvider
                  v-slot="{ errors }"
                  :name="$t('ore.report.primary_crushing_qty')"
                  rules=""
                >
                  <exp-mask
                    :value="materialSumPrimaryCrushingQty()"
                    :label="$t('ore.report.primary_crushing_qty')"
                    class="input-number"
                    outlined
                    dense
                    :readonly="true"
                    :error-messages="errors"
                    :mask="maskDecimalNumber"
                  />
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="4" v-if="!activeStartStopModule && configForms.running_time.visible">
                <!-- enabled if activeStartStopModule is false -->
                <ValidationProvider
                  v-slot="{ errors }"
                  :name="$t('ore.report.running_time')"
                  rules=""
                >
                  <exp-date-time
                    name="ore.report.running_time"
                    v-model="data.running_time"
                    formatTime="H[h] m[m]"
                    :type="'time'"
                    :label="$t('ore.report.running_time')"
                    :error-messages="errors"
                    :hiddeTextDisabled="true"
                  />
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row dense>
              <v-card-text>
                <v-row class="text-right">
                  <v-col>
                    <v-btn
                      rounded
                      color="blue darken-2"
                      @click="clkFunction('_new', 0)"
                      class="mb-1"
                      :disabled="storeOnlyReadReport"
                    >
                      <v-icon left>mdi-plus</v-icon>
                      {{ $t("ore.report.add_new") }}&nbsp;
                    </v-btn>
                  </v-col>
                </v-row>
                <v-card flat height="330px">
                  <exp-report-table
                    :direction="configTable.direction"
                    :headers="configTable.headers"
                    :items="items"
                    :funcEdit="editProductionItem"
                    :funcDelete="deleteProductFntDT"
                    :readOnly="storeOnlyReadReport"
                    :optionsButtons="{ edit: true, delete: !storeOnlyReadReport }"
                  />
                </v-card>
                <expOreCalculationBar
                  :items="itemsCalculationBar"
                  :color="color"
                  :colorText="colorText"
                />
              </v-card-text>
            </v-row>
          </v-card-text>
        </form>
      </ValidationObserver>
    </v-card>
  </div>
</template>
<script>
import basicMixin from "@/mixins/basic";
import customerMixin from "@/mixins/customer";
import oreMixin from "@/mixins/ore";
import oreModulesHelperMixin from "@/mixins/oreModulesHelper";

import { ValidationObserver, ValidationProvider } from "vee-validate";
import expMask from "@/components/expMask.vue";
import expDateTime from "@/components/expDateTime.vue";
import expReportTable from "@/components/expReportTable";

import productionForm from "./form";
import {
  getAllProductionItems,
  getProductionItem,
  deleteProductionItem,
} from "@/gateway/production_man_hours";

import {
  createProduction,
  updateProduction,
  getProductionByReport,
} from "@/gateway/production_hours";

export default {
  name: "page-reports-production",
  layout: "ore",
  mixins: [basicMixin, customerMixin, oreMixin, oreModulesHelperMixin],
  components: {
    ValidationObserver,
    ValidationProvider,
    expMask,
    expDateTime,
    expReportTable,
    productionForm,
  },
  data() {
    return {
      isLoading: false,
      saving: false,
      currentDataProdHours: [],
      currentDataProdManHours: [],
      data: {
        reprocessing_hours: "00:00",
        production_hours: "00:00",
        running_time: "00:00",
        primary_crushing_qty: 0,
      },
      isDirty: false,
      color: "blue darken-2",
      colorText: "black",
      nextPage: "",
      msg_modal: "",
      msg_delete: "",
      items: [],
      headers: [
        {
          text: "ore.report.number_of_men",
          value: "number_of_men",
          alignh: "text-center",
          alignb: "text-center",
        },
        {
          text: "ore.report.number_hours_worked",
          value: "hours_worked",
          alignh: "text-center",
          alignb: "text-center",
        },
        {
          text: "ore.comun.total",
          value: "total",
          alignh: "text-center",
          alignb: "text-center",
        },
        {
          text: "core.comun.options",
          value: "options",
          width: "90px",
          alignh: "text-center",
          alignb: "text-center",
        },
      ],
      configForms: {
        // page
        production_hours: {
          visible: true,
          disabled: false,
        },
        reprocessing_hours: {
          visible: true,
          disabled: false,
        },
        primary_crushing_qty: {
          visible: true,
          disabled: false,
        },
        // modal
        number_of_men: {
          visible: true,
          disabled: false,
        },
        number_hours_worked: {
          visible: true,
          disabled: false,
        },
        running_time: {
          visible: true,
          disabled: false,
        }
      },
      configTable: {
        direction: "v",
          width: "80px",
        headers: [],
      },
    };
  },
  async mounted() {
    this.configForms = this.getModuleConfigForm(this.configForms);
    this.configTable = this.getModuleConfigTable(this.headers);
    this.isLoading = true;
    const self = this;
    setTimeout(() => {
      if(self.$refs.observerStartStop != undefined)
        self.$refs.observerStartStop.reset();
      self.isDirty = false;
    }, 10);
    if (this.isCustomersAuthenticated && this.reportSelectedId > 0)
      this.getAllData();
  },
  async beforeRouteLeave(to, from, next) {
    if (!this.isDirty) {
      next();
    } else if (this.storeOnlyReadReport) {
      next();
    } else {
      this.nextPage = to.path;
      next(false);
      this.msg_modal = "ore.report.msg_data_no_save";
      this.$refs.modalSaveChanges.showDialog = true;
    }
  },
  methods: {
    async getAllData(data = null) {
      this.isLoading = true;
      let reportSelected = this.$store.getters["ore/getReportSelected"];
      this.currentDataProdHours =
        data != null ? data : reportSelected.production_hours;
        try {
          if (!this.isset(this.currentDataProdHours)) {
            this.currentDataProdHours = await getProductionByReport(
              this.reportSelectedId
            );
          }
        } catch (error) {
          this.logError(error)
          this.currentDataProdHours = {}
        }
      this.$store.commit("ore/setUpdateReportsSelected", {
        production_hours: this.currentDataProdHours,
      });
      if (this.isset(this.currentDataProdHours)) {
        this.data = this.$_.clone(this.currentDataProdHours);
      }
      this.isLoading = false;
      setTimeout(() => {
        this.$refs.observerProduction.reset();
        this.isDirty = false;
      }, 10);
      this.getAllTableItems();
    },
    async getAllTableItems(data_items = null) {
      this.isLoading = true;
      let reportSelected = this.$store.getters["ore/getReportSelected"];
      let allDataItems = data_items
        ? data_items
        : reportSelected.production_man_hours;
      try {
        if (!this.isset(allDataItems))
          allDataItems = await getAllProductionItems(this.reportSelectedId);
      } catch (error) {
        this.logError(error)
        allDataItems = []
      }
      this.$store.commit("ore/setUpdateReportsSelected", {
        production_man_hours: allDataItems,
      });
      let _data = [];
      this.$_.forEach(allDataItems, (item) => {
        let _total_row = item.number_of_men * item.hours_worked;
        _data.push({
          id: item.id,
          number_of_men: this.numberToFormatNumeral(item.number_of_men),
          hours_worked: this.numberToFormatNumeral(item.hours_worked),
          total: this.numberToFormatNumeral(_total_row),
        });
      });
      this.items = _data;
      this.isLoading = false;
    },
    async clkFunction(action, id) {
      switch (action) {
        case "_edit":
          this.isLoading = true;
          try {
            this.$refs.productionForm.data = await getProductionItem(id);
            this.$refs.productionForm.showDialog = true;
          } catch (error) {
            this.logError(error)
          }
          this.isLoading = false;
          break;
        case "_new":
          this.isLoading = true;
          this.$refs.productionForm.data = {};
          this.$refs.productionForm.showDialog = true;
          this.isLoading = false;
          break;
        case "_delete":
          this.msg_delete = "ore.report.confirm_delete_prod_item";
          this.$refs.deleteProdItem.id = id;
          this.$refs.deleteProdItem.showDialog = true;
          break;
        case "_set_dirty":
          this.isDirty = id;
          break;
        default:
          break;
      }
    },
    editProductionItem(id) {
      this.clkFunction("_edit", id);
    },
    deleteProductFntDT(id) {
      this.clkFunction("_delete", id);
    },
    async saveProduction() {
      this.isLoading = true;
      let frmValid = await this.$refs.observerProduction.validate();
      if (frmValid) {
        let _data;
        this.saving = true;
        this.data.report = this.reportSelectedId;
        try {
          if (this.isset(this.data.id)) {
            this.data.current_status = 5;
            _data = await updateProduction(this.data.id, this.data);
            await this.$refs.observerProduction.reset();
            this.$refs.modalSaveChanges.showDialog = false;
            this.isDirty = false;
          } else {
            this.data.uuid = this.createUUID();
            _data = await createProduction(this.data);
            await this.$refs.observerProduction.reset();
            this.$refs.modalSaveChanges.showDialog = false;
            this.isDirty = false;
          }
          await this.$store.commit("ore/setUpdateReportsSelected", {
            production_hours: _data,
          });
          await this.getAllData(_data);
          this.$refs.expHeader.isValidForm = true;
          await this.$refs.expHeader.moduleStatusSave();
          if (this.isset(this.nextPage)) this.openRoute(this.nextPage);
        } catch (error) {
          this.logError(error)
        }
        this.saving = false;
        this.isLoading = false;
      }
      this.isLoading = false;
    },
    async deleteProductionFntModal(id) {
      const self = this;
      self.isLoading = true;
      try {
        let _data = await deleteProductionItem(id);
        self.isDirty = true;
        await self.getAllTableItems(_data);
      } catch (error) {
        self.logError(error)
      }
      self.isLoading = false;
    },
    async dontSaveChange() {
      const self = this;
      await self.$refs.observerProduction.reset();
      self.$refs.modalSaveChanges.showDialog = false;
      this.isDirty = false;
      self.openRoute(self.nextPage);
    },
  },
  computed: {
    activeStartStopModule() {
      return this.storeGlobalSettingsgModules.active_start_stop_module;
    },
    itemsCalculationBar() {
      return this.$store.getters["ore/getSummaryReport"](
        this.pageModuleConfig.code
      )[0].summaryBar;
    },
  },
  watch: {
    data: {
      deep: true,
      handler(newValue, oldValue) {
        try {
          if (
            this.$refs.observerProduction &&
            this.$refs.observerProduction.ctx.dirty
          ) {
            this.isDirty = true;
          }
        } catch (e) {
          this.isDirty = false;
        }
      },
    },
    reportSelectedId() {
      this.getAllData();
    },
  },
};
</script>

<style scoped>
/* to put scroll on v-card */
.v-card {
  height: 580px;
  display: flex !important;
  flex-direction: column;
}

.card-text-production {
  flex-grow: 1;
  overflow: auto;
}
</style>

<style>
.bar-title, .bar-value {
  white-space: nowrap;
}
</style>