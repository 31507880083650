import { baseApi, isConfigMobile, getCustomerJWT, GetIdAccessRoles } from './base'
import axios from 'axios'
import { createQueryBuilder, getRepository } from "typeorm";
import store from "@/store"

/**
 * It is used to get all the region table records allowed for the user and adds the information of its modules.
 * @returns {Array} All regions available for the user.
 */
export function getRegions() {
    const self = this
    let itemsRegions = store.getters['ore/getListRegions']

    let isMobile = isConfigMobile()
    if (isMobile) {
        return new Promise(async function (resolve) {
            if (typeof (itemsRegions) != 'undefined' && itemsRegions.length != 0) {
                resolve(itemsRegions)
            } else {
                const regionRepository = await getRepository("oreConfigRegion")
                    .createQueryBuilder("region")
                    .where("region.published = :published", { published: 1 })
                let _idsRegions = await listIdRegions()
                if (_idsRegions != null) {
                    regionRepository.andWhere("region.id IN (:...idsRegions)", { idsRegions: _idsRegions })
                }
                let _regions = regionRepository.getMany().then(response => { return response; }).catch(error => { console.log(error); })
                store.commit('ore/setListRegions', _regions)
                resolve(_regions)
            }
        })
    } else {
        return new Promise(function (resolve, reject) {
            if (typeof (itemsRegions) != 'undefined' && itemsRegions.length != 0) {
                resolve(itemsRegions)
            } else {
                const token = getCustomerJWT()
                axios.get(baseApi() + '1.0/ore/crushing/region/lts/', { headers: { Authorization: `Bearer ${token}` } })
                    .then(response => {
                        store.commit('ore/setListRegions', response.data)
                        resolve(response.data)
                    })
                    .catch(error => reject(error))
            }
        })
    }
}

/**
 * It is used to get an array of regions ids filtered by user.
 * @returns {Array} - Array of regions ids.
 */
async function listIdRegions() {
    let user_id = store.getters["auth/getAuthCustomer"].data.id
    let _idAccessRoles = await GetIdAccessRoles(user_id);
    return _idAccessRoles.regions
}