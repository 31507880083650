var EntitySchema = require("typeorm").EntitySchema;

module.exports = new EntitySchema({
    name: "oreReportMaterialCrushing",
    tableName: "ore_report_materials_crushing",
    columns: {
        id: {
            primary: true,
            type: "int",
            generated: true
        },
        report: {
            type: "int"
        },
        source: {
            type: "int",
            nullable: true
        },
        primary_crushing_qty: {
            type: "float",
            nullable: true
        },
        number_of_loads: {
            type: "float",
            nullable: true
        },
        campaign: {
            type: "int",
            nullable: true
        },
        scenario: {
            type: "int",
            nullable: true
        },
        uuid: {
            type: "varchar",
            length: 50,
            nullable: true
        },
        web_id: {
            type: "int",
            nullable: true
        },
        sync: {
            type: "int",
            default: "0"
        },
        update_web: {
            type: "datetime",
            nullable: true
        },
        created: {
            type: "datetime",
            nullable: true
        },
        created_by: {
            type: "int",
            nullable: true
        },
        update: {
            type: "datetime",
            nullable: true
        },
        update_by: {
            type: "int",
            nullable: true
        },
        deleted: {
            type: "datetime",
            nullable: true
        },
        deleted_by: {
            type: "int",
            nullable: true
        },
        published: {
            type: "int",
            default: "1"
        }
    }
})