<template>
  <div>
    <exp-ore-modal
      ref="materialSummaryModal"
      :openModal="showDialog"
      @onCloseModal="onCloseModal"
      width="900px"
      height="650px"
      :noTitle="true"
      :actionsConfig="{ align_actions: 'right', action_accept: false }"
    >
      <template v-slot:body>
        <v-card class="d-flex flex-column" height="100%" flat tile>
          <v-card
            flat
            class="card-bitumenreception"
            height="100%"
            :style="`flex: 1 1 auto; border: 1px solid ${parentModuleConfig.color}; display: flex;`"
          >
            <exp-report-table
              :style="`width: 100%; margin-top: -8px;`"
              :direction="'v'"
              :tableHeight="'calc(100%)'"
              :headers="materialsSummaryProductsDtHeaders"
              :items="productItemsDT"
              :funcEdit="(_id) => clkFunction('_dt_action_edit', _id)"
              :funcDelete="(_id) => clkFunction('_dt_action_delete', _id)"
              :readOnly="storeOnlyReadReport"
              :optionsButtons="{ edit: true, delete: true }"
            />
          </v-card>
          <v-card flat class="mt-1">
            <v-row dense>
              <v-col cols="4">
                <v-card
                  class="pa-1"
                  :style="`background: ${parentModuleConfig.color}; color: ${parentModuleConfig.color_text};`"
                  flat
                  tile
                >
                  <span class="font-weight-bold">
                    {{ `${$t("ore.report.total_crushed_products")}` }}
                  </span>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card
                  class="pa-1 text-right"
                  :style="`background: ${parentModuleConfig.color}; color: ${parentModuleConfig.color_text};`"
                  flat
                  tile
                >
                  <span class="font-weight-bold">
                    {{ `${numberToFormatNumeral(summaryProductsTotalQTY())}` }}
                  </span>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card
                  class="pa-1 text-right"
                  :style="`background: ${parentModuleConfig.color}; color: ${parentModuleConfig.color_text};`"
                  flat
                  tile
                >
                  <span class="font-weight-bold">
                    {{
                      `${numberToPercent(100)} %`
                    }}
                  </span>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-card>
      </template>
    </exp-ore-modal>
  </div>
</template>

<script>
import oreMixin from "@/mixins/ore";
import expOreModal from "@/components/expOreModal";
import expReportTable from "@/components/expReportTable";

export default {
  name: "material-summary-products-modal",
  mixins: [oreMixin],
  components: { expOreModal, expReportTable },
  data() {
    return {
      showDialog: false,
      materialsSummaryProductsDtHeaders: [
        {
          text: "ore.report.products",
          value: "product",
          alignh: "text-center",
          alignb: "text-left",
        },
        {
          text: "ore.report.quantity",
          value: "total_qty",
          width: "120px",
          alignh: "text-center",
          alignb: "text-center",
        },
        {
          text: "ore.report.total_products",
          value: "proportion",
          width: "120px",
          alignh: "text-center",
          alignb: "text-center",
        },
      ],
    };
  },
  props: {
    parentModuleConfig: {
      type: Object,
      default: () => ({
        color: "",
        color_text: "",
      }),
    },
    productItemsDT: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    onCloseModal() {
      this.showDialog = false;
    },
  },
  computed: {},
  watch: {},
};
</script>
