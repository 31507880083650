import { createQueryBuilder, getRepository } from "typeorm";
import { baseApi, isConfigMobile, getCustomerJWT } from './base'
import axios from 'axios'
import store from "@/store"

/**
 * It is used to get all product records.
 * @returns All products available.
 */
export function getProducts() {
    let itemsProduct = store.getters['ore/getListProducts']

    let isMobile = isConfigMobile()
    if (isMobile) {
        return new Promise(async function (resolve) {
            if (typeof (itemsProduct) != 'undefined' && itemsProduct.length != 0) {
                resolve(itemsProduct)
            } else {
                const products = await getRepository("oreConfigProduct")
                    .createQueryBuilder("product")
                    .select(["product.*", "productList.title as product", "productList.product_id as product_code"])
                    .leftJoin("oreConfigProductList", "productList", "productList.id = product.product_id")
                    .where("product.deleted IS NULL")
                    .andWhere("product.published = true")
                    .getRawMany()
                    .then(res => { return res })
                    .catch(e => console.log(e))
                store.commit('ore/setListProducts', products)
                resolve(products)
            }
        })
    } else {
        return new Promise(function (resolve, reject) {
            if (typeof (itemsProduct) != 'undefined' && itemsProduct.length != 0) {
                resolve(itemsProduct)
            } else {
                const token = getCustomerJWT()
                axios.get(baseApi() + '1.0/ore/crushing/product/lts/', { headers: { Authorization: `Bearer ${token}` } })
                    .then(response => {
                        store.commit('ore/setListProducts', response.data)
                        resolve(response.data)
                    })
                    .catch(error => reject(error))
            }
        })
    }
}

