<template>
  <div>
    <v-dialog v-model="showDialog" persistent :width="width">
      <v-card :height="height" class="d-flex flex-column">
        <v-card flat style="flex: 1 1 auto; overflow: hidden">
          <slot v-if="!(noTitle == true)" name="title">
            <v-card-title class="headline lighten-2 pb-0">
              {{ modalTitle }}
            </v-card-title>
            <v-divider v-if="!(noTitle == true)"></v-divider>
          </slot>
          <v-card
            :height="'100%'"
            class="d-flex flex-column"
            style="overflow: hidden"
            tile
            flat
            :disabled="localConfigActions.disabled_content"
          >
            <v-card-text
              :class="`${localConfigModal.flat ? 'pa-0' : 'pb-0'}`"
              style="height: 100%"
            >
              <slot name="body"> </slot>
            </v-card-text>
          </v-card>
        </v-card>
        <slot name="actions">
          <v-card-actions>
            <v-row
              no-gutters
              :class="`d-flex ${localConfigActions.align_actions} ${localConfigActions.order_actions}`"
            >
              <v-btn
                v-if="localConfigActions.action_accept"
                :rounded="localConfigActions.action_accept_config.rounded"
                :disabled="localConfigActions.action_accept_config.disabled"
                @click="acceptModal"
                :color="localConfigActions.action_accept_config.color"
              >
                <v-icon
                  left
                  v-if="!localConfigActions.action_accept_config.loading"
                  >mdi-content-save</v-icon
                >
                <v-progress-circular
                  v-else
                  indeterminate
                  size="24"
                  class="ore-progress-circular-loading"
                ></v-progress-circular>
                {{ $t("core.comun.save") }}&nbsp;
              </v-btn>
              <v-btn
                v-if="localConfigActions.action_close"
                :rounded="localConfigActions.action_close_config.rounded"
                @click="closeModal"
                :disabled="localConfigActions.action_close_config.disabled"
                :color="localConfigActions.action_close_config.color"
              >
                <v-icon left>mdi-close-circle</v-icon>
                {{ $t("core.comun.cancel") }}&nbsp;
              </v-btn>
            </v-row>
          </v-card-actions>
        </slot>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "exp-ore-modal",
  data() {
    return {
      showDialog: false,
    };
  },
  props: {
    openModal: {
      type: Boolean,
      default: false,
    },
    noTitle: {
      type: Boolean,
      default: false,
    },
    modalTitle: {
      type: String,
      default: "",
    },
    disabledForm: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: "700px",
    },
    height: {
      type: String,
      default: "auto",
    },
    modalConfig: {
      type: Object,
      default: () => ({}),
    },
    actionsConfig: {
      type: Object,
      default: () => ({
        action_accept: true,
        action_accept_config: {
          color: "green",
        },
        action_close: true,
        action_close_config: {},
      }),
    },
  },
  async mounted() {},
  methods: {
    initData() {},
    acceptModal() {
      this.$emit("onAcceptModal");
    },
    closeModal() {
      if (!this.localConfigActions.action_close_config.onlyEmmit) {
        this.showDialog = false;
      }
      this.$emit("onCloseModal");
    },
  },
  computed: {
    localConfigModal() {
      let localConfigModalObject = {};
      let _modalConfig = this.$_.cloneDeep(this.modalConfig);
      let _localModalConfig = {
        flat: false,
        loading: false,
        disabled: false,
      };
      localConfigModalObject = this.$_.merge(_localModalConfig, _modalConfig);
      return localConfigModalObject;
    },
    localConfigActions() {
      let localConfigActionsObject = {};
      let _actionsConfig = this.$_.cloneDeep(this.actionsConfig);
      let _localConfigActions = {
        disabled_content: false,
        align_actions: "justify-space-between",
        order_actions: "",
        action_accept: true,
        action_accept_config: {
          color: "green",
          loading: false,
          disabled: false,
          rounded: true,
        },
        action_close: true,
        action_close_config: {
          color: "red",
          loading: false,
          disabled: false,
          onlyEmmit: false,
          rounded: true,
        },
      };
      localConfigActionsObject = this.$_.merge(
        _localConfigActions,
        _actionsConfig
      );
      if ("order_actions" in _actionsConfig) {
        if (
          _actionsConfig["order_actions"] &&
          _actionsConfig["order_actions"] != ""
        ) {
          localConfigActionsObject.order_actions =
            _actionsConfig["order_actions"] === "reverse"
              ? "flex-row-reverse"
              : "";
        }
      }
      if ("align_actions" in _actionsConfig) {
        if (_actionsConfig["align_actions"] == "center") {
          localConfigActionsObject.align_actions = "justify-center";
        }
        if (_actionsConfig["align_actions"] == "right") {
          localConfigActionsObject.align_actions = "justify-end";
        }
        if (_actionsConfig["align_actions"] == "left") {
          localConfigActionsObject.align_actions = "justify-start";
        }
      }
      return localConfigActionsObject;
    },
  },
  watch: {
    openModal(value) {
      this.initData();
      this.showDialog = value;
    },
  },
};
</script>