import mobileMixins from "@/mixins/mobile";
import { getSyncFiles } from "@/gateway/sync";
import { createQueryBuilder, getRepository } from "typeorm";
import moment from "moment";

const syncFilesMixin = {
    mixins: [mobileMixins],
    data() {
        return {}
    },
    methods: {
        /**
         * It is used to get web application information from the table named core_file. 
         * If the response is valid, the synchronization date is updated and the data is processed and saved.
         * @returns true
         */
        async syncFilesFn() {
            const self = this;
            return new Promise(async function (resolve) {
                if (self.checkOnLine())  {
                    if (self.isOnline == true) {
                        const sync = await getRepository("syncDate");
                        let lastSync = await sync.find().then(response => { return response; }).catch(error => { console.log("error", error) })

                        let _user_id = self.$store.getters["auth/getAuthCustomer"].data.id
                        let _lastSync = lastSync.filter(x => x.user_id == _user_id)
                        let newDataToSync = {}
                        if (_lastSync.length > 0) {
                            newDataToSync = await getSyncFiles(_lastSync[0].date_files)

                            if(Object.entries(newDataToSync).length > 0){
                                await sync.update(_lastSync[0].id, { date_files: newDataToSync.sync_date, user_id: _user_id});
                            }
                        } else {
                            newDataToSync = await getSyncFiles()
                            if(Object.entries(newDataToSync).length > 0){
                                await sync.save({ date_files: newDataToSync.sync_date, user_id: _user_id});
                            }
                        }

                        const coreFileRepository = await getRepository("coreFile")
                       
                        if(typeof(newDataToSync.data) != 'undefined' && newDataToSync.data.length > 0){
                            await newDataToSync.data.forEach(async (element) => {
                                let _fileToUpdate = await coreFileRepository.find({uuid: element.uuid}).then(res => { return res}).catch(e => console.log(e))
                                if(_fileToUpdate.length > 0){
                                    await self.updateFile([element], coreFileRepository);
                                } else {
                                    await self.saveFile([element],coreFileRepository);
                                }
                                
                            });
                        }
                    }
                }
                setTimeout(() => {
                    resolve(true)
                }, 5000);
            })
        },

        /**
         * It is used to parse the data to be saved.
         * @param {Array} data - Data to be parsed and saved.
         * @param {Class} repository - Entity to manipulate the model (entitie).
         */
        async saveFile(data, repository){
            let _items = data
            let _itemsTosave = []
            await _items.forEach(async (item) => {
                item.web_id = item.id
                item.sync = true
                // To avoid problems in safari (IOS)
                if (("update" in item) && item.update != null )
                    item.update = moment(item.update).toISOString()

                if(("created" in item) && item.created != null){
                    item.created = moment(item.created).toISOString();
                }
                if(("deleted" in item) && item.deleted != null){
                    item.deleted = moment(item.deleted).toISOString();
                }
                
                _itemsTosave.push(item)
            })
            if(_itemsTosave.length > 0){
                let _query = await this.createInsertSqlFile(repository,_itemsTosave);
                await repository.query(_query)
            }
        },

        /**
         * It is used to parse the data to be updated.
         * @param {Array} data - Data to be parsed and updated.
         * @param {Class} repository - Entity to manipulate the model (entitie).
         */
        async updateFile(data, repository){
            let _items = data
            let _itemsTosave = []
            let _uuidArray = []
            await _items.forEach(async (item) => {
                item.web_id = item.id
                item.sync = true
                _uuidArray.push(item.uuid)    
                // To avoid problems in safari (IOS)
                if ("update" in item)
                    item.update = moment(item.update).toISOString();
                
                if(("created" in item) && item.created != null){
                    item.created = moment(item.created).toISOString();
                }
                if(("deleted" in item) && item.deleted != null){
                    item.deleted = moment(item.deleted).toISOString();
                }

                _itemsTosave.push(item)
            })
            if(_uuidArray.length > 0){
                await repository.delete({ uuid: _uuidArray[0] })
            }
            if(_itemsTosave.length > 0){
                let _query = await this.createInsertSqlFile(repository,_itemsTosave);
                await repository.query(_query)
            }
        },

        /**
         * It is used to create the query to insert data into the mobile application,
         * taking the attributes of the models (entities) and the incoming values.
         * @param {Class} entity - Entity to manipulate the model (entitie).
         * @param {Array} dataToInsert - Data to be saved in the mobile application.
         * @returns {String} - Query to be executed.
         */
        createInsertSqlFile(entity, dataToInsert) {
            let strQuery = "INSERT INTO '" + entity.metadata.givenTableName + "' (";
            for (const [key, value] of Object.entries(entity.metadata.propertiesMap)) {
                if(key != "id")
                    strQuery += "'" + key + "',"
            }
            strQuery = strQuery.substr(0, strQuery.length - 1)
            strQuery += ') VALUES '
            for (let item of dataToInsert) {
                strQuery += "(";
                for (const [key, value] of Object.entries(entity.metadata.propertiesMap)) {
                    if(key != "id"){

                        if ((key == "update") && item[key] != null){
                            item[key] = item[key].slice(0,-1)
                        }

                        if ((key == "created") && item[key] != null){
                            item[key] = item[key].slice(0,-1)
                        }

                        if ((key == "deleted") && item[key] != null){
                            item[key] = item[key].slice(0,-1)
                        }

                        strQuery += this.fixValue(item[key], key, entity.metadata.ownColumns) + ","
                    }
                }
                strQuery = strQuery.substr(0, strQuery.length - 1)
                strQuery += "),";
            }
            strQuery = strQuery.substr(0, strQuery.length - 1)
            return strQuery;
        },

        /**
         * It is used to fix values and prevent error in the application mobile.
         * @param {Any} _val - The value to be fixed.
         * @param {String} key - The name of the column of the model (entitie).
         * @param {Array} columns - Array of the columns of the model (entitie).
         * @returns The value fixed to be saved in the mobile application.
         */
        fixValue(_val, key, columns) {
            let valReturn = null
            if (!(_val == null || _val == undefined)) {
                if (typeof (_val) == "number" || typeof (_val) == "boolean") {
                    valReturn = _val
                } else {
                    var regex = new RegExp("\"", "g");
                    try {
                        _val = _val.replace(regex, "\'")
                    } catch {
                        console.log(_val)
                    }
                    valReturn = `"${_val}"`
                }
            }

            if (valReturn == null) {
                let _default = columns.find(x => x.databaseName == key)["default"]
                if (_default != undefined) {
                    let _type = columns.find(x => x.databaseName == key)["type"]
                    if (_type == "int") {
                        return parseInt(_default)
                    }
                    else if (_type == "varchar") {
                        return `"${_default}"`
                    } else {
                        return _default
                    }
                } else {
                    return valReturn
                }
            } else {
                return valReturn
            }
        }
    }
}
export default syncFilesMixin