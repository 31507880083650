<template>
  <v-app>
    <v-main>
      <div class="ore-template">
        <div class="ore-wrap-ipad">
          <div class="main-container">
            <v-app :class="pageClass">
              <exp-header-bar class="header-sumary"></exp-header-bar>
              <v-row>
                <v-col cols="1">
                  <exp-report-menu />
                </v-col>
                <v-col cols="11 pl-1">
                  <slot />
                </v-col>
              </v-row>
            </v-app>
            <exp-snack-bar ref="expSnackBar"></exp-snack-bar>
          </div>
        </div>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import expHeaderBar from "@/components/expHeaderBar";
import expReportMenu from "@/components/reportMenu";
import expSnackBar from "@/components/expSnackBar";

const defaultLayout = "AppLayoutDefault";
export default {
  name: "ReportLayout",
  components: {
    expHeaderBar,
    expReportMenu,
    expSnackBar
  },
  data() {
    return {};
  },
  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "expSnackBarStore/setSnackBar") {
        let _stateComponent = state.expSnackBarStore;
        if ("expSnackBar" in this.$refs) {
          this.$refs.expSnackBar.message = _stateComponent.snackMsg
          this.$refs.expSnackBar.localShow = true
        }
      }
    });
  },
  computed: {
    layout() {
      const layout = this.$route.meta.layout || defaultLayout;
      return () => import(`@/layouts/${layout}.vue`);
    },
    pageClass() {
      return this.$route.meta.pageClass || "ore-report";
    },
  },
};
</script>
