<template>
  <div>
    <v-card>
      <v-img :height="imgHeight" :width="imgWidth" :src="urlImg"> </v-img>
      <v-card-actions>
        <v-btn icon color="blue" @click="fnView(data.id)">
          <v-icon>mdi-eye</v-icon>
        </v-btn>
        <v-btn
          v-if="isMobile && !imageFound"
          :disabled="!isOnline"
          icon
          color="green"
          @click="fnDownLoadImage(data.title, data.attachment_type)"
        >
          <v-icon>mdi-download-circle-outline</v-icon>
        </v-btn>
        <v-btn
          v-if="isMobile && !data.sync"
          :disabled="!isOnline"
          icon
          color="green"
          @click="fnUploadImage(data)"
        >
          <v-icon>mdi-cloud-upload-outline</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="showBtnDelete"
          :disabled="disabledBtnDelete"
          icon
          color="red"
          @click="fnDelete(data.id)"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import { baseApi, isConfigMobile } from "@/gateway/base";
import basicMixin from "@/mixins/basic";
export default {
  name: "page-report-images-image",
  mixins: [basicMixin],
  data() {
    return {
      imageFound: true,
      urlImg: "../../../assets/theme/img/nopicture.jpg",
    };
  },
  props: {
    data: {
      type: Object,
      default: (value) => {
        return {};
      },
    },
    fnDelete: {
      type: Function,
      default: (value) => {
        return value;
      },
    },
    fnView: {
      type: Function,
      default: (value) => {
        return value;
      },
    },
    fnDownLoadImage: {
      type: Function,
      default: (value) => {
        return value;
      },
    },
    fnUploadImage: {
      type: Function,
      default: (value) => {
        return value;
      },
    },
    disabledBtnDelete: {
      type: Boolean,
      default: false,
    },
    showBtnDelete: {
      type: Boolean,
      default: true,
    },
    imgHeight: {
      type: String,
      default: '150'
    },
    imgWidth: {
      type: String,
      default: '200'
    },
  },
  async mounted() {
    this.urlImg = await this.getPathImagen();
  },
  methods: {
    async getPathImagen() {
      if (this.isMobile) {
        return new Promise((resolve) => {
          const self = this;
          const basePath =
            cordova.file.dataDirectory + this.data.attachment_type;
          window.resolveLocalFileSystemURL(
            basePath,
            function (fileSystem) {
              fileSystem.getFile(
                self.data.title,
                { created: false },
                function (fileEntry) {
                  self.imageFound = true;
                  fileEntry.file(function (file) {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => resolve(reader.result);
                    reader.onerror = (error) => console.log(error);
                  });
                },
                function (e) {
                  console.log("file_not_found", e);
                  self.imageFound = false;
                  resolve(self.noImage);
                }
              );
            },
            function (e) {
              console.log("error", e);
              self.imageFound = false;
              resolve(self.noImage);
            }
          );
        });
      } else {
        let url = baseApi() + "1.0/image/" + this.data.file_name;
        console.log(url);
        return url;
      }
    },
  },
  computed: {
    noImage() {
      return this.imgPath("nopicture.jpg");
    },
    isMobile() {
      return isConfigMobile();
    },
    isOnline() {
      return this.$store.getters["comun/getIsOnline"];
    },
  },
  watch: {
    async data(newVal) {
      this.urlImg = await this.getPathImagen();
    },
  },
};
</script>