<template>
  <v-dialog v-model="showDialog" max-width="350px">
    <v-card>
      <v-card-text class="pb-0">
        <v-row>
          <v-col cols="12" class="text-center pb-0">
            <v-icon size="70" :color="colorIcon">{{ icon }}</v-icon>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-title class="d-block text-center" style="word-break: normal">
        <span>{{ $t(message) }}</span>
      </v-card-title>
      <v-card-actions class="justify-center">
        <v-btn
          v-if="optionsButtons.confirm"
          :color="colorOk"
          rounded
          @click="_actionOk()"
        >
          <v-icon left>{{ iconOk }}</v-icon>
          {{ $t(labelOk) }}&nbsp;
        </v-btn>
        <v-spacer v-if="optionsButtons.confirm && optionsButtons.cancel"></v-spacer>
        <v-btn
          v-if="optionsButtons.cancel"
          :color="colorCancel"
          rounded
          @click="_actionCancel()"
          :style="
            optionsButtons.confirm == true ? 'float-right' : 'margin: auto;'
          "
        >
          <v-icon left>{{ iconCancel }}</v-icon>
          {{ $t(labelCancel) }}&nbsp;
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "alertConfirm",
  mixins: [],
  data() {
    return {
      showDialog: false,
      id: 0,
    };
  },
  props: {
    title: {
      type: String,
      default: "core.comun.delete",
    },
    message: {
      type: String,
      default: "core.comun.confirm_delete",
    },
    icon: {
      type: String,
      default: "mdi-alert-octagram",
    },
    iconOk: {
      type: String,
      default: "mdi-close-circle",
    },
    iconCancel: {
      type: String,
      default: "mdi-check-circle",
    },
    colorIcon: {
      type: String,
      default: "red",
    },
    colorOk: {
      type: String,
      default: "red",
    },
    labelOk: {
      type: String,
      default: "core.comun.accept",
    },
    colorCancel: {
      type: String,
      default: "primary",
    },
    labelCancel: {
      type: String,
      default: "core.comun.cancel",
    },
    fnOk: {
      type: Function,
      default: (value) => {
        return value;
      },
    },
    fnCancel: {
      type: Function,
      default: (value) => {
        return value;
      },
    },
    optionsButtons: {
      type: Object,
      default: (value) => {
        return {
          confirm: true,
          cancel: true,
        };
      },
    },
  },
  methods: {
    _actionOk() {
      this.fnOk(this.id);
      this.showDialog = false;
    },
    _actionCancel() {
      this.fnCancel(this.id);
      this.showDialog = false;
    },
  },
};
</script>
