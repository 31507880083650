import { createQueryBuilder, getRepository } from "typeorm";
import store from "@/store"
import configMixin from "@/mixins/config"
import moment from "moment";
import router from '@/router'

/**
 * It is used to get the server URL to make the requests.
 * @returns {String} The server URL
 */
export function baseApi() {
    let _configMixin = configMixin.data();
    return _configMixin.baseApi;
}

export function isConfigMobile() {
    let _configMixin = configMixin.data();
    return _configMixin.isMobile;
}

/**
 * It is used to print any message in the browser console,
 * this helps to debug, but the messages just is visible if
 * the config variable named isProduction is set in false.
 * @param {Any} data - Data to print in the browser console.
 */
export function consolelog(data) {
    let _configMixin = configMixin.data();
    if (_configMixin.isProduction === false) {
        console.log(data)
    }
}

/**
 * It is used to get the access token of the user to send requests.
 * @returns {String} - User Token.
 */
export function getCustomerJWT() {
    try {
        if (store.getters["auth/isAuthCustomer"]) {
            let _customer = store.getters["auth/getAuthCustomer"];
            return _customer["access"];
        } else {
            return "";
        }
    }
    catch {
        return "";
    }
}

/**
 * It is used to parse data fields to send information to the web application
 * in the synchronization process.
 * @param {Array} data - Data to be parse.
 * @returns {Array} An array with the data parsed.
 */
export function parseDate(data) {
    let _array = data
    if (!Array.isArray(data)) {
        _array = [data]
    }
    if (typeof (data[0]) != 'undefined') {

        _array.forEach(element => {

            if (element.created == null) {
                delete element.created
            } else {
                element.created = element.created != null ? moment(element.created).format("YYYY-MM-DD HH:mm") : element.created
            }
            element.update = moment(element.update).format("YYYY-MM-DD HH:mm")

            if (element.deleted != null && element.deleted != "")
                element.deleted = moment(element.deleted).format("YYYY-MM-DD HH:mm")

            if ("date_time" in element)
                element.date_time = moment(element.date_time).format("YYYY-MM-DD HH:mm")

            if ("status_date" in element)
                element.status_date = moment(element.status_date).format("YYYY-MM-DD HH:mm")

            if (("production_start" in element) && element.production_start != null)
                element.production_start = moment(element.production_start).format("YYYY-MM-DD HH:mm")

            if (("production_end" in element) && element.production_end != null)
                element.production_end = moment(element.production_end).format("YYYY-MM-DD HH:mm")

            if (("shift_start" in element) && element.shift_start != null)
                element.shift_start = moment(element.shift_start).format("YYYY-MM-DD HH:mm")

            if (("shift_end" in element) && element.shift_end != null)
                element.shift_end = moment(element.shift_end).format("YYYY-MM-DD HH:mm")

            if (("stop_time" in element) && element.stop_time != null)
                element.stop_time = moment(element.stop_time).format("YYYY-MM-DD HH:mm")

            if (("restart_time" in element) && element.restart_time != null)
                element.restart_time = moment(element.restart_time).format("YYYY-MM-DD HH:mm")

            if (("start_time" in element) && element.start_time != null)
                element.start_time = moment(element.start_time).format("YYYY-MM-DD HH:mm")

            if (("end_time" in element) && element.end_time != null)
                element.end_time = moment(element.end_time).format("YYYY-MM-DD HH:mm")

        });
    }
    return _array;
}

/**
 * It is used to update the audit fields of the report synchronized,
 * if the report was reject, the user will be redirecting to the dashboard after
 * update the audit fields.
 * @param {Object} reportRes - Object with the report data synchronized.
 * @param {Class} reportRepository - Entity to manipulate the report model.
 * @param {Number} id - Report Id.
 */
export async function updateReportAuditFields(reportRes, reportRepository, id) {
    let _message = ""
    try {
        let _translates = store.getters['auth/getLastLanguage']['messages']
        _message = _translates.ore.sync.report_reject
    } catch {
        _message = "ore.sync.report_reject"
    }
    // If the report is not rejected and the report was created in the web application, the audit fields are updated.
    if (reportRes.action_status == true && reportRes.web_id != null) {
        await reportRepository.update(id,
            {
                web_id: reportRes.web_id,
                update_web: new Date(reportRes.update_web),
                sync: true,
            }).then((res) => { }).catch(error => console.log(error))

    } else if (reportRes.action_msg == "reject") {
        await reportRepository.update(id,
            {
                deleted: reportRes.deleted,
                deleted_by: reportRes.deleted_by,
                update: reportRes.update,
                update_by: reportRes.update_by,
                web_id: reportRes.web_id,
                update_web: new Date(reportRes.update_web),
                sync: true,
            }).then((res) => { }).catch(error => console.log(error))
        setTimeout(() => {
            alert(_message);
            if (router.history.current.fullPath === "/") {
                window.location.reload()
            } else {
                router.push("/")
            }
        }, 1000)
    }
}

/**
 * It is used to fix date type data fields to send information to the web application
 * in the synchronization process.
 * @param {Object} data - Data to be parse.
 * @returns {Array} An array with the data parsed.
 */
export function fixDateObj(data) {
    data.forEach(_data => {
        if ("created" in _data) {
            let date = new Date(_data.created)
            if (!(date instanceof Date && !isNaN(date))) {
                _data.created = moment().toISOString()
            }
        }
        if ("update" in _data) {
            let date = new Date(_data.update)
            if (!(date instanceof Date && !isNaN(date))) {
                _data.update = moment().toISOString()
            }
        }
    })
    return data
}

export async function GetIdAccessRoles(userId)
{
    const self = this
    let getIdAccessRoles = store.getters['ore/getIdAccessRoles']

    let _configMixin = configMixin.data()
    let isMobile = _configMixin.isMobile
    if (isMobile) {
        return new Promise(async function (resolve)
        {
            if (typeof (getIdAccessRoles) != 'undefined' && (getIdAccessRoles) != null)
            {
                resolve(getIdAccessRoles)
            }
            else
            {
                const userRepository = await getRepository("coreUser");
                const userRoleRepository = await getRepository("coreUsersRoles");
                const regionRepository = await getRepository("oreConfigRegion");
                const siteRepository = await getRepository("oreConfigSite");

                let user = await userRepository.findOne({ id: userId }).then(response => { return response; }).catch(error => { console.log(error); })
                let userType = user.type_user

                if(userType > 2)
                {
                    let userRoles = await userRoleRepository.createQueryBuilder("roles")
                        .where("roles.user = :userId", { userId: userId })
                        .andWhere("roles.deleted IS NULL")
                        .getMany()
                        .then(response => { return response; })
                        .catch(error => { console.log(error); })

                    let regionArray = []
                    let sitesArray = []
                    let crusherArray = []

                    userRoles.forEach((row) => {
                        if(row.region != undefined && row.region != null && row.region != "") regionArray.push(row.region)
                        if(row.site != undefined && row.site != null && row.site != "") sitesArray.push(row.site)
                        if(row.crusher != undefined && row.crusher != null && row.crusher != "") crusherArray.push(row.crusher)
                    });

                    if(userType == 3 || userType == 4 || userType == 6) {
                        const _sites = await siteRepository.createQueryBuilder("sites")
                            .where("sites.deleted IS NULL")
                            .andWhere("sites.region IN (:...regionArray)", { regionArray: regionArray })
                            .getMany()
                            .then(response => { return response; })
                            .catch(error => { console.log(error); })
                        _sites.forEach((row) => {
                            if(!(row.id in sitesArray))
                                sitesArray.push(row.id)
                        });
                        const _regions = await regionRepository.createQueryBuilder("regions")
                            .leftJoin("oreConfigSite", "sites", "regions.id = sites.region")
                            .where("regions.deleted IS NULL").andWhere("sites.deleted IS NULL")
                            .andWhere("sites.id IN (:...sitesArray)", { sitesArray: sitesArray })
                            .getMany()
                            .then(response => { return response; })
                            .catch(error => { console.log(error); })
                        _regions.forEach((row) => {
                            if(!(row.id in regionArray))
                                regionArray.push(row.id)
                        });
                    } else {
                        const _regions = await regionRepository.createQueryBuilder("regions")
                            .leftJoin("oreConfigSite", "sites", "regions.id = sites.region")
                            .where("regions.deleted IS NULL").andWhere("sites.deleted IS NULL")
                            .andWhere("sites.id IN (:...sitesArray)", { sitesArray: sitesArray })
                            .getMany()
                            .then(response => { return response; })
                            .catch(error => { console.log(error); })
                        _regions.forEach((row) => {
                            if(!(row.id in regionArray))
                                regionArray.push(row.id)
                        });
                    }
                    getIdAccessRoles = {
                        crushers: crusherArray,
                        sites: sitesArray,
                        regions: regionArray
                    }
                    store.commit('ore/setIdAccessRoles', getIdAccessRoles)
                    resolve(getIdAccessRoles)
                } else {
                    getIdAccessRoles = {
                        crushers: null,
                        sites: null,
                        regions: null
                    }
                    store.commit('ore/setIdAccessRoles', getIdAccessRoles)
                    resolve(getIdAccessRoles)
                }
            }
        })
    }
}