import { createQueryBuilder, getRepository } from "typeorm";
import { baseApi, isConfigMobile, getCustomerJWT, parseDate, updateReportAuditFields } from './base'
import axios from 'axios'
import store from "@/store"
import moment from "moment";

/**
 * It is used to get all records from energy table
 * @param {Number} reportId - Report Id.
 * @returns All records available in the report.
 */
export function getAllEnergy(reportId) {
    let isMobile = isConfigMobile()
    if (isMobile) {
        return new Promise(function (resolve) {
            const energy = getRepository("oreReportEnergy")
                .find({
                    where: { report: reportId, deleted: null },
                    order: {
                        created: "ASC",
                    }
                }).then(response => { store.commit("ore/setUpdateReportsSelected", { 'energy': response }); resolve(response); })
                .catch(error => console.log(error))
        })
    } else {
        return new Promise(function (resolve, reject) {
            const token = getCustomerJWT()
            axios.get(baseApi() + '1.0/ore/report/energy/all/' + reportId + "/", { headers: { Authorization: `Bearer ${token}` } })
                .then(response => resolve(response.data))
                .catch(error => reject(error))
        })
    }
}

/**
 * It gets a record from energy to edit
 * @param {Number} id - Energy Id.
 * @returns {Object} The record to edit
 */
export function getEnergy(id) {
    let isMobile = isConfigMobile()
    if (isMobile) {
        return new Promise(function (resolve) {
            const energy = getRepository("oreReportEnergy")
                .findOne({ id: id, deleted: null, }).then(response => { resolve(response); })
                .catch(error => console.log(error))
        })
    } else {
        return new Promise(function (resolve, reject) {
            const token = getCustomerJWT()
            axios.get(baseApi() + '1.0/ore/report/energy/' + id + "/", { headers: { Authorization: `Bearer ${token}` } })
                .then(response => resolve(response.data))
                .catch(error => reject(error))
        })
    }
}

/**
 * It makes a soft delete of a record from energy table
 * @param {Number} id - Energy Id.
 * @returns {Array} All records available in the report.
 */
export async function deleteEnergy(id) {
    let isMobile = isConfigMobile()
    if (isMobile) {
        const energy = await getRepository("oreReportEnergy");
        let user_id = store.getters["auth/getAuthCustomer"].data.id
        await energy.update(id, {
            update: new Date(),
            update_by: user_id,
            deleted: new Date(),
            deleted_by: user_id,
            sync: false,
        })
        await energy.find({ id: id }).then((res) => { console.log("secondAwait"); return sync(res); });
    } else {
        return new Promise(function (resolve, reject) {
            const token = getCustomerJWT()
            axios.delete(baseApi() + '1.0/ore/report/energy/' + id + "/", { headers: { Authorization: `Bearer ${token}` } })
                .then(response => resolve(response.data))
                .catch(error => reject(error))
        })
    }
}

/**
 * It creates a new record in the energy table
 * @param {Object} data - Object with data to save
 * @returns {Array} All records available in the report.
 */
export async function createEnergy(data) {
    let isMobile = isConfigMobile()
    if (isMobile) {
        const energy = getRepository("oreReportEnergy");
        let now = new Date();
        let user_id = store.getters["auth/getAuthCustomer"].data.id
        data.update = now;
        data.update_by = user_id;
        data.created = now;
        data.created_by = user_id;

        if(data.start_time != null){
            data.start_time = store.getters["ore/getReportSelected"].report.date + data.start_time
            data.start_time = moment(data.start_time).toISOString()
        }
        if(data.end_time != null){
            data.end_time = store.getters["ore/getReportSelected"].report.date + data.end_time
            data.end_time = moment(data.end_time).toISOString()
        }

        await energy.save(data)
            .then((res) => { return sync([res]); }).catch(error => console.log(error))
    } else {
        return new Promise(function (resolve, reject) {
            const token = getCustomerJWT()
            axios.post(baseApi() + '1.0/ore/report/energy/', data, { headers: { Authorization: `Bearer ${token}` } })
                .then(response => resolve(response.data))
                .catch(error => reject(error))
        })
    }
}

/**
 * It updates a record in the energy table
 * @param {Number} id - Energy Id.
 * @param {Object} data - Object with data to update
 * @returns {Array} All records available in the report.
 */
export async function updateEnergy(id, data) {
    let isMobile = isConfigMobile()
    if (isMobile) {

        if(data.start_time != null){
            data.start_time = store.getters["ore/getReportSelected"].report.date + data.start_time
            data.start_time = moment(data.start_time).toISOString()
        }
        if(data.end_time != null){
            data.end_time = store.getters["ore/getReportSelected"].report.date + data.end_time
            data.end_time = moment(data.end_time).toISOString()
        }

        const energy = await getRepository("oreReportEnergy");
        let user_id = store.getters["auth/getAuthCustomer"].data.id
        await energy.update(id,
            {
                energy_use: data.energy_use,
                energy_type: data.energy_type,
                units: data.units,
                started: data.started,
                received: data.received,
                remaining: data.remaining,
                difference: data.difference,
                factor: data.factor,
                kWh: data.kWh,
                start_time: data.start_time,
                end_time: data.end_time,
                update: new Date(),
                update_by: user_id,
                sync: false
            },
        )
        await energy.find({ id: id, deleted: null }).then(res => { return sync(res) });
    } else {
        return new Promise(function (resolve, reject) {
            const token = getCustomerJWT()
            axios.put(baseApi() + '1.0/ore/report/energy/' + id + "/", data, { headers: { Authorization: `Bearer ${token}` } })
                .then(response => resolve(response.data))
                .catch(error => reject(error))
        })
    }
}

/**
 * It is used to synchronize data from energy module.
 * It is send from mobile aplication to web aplication
 * @param {Object} data - Object with data to send to web application
 * @returns {Array} All records available in the report.
 */
async function sync(data) {
    store.commit("comun/setSynchronizing", true);
    const reportRepository = getRepository("oreReport");
    const energy = getRepository("oreReportEnergy");
    let data_energy = parseDate(data);

    let reportData = await reportRepository.find({ id: data_energy[0].report }).then(res => { return parseDate(res) })
    await reportRepository.update(reportData[0].id, { sync: false, }).then((res) => {}).catch(error => console.log(error))

    let checkOnLine =  store.getters["comun/getIsOnline"]
    if(checkOnLine){
        let objToSync = {
            reports: [
                {
                    report: reportData[0],
                    data: { energy: data_energy }
                }
            ],
        }

        const token = getCustomerJWT()
        await axios.post(baseApi() + '1.0/ore/mobile/sync/', objToSync, { headers: { Authorization: `Bearer ${token}` } })
            .then(async function (response) {
                let id = data_energy[0].id
                let reportRes = response.data.reports[0].report
                let dataRes = response.data.reports[0].data

                await updateReportAuditFields(reportRes, reportRepository, reportData[0].id)
                if ("energy" in dataRes) {
                    let _items = dataRes.energy
                    _items.forEach(async (item) => {
                        //If the sync is rejected, the item is updated with the information that comes from web.
                        if (item.action_msg == "reject") {
                            await energy.update(id,
                                {
                                    energy_use: item.energy_use,
                                    energy_type: item.energy_type,
                                    units: item.units,
                                    started: item.started,
                                    received: item.received,
                                    remaining: item.remaining,
                                    difference: item.difference,
                                    factor: item.factor,
                                    kWh: item.kWh,
                                    start_time: item.start_time != null ? moment(item.start_time).toISOString() : null,
                                    end_time: item.end_time != null ? moment(item.end_time).toISOString() : null,
                                    created: new Date(item.created),
                                    created_by: item.created_by,
                                    update: new Date(item.update),
                                    update_by: item.update_by,
                                    web_id: item.web_id,
                                    update_web: new Date(item.update_web),
                                    sync: true,
                                },
                            ).then((res) => {}).catch(error => console.log(error))
                        } else {
                            //If it is not rejected and the sync was successful, the variables are updated.
                            if (item.action_status) {
                                await energy.update(id,
                                    {
                                        web_id: item.web_id,
                                        update_web: new Date(item.update_web),
                                        sync: true,
                                    },
                                ).then((res) => {}).catch(error => console.log(error))
                            }
                        }
                    })
                }

                store.commit("comun/setSynchronizing", false);
                return getAllEnergy(data_energy[0].report)

            }).catch(error => {store.commit("comun/setSynchronizing", false); console.log(error);return getAllEnergy(data_energy[0].report)})
    } else {
        store.commit("comun/setSynchronizing", false);
        return getAllEnergy(data_energy[0].report)
    }
}
