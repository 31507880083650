import { createQueryBuilder, getRepository } from "typeorm";
import { baseApi, isConfigMobile, getCustomerJWT, GetIdAccessRoles } from './base'
import { getConfigModule } from './crusher_config_module'
import axios from 'axios'
import store from "@/store"

function mergeCrusherModules(ltsDetailsCrusher, lstModules) {
    for(let i = 0; i < ltsDetailsCrusher.length; i++){
        let crusher = ltsDetailsCrusher[i]
        let format = { ...crusher.format }
        delete crusher.format
        crusher = {
            ...crusher,
            settings: { modules: format.modules },
            format: format.format,
        }
        for(let j = 0; j < crusher.settings.modules.length; j++){
            let module = crusher.settings.modules[j]
            let _module = lstModules.find(x => x.code == module.code)
            crusher.settings.modules[j] = {
                ...module,
                color: _module.color,
                color_soft: _module.color_soft,
                color_text: _module.color_text,
                icon: _module.icon,
                icon_svg: _module.icon_svg
            }
        }
        ltsDetailsCrusher[i] = { ...crusher }
    };
    return ltsDetailsCrusher
}

/**
 * It is used to get all the crusher table records allowed for the user and adds the information of its modules.
 * @returns {Array} All crushers available for the user.
 */
export function getCrushers() {
    let itemsCrushers = store.getters['ore/getListCrushers']

    let isMobile = isConfigMobile()
    if (isMobile) {
        return new Promise(async function (resolve) {
            if (typeof (itemsCrushers) != 'undefined' && itemsCrushers.length != 0) {
                resolve(itemsCrushers)
            } else {
                const modules = store.getters["ore/getListModule"]
                const crusherRepository = await getRepository("oreConfigCrusher")
                    .createQueryBuilder("crusher")
                    .select(["crusher.*", "buid.number as buid_number"])
                    .leftJoin("oreConfigBuid", "buid", "buid.id = crusher.buid")
                    .where("crusher.published = :published", { published: 1 })
                let _idsCrushers = await listIdCrushers()
                if (_idsCrushers != null) {
                    crusherRepository.andWhere("crusher.id IN (:...idscrushers)", { idscrushers: _idsCrushers })
                }
                let _crushers = await crusherRepository.getRawMany().then(response => { return response; }).catch(error => { console.log(error); })

                let crushers = loadSettingInCrusher(_crushers, modules, false);
                let ltsDetailsCrushers = mergeCrusherModules(crushers, modules);
                store.commit('ore/setListCrushers', ltsDetailsCrushers)
                resolve(ltsDetailsCrushers)
            }
        })
    } else {
        return new Promise(function (resolve, reject) {
            if (typeof (itemsCrushers) != 'undefined' && itemsCrushers.length != 0) {
                resolve(itemsCrushers)
            } else {
                const token = getCustomerJWT()
                axios.get(baseApi() + '1.0/ore/crusher/lts/details/', { headers: { Authorization: `Bearer ${token}` } })
                    .then(response => {
                        const modules = store.getters["ore/getListModule"]

                        let crushers = response.data;
                        let ltsDetailsCrushers = mergeCrusherModules(crushers, modules);
                        store.commit('ore/setListCrushers', ltsDetailsCrushers)
                        resolve(ltsDetailsCrushers)
                    })
                    .catch(error => reject(error))
            }
        })
    }
}

//This function will replace the function above when all crushers have the new format.
/**
 * It is used to get the list of all base modules with their configuration.
 * If you find information for any module within crushersModules,
 * then replaced the settings field with the information found.
 * @param {Array} crushers - Modules of the crusher.
 * @param {Array} modules - All modules available.
 * @param {boolean} module_schema - Should return the module schema.
 * @returns
 */
function loadSettingInCrusher(crushers, modules, module_schema) {
    let items_return = []
    for (const crusher of crushers) {
        let item_return = {
            ...crusher,
            format: atob(crusher["format"])
        };
        const listModules = [];
        let format_obj = { format: "{}", modules: [] };
        try {
            const _format = JSON.parse(item_return.format);
            if (_format && _format !== null && _format !== "") {
                if ("modules" in _format && "format" in _format) {
                    format_obj = _format;
                } else {
                    format_obj.format = item_return.format;
                }
            }
        } catch (error) { console.log(error)/* */ }

        for (const module of modules) {
            try {
                const _crusherModule = format_obj.modules.filter(x => x.id === module.id);
                listModules.push({
                    id: module.id,
                    name: module.name,
                    code: module.code,
                    order: module.order,
                    status: (_crusherModule.length > 0 && "status" in _crusherModule[0] && _crusherModule[0].status) || false,
                    settings: (_crusherModule.length > 0 && "settings" in _crusherModule[0] && _crusherModule[0].settings) || "{}",
                    settings_schema: module.setting || "{}",
                    rules: (_crusherModule.length > 0 && "rules" in _crusherModule[0] && _crusherModule[0].rules) || "{}",
                    rules_schema: module_schema && { json_schema: module.json_schema } || "{}",
                });
            } catch {
                console.log(item_return)
            }
        }
        format_obj.modules = listModules;
        item_return.format = format_obj;
        items_return.push(item_return);
    }
    return items_return;
}

/**
 * It is used to get an array of crushers ids filtered by user.
 * @returns {Array} - Array of crushers ids.
 */
async function listIdCrushers() {
    let user_id = store.getters["auth/getAuthCustomer"].data.id
    let _idAccessRoles = await GetIdAccessRoles(user_id);
    return _idAccessRoles.crushers
}