<template>
  <div>
    <v-dialog v-model="showDialog" persistent max-width="500px">
      <v-card>
        <v-tabs v-model="tab" dark>
          <v-tab>{{ $t("ore.report.log") }}</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <div class="mt-5">
              <v-card-text
                class="mt-0 pt-0"
                style="max-height: 300px; overflow: auto"
              >
                <template>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">{{ $t("ore.report.date") }}</th>
                          <th class="text-left">
                            {{ $t("ore.report.status") }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in items" :key="item.id">
                          <td>{{ item.created }}</td>
                          <td>{{ $t(findStatus(item.status)) }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </template>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn rounded @click="showDialog = false" color="red">
                  <v-icon right>mdi-close-circle</v-icon>
                  {{ $t("core.comun.close") }}&nbsp;
                </v-btn>
              </v-card-actions>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import oreMixin from "@/mixins/ore";
import basicMixin from "@/mixins/basic";
export default {
  name: "modal-info-log",
  mixins: [basicMixin, oreMixin],
  components: {},
  data() {
    return {
      showDialog: false,
      tab: 0,
      items: [],
      itemsStateLog: [
        {
          id: "1",
          name: "ore.report.status_approved",
        },
        {
          id: "2",
          name: "ore.report.status_reject",
        },
        {
          id: "3",
          name: "ore.report.status_created",
        },
        {
          id: "4",
          name: "ore.report.status_submit",
        },
        {
          id: "96",
          name: "ore.report.status_reopen",
        },
        {
          id: "97",
          name: "ore.report.status_deleted",
        },
      ],
    };
  },
  methods: {
    findStatus(id) {
      let _item = this.itemsStateLog.find((x) => x.id == id);
      if (_item != undefined) return _item.name;
      else return "";
    },
  },
};
</script>
