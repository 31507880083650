import store from "@/store";

const basicMixin = {
  data() {
    return {
      isLoading: false,
      heightScreen: 0,
    };
  },
  mounted() {
    this.heightScreen = window.screen.height;
  },
  methods: {
    parseNumber(number) {
      var n = 1.1;
      n = n.toLocaleString().substring(1, 2);
      let _number
      if (n == ".") {
        _number = number.toString().replace(",", ".")
      } else {
        _number = number.toString().replace(".", ",")
      }
      _number = parseFloat(_number)
      return _number
    },
    compareObjects(object1, object2, key) {
      const obj1 = object1[key] != undefined ? object1[key].toUpperCase() : "";
      const obj2 = object2[key] != undefined ? object2[key].toUpperCase() : "";
      if (obj1 < obj2) {
        return -1;
      }
      if (obj1 > obj2) {
        return 1;
      }
      return 0;
    },
    sortList(obj, sortBy = "name") {
      return obj.sort((item1, item2) => {
        return this.compareObjects(item1, item2, sortBy);
      });
    },
    openRoute(route) {
      if (this.$route.fullPath != route)
        this.$router.push(route).catch((err) => err);
    },
    listTranslate(list, id = "id", name = "name") {
      let _listItems = [];
      list.forEach((item) => {
        _listItems.push({
          ...item,
          id: item[id],
          name: this.$t("core.list_item." + item[name]),
        });
      });
      return _listItems;
    },
    getFirstList(list, data) {
      if (data > 0) {
        return data;
      } else {
        if (list.length > 0 && (data == 0 || this.$_.isNil(data))) {
          return this.$_.first(list)["id"];
        } else return null;
      }
    },
    findTextList(id, list) {
      const _item = list.find((x) => x.id === id);
      if (this.isset(_item)) {
        return _item.name;
      } else {
        return "";
      }
    },
    msgError(_msg, _time = 1500, _type = "error") {
      this.$toasted.show(_msg, {
        type: _type,
        theme: "bubble",
        duration: _time,
        position: "bottom-center",
      });
    },
    createUUID() {
      let dt = new Date().getTime();
      const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          const r = (dt + Math.random() * 16) % 16 | 0;
          dt = Math.floor(dt / 16);
          return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
        }
      );
      return uuid.toUpperCase();
    },
    isset(obj) {
      let objIsset = false;
      try {
        if (Array.isArray(obj) && obj.length > 0) {
          objIsset = Array.isArray(obj);
        } else if (typeof obj == "object") {
          objIsset = Object.keys(obj).length > 0;
        } else {
          objIsset = obj !== null && obj !== undefined && obj !== "";
        }
        return objIsset;
      } catch {
        return objIsset;
      }
    },
    imgPath(img) {
      return require("../assets/theme/img/" + img);
    },
    replaceAll(str, find, replace) {
      return str.replace(new RegExp(find, "g"), replace);
    },
    JSONparse(str) {
      try {
        return JSON.parse(str);
      } catch {
        return JSON.parse(this.replaceAll(str, "'", '"'));
      }
    },
    getSecureName() {
      const md5 = require("md5");
      const timestamp = Date.now();
      let chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890";
      let result = "";
      for (let i = 0; i < 10; i++) {
        result += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      result += timestamp;
      return md5(result);
    },
    customParseJSON(obj = {}, fields = []) {
      let _obj = JSON.parse(JSON.stringify(obj));
      fields.forEach((_field) => {
        let field = "";
        let _fieldData = "{}";
        try {
          field = _field.toString();
        } catch (error) {
          field = null;
        }
        if (!field) return;
        if (typeof _obj[field] == "string" && _obj[field].length > 0)
          _fieldData = _obj[field];
        _obj[field] = this.JSONparse(_fieldData);
      });
      return _obj;
    },
    logError(err) {
      this.$store.dispatch("expSnackBarStore/showSnack", {
        msg: err,
      });
    },
    reportOnlyRead(status) {
      try {
        let _auth_user = this.$store.getters["auth/getAuthCustomer"];
        if (_auth_user.data.user_type == 6) return true;
      } catch {}
      return status == 1;
    },
  },
  filters: {
    lower: function (value) {
      if (!value) return "";
      return value.toString().toLowerCase();
    },
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString().toLowerCase();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
  computed: {
    isAuthenticated() {
      return store.getters["isAuthenticated"];
    },
    loggedInUser() {
      return store.getters["loggedInUser"];
    },
    userType() {
      let _auth_user = store.getters["auth/getAuthCustomer"];
      return _auth_user.data.user_type;
    },
  },
};
export default basicMixin;
