
export const storeComun = {
  namespaced: true,
  state: {
    is_mobile: false,
    list_lang_allowed: [],
    lang_default: '',
    lists: [],
    lists_pivot: [],
    is_online: false,
    is_synchronizing: false,
    last_sync: '',
    app_version: ''
  },
  getters: {
    getLangAllowed(state) {
      return state.list_lang_allowed
    },
    getLangDefault(state) {
      return state.lang_default
    },
    getLists(state) {
      return state.lists
    },
    getListsPivot(state) {
      return state.lists_pivot
    },
    getIsOnline(state) {
      return state.is_online
    },
    getIsMobile(state) {
      return state.is_mobile
    },
    getSynchronizing(state) {
      return state.is_synchronizing
    },
    getListItemByCode: (state) => (code = '') => {
      let items = []
      let codeFilter = state.lists.find(list => list.code === code)
      if (codeFilter) items = codeFilter.items
      return items
    },
    getLastSync(state) {
      return state.last_sync
    },
    getAppVersion(state) {
      return state.app_version
    },
  },
  mutations: {
    setLangAllowed(state, data) {
      state.list_lang_allowed = data
    },
    setLists(state, data) {
      if (data != undefined && data != null) {
        let itemsByCode = state.lists.find(x => x.code == data.code)
        if (itemsByCode == undefined) {
          state.lists.push(data)
        } else {
          itemsByCode.items = data.items
        }
      }
    },
    setListsPivot(state, data) {
      if (data != undefined && data != null) {
        let itemsPivotByCode = state.lists_pivot.find(x => x.code == data.code)
        if (itemsPivotByCode == undefined) {
          state.lists_pivot.push(data)
        } else {
          itemsPivotByCode.items = data.items
        }
      }
    },
    setLangDefault(state, data) {
      state.lang_default = data
    },
    setAllLists(state, data) {
      state.lists = data
    },
    setAllListsPivot(state, data) {
      state.lists_pivot = data
    },
    setIsOnline(state, data) {
      state.is_online = data
    },
    setIsMobile(state, data) {
      state.is_mobile = data
    },
    setSynchronizing(state, data) {
      state.is_synchronizing = data
    },
    setLastSync(state, data) {
      state.last_sync = data
    },
    setAppVersion(state, data) {
      state.app_version = data
    }
  },
}