var EntitySchema = require("typeorm").EntitySchema;

module.exports = new EntitySchema({
    name: "oreConfigCampaign",
    tableName: "ore_config_campaign",
    columns: {
        id: {
            primary: true,
            type: "int",
            generated: false
        },
        name: {
            type: "varchar"
        },
        description: {
            type: "varchar",
            nullable: true
        },
        site:{
            type: "int"
        },
        crusher:{
            type: "int"
        },
        start_date: {
            type: "datetime",
            nullable: true
        },
        end_date: {
            type: "datetime",
            nullable: true
        },
        sub_contractor: {
            type: "int",
            nullable: true
        },
        sub_contractor_external: {
            type: "int",
            nullable: true
        },
        no_blasting: {
            type: "int",
            default: "0"
        },
        product_mode: {
            type: "int",
            nullable: true
        },
        supervisor: {
            type: "int",
            nullable: true
        },
        created: {
            type: "datetime",
            nullable: true
        },
        created_by: {
            type: "int",
            nullable: true
        },
        update: {
            type: "datetime",
            nullable: true
        },
        update_by: {
            type: "int",
            nullable: true
        },
        deleted: {
            type: "datetime",
            nullable: true
        },
        deleted_by: {
            type: "int",
            nullable: true
        },
        published: {
            type: "int",
            default: "1"
        }
    }
})