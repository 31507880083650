<template>
  <v-app class="ore-index">
    <exp-loading v-show="isLoading" />
    <v-container class="login-container">
      <exp-login
        class="exp-login-control"
        :loginBackend="false"
        :funcLogin="customersLogin"
        :funcLoginMsal="customersLoginMsal"
        :haveTranslations="false"
        :colorPrimary="'yellow darken-1'"
        :textPrimary="'#000000'"
      />
    </v-container>
  </v-app>
</template>
<script>
import customersMixin from "../../mixins/customer";

import expLogin from "../../components/expLogin";
import expLoading from "../../components/expLoading";
import basicMixin from "../../mixins/basic";

export default {
  auth: false,
  name: "page-login",
  layout: "colas",
  mixins: [customersMixin, basicMixin],
  components: { expLogin, expLoading },
  mounted() {
    if (this.isCustomersAuthenticated) {
      this.openRoute("/");
    }
  }
};
</script>
