import { loginCustomer, loginCustomerMsal, dataCustomer } from "@/gateway/customer"

const customersMixin = {
  methods: {
    async customersLogin(data) {
      const self = this
      self.isLoading = true
      let _loginCustomer
      await loginCustomer(data)
        .then(response => _loginCustomer = response.data)
        .catch(error => {
          _loginCustomer = false
          self.msgError(self.$t('user.errors.' + error.response.data.msg))
        })
      if (_loginCustomer !== false) {
        let _dataCustomer
        await dataCustomer(_loginCustomer['access'])
          .then(response => {
            _dataCustomer = response.data
            this.$store.commit('auth/setAuthCustomer', {
              access: _loginCustomer['access'],
              refresh: _loginCustomer['refresh'],
              data: _dataCustomer
            })
            window.location.reload()
          })
          .catch(error => _dataCustomer = false)
        if (_dataCustomer !== false) {

        } else {

        }
      }
      self.isLoading = false
    },
    async customersLoginMsal(data, msal = null) {
      const self = this
      self.isLoading = true
      let _loginCustomer
      await loginCustomerMsal(data)
        .then(response => _loginCustomer = response.data)
        .catch(error => {
          _loginCustomer = false
          self.msgError(self.$t('user.errors.' + error.response.data.msg))
          this.$store.commit('auth/setAuthCustomer', null)
          msal.signOut()
        })
      if (_loginCustomer !== false) {
        let _dataCustomer
        await dataCustomer(_loginCustomer['access'])
          .then(response => {
            _dataCustomer = response.data
            this.$store.commit('auth/setAuthCustomer', {
              access: _loginCustomer['access'],
              refresh: _loginCustomer['refresh'],
              data: _dataCustomer
            })
            window.location.reload()
          })
          .catch(error => _dataCustomer = false)
        if (_dataCustomer !== false) {

        } else {

        }
      }
      self.isLoading = false
    },
    customersLogout() {
      this.$store.commit('auth/setAuthCustomer', {})
      this.$store.commit('auth/setLastLanguage', {
        lang: "",
        messages: {}
      })
      window.location.reload()
    }
  },
  async mounted() {
    if (this.isCustomersAuthenticated) {
      const data = this.$store.getters['auth/getAuthCustomer']
    }
  },
  computed: {
    isCustomersAuthenticated() {
      return this.$store.getters['auth/isAuthCustomer']
    },
    hasCustomerProfile() {
      try {
        let _customer = this.$store.getters['auth/getAuthCustomer']
        return (_customer.data.first_name.length > 0 &&
          _customer.data.last_name.length > 0 &&
          parseInt('0' + _customer.data.mobile_number) > 0)
      } catch (e) {
        return false
      }
    }
  }
}
export default customersMixin