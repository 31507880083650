var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('maintenanceForm',{ref:"maintenanceForm",attrs:{"idReport":_vm.storeReportId,"idCrusher":_vm.storeCrusherId,"equipmentSelected":_vm.equipmentSelectedData,"locked":_vm.lockTypeLocked,"disabledBtnReport":_vm.storeOnlyReadReport},on:{"onSavedData":_vm.onSavedDataForm}}),_c('v-card',{attrs:{"elevation":"0","color":'transparent'}},[_c('exp-report-header',{ref:"expHeader",attrs:{"color":_vm.color,"code":'',"colorText":_vm.colorText,"disabledBtnReport":_vm.storeOnlyReadReport},on:{"onSave":function($event){return _vm.saveModuleStatus()}}}),_c('v-card-text',{staticClass:"table_module"},[_c('v-row',{staticClass:"my-1",attrs:{"no-gutters":""}},[_c('v-col',[_c('v-sheet',{staticClass:"mx-auto"},[_c('v-slide-group',{attrs:{"multiple":"","show-arrows":""}},_vm._l((_vm.maintenancePlan),function(itemZone,index){return _c('v-slide-item',{key:index,scopedSlots:_vm._u([{key:"default",fn:function({ active, toggle }){return [_c('v-btn',{key:index,staticClass:"mx-1",attrs:{"height":"70","width":"154","color":!itemZone._in_maintenance
                      ? _vm.getColorVerifyCondition(undefined, itemZone.id).color
                      : _vm.getColorVerifyCondition(
                          itemZone._condition,
                          itemZone.id
                        ).color,"rounded":"","depressed":_vm.actualZone != itemZone.id},on:{"click":function($event){return _vm.changeZone(itemZone)}}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t(`core.list_item.${itemZone.name}`))+" ")])])]}}],null,true)})}),1)],1)],1)],1),_c('div',{staticClass:"custom-v-data-table",staticStyle:{"width":"100%","height":"100%"}},[_c('v-data-table',{attrs:{"height":"496","fixed-header":"","headers":_vm.headers,"items":_vm.equipmentsZone,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.options",fn:function({ item }){return [_c('div',{staticClass:"d-flex align-center justify-center body-option-table"},[_c('v-icon',{staticClass:"body-icon-option-table",attrs:{"large":""},on:{"click":function($event){return _vm.openModalVerifyEquip(item.id)}}},[_vm._v(" "+_vm._s(_vm.storeOnlyReadReport == false ? "mdi-pencil" : "mdi-eye")+" ")])],1)]}},{key:"item.condition",fn:function({ item }){return [_c('div',{staticClass:"text-center"},[_c('v-icon',{attrs:{"x-large":"","color":!item._in_maintenance
                    ? _vm.getColorVerifyCondition().color
                    : _vm.getColorVerifyCondition(item._condition).color}},[_vm._v("mdi-checkbox-blank-circle")])],1)]}},{key:"item.last_checked",fn:function({ item }){return [_c('div',[_c('span',[_vm._v(_vm._s(_vm.getDateTimeFormated(item._last_date_condition)))])])]}}])})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }