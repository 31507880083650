import { getRepository } from "typeorm";

/**
 * It is used to get all records from inventory table
 * @param {Number} reportId - Report Id.
 * @returns {Array} All records available in the report.
 */
export async function getMaintenanceDetailsOthers(crusherId, shift, date) {
  // ONLY mobile
  const repository = await getRepository("oreReportMaintenance");
  crusherId = Number(crusherId);
  let sameDate = "";
  if (shift === 0) {
    sameDate = `OR (r.date = '${date}' AND r.shift = 1)`;
  }

  let maintence_dails_others = await repository.query(`
            select
            ORMD.created,
            ORMD.created_by,
            ORMD.deleted,
            ORMD.deleted_by,
            ORMD.equipment,
            ORMD.equipment_maintenance,
            ORMD.id,
            ORMD.published,
            ORMD.report,
            ORMD.sync,
            ORMD.type,
            STRFTIME('%Y-%m-%dT%H:%M:%SZ', ORMD.[update]) as [update],
            ORMD.update_by,
            ORMD.update_web,
            ORMD.uuid,
            ORMD.value,
            ORMD.web_id
            from [ore_report_maintenance_details] as ORMD inner join [ore_report] AS r on ORMD.report = r.id
                JOIN (select i.equipment_maintenance, i.equipment, r.crusher, max(i.id) as max_id
                    from [ore_report_maintenance_details] as i
                    inner join [ore_report] AS r on i.report = r.id
                    where r.crusher = ${crusherId} AND (r.date < '${date}' ${sameDate}) AND r.deleted IS NULL
                    group by i.equipment_maintenance, i.equipment, r.crusher) AS bridge
                    on
                    r.crusher = bridge.crusher AND
                    ORMD.equipment = bridge.equipment AND
                    ORMD.equipment_maintenance = bridge.equipment_maintenance AND
                    ORMD.id = bridge.max_id
            `).then(response => { return response}).catch(error => { console.log(error); return [] });
  return maintence_dails_others
}