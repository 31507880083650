import { createQueryBuilder, getRepository, In } from "typeorm";
import { getPivoteItems, getListItems } from "@/gateway/lists"
import { listAllMaintenanceByReport } from "@/gateway/maintenance"
import { getMaintenanceDetailsOthers } from "./helper"
import { listAllMaintenanceDetailsByMaintenance } from "@/gateway/maintenance_details"
import { baseApi, isConfigMobile, consolelog, getCustomerJWT } from './base'
import axios from 'axios'
import store from "@/store"
import router from '@/router'
import moment from "moment";
import lodash from 'lodash'

/**
 * It is used to check if the user can create a new report,
 * this search an existing report filtering by campaign, scenario, crusher, shift and date.
 * @param {Object} data - Object with the data to find a report.
 * @returns {Object} An object with the an state to create or no a report.
 */
export function checkReport(data) {
    let isMobile = isConfigMobile()
    if (isMobile) {
        return new Promise(async function (resolve) {
            const report = getRepository("oreReport")
                .find({ campaign: data.campaign, scenario: data.scenario, shift: data.shift, date: data.date, deleted: null })
                .then(response => {
                    if (response.length > 0) {
                        response = { "id": response[0].id, "state": true, "state_report": response[0].status };
                        resolve(response)
                    } else {
                        response = { "id": 0, "state": false };
                        resolve(response)
                    }
                }).catch(error => console.log(error))
        })
    } else {
        return new Promise(function (resolve) {
            const token = getCustomerJWT()
            axios.post(baseApi() + '1.0/ore/report/check/', data, { headers: { Authorization: `Bearer ${token}` } })
                .then(response => { resolve(response.data) })
                .catch(error => reject(error))
        })
    }
}

/**
 * It is used to create a new record in the report table,
 * based on the information received. Also, this create
 * the defaults records of all modules of the new report.
 * @param {Object} data - Object with the information to create a new report.
 * @returns {Object} All information from the new report created.
 */
export function newReport(data) {
    let isMobile = isConfigMobile()
    if (isMobile) {
        store.commit("comun/setSynchronizing", true)
        return new Promise(async function (resolve) {
            const coreUsersRolesRepository = await getRepository("coreUsersRoles");
            const coreUserRepository = await getRepository("coreUser");
            const reportRepository = await getRepository("oreReport");
            const configCampaignTargetRepository = await getRepository("oreConfigCampaignTarget");

            const configCampaign = await getRepository("oreConfigCampaign");
            const configCrusherRepository = await getRepository("oreConfigCrusher");

            const current_date = new Date().toISOString()
            const report_date = data.date
            const report_date_time = new Date(report_date + " 12:00:00")
            const user_id = store.getters["auth/getAuthCustomer"].data.id

            // Validate that the same report hasn't yet been created and the system doesn't have a more recent report
            let next_reports = await reportRepository.createQueryBuilder("report")
                .where("report.campaign = :campaign", { campaign: data.campaign })
                .andWhere("report.scenario = :scenario", { scenario: data.scenario })
                .andWhere("report.date >= :date", { date: data.date })
                .andWhere("report.deleted IS NULL")
                .orderBy("report.date", "DESC")
                .addOrderBy("report.shift", "ASC")
                .getMany(); // getMany return array -> getOne return obj or undefined if not found item
            //consolelog({ msg: 'Next reports', data: next_reports });

            if (next_reports.length > 0) {
                if (next_reports.find(x => x.date == data.date && x.shift == data.shift) != undefined) {
                    return { "msg": "_ERROR_REPORT_CREATE" }
                }
            }

            // Look for the last report created in the selected campaing, scenario and crusher.
            let last_report_created = { id: null }
            if (next_reports.find(x => (x.date == data.date && x.shift < data.shift) || (x.date > data.date)) == undefined) {
                last_report_created = await reportRepository.createQueryBuilder("report")
                    .where("report.campaign = :campaign", { campaign: data.campaign })
                    .andWhere("report.scenario = :scenario", { scenario: data.scenario })
                    .andWhere("report.date <= :date", { date: data.date })
                    .andWhere("report.deleted IS NULL")
                    .orderBy("report.date", "DESC")
                    .addOrderBy("report.shift", "ASC")
                    .getOne().then(response => { if (typeof (response) == 'undefined') { return { id: null } } else { return response } })
            }

            let last_report_created_crusher = { id: null }
            last_report_created_crusher = await reportRepository.createQueryBuilder("report")
                .where("report.crusher = :crusher", { crusher: data.crusher })
                .andWhere("report.deleted IS NULL")
                .orderBy("report.date", "DESC")
                .addOrderBy("report.shift", "ASC")
                .addOrderBy("report.created", "DESC")
                .getOne().then(response => { if (typeof (response) == 'undefined') { return { id: null } } else { return response } })
            //consolelog({ msg: 'Last report Crusher', data: last_report_created_crusher });

            let _campaign = await configCampaign.findOne({ id: data.campaign}).then(response => { return response }).catch(error => console.log(error))
            let _user = await coreUserRepository.findOne({ id: user_id}).then(response => { return response }).catch(error => console.log(error))
            let _isOperator = false
            let _supervisor = 0
            if(typeof(_user) != 'undefined' && _user.type_user == 5) {
                let _userRole

                if(_campaign.supervisor != 1)
                    _userRole = await coreUsersRolesRepository.findOne({ user: user_id, crusher: _campaign.crusher, type: 5, published: true })
                        .then(response => { return response }).catch(error => console.log(error))
                else
                    _userRole = await coreUsersRolesRepository.findOne({ user: user_id, site: _campaign.site, type: 5, published: true })
                        .then(response => { return response }).catch(error => console.log(error))

                if(typeof(_userRole) != 'undefined'){
                    _isOperator = true
                    _supervisor = _userRole.supervisor
                }
            }

            // Get Campaign Target
            let campaignTarget = await configCampaignTargetRepository.findOne({ campaign: data.campaign })
            let scheduleAll = data.shift ? campaignTarget.schedule_day : campaignTarget.schedule_night
            scheduleAll = scheduleAll.replaceAll("'", "\"")
            try { scheduleAll = JSON.parse(scheduleAll) } catch { scheduleAll = {} }
            let d = new Date(data.date + ' 00:00:00')
            let scheduleDay
            try { scheduleDay = scheduleAll[d.getDay()] } catch { scheduleDay = {} }
            let getDatesFromSchedule = {
                production_start: fixDataSchedule("planned_production_hours_start", scheduleDay, "date_d", data.date),
                production_end: fixDataSchedule("planned_production_hours_end", scheduleDay, "date_u", data.date),
                production_time: fixDataSchedule("planned_production_hours", scheduleDay, "time", data.date),
                planned_down_time: fixDataSchedule("planned_downtime", scheduleDay, "time", data.date)
            }
            if (getDatesFromSchedule.production_start > getDatesFromSchedule.production_end) {
                getDatesFromSchedule.production_end = moment(getDatesFromSchedule.production_end).add('days', 1)
            }

            // Create the new report
            data.uuid = createUUID();
            data.status = 3;
            data.update = current_date;
            data.update_by = user_id;
            data.created = current_date;
            data.created_by = user_id;
            data.status_by = user_id;
            data.assigned = _isOperator ? _supervisor : user_id; // If the report is created by an operator, Must assigned to the supervisor of the crusher
            let new_report = await reportRepository.save(data).then(response => { return response })
            //consolelog({ msg: 'New report', data: new_report });

            let crusher_config = await configCrusherRepository.findOne({ id: data.crusher }).then(response => {
                if (typeof (response) == 'undefined') {
                    return { format: '{}'}
                } else {
                    response.format = atob(response.format)
                    return response
                }
            });

            let global_data = {
                crusher_config: crusher_config,
                last_report_id: last_report_created.id,
                last_report_crusher_id: last_report_created_crusher.id,
                new_report_id: new_report.id,
                report_date_time: report_date_time,
                report_date: report_date,
                current_date: current_date,
                user_id: user_id,
                production_start: getDatesFromSchedule.production_start,
                production_end: getDatesFromSchedule.production_end,
                production_time: getDatesFromSchedule.production_time,
                planned_down_time: getDatesFromSchedule.planned_down_time,
            }

            // Create the data for the modules
            await new_startStopReport(global_data);
            await new_energyReport(global_data);
            await new_inventoryReport(global_data);
            await new_productionReport(global_data);

            // We validate if the iPad is online to sync the information
            let checkOnLine = store.getters["comun/getIsOnline"]
            if (checkOnLine) {
                await syncNewReport(new_report.id)
            } else {
                resolve(new_report);
            }

        })
    } else {
        return new Promise(function (resolve) {
            const token = getCustomerJWT()
            axios.post(baseApi() + '1.0/ore/report/', data, { headers: { Authorization: `Bearer ${token}` } })
                .then(response => resolve(response.data))
                .catch(error => console.log(error))
        })
    }
}

function fixDataSchedule(fieldSchedule, schedule, type, rdate) {
    let valReturn = "";
    try {
        let valSC = schedule[fieldSchedule].split(".")
        let time = ("00" + valSC[0]).substr(-2) + ":" + ("00" + Math.round(valSC[1] * 60 / 100)).substr(-2) + ":00"
        if(type == "time") {
            valReturn = time
        } else {
            valReturn = moment(rdate + " " + time).toISOString()
        }
    } catch {
        if(type == "time") {
            valReturn = "00:00:00"
        } else if (type == "date_d") {
            valReturn = moment(rdate + " 06:00:00").toISOString()
        } else {
            valReturn = moment(rdate + " 18:00:00").toISOString()
        }
    }
    return valReturn
}

 /**
  * It is used to create the default information in the start stop and start stop events table,
  * using the information from the new report, the last report created before and the crusher.
  * @param {Object} data - Object with information to set default values.
  */
async function new_startStopReport(data) {
    const startStopRepository = await getRepository("oreReportStartStop");
    const startStopEventsRepository = await getRepository("oreReportStartStopEvents");

    let obj_start_stop = {
        report: data.new_report_id,
        production_no: false,
        production_start: data.production_start,
        production_end: data.production_end,
        shift_start: data.production_start,
        shift_end: data.production_end,
        production_time: data.production_time,
        planned_down_time: data.planned_down_time,
        running_time: "00:00:00",
        uuid: createUUID(),
        update: data.current_date,
        update_by: data.user_id,
        created: data.current_date,
        created_by: data.user_id
    }
    await startStopRepository.save(obj_start_stop)
        .then(resp => { consolelog({ msg: 'StartStop Created', data: resp }); return resp })
        .catch(error => consolelog({ msg: 'Error creating StartStop', data: error }))

    // Look for all the events unresolved
    let unresolved_events = []
    if (data.last_report_id != null) {
        unresolved_events = await startStopEventsRepository.find({ report: data.last_report_id, unresolved: true, deleted: null })
            .then(response => { if (response.length > 0) { return response } else { return [] } })
            .catch(error => consolelog(error))
    }

    if (unresolved_events.length > 0) {
        let _items_to_save = []
        await unresolved_events.map(async item => {
            delete item.id;
            item.report = data.new_report_id;
            item.web_id = null
            item.sync = false;
            item.uuid = createUUID();
            item.update = data.current_date;
            item.update_by = data.user_id;
            item.created = data.current_date;
            item.created_by = data.user_id;
            _items_to_save.push(item)
        })
        await startStopEventsRepository.save(_items_to_save)
            .then(resp => consolelog({ msg: "StartStopEvents created", data: resp }))
            .catch(error => consolelog({ msg: "Error creating StartStopEvents", data: error }));
    }
}

 /**
  * It is used to create the default information in the production module,
  * using the information from the new report, the last report created before and the crusher.
  * @param {Object} data - Object with information to set default values.
  */
async function new_productionReport(data) {
    const productionRepository = await getRepository("oreReportProductionHours");

    let obj_production = {
        report: data.new_report_id,
        uuid: createUUID(),
        reprocessing_hours: "00:00:00",
        running_time: "00:00:00",
        production_hours: data.production_time,
        update: data.current_date,
        update_by: data.user_id,
        created: data.current_date,
        created_by: data.user_id
    }
    await productionRepository.save(obj_production)
        .then(resp => { consolelog({ msg: 'Production Created', data: resp }); return resp })
        .catch(error => consolelog({ msg: 'Error creating Production', data: error }))
}

 /**
  * It is used to create the default information in the energy table,
  * using the information from the new report, the last report created before and the crusher.
  * @param {Object} data - Object with information to set default values.
  */
async function new_energyReport(data) {
    const energyRepository = await getRepository("oreReportEnergy");
    let crusher_config = data.crusher_config;
    let energy_default_items = await getPivoteItems("EAS", crusher_config.id);
    let energy_last_report = []

    if (data.last_report_id != null) {
        await energyRepository.find({ report: data.last_report_id, deleted: null })
            .then(response => { if (response.length > 0) { energy_last_report = response; return energy_last_report } else { return [] } })
    }

    let energy_last_report_valid = energy_last_report
    let _items_to_save_master = []
    let _items_to_save_sub = []
    let energy_new_item = {
        energy_type: 0,
        energy_use: 0,
        report: data.new_report_id,
        factor: 0,
        started: 0,
        received: 0,
        consumed: 0,
        sold: 0,
        remaining: 0,
        manual: 0,
        comment: "",
        start_time: moment(data.report_date + " 06:00:00").toISOString(),
        end_time: moment(data.report_date + " 18:00:00").toISOString(),
        master_sub: true,
        master_uuid: null,
        uuid: 0,
        update: data.current_date,
        update_by: data.user_id,
        created: data.current_date,
        created_by: data.user_id,
    }
    // MASTERS
    await energy_default_items.filter(x => x.parent_id == null).forEach(async e => {
        if (e.parent_id == null) {
            let _energy_new_item = lodash.cloneDeep(energy_new_item)
            _energy_new_item.master_sub = true
            _energy_new_item.master_uuid = null
            _energy_new_item.energy_use = e.list_itema
            _energy_new_item.energy_type = e.list_itemb
            _energy_new_item.uuid = createUUID()

            let energy_last_item_master = energy_last_report_valid.find(x =>
                x.master_uuid == null &&
                x.energy_type == e.list_itemb &&
                x.energy_use == e.list_itema
            );
            if ( typeof(energy_last_item_master) != "undefined" ) {
                _energy_new_item.started = energy_last_item_master.remaining
                _energy_new_item.factor = energy_last_item_master.factor
                _energy_new_item.factor_carbon = energy_last_item_master.factor_carbon
                _energy_new_item.factor_type = energy_last_item_master.factor_type
                // SUBS
                await energy_default_items.filter(y => y.parent_id == e.id).forEach(async e => {
                    let _energy_new_item_sub = lodash.cloneDeep(energy_new_item)
                    if(e.parent_id != null) {
                        _energy_new_item_sub.energy_type = e.list_itemb
                        _energy_new_item_sub.energy_use = e.list_itema
                        _energy_new_item_sub.uuid = createUUID()
                        _energy_new_item_sub.master_sub = false
                        _energy_new_item_sub.master_uuid = _energy_new_item.uuid

                        let energy_last_item = energy_last_report_valid.find(y =>
                            y.energy_type == e.list_itemb &&
                            y.energy_use == e.list_itema &&
                            y.master_uuid == energy_last_item_master.uuid
                        );
                        if ( typeof(energy_last_item) != "undefined" ) {
                            _energy_new_item_sub.started = energy_last_item.remaining
                            _energy_new_item_sub.factor = energy_last_item.factor
                            _energy_new_item_sub.factor_carbon = energy_last_item.factor_carbon
                            _energy_new_item_sub.factor_type = energy_last_item.factor_type
                        }
                        _items_to_save_sub.push(_energy_new_item_sub)
                    }
                })
            } else {
                // SUBS
                await energy_default_items.filter(y => y.parent_id == e.id).forEach(async e => {
                    let _energy_new_item_sub = lodash.cloneDeep(energy_new_item)
                    if(e.parent_id != null) {
                        _energy_new_item_sub.energy_type = e.list_itemb
                        _energy_new_item_sub.energy_use = e.list_itema
                        _energy_new_item_sub.uuid = createUUID()
                        _energy_new_item_sub.master_sub = false
                        _energy_new_item_sub.master_uuid = _energy_new_item.uuid

                        _items_to_save_sub.push(_energy_new_item_sub)
                    }
                })
            }
            _items_to_save_master.push(_energy_new_item)
        }

    });

    await energyRepository.save(_items_to_save_master)
        .then(resp => {  consolelog({ msg: "Energy Master created", data: resp }); return resp }).catch(error => console.log("error energy master", error))
    await energyRepository.save(_items_to_save_sub)
        .then(resp => {  consolelog({ msg: "Energy SUBS created", data: resp }); return resp }).catch(error => console.log("error energy sub", error))


}

 /**
  * It is used to create the default information in the inventory table,
  * using the information from the new report, the last report created before and the crusher.
  * @param {Object} data - Object with information to set default values.
  */
async function new_inventoryReport(data) {
    const inventoryRepository = await getRepository("oreReportInventory");
    let crusher_config = data.crusher_config;
    let default_inventory_items = await getListItems("INV", crusher_config.id);
    /*
    * It's 25 and it's not the weekend? - create items
    * no - is the weekend that 25? - Is the current day equal to the next business day after the 25th? - create items
    * no - not created
    */
    const date_report = data.report_date_time
    // Capture the day of the week [0-6]
    let today = date_report.getDay()
    let can_create_report;

    // If Today is 25 and it is NOT the weekend [6 or 0] it is created
    if (date_report.getDate() == 25 && (today != 6 && today != 0)) {
        can_create_report = true

        // If Today is the 26th and IS Monday [1] is created (Because the 25th was Sunday)
    } else if (date_report.getDate() == 26 && today == 1) {
        can_create_report = true

        // If Today is the 27th and IS Monday [1] is created (Because the 25th was Saturday)
    } else if (date_report.getDate() == 27 && today == 1) {
        can_create_report = true
    } else {
        can_create_report = false
    }
    // For Canada, Inventory is always created
    can_create_report = true
    if (can_create_report) {
        let inventory_last_report = []
        if (data.last_report_crusher_id != null) {
            await inventoryRepository.find({ report: data.last_report_crusher_id, deleted: null })
                .then(response => { if (response.length > 0) { inventory_last_report = response; return inventory_last_report } else { return [] } })
        }
        let _items_to_save = []
        await default_inventory_items.forEach(async e => {
            let inventory_item_last_report = inventory_last_report.filter(x => x.inventory_item == e.id)
            let item_last_report = inventory_item_last_report.length > 0 ? inventory_item_last_report[0] : null
            let obj_inventory = {
                report: data.new_report_id,
                uuid: createUUID(),
                inventory_item: e.id,
                started: item_last_report == null ? 0 : item_last_report.remaining > 0 ? item_last_report.remaining : 0,
                remaining: item_last_report == null ? 0 : item_last_report.remaining > 0 ? item_last_report.remaining : 0,
                consumed: 0,
                update: data.current_date,
                update_by: data.user_id,
                created: data.current_date,
                created_by: data.user_id
            }
            _items_to_save.push(obj_inventory)
        })
        await inventoryRepository.save(_items_to_save)
            .then(resp => { consolelog({ msg: 'inventory created', data: resp }); return resp }).catch(error => console.log(error))
    } else {
        let msg = `HOY NO SE PUEDE CREAR INVENTORY PORQUE ES: ${date_report.getDate()} y cae un ${today}`;
        consolelog({ msg: msg, data: [] });
    }
}

/**
 * It is used to get records about reports, the information is filtered and paginated by parameters.
 * @param {Object} data - Object with the data to find.
 * @param {Number} page - The number of the current page.
 * @param {Number} itemsPerPage - The number of the items per page.
 * @returns {Array} An array with all the data available by the filters.
 */
export function getAllReports(data, page, itemsPerPage) {
    let isMobile = isConfigMobile()
    if (isMobile) {
        if (page == 0)
            page = 1
        return new Promise(async function (resolve) {
            // A format is given to the dates that come to be able to make the query by dates
            let _endDate = new Date(data.dates[1])
            _endDate.setHours(_endDate.getHours() + 23);
            let _startDate = new Date(data.dates[0]).toISOString().slice(0, 10).replace(/[A-Z]/, " ");
            _endDate = _endDate.toISOString().slice(0, -1).replace(/[A-Z]/, " ");
            // To filter reports by flowsheet
            let _inactiveCampaign = data.state.find(x => x === 100)
            let _flowsheet = data.state.find(x => x === 101)
            let interval = setInterval(async () => {
                if (store.getters['ore/getConnection'] == true) {
                    const report = getRepository("oreReport")
                        .createQueryBuilder("report")
                        .select(["report.*", "user.email as user_email", "campaign.name as campaign_name", "site.name as site_name", "crusher.name as crusher_name", "scenario.name as scenario_name"])
                        .leftJoin("oreConfigCampaign", "campaign", "campaign.id = report.campaign")
                        .leftJoin("oreConfigSite", "site", "site.id = campaign.site")
                        .leftJoin("oreConfigCrusher", "crusher", "crusher.id = report.crusher")
                        .leftJoin("oreConfigScenario", "scenario", "scenario.id = report.scenario")
                        .leftJoin("coreUser", "user", "user.id = report.assigned")
                        .orderBy("report.date", "DESC")
                        .addOrderBy("campaign.name", "ASC")
                        .addOrderBy("report.shift", "ASC")
                        .where("report.status IN (:...states)", { states: data.state })
                        .andWhere("report.deleted IS NULL")
                        .andWhere("report.date BETWEEN :startDate AND :endDate", { startDate: _startDate, endDate: _endDate })
                        .andWhere("report.campaign IN (:...campaigns)", { campaigns: data.campaigns })
                        if (!_inactiveCampaign) {
                            report.andWhere("campaign.published = true")
                        }
                        let skip = 0
                    // The total of records is obtained for the # of pages
                    let totalRecords = await report.getRawMany()
                        .then(response => { return response.length })
                        .catch(error => console.log(error))
                    // # of pages
                    let pages = Math.ceil(totalRecords / itemsPerPage);
                    // If current page > # of new pages, it is set equal to the new value.
                    if (page > pages) page = pages
                    if (page != 1) skip = (page - 1) * itemsPerPage
                    // Data is filtered according to page #
                    let result = await report.offset(skip).limit(itemsPerPage).getRawMany()
                        .then(response => { return response })
                        .catch(error => console.log(error))

                    let _array = []
                    let _users = []
                    result.forEach(async (e) => {
                        if (e.deleted == null) {
                            _users.push({ "id": e.assigned, "email": e.user_email })
                            _array.push(e)
                        }
                    })

                    _users = _users.filter(x => x.email != null)
                    // An object is created with the pagination info
                    let obj = {
                        totalRecords: totalRecords,
                        pagesPagination: pages,
                        page: page,
                        users: _users
                    }
                    _array.push(obj)
                    resolve(_array)
                    clearInterval(interval);
                }
            }, 400)
        })
    } else {
        return new Promise(function (resolve) {
            let options = {
                filters: data,
                page: page,
                itemsPerPage: itemsPerPage
            }
            const token = getCustomerJWT()
            axios.post(baseApi() + '1.0/ore/report/all_reports/', options, { headers: { Authorization: `Bearer ${token}` } })
                .then(response => { resolve(response.data) })
                .catch(error => console.log(error))
        })
    }
}

/**
 * It is used to validate the information of the report
 * filtered by the id of the report.
 * @returns {Object} An object with the basic information of the report
 */
export async function getReportById(id) {
    let isMobile = store.getters["comun/getIsMobile"];
    if (isMobile) {
        return new Promise(async function (resolve) {
            let _array = await getRepository("oreReport").query(`SELECT * FROM [ore_report] WHERE id = ${id}`);
            resolve(_array);
        });
    } else {
        return new Promise(function (resolve) {
            const token = getCustomerJWT();
            axios
                .get(baseApi() + "1.0/ore/reportbyid/" + id + "/", {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => console.log(error));
        });
    }
}

/**
 * It is used to get all the information of the report
 * and all the information of its subreports,
 * filtered by the id of the report.
 * @returns {Object} An object with all information of the report and sub-reports.
 */
export async function getReport() {
    let reportId = store.getters['ore/getReportId']
    let isMobile = isConfigMobile()
    if (isMobile) {
        if (reportId != undefined && reportId > 0) {
            let reportSelected = await store.getters['ore/getReportSelected']
            console.log("before if", reportSelected)
            if (reportSelected.report != undefined && reportSelected.report.id == reportId) {
                return new Promise(function (resolve) {
                    resolve(reportSelected)
                })
            } else {
                let item = {}
                const report = await getRepository("oreReport").findOne({ id: reportId, deleted: null }).then(response => { item.report = response; return response });
                const reportStatus = await getRepository("oreReportStatus").find({ report: reportId }).then(response => { item.report_status = response; /*console.log("report_status", response)*/ });
                const startStop = await getRepository("oreReportStartStop")
                    .findOne({ report: reportId, deleted: null })
                    .then(response => {
                        if (typeof (response) == "undefined") { item.start_stop = {} }
                        else if (response.production_no == 0 || response.production_no == false) {
                            response.production_start = moment(response.production_start).format("YYYY-MM-DD HH:mm")
                            response.production_end = moment(response.production_end).format("YYYY-MM-DD HH:mm")
                            response.shift_start = moment(response.shift_start).format("YYYY-MM-DD HH:mm")
                            response.shift_end = moment(response.shift_end).format("YYYY-MM-DD HH:mm")
                            item.start_stop = response
                        } else {
                            response.production_start = null
                            response.production_end = null
                            response.shift_start = moment(response.shift_start).format("YYYY-MM-DD HH:mm")
                            response.shift_end = moment(response.shift_end).format("YYYY-MM-DD HH:mm")
                            item.start_stop = response
                        }
                    });
                const startStopEvents = await getRepository("oreReportStartStopEvents").find({ report: reportId, deleted: null }).then(response => {
                    response.forEach((startStopEvent) => {
                        startStopEvent.restart_time = moment(startStopEvent.restart_time).format("YYYY-MM-DD HH:mm")
                        if (startStopEvent.start_time != null && startStopEvent.start_time != undefined) {
                            startStopEvent.restart_time = moment(startStopEvent.restart_time).format("YYYY-MM-DD HH:mm")
                        }
                    })
                    item.start_stop_events = response
                });
                const productionHours = await getRepository("oreReportProductionHours").findOne({ report: reportId, deleted: null }).then(response => { if (typeof (response) == "undefined") { item.production_hours = {} } else { item.production_hours = response } });
                const productionManHours = await getRepository("oreReportProductionManHours").find({ report: reportId, deleted: null }).then(response => { item.production_man_hours = response });
                const materialCrushing = await getRepository("oreReportMaterialCrushing").find({ report: reportId, deleted: null }).then(response => { item.material_crushing = response; });
                const materialCrushingActivities = await getRepository("oreReportMaterialCrushingActivities").find({ report: reportId, deleted: null }).then(response => { item.material_crushing_activities = response; });
                const energy = await getRepository("oreReportEnergy").find({ report: reportId, deleted: null }).then(response => { item.energy = response; });
                const inventory = await getRepository("oreReportInventory").find({ report: reportId, deleted: null }).then(response => { item.inventory = response; });
                const comments = await getRepository("oreReportComments")
                    .createQueryBuilder("comment")
                    .select(["comment.*", "user.email as user_email"])
                    .leftJoin("coreUser", "user", "user.id = comment.update_by")
                    .where("comment.report = :report", { report: reportId })
                    .andWhere("comment.deleted IS NULL")
                    .getRawMany().then(response => { item.comments = response; });
                const modulesStatus = await getRepository("oreReportModuleStatus").find({ report: reportId, deleted: null }).then(response => { item.modules_status = response; });
                let idsEquipmentCrusher = []
                await getRepository("oreConfigEquipment").find({ select: ["id"], where: { crusher: report.crusher, deleted: null } }).then(response => { response.map(x => idsEquipmentCrusher.push(x.id)); return response; });

                const equipment = await getRepository("oreConfigEquipment").find({ crusher: report.crusher, deleted: null }).then(response => { return response; });

                const listItemRepository = await getRepository("coreListItem")
                let idsListItem = []
                await listItemRepository.query(`SELECT parent_id FROM core_listitem WHERE id IN(SELECT category FROM ore_config_equipment WHERE category IS NOT NULL AND crusher = ${report.crusher}) GROUP BY parent_id`).then(r => { r.map(x => { if (x.parent_id != null) { idsListItem.push(x.parent_id) } }); return r })
                await listItemRepository.query(`SELECT zone FROM ore_config_equipment WHERE zone IS NOT NULL AND crusher = ${report.crusher} GROUP BY zone`).then(r => { r.map(x => { if (x.zone != null) { idsListItem.push(x.zone) } }); return r })
                await listItemRepository.query(`SELECT category FROM ore_config_equipment WHERE category IS NOT NULL AND crusher = ${report.crusher} GROUP BY category`).then(r => { r.map(x => { if (x.category != null) { idsListItem.push(x.category) } }); return r })

                const equipmentMaintenanceRepository = await getRepository("oreConfigEquipmentMaintenance");
                const equipment_maintenance = await equipmentMaintenanceRepository.query(`SELECT * FROM ore_config_equipment_maintenance WHERE deleted IS NULL AND equipment IN(${idsEquipmentCrusher})`)

                const zone = await listItemRepository.query(`SELECT * FROM core_listitem WHERE id IN (${idsListItem})`)

                const maintenance = await listAllMaintenanceByReport(reportId);

                const maintenance_details = await getRepository("oreReportMaintenanceDetails")
                .query(`SELECT ORMD.created, ORMD.created_by, ORMD.deleted, ORMD.deleted_by, ORMD.equipment,
                        ORMD.equipment_maintenance, ORMD.id, ORMD.published, ORMD.report, ORMD.sync, ORMD.type,
                        STRFTIME('%Y-%m-%dT%H:%M:%SZ', ORMD.[update]) as [update], ORMD.update_by, ORMD.update_web,
                        ORMD.uuid, ORMD.value, ORMD.web_id FROM ore_report_maintenance_details as ORMD WHERE report = ${reportId} AND deleted IS NULL`)

                const maintenance_details_others = await getMaintenanceDetailsOthers(report.crusher, report.shift, report.date)

                item.maintenance = maintenance;
                item.maintenance_details = maintenance_details;
                item.maintenance_others = [];
                item.maintenance_details_others = maintenance_details_others;
                item.zone = zone;
                item.equipment = equipment;
                item.equipment_maintenance = equipment_maintenance;

                store.commit('ore/setReportSelected', item);
                return new Promise(function (resolve) {
                    resolve(item)
                })
            }
        } else {
            return new Promise(function (resolve) {
                resolve({})
            })
        }
    } else {
        return new Promise(function (resolve) {
            if (reportId != undefined && reportId > 0) {
                let reportSelected = store.getters['ore/getReportSelected']
                if (reportSelected.report != undefined && reportSelected.report.id == reportId) {
                    resolve(reportSelected)
                } else {
                    const token = getCustomerJWT()
                    axios.get(baseApi() + '1.0/ore/report/all/' + reportId + "/", { headers: { Authorization: `Bearer ${token}` } })
                        .then(function (response) {
                            store.commit('ore/setReportSelected', response.data)
                            resolve(response.data)
                        })
                        .catch(error => reject(error))
                }
            } else {
                resolve({})
            }
        })
    }
}

/**
 * It is used to make a soft delete of the report and all sub-reports.
 * If it is mobile, this function takes two additional parameters to return the updated information.
 * @param {Number} id - Report Id.
 * @param {Object} filters - Filters to return the information in the mobile application.
 * @param {Object} options - Options to return the information in the mobile application
 * @returns {Array} - All reports available.
 */
export async function deleteFullReport(id, filters, options) {
    let isMobile = isConfigMobile()
    if (isMobile) {
        store.commit("comun/setSynchronizing", true);
        const reportRepository = await getRepository("oreReport")
        const startStopRepository = await getRepository("oreReportStartStop");
        const startStopEventsRepository = await getRepository("oreReportStartStopEvents");
        const productionHoursRepository = await getRepository("oreReportProductionHours");
        const productionManHoursRepository = await getRepository("oreReportProductionManHours");
        const materialCrushingRepository = await getRepository("oreReportMaterialCrushing");
        const materialCrushingActivitiesRepository = await getRepository("oreReportMaterialCrushingActivities");
        const energyRepository = await getRepository("oreReportEnergy");
        const inventoryRepository = await getRepository("oreReportInventory");
        const commentsRepository = await getRepository("oreReportComments");
        const maintenanceRepository = await getRepository("oreReportMaintenance");
        const maintenanceDetailsRepository = await getRepository("oreReportMaintenanceDetails");
        let _reportSync = await reportRepository.findOne({ id: id })
        //If the report is NOT synchronized, it is deleted all at once.
        if (_reportSync.web_id == null) {

            await startStopEventsRepository.delete({ report: id })
            await startStopRepository.delete({ report: id })
            await productionHoursRepository.delete({ report: id })
            await productionManHoursRepository.delete({ report: id })
            await materialCrushingRepository.delete({ report: id })
            await materialCrushingActivitiesRepository.delete({ report: id })
            await energyRepository.delete({ report: id })
            await inventoryRepository.delete({ report: id })
            await commentsRepository.delete({ report: id })
            await maintenanceRepository.delete({ report: id })
            await maintenanceDetailsRepository.delete({ report: id })
            await reportRepository.delete(id)
            return getAllReports(filters, options.page, options.itemsPerPage);
        } else {
            //If the report is synchronized, it is deleted softly to send it to the web application.
            let item = {}
            let now = new Date()
            let user_id = store.getters["auth/getAuthCustomer"].data.id
            await startStopEventsRepository.update({ report: id }, { update: now, update_by: user_id, deleted: now, deleted_by: user_id, sync: false, }).then(async (resUp) => { await startStopEventsRepository.find({ report: id }).then(async (res) => { console.log("find", res); item.start_stop_events = parseDate(res); }).catch(e => console.log(e)) });
            await startStopRepository.update({ report: id }, { update: now, update_by: user_id, deleted: now, deleted_by: user_id, sync: false, }).then(async (resUp) => { await startStopRepository.find({ report: id }).then(async (res) => { item.start_stop = parseDate(res) }).catch(e => console.log(e)) });
            await productionHoursRepository.update({ report: id }, { update: now, update_by: user_id, deleted: now, deleted_by: user_id, sync: false, }).then(async (resUp) => { await productionHoursRepository.find({ report: id }).then(async (res) => { item.production_hours = parseDate(res) }).catch(e => console.log(e)) });
            await productionManHoursRepository.update({ report: id }, { update: now, update_by: user_id, deleted: now, deleted_by: user_id, sync: false, }).then(async (resUp) => { await productionManHoursRepository.find({ report: id }).then(async (res) => { item.production_man_hours = parseDate(res) }).catch(e => console.log(e)) });
            await materialCrushingRepository.update({ report: id }, { update: now, update_by: user_id, deleted: now, deleted_by: user_id, sync: false, }).then(async (resUp) => { await materialCrushingRepository.find({ report: id }).then(async (res) => { item.material_crushing = parseDate(res) }).catch(e => console.log(e)) });
            await materialCrushingActivitiesRepository.update({ report: id }, { update: now, update_by: user_id, deleted: now, deleted_by: user_id, sync: false, }).then(async (resUp) => { await materialCrushingActivitiesRepository.find({ report: id }).then(async (res) => { item.material_crushing_activities = parseDate(res) }).catch(e => console.log(e)) });
            await energyRepository.update({ report: id }, { update: now, update_by: user_id, deleted: now, deleted_by: user_id, sync: false, }).then(async (resUp) => { await energyRepository.find({ report: id }).then(async (res) => { item.energy = parseDate(res) }).catch(e => console.log(e)) });
            await inventoryRepository.update({ report: id }, { update: now, update_by: user_id, deleted: now, deleted_by: user_id, sync: false, }).then(async (resUp) => { await inventoryRepository.find({ report: id }).then(async (res) => { item.inventory = parseDate(res) }).catch(e => console.log(e)) });
            await commentsRepository.update({ report: id }, { update: now, update_by: user_id, deleted: now, deleted_by: user_id, sync: false, }).then(async (resUp) => { await commentsRepository.find({ report: id }).then(async (res) => { item.comments = parseDate(res) }).catch(e => console.log(e)) });
            await maintenanceRepository.update({ report: id }, { update: now, update_by: user_id, deleted: now, deleted_by: user_id, sync: false, }).then(async (resUp) => { await maintenanceRepository.find({ report: id }).then(async (res) => { item.maintenance = parseDate(res) }).catch(e => console.log(e)) });
            await maintenanceDetailsRepository.update({ report: id }, { update: now, update_by: user_id, deleted: now, deleted_by: user_id, sync: false, }).then(async (resUp) => { await maintenanceDetailsRepository.find({ report: id }).then(async (res) => { item.maintenance_details = parseDate(res) }).catch(e => console.log(e)) });
            await reportRepository.update(id, { update: now, update_by: user_id, deleted: now, deleted_by: user_id, sync: false, });

            let checkOnLine = store.getters["comun/getIsOnline"]
            if (checkOnLine) {
                let reportData = await reportRepository.find({ id: id }).then(res => { return parseDate(res) })

                let objToSync = {
                    reports: [
                        {
                            report: reportData[0],
                            data: {
                                start_stop: typeof (item.start_stop[0]) == 'undefined' ? {} : item.start_stop[0],
                                start_stop_events: item.start_stop_events,
                                production_hours: typeof (item.production_hours[0]) == 'undefined' ? {} : item.production_hours[0],
                                production_man_hours: item.production_man_hours,
                                material_crushing: item.material_crushing,
                                material_crushing_activities: item.material_crushing_activities,
                                energy: item.energy,
                                inventory: item.inventory,
                                comments: item.comments,
                                maintenance: item.maintenance,
                                maintenance_details: item.maintenance_details,
                            }
                        }
                    ],
                }
                const token = getCustomerJWT()
                await axios.post(baseApi() + '1.0/ore/mobile/sync/', objToSync, { headers: { Authorization: `Bearer ${token}` } })
                    .then(async function (response) {
                        let reportRes = response.data.reports[0].report
                        let dataRes = response.data.reports[0].data
                        if (reportRes.action_msg != "reject") {
                            await startStopEventsRepository.delete({ report: id }).then().catch(error => console.log(error))
                            await startStopRepository.delete({ report: id }).then().catch(error => console.log(error))
                            await productionHoursRepository.delete({ report: id }).then().catch(error => console.log(error))
                            await productionManHoursRepository.delete({ report: id }).then().catch(error => console.log(error))
                            await materialCrushingRepository.delete({ report: id }).then().catch(error => console.log(error))
                            await materialCrushingActivitiesRepository.delete({ report: id }).then().catch(error => console.log(error))
                            await energyRepository.delete({ report: id }).then().catch(error => console.log(error))
                            await inventoryRepository.delete({ report: id }).then().catch(error => console.log(error))
                            await commentsRepository.delete({ report: id }).then().catch(error => console.log(error))
                            await maintenanceRepository.delete({ report: id }).then().catch(error => console.log(error))
                            await maintenanceDetailsRepository.delete({ report: id }).then().catch(error => console.log(error))
                            await reportRepository.delete(id).then().catch(error => console.log(error))
                        }
                        store.commit("comun/setSynchronizing", false);
                        return getAllReports(filters, options.page, options.itemsPerPage);
                    }).catch(error => { console.log(error); store.commit("comun/setSynchronizing", false); return getAllReports(filters, options.page, options.itemsPerPage); })
            } else {
                return getAllReports(filters, options.page, options.itemsPerPage);
            }
        }
        store.commit("comun/setSynchronizing", false);
    } else {
        return new Promise(function (resolve, reject) {
            const token = getCustomerJWT()
            axios.delete(baseApi() + '1.0/ore/report/full_delete/' + id + "/", { headers: { Authorization: `Bearer ${token}` } })
                .then(response => resolve(response.data))
                .catch(error => console.log(error))
        })
    }
}

/**
 * It is used to get to get all the status saved of the report.
 * @param {Number} reportId - Report Id.
 * @returns {Array} An array with all the status report.
 */
export function getReportStatus(reportId) {
    let isMobile = isConfigMobile()
    if (isMobile) {
        return new Promise(async function (resolve) {
            const reportStatus = await getRepository("oreReportStatus");
            await reportStatus.createQueryBuilder("oreReportStatus")
                .where("oreReportStatus.report = :report", { report: reportId })
                .orderBy("oreReportStatus.created", "ASC")
                .getMany()
                .then(response => {
                    if (response.length > 0) {
                        resolve(response)
                    } else {
                        resolve([]);
                    }
                }).catch(error => { console.log("error", error) })
        })
    } else {
        return new Promise(function (resolve) {
            if (reportId != undefined && reportId > 0) {
                const token = getCustomerJWT()
                axios.get(baseApi() + '1.0/ore/report/get_status/' + reportId + "/", { headers: { Authorization: `Bearer ${token}` } })
                    .then(function (response) {
                        resolve(response.data)
                    }).catch(error => reject(error))
            } else {
                resolve({})
            }
        })
    }
}

/**
 * It is used to get all status saved of the report modules (sub-reports).
 * @param {Number} report - Report Id.
 * @returns {Array} An array with all the modules status of the report.
 */
async function getAllModulesStatus(report) {
    const modulesStatusRepository = await getRepository("oreReportModuleStatus");
    await modulesStatusRepository.createQueryBuilder("moduleStatus")
        .where("moduleStatus.report = :report", { report: report })
        .orderBy("moduleStatus.created", "DESC")
        .getMany()
        .then(response => {
            return new Promise(function (resolve) {
                if (typeof (response) == 'undefined') {
                    store.commit("ore/setLoadingReport", false)
                    resolve([]);
                } else {
                    store.commit("ore/setUpdateReportsSelected", { 'modules_status': response });
                    store.commit("ore/setLoadingReport", false)
                    console.log("getAllModulesStatus", response);
                    resolve(response);
                }
            })
        }).catch(error => { console.log("error", error) })
}

/**
 * It is used to create (if not exist) or update the status of the report module.
 * @param {String} code - Module Code.
 * @returns {Array} An array with all the modules status of the report.
 */
export async function saveUpdateModuleSatus(code) {
    let reportId = store.getters['ore/getReportId']
    store.commit("ore/setLoadingReport", true)
    let isMobile = isConfigMobile()
    if (isMobile) {
        store.commit("comun/setSynchronizing", true);
        let reportId = store.getters['ore/getReportId']
        let now = new Date().toISOString()
        let user_id = store.getters["auth/getAuthCustomer"].data.id
        store.commit("ore/setLoadingReport", true)

        if (reportId != undefined && reportId > 0) {
            let objSend = {
                "report": reportId,
                "module": code,
                "uuid": createUUID()
            }

            const modulesStatusRepository = await getRepository("oreReportModuleStatus");
            let item_last = await modulesStatusRepository.createQueryBuilder("moduleStatus")
                .where("moduleStatus.report = :report", { report: reportId })
                .andWhere("moduleStatus.module = :code", { code: code })
                .orderBy("moduleStatus.created", "DESC")
                .getMany()
                .then(response => { if (typeof (response) == 'undefined') { return []; } else { return response; } })
                .catch(error => { console.log("error", error) })

            if (item_last.length > 0) {
                let _item = item_last[0]
                await modulesStatusRepository.update(_item.id, {
                    update: now,
                    update_by: user_id,
                    sync: false
                }).then(async () => { return true }).catch(error => console.log(error))
            } else {
                objSend.update = now;
                objSend.update_by = user_id;
                objSend.created = now;
                objSend.created_by = user_id;
                await modulesStatusRepository.save(objSend).then(async () => { return true }).catch(error => console.log(error))
            }

            const reportRepository = await getRepository("oreReport")
            await reportRepository.update(reportId, { sync: false, }).then((res) => { }).catch(error => console.log(error))

            let checkOnLine = store.getters["comun/getIsOnline"]
            if (checkOnLine) {

                let item = {}
                await reportRepository.findOne({ id: reportId, deleted: null }).then(response => { item.report = parseDate([response]); return response });
                item.modules_status = await modulesStatusRepository.createQueryBuilder("moduleStatus")
                    .where("moduleStatus.report = :report", { report: reportId })
                    .andWhere("moduleStatus.module = :code", { code: code })
                    .orderBy("moduleStatus.update", "DESC")
                    .getMany()
                    .then(response => { if (typeof (response) == 'undefined') { return []; } else { return parseDate(response); } })
                    .catch(error => { console.log("error", error) })

                let objToSync = {
                    reports: [
                        {
                            report: typeof (item.report[0]) == 'undefined' ? {} : item.report[0],
                            data: {
                                modules_status: item.modules_status,
                            }
                        }
                    ]
                }
                const token = getCustomerJWT()
                await axios.post(baseApi() + '1.0/ore/mobile/sync/', objToSync, { headers: { Authorization: `Bearer ${token}` } })
                    .then(async function (response) {
                        let reportRes = response.data.reports[0].report //report web
                        let dataRes = response.data.reports[0].data
                        //If report is not synchronized and it was already created in web, the variables are updated.
                        if (reportRes.action_status == true && reportRes.web_id != null) {
                            await reportRepository.update(item.report[0].id,
                                {
                                    web_id: reportRes.web_id,
                                    update_web: new Date(reportRes.update_web),
                                    sync: true,
                                })
                        }

                        if ("modules_status" in dataRes) {
                            await syncModule(dataRes.modules_status, modulesStatusRepository, dataRes.newReporId);
                        }
                        store.commit("comun/setSynchronizing", false);
                        return getAllModulesStatus(reportId)

                    }).catch(error => { store.commit("comun/setSynchronizing", false); console.log("Module status", error); return getAllModulesStatus(reportId) })

            } else {
                store.commit("comun/setSynchronizing", false);
                return getAllModulesStatus(reportId)
            }
        } else {
            store.commit("ore/setLoadingReport", false)
        }
    } else {
        return new Promise(function (resolve, reject) {
            if (reportId != undefined && reportId > 0) {
                let objSend = {
                    "report": reportId,
                    "module": code
                }
                const token = getCustomerJWT()
                axios.post(baseApi() + '1.0/ore/report/module_status/register_save/', objSend, { headers: { Authorization: `Bearer ${token}` } })
                    .then(function (response) {
                        store.commit("ore/setUpdateReportsSelected", { 'modules_status': response.data });
                        store.commit("ore/setLoadingReport", false)
                        resolve(response.data)
                    })
                    .catch(error => {
                        store.commit("ore/setLoadingReport", false)
                        reject(error)
                    })
            } else {
                store.commit("ore/setLoadingReport", false)
                resolve({})
            }
        })
    }
}

/**
 * It is used to save the current state of the report in the table report status.
 * @param {Object} data - Object with the data about report status.
 * @returns {Array} An array with all the status report.
 */
export async function saveUpdateReportSatus(data) {
    data.published = true;
    let reportId = data.report ? data.report : store.getters['ore/getReportId']
    store.commit("ore/setLoadingReport", true)
    let isMobile = isConfigMobile()
    if (isMobile) {
        store.commit("comun/setSynchronizing", true);
        const reportRepository = getRepository("oreReport");
        const reportStatusRepository = getRepository("ore_report_status");
        store.commit("ore/setLoadingReport", true)
        let user_id = store.getters["auth/getAuthCustomer"].data.id
        let now = new Date().toISOString()

        // Report status is updated
        await reportRepository.update(reportId, {
            status: data.status,
            sync: false
        }).then(response => { console.log(response); return response; })
            .catch(error => console.log(error))

        //The record is created for the report status log
        let obj = {
            report: reportId,
            status: data.status == 3 ? 96 : data.status, // Only get 3 when user have reopened the report
            comment: data.comment ? data.comment : null,
            created: now,
            created_by: user_id,
            update: now,
            update_by: user_id,
            published: true,
            uuid: createUUID()
        }
        await reportStatusRepository.save(obj)
            .then(response => { return response; })
            .catch(error => console.log(error))

        let checkOnLine = store.getters["comun/getIsOnline"]
        if (checkOnLine) {
            let item = {}
            await reportRepository.findOne({ id: reportId, deleted: null }).then(response => { item.report = parseDate([response]); return response });
            item.report_status = await reportStatusRepository.createQueryBuilder("reportStatus")
                .where("reportStatus.report = :report", { report: reportId })
                .orderBy("reportStatus.update", "DESC")
                .getMany()
                .then(response => { if (typeof (response) == 'undefined') { return []; } else { return parseDate(response); } })
                .catch(error => { console.log("error", error) })

            let objToSync = {
                reports: [
                    {
                        report: typeof (item.report[0]) == 'undefined' ? {} : item.report[0],
                        data: {
                            report_status: item.report_status,
                        }
                    }
                ]
            }

            const token = getCustomerJWT()
            await axios.post(baseApi() + '1.0/ore/mobile/sync/', objToSync, { headers: { Authorization: `Bearer ${token}` } })
                .then(async function (response) {
                    let reportRes = response.data.reports[0].report //report web
                    let dataRes = response.data.reports[0].data
                    //If report is not synchronized and it was already created in web, the variables are updated.
                    if (reportRes.action_status == true && reportRes.web_id != null) {
                        await reportRepository.update(item.report[0].id,
                            {
                                web_id: reportRes.web_id,
                                update_web: new Date(reportRes.update_web),
                                sync: true,
                            })
                    }

                    if ("report_status" in dataRes) {
                        await syncModule(dataRes.report_status, reportStatusRepository, dataRes.newReporId);
                    }

                    store.commit("comun/setSynchronizing", false);
                    if (router.history.current.fullPath === "/") {
                        return new Promise(function (resolve) {
                            store.commit("ore/setLoadingReport", false)
                            resolve(response.data)
                        })
                    } else {
                        router.push("/")
                    }

                }).catch(error => {
                    store.commit("comun/setSynchronizing", false);

                    if (router.history.current.fullPath === "/") {
                        return new Promise(function (resolve) {
                            store.commit("ore/setLoadingReport", false)
                            resolve(response.data)
                        })
                    } else {
                        router.push("/")
                    }
                    console.log(error);
                })

        } else {
            store.commit("comun/setSynchronizing", false);
            if (router.history.current.fullPath === "/") {
                return new Promise(function (resolve) {
                    store.commit("ore/setLoadingReport", false)
                    resolve(response.data)
                })
            } else {
                router.push("/")
            }
        }

    } else {
        return new Promise(function (resolve, reject) {
            if (reportId != undefined && reportId > 0) {
                let objSend = {
                    "report": reportId,
                    "status": data.status,
                    "published": true
                }
                if (data.comment) objSend.comment = data.comment
                const token = getCustomerJWT()
                axios.post(baseApi() + '1.0/ore/report/report_status/register_save/', objSend, { headers: { Authorization: `Bearer ${token}` } })
                    .then(function (response) {
                        store.commit("ore/setLoadingReport", false)
                        if (router.history.current.fullPath === "/") {
                            resolve(response.data)
                        } else {
                            router.push("/")
                        }

                    })
                    .catch(error => {
                        store.commit("ore/setLoadingReport", false)
                        console.log(error)
                        reject(error)
                    })
            } else {
                store.commit("ore/setLoadingReport", false)
                resolve({})
            }
        })
    }
}

/**
 * It is used to update the data in the mobile application after syncronizing a new report,
 * if the process is rejected, the data incoming is parsed and saved; otherwise the audit fields are updated.
 * @param {Array} dataRes - Array with the data synchronized.
 * @param {Class} repository - Entity to manipulate the models.
 * @param {Number} newReporId - Report Id.
 */
async function syncModule(dataRes, repository, newReporId) {
    let _items = dataRes
    let _itemsTosave = []
    await _items.forEach(async (item) => {
        //If the synchronization is rejected, the element is updated with the information that comes from the web application.
        if (item.action_msg == "reject") {
            item.report = newReporId
            item.sync = true
            // To prevent fails in safari (IOS)
            if ("date_time" in item)
                item.date_time = moment(item.date_time).toISOString();

            if ("update_web" in item) {
                item.update_web = moment(item.update_web).toISOString();
            }
            if (("production_start" in item) && item.production_start != null)
                item.production_start = moment(item.production_start).toISOString();

            if (("production_end" in item) && item.production_end != null)
                item.production_end = moment(item.production_end).toISOString();

            if (("shift_start" in item) && item.shift_start != null)
                item.shift_start = moment(item.shift_start).toISOString();

            if (("shift_end" in item) && item.shift_end != null)
                item.shift_end = moment(item.shift_end).toISOString();

            if (("stop_time" in item) && item.stop_time != null)
                item.stop_time = moment(item.stop_time).toISOString();

            if (("restart_time" in item) && item.restart_time != null)
                item.restart_time = moment(item.restart_time).toISOString();

            if (("start_time" in item) && item.start_time != null)
                item.start_time = moment(item.start_time).toISOString();

            if (("end_time" in item) && item.end_time != null)
                item.end_time = moment(item.end_time).toISOString();

            _itemsTosave.push(item)
        } else {
            // If the report is not rejected and the report was created in the web application, the audit fields are updated.
            if (item.action_status) {
                await repository.update({ uuid: item.uuid },
                    {
                        web_id: item.web_id,
                        update_web: new Date(item.update_web),
                        sync: true,
                    },
                ).then((res) => {}).catch(error => console.log(error))
            }
        }
    })
    if (_itemsTosave.length > 0) {
        await repository.save(_itemsTosave).then((res) => {}).catch(error => console.log(error));
    }
}

/**
 * It is used to send the full report data with subreports to the web application.
 * Filtering the information by the id of the newly created report.
 * @param {Number} reportId - Report Id.
 */
export async function syncNewReport(reportId) {
    store.commit("comun/setSynchronizing", true);
    // This object will be the one that is sent as data to save in the web application.
    let _reportToResolve = {}
    const reportRepository = await getRepository("oreReport")
    const startStopRepository = await getRepository("oreReportStartStop");
    const startStopEventsRepository = await getRepository("oreReportStartStopEvents");
    const productionHoursRepository = await getRepository("oreReportProductionHours");
    const productionManHoursRepository = await getRepository("oreReportProductionManHours");
    const materialCrushingRepository = await getRepository("oreReportMaterialCrushing");
    const materialCrushingActivitiesRepository = await getRepository("oreReportMaterialCrushingActivities");
    const energyRepository = await getRepository("oreReportEnergy");
    const inventoryRepository = await getRepository("oreReportInventory");
    const commentsRepository = await getRepository("oreReportComments");
    const maintenanceRepository = await getRepository("oreReportMaintenance");
    const maintenanceDetailsRepository = await getRepository("oreReportMaintenanceDetails");
    const modulesStatusRepository = await getRepository("oreReportModuleStatus");
    const reportStatusRepository = getRepository("ore_report_status")

    let item = {}
    await reportRepository.findOne({ id: reportId, deleted: null, sync: false }).then(response => { item.report = parseDate([response]); _reportToResolve = response; return response });
    await startStopRepository.findOne({ report: reportId, deleted: null, sync: false })
        .then(response => {
            if (typeof (response) == "undefined") { item.start_stop = {} }
            else if (response.production_no == 0 || response.production_no == false) {
                response.production_start = new Date(response.production_start).toISOString();
                response.production_end = new Date(response.production_end).toISOString();
                response.shift_start = new Date(response.shift_start).toISOString();
                response.shift_end = new Date(response.shift_end).toISOString();
                item.start_stop = parseDate([response])
            } else {
                response.production_start = null
                response.production_end = null
                response.shift_start = new Date(response.shift_start).toISOString();
                response.shift_end = new Date(response.shift_end).toISOString();
                item.start_stop = parseDate([response])
            }
        });
    await startStopEventsRepository.find({ report: reportId, deleted: null, sync: false }).then(response => { item.start_stop_events = parseDate(response); });
    await productionHoursRepository.findOne({ report: reportId, deleted: null, sync: false }).then(response => { if (typeof (response) == "undefined") { item.production_hours = {} } else { item.production_hours = parseDate([response]) } });
    await productionManHoursRepository.find({ report: reportId, deleted: null, sync: false }).then(response => { item.production_man_hours = parseDate(response) });
    await materialCrushingRepository.find({ report: reportId, deleted: null, sync: false }).then(response => { item.material_crushing = parseDate(response); });
    await materialCrushingActivitiesRepository.find({ report: reportId, deleted: null, sync: false }).then(response => { item.material_crushing_activities = parseDate(response); });
    await energyRepository.find({ report: reportId, deleted: null, sync: false }).then(response => { item.energy = parseDate(response); });
    await inventoryRepository.find({ report: reportId, deleted: null, sync: false }).then(response => { item.inventory = parseDate(response); });
    await commentsRepository.find({ report: reportId, deleted: null, sync: false }).then(response => { item.comments = parseDate(response); });

    let objToSync = {
        reports: [
            {
                report: typeof (item.report[0]) == 'undefined' ? {} : item.report[0],
                data: {
                    start_stop: typeof (item.start_stop[0]) == 'undefined' ? {} : item.start_stop[0],
                    start_stop_events: item.start_stop_events,
                    production_hours: typeof (item.production_hours[0]) == 'undefined' ? {} : item.production_hours[0],
                    production_man_hours: item.production_man_hours,
                    material_crushing: item.material_crushing,
                    material_crushing_activities: item.material_crushing_activities,
                    energy: item.energy,
                    inventory: item.inventory,
                    comments: item.comments,
                }
            }
        ],
    }

    const token = getCustomerJWT()
    await axios.post(baseApi() + '1.0/ore/mobile/sync/', objToSync, { headers: { Authorization: `Bearer ${token}` } })
        .then(async function (response) {
            let reportRes = response.data.reports[0].report //report web
            let dataRes = response.data.reports[0].data
            let reportData = item.report //report local

            //If the report is rejected, everything is deleted to save what comes from the web application.
            if (reportRes.action_msg == "reject") {
                await reportRepository.delete(reportData[0].id)
                reportRes.date = reportRes.date.slice(0, 10)
                let newReporId = await reportRepository.save(reportRes).then((res) => { _reportToResolve = res; return res.id; }).catch(error => console.log(error));
                await startStopEventsRepository.delete({ report: reportData[0].id })
                await startStopRepository.delete({ report: reportData[0].id })
                await productionHoursRepository.delete({ report: reportData[0].id })
                await productionManHoursRepository.delete({ report: reportData[0].id })
                await materialCrushingRepository.delete({ report: reportData[0].id })
                await materialCrushingActivitiesRepository.delete({ report: reportData[0].id })
                await energyRepository.delete({ report: reportData[0].id })
                await inventoryRepository.delete({ report: reportData[0].id })
                await commentsRepository.delete({ report: reportData[0].id })
                //Since the report was rejected, the id of the new report from the mobile application is needed to save it.
                dataRes.newReporId = newReporId;
            } else {
                // If the report is not rejected and the report was created in the web application, the audit fields are updated.
                if (reportData[0].web_id == null && reportRes.web_id != null) {
                    await reportRepository.update(reportData[0].id,
                        {
                            web_id: reportRes.web_id,
                            update_web: new Date(reportRes.update_web),
                            sync: true,
                        }).then(res => _reportToResolve = reportData[0])
                }
            }
            if ("start_stop" in dataRes) {
                await syncModule([dataRes.start_stop], startStopRepository, dataRes.newReporId);
            }
            if ("start_stop_events" in dataRes) {
                await syncModule(dataRes.start_stop_events, startStopEventsRepository, dataRes.newReporId);
            }
            if ("production_hours" in dataRes) {
                await syncModule([dataRes.production_hours], productionHoursRepository, dataRes.newReporId);
            }
            if ("production_man_hours" in dataRes) {
                await syncModule(dataRes.production_man_hours, productionManHoursRepository, dataRes.newReporId);
            }
            if ("material_crushing" in dataRes) {
                await syncModule(dataRes.material_crushing, materialCrushingRepository, dataRes.newReporId);
            }
            if ("material_crushing_activities" in dataRes) {
                await syncModule(dataRes.material_crushing_activities, materialCrushingActivitiesRepository, dataRes.newReporId);
            }
            if ("energy" in dataRes) {
                await syncModule(dataRes.energy, energyRepository, dataRes.newReporId);
            }
            if ("inventory" in dataRes) {
                await syncModule(dataRes.inventory, inventoryRepository, dataRes.newReporId);
            }
            if ("comments" in dataRes) {
                await syncModule(dataRes.comments, commentsRepository, dataRes.newReporId);
            }
            if ("maintenance" in dataRes) {
                await syncModule(dataRes.maintenance, maintenanceRepository, dataRes.newReporId);
            }
            if ("maintenance_details" in dataRes) {
                await syncModule(dataRes.maintenance_details, maintenanceDetailsRepository, dataRes.newReporId);
            }
            if ("modules_status" in dataRes) {
                await syncModule(dataRes.modules_status, modulesStatusRepository, dataRes.newReporId);
            }
            if ("report_status" in dataRes) {
                await syncModule(dataRes.report_status, reportStatusRepository, dataRes.newReporId);
            }

            await store.commit("ore/setReportId", _reportToResolve.id);
            await store.commit("ore/setCrusherId", _reportToResolve.crusher);
            await store.commit("ore/setLoadingData", { code: "report", state: false, });
            store.commit("comun/setSynchronizing", false);
            router.push("/report/summary");

        }).catch(async (error) => {
            console.log(error)
            await store.commit("ore/setReportId", _reportToResolve.id);
            await store.commit("ore/setCrusherId", _reportToResolve.crusher);
            await store.commit("ore/setLoadingData", { code: "report", state: false, });
            store.commit("comun/setSynchronizing", false);
            router.push("/report/summary");
        })
}

/**
 * It is used to parse data fields to send information to the web application
 * in the synchronization process.
 * @param {Array} data - Data to be parse.
 * @returns {Array} An array with the data parsed.
 */
function parseDate(data) {
    let _array = data
    if (!Array.isArray(data)) {
        _array = [data]
    }
    if (typeof (data[0]) != 'undefined') {
        _array.forEach(element => {
            if (element.created == null) {
                delete element.created
            } else {
                element.created = element.created != null ? moment(element.created).format("YYYY-MM-DD HH:mm") : element.created
            }
            element.update = moment(element.update).format("YYYY-MM-DD HH:mm")

            if (element.deleted != null && element.deleted != "")
                element.deleted = moment(element.deleted).format("YYYY-MM-DD HH:mm")

            if ("date" in element)
                element.date = new Date(element.date).toISOString();

            if ("date_time" in element)
                element.date_time = moment(element.date_time).format("YYYY-MM-DD HH:mm")

            if ("status_date" in element)
                element.status_date = moment(element.status_date).format("YYYY-MM-DD HH:mm")

            if (("production_start" in element) && element.production_start != null)
                element.production_start = moment(element.production_start).format("YYYY-MM-DD HH:mm")

            if (("production_end" in element) && element.production_end != null)
                element.production_end = moment(element.production_end).format("YYYY-MM-DD HH:mm")

            if (("shift_start" in element) && element.shift_start != null)
                element.shift_start = moment(element.shift_start).format("YYYY-MM-DD HH:mm")

            if (("shift_end" in element) && element.shift_end != null)
                element.shift_end = moment(element.shift_end).format("YYYY-MM-DD HH:mm")

            if (("stop_time" in element) && element.stop_time != null)
                element.stop_time = moment(element.stop_time).format("YYYY-MM-DD HH:mm");

            if (("restart_time" in element) && element.restart_time != null)
                element.restart_time = moment(element.restart_time).format("YYYY-MM-DD HH:mm");

            if (("start_time" in element) && element.start_time != null)
                element.start_time = moment(element.start_time).format("YYYY-MM-DD HH:mm")

            if (("end_time" in element) && element.end_time != null)
                element.end_time = moment(element.end_time).format("YYYY-MM-DD HH:mm")

        });
    }
    return _array;
}

/**
 * It is used to create a unique string to be able to identify the record when synchronizing.
 * @returns {String} - A unique string to identify the record.
 */
function createUUID() {
    let dt = new Date().getTime()
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (dt + Math.random() * 16) % 16 | 0
        dt = Math.floor(dt / 16)
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16)
    })
    return uuid.toUpperCase()
}