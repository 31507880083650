<template>
  <div>
    <exp-alert-confirm
        ref="modalSaveChanges"
        :message="msg_modal"
        :labelOk="'core.comun.yes'"
        :colorOk="'green'"
        :iconOk="'mdi-check-circle'"
        :fnOk="saveProductionItem"
        :labelCancel="'core.comun.no'"
        :colorCancel="'red'"
        :iconCancel="'mdi-close-circle'"
        :fnCancel="dontSaveChange"
    />
    <v-dialog v-model="showDialog" persistent max-width="700px">
      <exp-loading v-show="isLoading" />
      <v-card :disabled="saving">
        <v-card-title class="headline lighten-2 pb-0 mb-2">
          {{ $t("ore.report.add_production_item") }}
        </v-card-title>
        <ValidationObserver v-slot="{ dirty }" ref="observerProduction">
          <form class="form-production" @submit.prevent="saveProductionItem()">
            <v-card-text>
              <v-row justify="space-between">
                <v-col cols="6" class="py-0" v-if="configForms.number_of_men.visible">
                  <ValidationProvider
                    v-slot="{ errors }"
                    :name="$t('ore.report.number_of_men')"
                    rules="required"
                  >
                    <exp-mask
                        v-model="data.number_of_men"
                        :label="$t('ore.report.number_of_men')"
                        class="input-number"
                        outlined
                        dense
                        :error-messages="errors"
                        :mask="maskDecimalNumber"
                      />
                  </ValidationProvider>
                </v-col>
                <v-col cols="6" class="py-0" v-if="configForms.number_hours_worked.visible">
                  <ValidationProvider
                    v-slot="{ errors }"
                    :name="$t('ore.report.number_hours_worked')"
                    rules="required"
                  >
                    <exp-mask
                        v-model="data.hours_worked"
                        :label="$t('ore.report.number_hours_worked')"
                        class="input-number"
                        outlined
                        dense
                        :error-messages="errors"
                        :mask="maskDecimalNumber"
                      />
                  </ValidationProvider>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn rounded :disabled="!dirty || disabledBtnReport" type="submit" color="green">
                <v-icon left v-if="!saving">mdi-content-save</v-icon>
                <v-progress-circular
                  v-else
                  indeterminate
                  size="24"
                  class="ore-progress-circular-loading"
                ></v-progress-circular>
                {{ $t("core.comun.save") }}&nbsp;
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn rounded @click="cancelBtnForm()" :disabled="saving" color="red">
                <v-icon left>mdi-close-circle</v-icon>
                {{ $t("core.comun.cancel") }}&nbsp;
              </v-btn>
            </v-card-actions>
          </form>
        </ValidationObserver>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import oreMixin from "@/mixins/ore";
import basicMixin from "@/mixins/basic";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import expDateTime from "@/components/expDateTime.vue";
import { createProductionItem, updateProductionItem, getAllProductionItems } from "@/gateway/production_man_hours";
export default {
  name: "modal-add-comments",
  mixins: [basicMixin, oreMixin],
  components: {
    ValidationObserver,
    ValidationProvider,
    expDateTime,
  },
  data() {
    return {
      isLoading: false,
      showDialog: false,
      saving: false,
      msg_modal: "",
      data: {
        number_of_men: 0,
        hours_worked: 0,
      },
      allData: {},
    };
  },
  props: {
    configForms: {
      type: Object,
      default: () => {},
    },
    disabledBtnReport: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    async getAllData(data = null) {
      const self = this;
      try {
        this.allData = data ? data : await getAllProductionItems(this.reportSelectedId);
        this.$store.commit("ore/setUpdateReportsSelected", {
          production_man_hours: self.allData,
        });
      } catch (error) {
        this.logError(error)
      }
    },
    async saveProductionItem() {
      const self = this;
      self.isLoading = true;
      let valid = await self.$refs.observerProduction.validate();
      let _data = []
      if (valid) {
        self.saving = true;
        self.data.report = self.reportSelectedId;
        try {
          if (self.isset(self.data.id)) {
            _data = await updateProductionItem(self.data.id,self.data);
          } else {
            self.data.uuid = self.createUUID()
            _data = await createProductionItem(self.data);
          }
          await self.getAllData(_data);
          self.$emit("parentActions", "_set_dirty", true)
          self.dataForm = true;
          self.showDialog = false;
        }
         catch (error) {
          this.logError(error)
        }
      }
      self.saving = false;
      self.isLoading = false;
    },
    async cancelBtnForm() {
      const self = this
      if(self.isDirty && !self.disabledBtnReport){
        self.msg_modal = "ore.report.msg_data_no_save"
        self.$refs.modalSaveChanges.showDialog = true
      } else {
        self.showDialog = false;
      }
    },
    async dontSaveChange() {
      this.$refs.modalSaveChanges.showDialog = false
      this.showDialog = false;
    }
  },
  computed: {
    isDirty() {
      return this.$refs.observerProduction.ctx.dirty
    },
  },
  watch: {
    showDialog(value) {
      const self = this
      if (value == true) {
        self.dataForm = false;
        setTimeout(function () {
          self.$refs.observerProduction.reset();
        }, 100);
      } else {
        if (self.dataForm) {
            self.$emit("change", self.allData);
        }
      }
    },
  }
};
</script>
