var EntitySchema = require("typeorm").EntitySchema;

module.exports = new EntitySchema({
    name: "oreReportInventory",
    tableName: "ore_report_inventory",
    columns: {
        id: {
            primary: true,
            type: "int",
            generated: true
        },
        report: {
            type: "int"
        },
        inventory_item:{
            type: "int",
            nullable: true
        },
        uuid: {
            type: "varchar",
            length: 50,
            nullable: true
        },
        web_id: {
            type: "int",
            nullable: true
        },
        element_type: {
            type: "int",
            default: "1"
        },  
        element_number: {
            type: "int",
            nullable: true
        },
        element_level: {
            type: "int",
            nullable: true
        },
        started: {
            type: "float",
            nullable: true
        },
        received: {
            type: "float",
            nullable: true
        },
        consumed: {
            type: "float",
            nullable: true
        },
        sold: {
            type: "float",
            nullable: true
        },
        remaining: {
            type: "float",
            nullable: true
        },
        manual: {
            type: "float",
            nullable: true
        },
        comment: {
            type: "varchar",
            nullable: true
        },
        sync: {
            type: "int",
            default: "0"
        },
        update_web: {
            type: "datetime",
            nullable: true
        },
        created: {
            type: "datetime",
            nullable: true
        },
        created_by: {
            type: "int",
            nullable: true
        },
        update: {
            type: "datetime",
            nullable: true
        },
        update_by: {
            type: "int",
            nullable: true
        },
        deleted: {
            type: "datetime",
            nullable: true
        },
        deleted_by: {
            type: "int",
            nullable: true
        },
        published: {
            type: "int",
            default: "1"
        }
    }
})