var EntitySchema = require("typeorm").EntitySchema;

module.exports = new EntitySchema({
    name: "oreConfigProduct",
    tableName: "ore_config_product",
    columns: {
        id: {
            primary: true,
            type: "int",
            generated: false
        },
        qty_to_produce: {
            type: "float",
            nullable: true
        },
        valorization_price: {
            type: "float",
            nullable: true
        },
        cost: {
            type: "float",
            nullable: true
        },
        product_id: {
            type: "int",
            nullable: true
        },
        campaign: {
            type: "int",
            nullable: true
        },
        created: {
            type: "datetime",
            nullable: true
        },
        created_by: {
            type: "int",
            nullable: true
        },
        update: {
            type: "datetime",
            nullable: true
        },
        update_by: {
            type: "int",
            nullable: true
        },
        deleted: {
            type: "datetime",
            nullable: true
        },
        deleted_by: {
            type: "int",
            nullable: true
        },
        published: {
            type: "int",
            default: "1"
        }
    }
})