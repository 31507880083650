<template>
  <div>
    <v-dialog v-model="showDialog" persistent max-width="700px">
      <v-card>
        <v-card-title class="headline lighten-2 pb-0"> </v-card-title>
        <v-card-text>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center" width="100">
                    {{ $t("core.comun.email") }}
                  </th>
                  <th class="text-center">
                    {{ $t("ore.report.date") }}
                  </th>
                  <th class="text-center" width="100">
                    {{ $t("ore.report.comment") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in allData" :key="index">
                  <td>{{ item.user_email }}</td>
                  <td class="text-center">
                    {{ $moment(item.created, "YYYY-MM-DDTHH:mm:ss").format(crusherFormat.format_date + ' HH:mm') }}
                  </td>
                  <td class="text-center"> {{ item.comments }} </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn rounded @click="showDialog = false" color="red">
            <v-icon left>mdi-close-circle</v-icon>
            {{ $t("core.comun.close") }}&nbsp;
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
import oreMixin from "@/mixins/ore";
import basicMixin from "@/mixins/basic";

export default {
  name: "modal_bitumen_reception",
  mixins: [basicMixin, oreMixin],
  data() {
    return {
      showDialog: false,
      report: {},
      allData: [],
    };
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {
    async showDialog(isOpen) {
      const self = this;
      if (isOpen === true) {
        this.report = this.$store.getters["ore/getReportSelected"];
        this.allData = this.report.comments;
      }
    },
  },
};
</script>
