import { baseApi, getCustomerJWT } from './base'
import axios from 'axios'
import store from "@/store"

/**
 * It is used to download all lists from web application to mobile application,
 * filtering by a date to find the latest updated records updated.
 * @param {String} date - Date to synchronize lists.
 * @returns {Object} Object with the data of the lists to save in the mobile application.
 */
export function getSync(date = null, url = "") {
    return new Promise(async function (resolve) {
        const token = getCustomerJWT()
        date = btoa(date)

        let objToResponse = await axios.get(baseApi() + '1.0/' + url + '/' + date + "/", { headers: { Authorization: `Bearer ${token}` } })
            .then(response => { return response.data })
            .catch(error => {console.log(error); return {} })

        resolve(objToResponse)
    })
}

/**
 * It is used to download all reports from web application to mobile application,
 * filtering by a date to find the latest updated records updated and paginated by the index param.
 * @param {String} date - Date to synchronize reports.
 * @param {Number} index - Index to paginate the reports.
 * @returns {Object} Object with the data of the reports to save in the mobile application.
 */
export function getSyncReport(date = null, index = 0) {
    return new Promise(function (resolve) {
        date = btoa(date)
        const token = getCustomerJWT()
        axios.get(baseApi() + '1.0/ore/report/reports_sync/' + date + "/" + index + "/", { headers: { Authorization: `Bearer ${token}` } })
            .then(response => { resolve(response.data) })
            .catch(error => {console.log(error); resolve({})})
    })
}

/**
 * It is used to get the number of total of reports and make the pagination.
 * @param {String} date - Date to get the total of reports.
 * @returns {Number} The number of total of reports.
 */
export function getReportsLen(date = null) {
    return new Promise(function (resolve) {
        date = btoa(date)
        const token = getCustomerJWT()
        axios.get(baseApi() + '1.0/ore/report/reports_len/' + date + "/", { headers: { Authorization: `Bearer ${token}` } })
            .then(response => { resolve(response.data) })
            .catch(error => {console.log(error); resolve({ reports_len : 0 })})
    })
}

/**
 * It is used to download all files records from web application to mobile application,
 * filtering by a date to find the latest updated records updated
 * @param {String} date - Date to synchronize files.
 * @returns {Array} Array with all records from files table.
 */
export function getSyncFiles(date = null) {
    return new Promise(function (resolve) {
        date = btoa(date)
        const token = getCustomerJWT()
        axios.get(baseApi() + '1.0/file/all/' + date + "/", { headers: { Authorization: `Bearer ${token}` } })
            .then(response => { resolve(response.data) })
            .catch(error => {console.log(error); resolve({})})
    })
}
