import Vue from "vue";
import VueRouter from "vue-router";
import VueRouterMultiguard from "vue-router-multiguard";

import store from "@/store";
const authCustomer = (to, from, next) => {
  if (store.getters["auth/isAuthCustomer"] !== true) {
    router.push({ name: "login" });
  }
  next();
}

const guardPageIndexRestoreDataReport = (to, from, next) => {
  /* fuerza el seteo del store siempre que se ubique en esta ruta -> index

     forces the setting of the store whenever it is located in this path -> index
  */
  store.commit('ore/setActualModuleCode', '')
  next();
}

const checkSummaryModule = (to, from, next) => {
  /* siempre que este en la ruta summary, el codigo es seteado a la fuerza

     as long as it is in the summary path, the code is set by force
  */
  store.commit('ore/setActualModuleCode', 'MSM')
  next();
}

const checkCodeModuleInCrusher = (to, from, next) => {
  store.commit("ore/setDirtyBtnSave", "");
  let crushers = store.getters['ore/getListCrushers']
  let reportId = store.getters['ore/getReportId']
  if (crushers.length > 0) {
    let selectedCrusher = store.getters['ore/getCrusherId']
    if (to.meta !== undefined && to.meta.moduleCode !== undefined && selectedCrusher > 0) {
      let crusher = crushers.filter(itemCrusher => itemCrusher.id === selectedCrusher)
      let crusherModules = crusher[0].settings.modules
      if (crusherModules && crusherModules.length > 0) {
        let moduleToRouter = crusherModules.filter(itemModuleCrusher => itemModuleCrusher.code === to.meta.moduleCode)
        if (moduleToRouter && moduleToRouter.length > 0) {
          store.commit('ore/setActualModuleCode', to.meta.moduleCode)
          next();
        }
      } else return
    } else router.push({ name: "summary" });
  }
  else if (reportId > 0) router.push({ name: "summary" });
  else router.push({ name: "index" });
}

import LoginView from "../views/index/login"
import LoginMobileView from "../views/index/login_mobile"
import IndexView from "../views/index/index"
import SummaryView from "../views/reports/summary/index"
import EnergyView from "../views/reports/energy/index.vue"
import MaterialView from "../views/reports/material/index"
import InventoryView from "../views/reports/inventory/index.vue"
import CommentsView from "../views/reports/comments/index"
import StartStopView from "../views/reports/startstop/index"
import ProductionHoursView from "../views/reports/production_hours/index"
import MaintenanceView from "../views/reports/maintenance/"

import ReportLayout from "@/layouts/Report";
import Default from "@/layouts/Default";
import Blank from "@/layouts/Blank";


Vue.use(VueRouter);
const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/:id?",
      name: "index",
      meta: { layout: Default },
      component: IndexView,
      beforeEnter: VueRouterMultiguard([authCustomer,guardPageIndexRestoreDataReport]),
    },
    {
      path: "/login",
      name: "login",
      meta: { layout: Default },
      component: LoginView,
    },
    {
      path: "/loginmobile",
      name: "login_mobile",
      meta: { layout: Blank },
      component: LoginMobileView,
    },
    {
      path: "/report/summary",
      name: "summary",
      component: SummaryView,
      meta: { layout: ReportLayout, pageTitle: "ore.report.summary", moduleCode: "MSM" },
      beforeEnter: VueRouterMultiguard([authCustomer, checkSummaryModule]),
    },
    {
      path: "/report/start_stop",
      name: "start_stop",
      component: StartStopView,
      meta: { layout: ReportLayout, pageTitle: "ore.report.start_stop", pageClass: "ore-reports-startstop", moduleCode: "MST" },
      beforeEnter: VueRouterMultiguard([authCustomer, checkCodeModuleInCrusher]),
    },
    {
      path: "/report/production",
      name: "production_hours",
      component: ProductionHoursView,
      meta: { layout: ReportLayout, pageTitle: "ore.report.production_hours", pageClass: "ore-reports-production-hours", moduleCode: "MPD" },
      beforeEnter: VueRouterMultiguard([authCustomer, checkCodeModuleInCrusher])
    },
    {
      path: "/report/energy",
      name: "energy",
      component: EnergyView,
      meta: { layout: ReportLayout, pageTitle: "ore.report.energy", pageClass: "ore-reports-energy", moduleCode: "MEG" },
      beforeEnter: VueRouterMultiguard([authCustomer, checkCodeModuleInCrusher]),
    },
    {
      path: "/report/material",
      name: "material",
      component: MaterialView,
      meta: { layout: ReportLayout, pageTitle: "ore.report.materials", pageClass: "ore-reports-material", moduleCode: "MMT" },
      beforeEnter: VueRouterMultiguard([authCustomer, checkCodeModuleInCrusher]),
    },
    {
      path: "/report/inventory",
      name: "inventory",
      component: InventoryView,
      meta: { layout: ReportLayout, pageTitle: "ore.report.inventory", pageClass: "ore-reports-inventory", moduleCode: "MIV" },
      beforeEnter: VueRouterMultiguard([authCustomer, checkCodeModuleInCrusher]),
    },
    {
      path: "/report/comments",
      name: "comments",
      component: CommentsView,
      meta: { layout: ReportLayout, pageTitle: "ore.report.comments", pageClass: "ore-reports-comments", moduleCode: "MCM" },
      beforeEnter: VueRouterMultiguard([authCustomer, checkCodeModuleInCrusher])
    },
    {
      path: "/report/maintenance",
      name: "maintenance",
      component: MaintenanceView,
      meta: { layout: ReportLayout, pageTitle: "ore.report.maintenance", pageClass: 'ore-reports-maintenance', moduleCode: "MMC" },
      beforeEnter: VueRouterMultiguard([authCustomer, checkCodeModuleInCrusher])
    },
  ],
});

export default router;