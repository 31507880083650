import { createQueryBuilder, getRepository } from "typeorm";
import store from "@/store"
import { baseApi, isConfigMobile, getCustomerJWT } from './base'
import axios from 'axios'

/**
 * It is used to get translations of the selected language.
 * @param {String} lang - Selected language.
 * @returns JSON with the translations.
 */
export async function loadLanguageAsync(lang) {
    let isMobile = isConfigMobile()
    let checkOnLine = store.getters["comun/getIsOnline"]
    if (isMobile) {
        const translateRepository = await getRepository("coreTranslate");
        let _translateLocal = await translateRepository.find().then(response => { return response; }).catch(error => { console.log(error) });
        let translations = {}
        if (_translateLocal.length > 0) {
            return new Promise(async function (resolve) {
                _translateLocal.forEach(item => {
                    if (!(item.module in translations)) {
                        translations[item.module] = {}
                    }
                    if (!(item.component in translations[item.module])) {
                        translations[item.module][item.component] = {}
                    }
                    let _itemTranslates = atob(item.translates);
                    try {
                        if ((lang in JSON.parse(_itemTranslates))) {
                            let _json = JSON.parse(_itemTranslates)
                            translations[item.module][item.component][item.tag] = JSON.parse(_itemTranslates)[lang]
                        }
                    } catch { }
                })
                resolve({ data: translations })
            })
        } else {
            if (checkOnLine) {
                return new Promise(function (resolve, reject) {
                    axios.get(baseApi() + '1.0/translate/locale/' + lang + '/')
                        .then(response => resolve(response))
                        .catch(error => reject(error))
                })
            } else {
                return store.getters['auth/getLastLanguage']['messages']
            }
        }
    } else {
        return new Promise(function (resolve, reject) {
            axios.get(baseApi() + '1.0/translate/locale/' + lang + '/')
                .then(response => {
                    resolve(response)
                })
                .catch(error => reject(error))
        })
    }
}