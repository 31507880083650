var EntitySchema = require("typeorm").EntitySchema;

module.exports = new EntitySchema({
    name: "oreReport",
    tableName: "ore_report",
    columns: {
        id: {
            primary: true,
            type: "int",
            generated: true
        },
        uuid: {
            type: "varchar",
            length: 50,
            nullable: true
        },
        web_id: {
            type: "int",
            nullable: true
        },
        crusher: {
            type: "int"
        },
        date: {
            type: "varchar"
        },
        shift: {
            type: "int",
            default:"1"
        },
        status: {
            type: "int",
            default:"1"
        },
        assigned: {
            type: "int",
            nullable: true
        },
        flowsheet: {
            type: "int",
            default: "0"
        },
        status_by: {
            type: "int",
            nullable: true
        },
        comment: {
            type: "varchar",
            nullable: true
        },
        campaign: {
            type: "int",
            nullable: true
        },
        scenario: {
            type: "int",
            nullable: true
        },
        sync: {
            type: "int",
            default: "0"
        },
        update_web: {
            type: "datetime",
            nullable: true
        },
        created: {
            type: "datetime",
            nullable: true
        },
        created_by: {
            type: "int",
            nullable: true
        },
        update: {
            type: "datetime",
            nullable: true
        },
        update_by: {
            type: "int",
            nullable: true
        },
        deleted: {
            type: "datetime",
            nullable: true
        },
        deleted_by: {
            type: "int",
            nullable: true
        },
        published: {
            type: "int",
            default: "1"
        }
    }
})