<template>
  <div :class="classes">
    <div class="v-input__control">
      <div class="v-input__slot">
        <fieldset v-if="outlined" aria-hidden="true">
          <legend>
            <span>​</span>
          </legend>
        </fieldset>
        <div class="v-text-field__slot">
          <label
            :disabled="disabled"
            :id="'label-' + idControl"
            ref="labelMask"
            :class="classesLabel"
            :style="{
              left: prefixWidth + 'px',
              right: 'auto',
              position: 'absolute',
            }"
            >{{ label }}</label
          >
          <div ref="divPrefix" class="v-text-field__prefix" v-if="prefix">
            {{ prefix }}
          </div>
          <input
            :disabled="disabled"
            :id="idControl"
            ref="textMask"
            v-imask="mask"
            :type="type"
            :value="localValue"
            @focus="onFocus"
            @blur="onBlur"
            @accept="onAccept"
            @complete="onComplete"
            v-bind:readonly="readonly"
          />
          <div class="v-text-field__suffix" v-if="suffix">{{ suffix }}</div>
        </div>
        <div class="v-input__append-inner" v-if="clearable && !showLabel">
          <div class="v-input__icon v-input__icon--clear">
            <button
              type="button"
              aria-label="clear icon"
              @click="OnClear"
              class="v-icon notranslate v-icon--link mdi mdi-close theme--light"
            ></button>
          </div>
        </div>
      </div>
      <div class="v-text-field__details" v-if="!hideDetails">
        <div :class="classesMessages">
          <div class="v-messages__wrapper" v-if="showMessage">
            {{ messageText }}
          </div>
        </div>
        <div class="v-counter theme--light"></div>
      </div>
    </div>
  </div>
</template>

<script>
/* https://www.npmjs.com/package/vue-imask */
import { IMaskDirective } from "vue-imask";

export default {
  directives: {
    imask: IMaskDirective,
  },
  data() {
    return {
      idControl: "",
      localValue: "",
      isFocused: false,
      refreshIdLabel: null,
    };
  },
  props: {
    value: {
      type: [String, Number],
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    solo: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    prefix: {
      type: String,
      default: undefined,
    },
    prefixValue: {
      type: String,
      default: "",
    },
    suffix: {
      type: String,
      default: null,
    },
    appendIcon: {
      type: String,
      default: null,
    },
    appendIconCb: {
      type: Function,
      default() {},
    },
    mask: {
      type: [String, Array, Object],
      default: () => {
        return {
          mask: Number,
        };
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },    
    errorMessages: {
      type: Array,
      default: null,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    light: {
      type: Boolean,
      default: true,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    shaped: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    persistentHint: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    let dt = new Date().getTime();
    const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        const r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );
    this.idControl = uuid;
    this.localValue = this.value;
  },
  methods: {
    onBlur(e) {
      this.isFocused = false;
      this.$emit("blur", e);
    },
    onFocus(e) {
      this.isFocused = true;
      document.getElementById(this.idControl).select();
    },
    OnClear(e) {
      this.localValue = "";
    },
    onAccept(e) {
      const maskRef = e.detail;
    },
    onComplete(e) {
      const maskRef = e.detail;
      this.localValue = maskRef.unmaskedValue;
    },
    fixInputValue(value) {
      if (this.mask == null || this.mask == undefined || value == null || value == undefined)
        return;

      if ((value).toString().search('e')) {
        try {
          value = parseFloat(value.toString()).toFixed(10).toString()
        } catch {
          value = "0"
        }
      }

      if(this.mask.radix == ",")
        return (value).toString().replace(".", this.mask.radix);
      else
        return (value).toString().replace(",", this.mask.radix);
    },
    fixOutputValue(value) {
      if(value != null && value != undefined)
        return (value).toString().replace(this.mask.radix, ".");
      else
        return ""
    }
  },
  computed: {
    isDark() {
      return this.dark && this.light;
    },
    showMessage() {
      return (
        this.persistentHint ||
        (this.isFocused && this.hint.length > 0) ||
        (this.errorMessages !== null && this.errorMessages.length > 0)
      );
    },
    prefixWidth() {
      return 0;
    },
    classes() {
      const result = [
        "expMask v-input theme--light v-text-field v-text-field--is-booted v-text-field--enclosed",
      ];
      if (this.isFocused) result.push("v-input--is-focused");
      if (
        this.localValue !== null &&
        this.localValue.length > 0 &&
        this.isFocused
      )
        result.push("primary--text");
      if (this.outlined) result.push("v-text-field--outlined");
      if (this.shaped) result.push("v-text-field--shaped");
      if (this.prefix) result.push("v-text-field--prefix");
      if (this.errorMessages !== null && this.errorMessages.length > 0) {
        result.push("error--text");
      }
      if (this.dense) {
        result.push("v-input--dense");
      }
      if (this.solo) {
        result.push("v-text-field--solo");
      }
      if (this.readonly) {
        result.push("readonly");
      }
      return result;
    },
    classesLabel() {
      var result = "v-label theme--light";
      if (this.isFocused || !this.showLabel)
        result += " v-label--active primary--text";
      if (!this.isFocused) result = result.replace("primary--text", "");
      if (this.errorMessages !== null && this.errorMessages.length > 0) {
        result += " error--text";
      }
      return result;
    },
    classesMessages() {
      var result = ["v-messages theme--light"];
      if (this.errorMessages !== null && this.errorMessages.length > 0) {
        result.push("error--text");
      }
      return result;
    },
    messageText() {
      var result = "";
      if (this.errorMessages !== null && this.errorMessages.length > 0) {
        result = this.errorMessages[this.errorMessages.length - 1];
      } else {
        result = this.hint;
      }
      return result;
    },
    showLabel() {
      return this.localValue !== null && this.localValue.length === 0;
    },
  },
  watch: {
    value(newVal, oldVal) {
      if (typeof newVal === "number") {
        newVal = newVal.toString();
      }
      if (newVal === oldVal) {
        return;
      }
      this.localValue = newVal;
    },
    localValue(newVal, oldVal) {
      if (newVal === oldVal) {
        return;
      }
      this.$emit("input", this.fixOutputValue(newVal));
      this.$emit("change", this.fixOutputValue(newVal));
    },
  },
};
</script>