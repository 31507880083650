var EntitySchema = require("typeorm").EntitySchema;

module.exports = new EntitySchema({
    name: "oreReportModuleStatus",
    tableName: "ore_report_module_status",
    columns: {
        id: {
            primary: true,
            type: "int",
            generated: true
        },
        uuid: {
            type: "varchar",
            length: 50,
            nullable: true
        },
        web_id: {
            type: "int",
            nullable: true
        },
        report: {
            type: "int"
        },
        module: {
            type: "varchar",
            nullable: true
        },
        comment: {
            type: "varchar",
            nullable: true
        },
        created: {
            type: "datetime",
            nullable: true
        },
        created_by: {
            type: "int",
            nullable: true
        },
        update: {
            type: "datetime",
            nullable: true
        },
        update_by: {
            type: "int",
            nullable: true
        },
        deleted: {
            type: "datetime",
            nullable: true
        },
        deleted_by: {
            type: "int",
            nullable: true
        },
        published: {
            type: "int",
            default: "1"
        }
    }
})