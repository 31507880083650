import store from "@/store";
import moment from "moment";

export const storeOre = {
  namespaced: true,
  state: {
    list_regions: [],
    list_crushers: [],
    list_sites: [],
    list_campaigns: [],
    list_campaign_target: [],
    list_scenarios: [],
    list_source: [],
    list_products: [],
    list_equipment: [],
    list_module: [],
    list_equipment_maintenance: [],
    report_id: 0,
    crusher_id: 0,
    crusher_selected: {},
    crusher_format: {},
    loading_report: false,
    loading_data: [],
    msg_loading: 'Loading...',
    modules_report: [],
    summary_report: [],
    maintenance_report: [],
    actual_module_code: '',
    only_read_report: false,
    report_selected: {
      bitumen: [],
      bitumen_reception: [],
      comments: [],
      energy: [],
      inventory: [],
      inventory_reception: [],
      material: [],
      production: {},
      production_hours: {},
      production_man_hours: [],
      report: {},
      start_stop: {},
      start_stop_events: [],
      waste_batch_bin: [],
      waste_batch_reject: [],
      waste_batch_return: [],
      waste_continuous: [],
      environmental: {},
      modules_status: [],
      report_status: [],
      maintenance: [],
      maintenance_details: [],
      maintenance_plan: [],
      jws: [],
      jws_type_other_summary: [],
      // CRUSHING
      material_crushing: [],
      material_crushing_activities: [],
    },
    report_campaign_target: {
      data: {},
      day_now: {},
      day_report: {}
    },
    global_settings_modules: {
      active_start_stop_module: false,
      start_stop_running_time_manual: true,
      bitumen_reservoir_or_tank: true, // true reservorio false tank
      inventory_reception: false,
      // CRUSHING
      campaign_material_no_blasting: false
    },
    savingModuleStatus: "",
    dirtyBtnSave: "",
    connection: false,
    dash_filter: {
      regions: [],
      sites: [],
      campaigns: [],
      states: [],
      start_date: moment().subtract(3, "weeks").format("YYYY-MM-DD"),
      end_date: moment().format("YYYY-MM-DD"),
      page: 1
    },
    reloadDashboard: false,
  },
  getters: {
    getListRegions(state) {
      return state.list_regions
    },
    getListCrushers(state) {
      return state.list_crushers
    },
    getListSites(state) {
      return state.list_sites
    },
    getListCampaigns(state) {
      return state.list_campaigns
    },
    getListCampaignTarget(state) {
      return state.list_campaign_target
    },
    getListScenario(state) {
      return state.list_scenarios
    },
    getListEquipment(state) {
      return state.list_equipment
    },
    getListModule(state) {
      return state.list_module
    },
    getListEquipmentMaintenance(state) {
      return state.list_equipment_maintenance
    },
    getReportId(state) {
      return state.report_id
    },
    getCrusherId(state) {
      return state.crusher_id
    },
    getCrusher(state) {
      return state.crusher_selected
    },
    getCrusherFormat(state) {
      return state.crusher_format
    },
    getMessageLoading(state) {
      return state.msg_loading
    },
    getReportSelected(state) {
      return state.report_selected
    },
    getLoadingReport(state) {
      return state.loading_report
    },
    getModulesReport(state) {
      return state.modules_report
    },
    getMaintenanceReport(state) {
      return state.maintenance_report
    },
    getModuleConfig: (state) => (code = '') => {
      let codeFilter = code != '' ? code : state.actual_module_code != '' ? state.actual_module_code : ''
      if (codeFilter != '') {
        let module = state.modules_report.filter(itemModule => itemModule.code === codeFilter)
        return module[0]
      } else return {}
    },
    getSummaryReport: (state) => (code = '') => {
      if (code && code != '') {
        let module = state.summary_report.filter(itemModule => itemModule.code === code)
        return module
      } else return state.summary_report
    },
    getSavingModuleStatus(state) {
      return state.savingModuleStatus
    },
    getDirtyBtnSave(state) {
      return state.dirtyBtnSave
    },
    // Para la app móvil
    // For the mobile app
    getConnection(state) {
      return state.connection
    },
    getLoadingData: (state) => (code = '') => {
      try {
        let _loading = state.loading_data.find(item => item.code === code)
        if (_loading != undefined && _loading != null) {
          return _loading.state
        } else {
          return false
        }
      } catch (e) {
        return false
      }
    },
    getDashFilter(state) {
      return state.dash_filter
    },
    getOnlyReadReport(state) {
      try {
        let _auth_user = store.getters["auth/getAuthCustomer"];
        if(_auth_user.data.user_type == 6)
          return true
      } catch {}
      return state.only_read_report
    },
    getSettingsModules(state) {
      return state.global_settings_modules
    },
    getReloadDashboard(state) {
      return state.reloadDashboard
    },
    getListSource(state) {
      return state.list_source
    },
    getListProducts(state) {
      return state.list_products
    },
    getReportCampaignTarget(state) {
      return state.report_campaign_target
    },
  },
  mutations: {
    setListRegions(state, list) {
      state.list_regions = list
    },
    setListCrushers(state, list) {
      state.list_crushers = list
    },
    setListSites(state, list) {
      state.list_sites = list
    },
    setListCampaigns(state, list) {
      state.list_campaigns = list
    },
    setListCampaignTarget(state, list) {
      state.list_campaign_target = list
    },
    setListScenario(state, list) {
      state.list_scenarios = list
    },
    setListEquipment(state, list) {
      state.list_equipment = list
    },
    setListModule(state, list) {
      state.list_module = list
    },
    setListEquipmentMaintenance(state, list) {
      state.list_equipment_maintenance = list
    },
    setReportId(state, id) {
      state.report_id = id
    },
    setCrusherId(state, id) {
      state.crusher_id = id
    },
    setCrusher(state, data) {
      state.crusher_selected = data
    },
    setCrusherFormat(state, data) {
      state.crusher_format = data
    },
    setReportSelected(state, data) {
      state.report_selected = data
      store.dispatch('ore/initOnlyReadReport')
    },
    setUpdateReportsSelected(state, data) {
      for (let n in Object.keys(data)) {
        state.report_selected[Object.keys(data)[n]] = data[Object.keys(data)[n]]
        if (Object.keys(data)[n] === "report") {
          store.dispatch('ore/initOnlyReadReport')
        }
      }
    },
    setUpdateReportsSelectedItems(state, data) {
      // data = {'module_name': [items]}
      for (let n in Object.keys(data)) {
        let moduleName = Object.keys(data)[n]
        let dataModuleStore = state.report_selected[moduleName]
        let itemsData = data[Object.keys(data)[n]]
        for (let item in itemsData) {
          if (itemsData[item].id) {
            let itemToEdit = dataModuleStore.findIndex(x => x.id === itemsData[item].id)
            if (itemToEdit) state.report_selected[moduleName][itemToEdit] = itemsData[item]
          }
        }
      }
    },
    setOnlyReadReport(state, stateBtn) {
      state.only_read_report = stateBtn
    },
    setLoadingReport(state, stateLoading) {
      state.loading_report = stateLoading.state
      state.msg_loading = stateLoading.msg
    },
    setModulesReport(state, modules) {
      state.modules_report = modules
    },
    setMaintenanceReport(state, modules) {
      state.maintenance_report = modules
    },
    setSummaryReport(state, summaryData) {
      state.summary_report = summaryData
    },
    setActualModuleCode(state, code) {
      state.actual_module_code = code
    },
    setSavingModuleStatus(state, code) {
      state.savingModuleStatus = code
    },
    setDirtyBtnSave(state, code) {
      state.dirtyBtnSave = code
    },
    // Para la app móvil
    // For the mobile app
    setConnection(state, data) {
      state.connection = data
    },
    setLoadingData(state, data) {
      try {
        let _loading = state.loading_data.find(item => item.code === data.code)
        if (_loading != undefined && _loading != null) {
          _loading.state = data.state
        } else {
          state.loading_data.push({
            code: data.code,
            state: data.state
          })
        }
      } catch (e) {
        state.loading_data.push({
          code: data.code,
          state: data.state
        })
      }
    },
    setDashFilter(state, data) {
      return state.dash_filter = data
    },
    setSettingsModules(state, data) {
      for (let n in Object.keys(data)) {
        state.global_settings_modules[Object.keys(data)[n]] = data[Object.keys(data)[n]]
      }
    },
    setReloadDashboard(state, value) {
      state.reloadDashboard = value
    },
    setListSource(state, value) {
      state.list_source = value
    },
    setListProducts(state, value) {
      state.list_products = value
    },
    setReportCampaignTarget(state, data) {
      state.report_campaign_target = data
    },
  },
  actions: {
    initOnlyReadReport({ dispatch, commit, getters, rootGetters }) {
      let userData = rootGetters['auth/getAuthCustomer']
      let reportData = getters.getReportSelected
      if (userData && userData.data) {
        if (reportData && reportData.report) {
          let status = reportData.report.status === 1
          commit('setOnlyReadReport', status)
        }
      }
    }
  }
}