import { baseApi, isConfigMobile, getCustomerJWT } from "./base";
import axios from "axios";
import store from "@/store"

/**
 * It is used to log in
 * @param {Object} data - Object with the user credentials
 * @returns {Array} - Array with access tokens
 */
export function loginCustomer(data) {
  return new Promise(function (resolve, reject) {
    axios
      .post(baseApi() + "1.0/user/login/", data)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export function loginCustomerMsal(data) {
  return new Promise(function (resolve, reject) {
    axios
      .post(baseApi() + "1.0/user/login/", data)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

/**
 * It is used to get user information
 * @param {Object} jwt - Encoded JSON
 * @returns {Object} - Object with user data
 */
export function dataCustomer(jwt) {
  let isMobile = isConfigMobile()
  let checkOnLine = store.getters["comun/getIsOnline"]
  if (checkOnLine) {
    return new Promise(function (resolve, reject) {
      let token = jwt != null && jwt != undefined ? jwt : getCustomerJWT();
      axios
        .get(baseApi() + "1.0/user/data/", {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }
}

/**
 *
 * @param {Object} data - Object with user data.
 * @returns {Object} Object with a success message
 */
export function simplePasswordChange(data) {
  return new Promise(function (resolve, reject) {
    let token = getCustomerJWT();
    axios
      .post(baseApi() + "1.0/user/password_update/", data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
