import { getSites } from "@/gateway/site"
import { getCampaigns } from "@/gateway/campaign"
import { getCrushers } from "@/gateway/crusher"
import { getScenarios } from "@/gateway/scenario"
import { getRegions } from "@/gateway/region"
import { getReport } from "@/gateway/report"
import { getAllEquipment } from "@/gateway/equipment"
import { getAllEquipmentMaintenance } from "@/gateway/equipmentMaintenance"
import { getListItemsByCode } from "@/gateway/lists";
import { mapGetters } from 'vuex'
import calculationsMixin from "./calculations";
import oreListsMixin from "./ore_lists";
import basicMixin from "./basic";

const oreMixin = {
  mixins: [oreListsMixin, calculationsMixin, basicMixin],
  data() {
    return {
      itemsRegions: [],
      itemsCrushers: [],
      itemsSites: [],
      itemsCampaigns: [],
      itemsScenarios: [],
      reportSelected: {},
      extrafilters: {},
      reportId: 0,
      isDash: false,
    }
  },
  async mounted() {
    /*  podria validar si estoy en el index para pedir datos de la tabla.
        could check if I'm in the index to request data from the table.
    */
    this.loadInitData()
  },
  computed: {
    ...mapGetters({
      storeReportSelected: 'ore/getReportSelected',
      storeCrusherSelected: 'ore/getCrusher',
      storeCrusherId: 'ore/getCrusherId',
      storeListCrushers: 'ore/getListCrushers',
      storeListEquipment: 'ore/getListEquipment',
      storeListEquipmentMaintenance: 'ore/getListEquipmentMaintenance',
      storeReportId: 'ore/getReportId',
      storeMaintenanceReport: 'ore/getMaintenanceReport',
      savingModuleStatus: 'ore/getSavingModuleStatus',
      crusherFormat: 'ore/getCrusherFormat',
      storeOnlyReadReport: 'ore/getOnlyReadReport',
      storeLang: 'auth/getLastLanguage',
      storeGlobalSettingsgModules: 'ore/getSettingsModules',
      storeListCampaignTarget: 'ore/getListCampaignTarget',
    }),
    validReportData() {
      return this.isset(this.storeReportSelected) && this.isset(this.storeReportSelected.report) && this.isset(this.storeReportSelected.report.crusher)
    },
    reportSelectedId() {
      try {
        this.extrafilters.reportId = this.reportId
        return this.reportId
      } catch (error) {
        return 0
      }
    },
    reportSelectedCrusher() {
      let defaultData = { id: 0, name: "", code: "", inventory_type: null }
      try {
        if (this.validReportData && this.storeListCrushers.length > 0) {
          defaultData = this.storeListCrushers.find(x => x.id == this.storeReportSelected.report.crusher)
        }
        return defaultData
      } catch (error) {
        return defaultData
      }
    },
    reportSelectedCampaignName() {
      try {
        if (this.validReportData) {
          let _campaign = this.itemsCampaigns.find(x => x.id == this.storeReportSelected.report.campaign)
          if (_campaign != undefined)
            return _campaign.name
        }
        return ""
      } catch (error) {
        return ""
      }
    },
    reportSelectedCrusherName() {
      try {
        if (this.validReportData) {
          let _crusher = this.itemsCrushers.find(x => x.id == this.storeReportSelected.report.crusher)
          if (_crusher != undefined)
            return `(${_crusher.code}) ${_crusher.name}`
        }
        return ""
      } catch (error) {
        return ""
      }
    },
    reportSelectedSiteName() {
      try {
        if (this.validReportData) {
          let _campaign = this.itemsCampaigns.find(x => x.id == this.storeReportSelected.report.campaign)
          let _site = this.itemsSites.find(x => x.id == _campaign.site)
          if (_site != undefined)
            return _site.name
        }
        return ""
      } catch (error) {
        return ""
      }
    },
    reportSelectedUUID() {
      try {
        if (this.isset(this.reportSelected) && this.isset(this.reportSelected.report)) {
          return this.reportSelected.report.uuid
        } else {
          return ""
        }
      } catch {
        return ""
      }
    },
    reportSelectedDate() {
      try {
        if (this.isset(this.reportSelected) && this.isset(this.reportSelected.report)) {
          return this.$moment(this.reportSelected.report.date).utc().format("YYYY/MM/DD")
        } else {
          return ""
        }
      } catch {
        return ""
      }
    },
    pageModuleConfig() {
      let configModule = this.$store.getters["ore/getModuleConfig"]('')
      if (this.isset(configModule.code)) return configModule
      else return {}
    },
    maskDecimalNumber() {
      return {
        mask: Number,
        min: 0,
        max: 10000000000,
        scale: this.crusherFormat.decimal_number,
        thousandsSeparator: this.crusherFormat.separator_for_thousand == 1 ? '.' : (this.crusherFormat.separator_for_thousand == 2 ? ',' : ' '),
        radix: this.crusherFormat.separator_for_thousand == 1 ? ',' : (this.crusherFormat.separator_for_thousand == 2 ? '.' : ','),
        normalizeZeros: true
      }
    },
    maskIntegerNumber() {
      return {
        mask: Number,
        min: 0,
        max: 1000000000,
        scale: 0
      }
    },
    maskDecimalNegativeNumber() {
      return {
        mask: Number,
        min: -1000000000,
        max: 1000000000,
        scale: this.crusherFormat.decimal_number,
        thousandsSeparator: this.crusherFormat.separator_for_thousand == 1 ? '.' : (this.crusherFormat.separator_for_thousand == 2 ? ',' : ' '),
        radix: this.crusherFormat.separator_for_thousand == 1 ? ',' : (this.crusherFormat.separator_for_thousand == 2 ? '.' : ','),
        normalizeZeros: true
      }
    },
    maskPercentNumber() {
      return {
        mask: Number,
        min: 0,
        max: 100000000,
        scale: this.crusherFormat.decimal_percent,
        thousandsSeparator: this.crusherFormat.separator_for_thousand == 1 ? '.' : (this.crusherFormat.separator_for_thousand == 2 ? ',' : ' '),
        radix: this.crusherFormat.separator_for_thousand == 1 ? ',' : (this.crusherFormat.separator_for_thousand == 2 ? '.' : ','),
        normalizeZeros: true
      }
    },
    maskPercentNegativeNumber() {
      return {
        mask: Number,
        min: -1000000000,
        max: 1000000000,
        scale: this.crusherFormat.decimal_percent,
        thousandsSeparator: this.crusherFormat.separator_for_thousand == 1 ? '.' : (this.crusherFormat.separator_for_thousand == 2 ? ',' : ' '),
        radix: this.crusherFormat.separator_for_thousand == 1 ? ',' : (this.crusherFormat.separator_for_thousand == 2 ? '.' : ','),
        padFractionalZeros: true,
        normalizeZeros: true
      }
    }
  },
  watch: {
    async reportSelectedId(newValue, oldValue) {
      if (this.reportSelectedId != undefined && this.reportSelectedId > 0) {
        this.loadInitReportData()
      } else {
        this.reportSelected = {}
      }
    },
    storeCrusherId(value) {
      let crushers = this.storeListCrushers
      if (!(value > 0)) this.$store.commit("ore/setModulesReport", [])
      if (!(value > 0)) this.$store.commit("ore/setSummaryReport", [])
      if (crushers.length > 0 && value > 0) {
        let crusher = this.itemsCrushers.find(x => x.id == this.storeCrusherId)
        this.$store.commit("ore/setCrusher", crusher)
        this.$store.commit("ore/setCrusherFormat", this.JSONparse(crusher.format))
      }
    },
    storeReportSelected: {
      handler(data) {
        if (this.validReportData) {
          this.$store.commit("ore/setCrusherId", data.report.crusher)
          if (this.storeListCrushers && this.storeListCrushers.length > 0) {
            this.generateMenuModules()
            this.generateSummaryModules()
            this.setCampaignTarget()
          }
        } else {
          this.reportId = 0
        }
      },
      deep: true
    },
  },
  methods: {
    getDateTimeFormated(date) {
      if (this.$moment(date).isValid()) {
        return this.$moment.utc(date).local().format(
          this.crusherFormat.format_date + " HH:mm"
          );
      } else {
        return ""
      }
    },
    getMaxDateFromArray(data, field) {
      let dates = this.$_.cloneDeep(data).map(x => this.$moment(x[field]))
      // get the most "recent" date
      let maxdate = Math.max(...dates)
      // find the element with the most "recent" date
      return this.$_.cloneDeep(data).find(x => this.$moment(x[field]).isSame(maxdate))
    },
    getTranslation(id, items) {
      let item = this.findTextList(id, items)
      let translation = item != "" && item != null ? this.$t(
        "core.list_item." +
        item
      ) : ""
      return translation
    },
    fastValidValue(value, defaultValue = 0, item) {
      try {
        let pointerObj = value.split('.')
        let valuePointer = pointerObj.reduce((a, prop) => a[prop], item)
        return valuePointer !== undefined && valuePointer !== "undefined" && valuePointer !== null ? valuePointer : defaultValue;
      } catch (error) {
        return defaultValue
      }
    },
    async loadInitData() {
      this.reportId = await this.$store.getters['ore/getReportId']
      this.itemsRegions = await getRegions()
      this.itemsSites = await getSites()
      let _itemsCampaigns = await getCampaigns()
      _itemsCampaigns.forEach((item) => {
        let _start = this.$moment(this.$moment(item.start_date).format('YYYY-MM-DD'))
        let _end = this.$moment(this.$moment(item.end_date).format('YYYY-MM-DD'))
        let _now = this.$moment(this.$moment().format('YYYY-MM-DD'))
        item.published = (_start <= _now && _now <= _end) && item.published
        this.itemsCampaigns.push(item)
      })
      this.itemsScenarios = await getScenarios()
      this.itemsCrushers = await getCrushers()
      //this.itemsCrushers = this.itemsCrushers.filter(x => x.region != null)
      if (this.itemsCrushers.length > 0 && this.storeCrusherId > 0) {
        let crusher = this.itemsCrushers.find(x => x.id == this.storeCrusherId)
        this.$store.commit("ore/setCrusher", crusher)
        this.$store.commit("ore/setCrusherFormat", this.JSONparse(crusher.format))
      }
      this.$store.commit("ore/setLoadingReport", { state: true, msg: 'getEquipments' })
      await getAllEquipment()
      this.$store.commit("ore/setLoadingReport", { state: true, msg: 'getEquipmentsMaintenance' })
      await getAllEquipmentMaintenance()
      // this.generateMenuModules()
      // this.generateSummaryModules()
      if (this.isDash == false && this.reportId == 0) {
        this.openRoute("/")
      }
      this.$store.commit("ore/setLoadingReport", { state: false, msg: '' })
    },
    async loadInitReportData() {
      let statusLoadingReport = this.$store.getters["ore/getLoadingData"]('report')
      if (statusLoadingReport === false) {
        this.$store.commit("ore/setLoadingData", { code: 'report', state: true })
        this.$store.commit("ore/setLoadingReport", { state: true, msg: 'getReport' })
        this.reportSelected = {}
        this.reportSelected = await getReport()
        this.$store.commit("ore/setCrusherId", this.fastValidValue("report.crusher", 0 , this.reportSelected))
        if (this.campaignReport) {
          this.$store.commit("ore/setSettingsModules", { "campaign_material_no_blasting": this.campaignReport.no_blasting });
        }
        this.$store.commit("ore/setLoadingData", { code: 'report', state: false })
        this.generateMenuModules()
        this.generateSummaryModules()
        this.setCampaignTarget()
      }
    },
    generateMenuModules() {
      let keyWordsExcludeTables = ["table_option"]
      let crushers = this.storeListCrushers
      let reportSelected = this.storeReportSelected
      let crusherReport = crushers.filter(crusher => crusher.id === this.storeCrusherId)
      let reportStatus = this.isset(reportSelected.modules_status) ? reportSelected.modules_status : [];
      let checkCrusherReport = crusherReport.length > 0 && this.isset(crusherReport[0].settings) && crusherReport[0].settings.modules.length > 0
      let checkReportStatus = reportStatus.length > 0
      if (checkCrusherReport) {
        let modules = crusherReport[0].settings.modules
        modules = modules.map(moduleItemMap => {
          let moduleItem = this.$_.clone(moduleItemMap)
          moduleItem["settingsConfig"] = { general: [], form: [], table: {}, formReception: [], tableReception: {} }
          if (this.isset(moduleItem) && this.isset(moduleItem.settings) && moduleItem.settings !== "" && moduleItem.code !== "MSM" && moduleItem.code !== "MOE" && moduleItem.code !== "MAL") {
            moduleItem.settings = this.JSONparse(moduleItem.settings)
            let itemsForm = Object.entries(moduleItem.settings).length > 0 ? moduleItem.settings.form : {}
            let configForms = {}
             // read config forms
            for (let n in Object.keys(itemsForm)) {
              let nameField = Object.keys(itemsForm)[n]
              let valueField = itemsForm[Object.keys(itemsForm)[n]]
              // set default conf for field
              configForms[nameField] = {
                visible: valueField !== 1,
                disabled: valueField === 3
              }
              switch (moduleItem.code) {
                case "MST":
                  if (nameField === "type_running_time") {
                    this.$store.commit("ore/setSettingsModules", { "start_stop_running_time_manual": valueField === 0 });
                    configForms[nameField]["manual"] = valueField === 0
                  }
                  if (nameField === "planned_down_time") {
                    this.$store.commit("ore/setSettingsModules", { "start_stop_planned_down_time_visible": valueField === 0 });
                    configForms[nameField]["visible"] = valueField === 0
                  }
                  if (nameField === "type_planned_down_time") {
                    this.$store.commit("ore/setSettingsModules", { "start_stop_planned_down_time_manual": valueField === 0 });
                    configForms[nameField]["manual"] = valueField === 0
                  }
                  break;
                case "MMC":
                  if (nameField === "unlock_type") {
                    configForms[nameField] = {
                      locked: valueField === 0,
                      hidden: valueField === 1
                    }
                  }
                  break;
              }
              // configForms[nameField] = {
              //   visible: true,
              //   disabled: false
              // }
              // if (nameField === "type_running_time") {
              //   this.$store.commit("ore/setSettingsModules", { "start_stop_running_time_manual": valueField === 0 });
              //   configForms[nameField]["manual"] = valueField === 0
              // }
              // if (valueField === 1) { configForms[nameField]["visible"] = false } else { configForms[nameField]["visible"] = true }
              // if (valueField === 3) { configForms[nameField]["disabled"] = true } else { configForms[nameField]["disabled"] = false }
            }
            moduleItem.settingsConfig.form = configForms

            let configGeneral = {}
            if (this.isset(moduleItem.settings.general)) {
              let itemsGeneral = moduleItem.settings.generalgenerateMenuModules
              if(this.isset(itemsGeneral)) {
                for (let n in Object.keys(itemsGeneral)) {
                  let nameField = Object.keys(itemsGeneral)[n]
                  let valueField = itemsGeneral[Object.keys(itemsGeneral)[n]]
                  switch (nameField) {
                    case "bin_agg":
                      configGeneral[nameField] = (valueField == 0)
                      break;
                    case "total_formulation":
                      configGeneral[nameField] = valueField + 1
                      break;
                    default:
                      configGeneral[nameField] = {
                        visible: true,
                        disabled: false
                      }
                      if (valueField === 1) { configGeneral[nameField]["visible"] = false } else { configGeneral[nameField]["visible"] = true }
                      if (valueField === 3) { configGeneral[nameField]["disabled"] = true } else { configGeneral[nameField]["disabled"] = false }
                      break;
                  }
                }
              }
            }
            moduleItem.settingsConfig.general = configGeneral

            let configFormsReception = {}
            if (this.isset(moduleItem.settings.extra1)) {
              let itemsFormReception = moduleItem.settings.extra1
              for (let n in Object.keys(itemsFormReception)) {
                let nameField = Object.keys(itemsFormReception)[n]
                let valueField = itemsFormReception[Object.keys(itemsFormReception)[n]]
                configFormsReception[nameField] = {
                  visible: true,
                  disabled: false
                }
                if (valueField === 1) { configFormsReception[nameField]["visible"] = false } else { configFormsReception[nameField]["visible"] = true }
                if (valueField === 3) { configFormsReception[nameField]["disabled"] = true } else { configFormsReception[nameField]["disabled"] = false }
              }
              moduleItem.settingsConfig.formReception = configFormsReception
            }

            let configTableReception = {}
            if (this.isset(moduleItem.settings.extra2)) {
              let itemsTableReception = moduleItem.settings.extra2
              for (let n in Object.keys(itemsTableReception)) {
                let nameField = Object.keys(itemsTableReception)[n]
                let valueField = itemsTableReception[Object.keys(itemsTableReception)[n]]
                if (this.$_.findIndex(keyWordsExcludeTables, x => x === nameField) < 0) {
                  configTableReception[nameField] = {
                    visible: true,
                    disabled: false
                  }
                  if (valueField === 1) { configTableReception[nameField]["visible"] = false } else { configTableReception[nameField]["visible"] = true }
                  if (valueField === 3) { configTableReception[nameField]["disabled"] = true } else { configTableReception[nameField]["disabled"] = false }
                }
              }
            }
            moduleItem.settingsConfig.tableReception["headers"] = configTableReception
            moduleItem.settingsConfig.tableReception["direction"] = this.fastValidValue('settings.extra2.table_option', 1, moduleItem) === 1 ? 'h' : 'v'

            let itemsTable = Object.entries(moduleItem.settings).length > 0 ? moduleItem.settings.table : {}
            let configTable = {}
            if(itemsTable != undefined) {
              for (let n in Object.keys(itemsTable)) {
                let nameField = Object.keys(itemsTable)[n]
                let valueField = itemsTable[Object.keys(itemsTable)[n]]
                if (this.$_.findIndex(keyWordsExcludeTables, x => x === nameField) < 0) {
                  configTable[nameField] = {
                    visible: true,
                    disabled: false
                  }
                  if (valueField === 1) { configTable[nameField]["visible"] = false } else { configTable[nameField]["visible"] = true }
                  if (valueField === 3) { configTable[nameField]["disabled"] = true } else { configTable[nameField]["disabled"] = false }
                  if (moduleItem.code === "MIV" && crusherReport[0].inventory_type === 1 && (nameField !== "inventory_item" || nameField !== "units" || nameField !== "remaining")) { configTable[nameField]["visible"] = false }
                  if (moduleItem.code === "MIV" && crusherReport[0].inventory_type === 1 && (nameField === "inventory_item" || nameField === "units" || nameField === "remaining")) { configTable[nameField]["visible"] = true }
                  if (moduleItem.code === "MBT") {
                    if (nameField === "reservoir_or_tank") {
                      this.$store.commit("ore/setSettingsModules", { "bitumen_reservoir_or_tank": valueField === 0 });
                    }
                  }
                  if (moduleItem.code === "MIV") {
                    if (nameField === "inventory_reception") {
                      this.$store.commit("ore/setSettingsModules", { "inventory_reception": valueField === 0 });
                    }
                  }
                }
              }
            }

            moduleItem.settingsConfig.table["headers"] = configTable
            moduleItem.settingsConfig.table["direction"] = this.fastValidValue('settings.table.table_option', 1, moduleItem) === 1 ? 'h' : 'v'
          }
          let data = null
          let itemsReportStatusCode = checkReportStatus ? reportStatus.filter((itemStatus) => itemStatus.module === moduleItem.code).length > 0 : false;
          let menuConfig = {
            title: "ore.report." + moduleItem.name,
            icon: moduleItem.icon_svg && moduleItem.icon_svg != '' ? moduleItem.icon_svg : moduleItem.icon,
            icon_svg: moduleItem.icon_svg && moduleItem.icon_svg != '',
            color: moduleItem.color,
            link: "/report/" + moduleItem.name,
            code: moduleItem.code,
            showBadge: itemsReportStatusCode,
            background: itemsReportStatusCode == true ? 'background: #e1f0e1; border-radius: 0px !important;' : '',
            showInMenu: moduleItem.code != "MOE" && moduleItem.code != "MAL",
            colorBadge: moduleItem.color,
            leftBadge: true
          }
          menuConfig["showBadge"] = false
          if (this.validReportData) {
            switch (moduleItem.code) {
              // case "MSM":
              //   menuConfig["showBadge"] = false
              //   break;
              case "MST":
                menuConfig["showBadge"] = this.startStopRunningTime() < this.startStopProductionHours()
                menuConfig["iconBadge"] = "mdi-flag";
                menuConfig["colorBadge"] = "red";
                menuConfig["leftBadge"] = false;
                break;
              // case "MPD":
              //   menuConfig["iconBadge"] = "mdi-check";
              //   break;
              // case "MBT":
              //   data = reportSelected.bitumen || [];
              //   menuConfig["totalBadge"] = data.length;
              //   break;
              // case "MMT":
              //   data = reportSelected.material || [];
              //   menuConfig["totalBadge"] = data.length
              //   break;
              // case "MEG":
              //   data = reportSelected.energy || [];
              //   menuConfig["totalBadge"] = data.length;
              //   break;
              // case "MSL":
              //   menuConfig["showBadge"] = false
              //   break;
              // case "MCM":
              //   break;
              // case "MIV":
              //   data = reportSelected.inventory || [];
              //   menuConfig["totalBadge"] = data.length;
              //   break;
            }
          }
          moduleItem['menuItem'] = menuConfig
          return moduleItem;
        })
        let activeStartStopModule = modules.find(_x => _x && _x.code === "MST");
        this.$store.commit("ore/setSettingsModules", { "active_start_stop_module": (activeStartStopModule !== undefined && activeStartStopModule.status) });
        this.$store.commit('ore/setModulesReport', this.$_.orderBy(modules, ['order'], ['asc']))
      }
    },
    async generateSummaryModules() {
      let crushers = this.storeListCrushers
      let crusherReport = crushers.filter(crusher => crusher.id === this.storeCrusherId)
      let checkCrusherReport = crusherReport.length > 0 && this.isset(crusherReport[0].settings) && crusherReport[0].settings.modules.length > 0
      if (checkCrusherReport) {
        let crusherData = crusherReport[0]
        let modules = crusherData.settings.modules
        let modulesData = this.$_.cloneDeep(modules)
        let energy_data = []
        if ("energy" in crusherData) {
          energy_data = crusherData["energy"]
        }
        modulesData.push({
          code: "MCMS",
          name: "comments",
          color_soft: "#98D04666",
          color_text: "#000000FF",
          color: "#98D046FF",
          icon: "mdi-comment-processing",
          icon_svg: "",
          settings: "",
          status: false,
          order: 99,
          showSummary: false
        })
        let modulesReport = []
        let msmSettings = {}
        modulesData.forEach(module => {
          let moduleItem = this.$_.cloneDeep(module);
          if (this.isset(moduleItem) && moduleItem.code !== "MSM" && this.isset(moduleItem.settings) && moduleItem.settings !== "") {
            moduleItem.settings = this.JSONparse(moduleItem.settings)
          }
          let summaryCalc = []
          let summaryBar = []
          let summarySettings = moduleItem.settings
          let _module_summarySettings = this.fastValidValue("settings.summary", undefined, moduleItem)
          let _module_barSettings = this.fastValidValue("settings.bar", undefined, moduleItem)
          let _module_generalSettings = this.fastValidValue("settings.general", undefined, moduleItem)
          if (this.validReportData) {
            switch (moduleItem.code) {
              case "MSM": // 0 visible, 1 hidden
                try {
                  let _summarySettings = JSON.parse(summarySettings)
                  msmSettings = (_summarySettings && this.isset(_summarySettings.summary)) ?
                    _summarySettings.summary: {}
                } catch { msmSettings = {} }
                moduleItem['showSummary'] = false
                break;
              case "MST":
                if (summarySettings && this.isset(summarySettings.summary)) {
                  let _runningTime = this.getRunningTime()
                  summaryCalc = [
                    (summarySettings.summary && summarySettings.summary.running_time === 0 ? { name: this.$t("ore.report.running_time") + ":", value: this.secondsToHours(_runningTime, ' ') } : []),
                    (summarySettings.summary && summarySettings.summary.down_time === 0 ? { name: this.$t("ore.report.down_time") + ":", value: this.numberToPercent(this.startStopDownTime()) + '%' } : []),
                    (summarySettings.summary && summarySettings.summary.up_time === 0 ? { name: this.$t("ore.report.up_time") + ":", value: this.numberToPercent(this.startStopUpTime()) + '%' } : []),
                    (summarySettings.summary && summarySettings.summary.total_working_hours === 0 ? { name: this.$t("ore.report.total_working_hours") + ":", value: this.secondsToHours(this.startStopProductionHours(), ' ') } : []),
                    (summarySettings.summary && summarySettings.summary.total_events_time === 0 ? { name: this.$t("ore.report.total_events_time") + ":", value: this.secondsToHours(this.startStopEventsTotal()) } : []),
                  ]
                }
                if (summarySettings && this.isset(summarySettings.bar)) {
                  summaryBar = [
                    (summarySettings.bar.down_time === 0 ? { name: this.$t("ore.report.down_time"), value: this.numberToPercent(this.startStopDownTime()) + '%' } : []),
                    (summarySettings.bar.up_time === 0 ? { name: this.$t("ore.report.up_time"), value: this.numberToPercent(this.startStopUpTime()) + '%' } : []),
                    (summarySettings.bar.unscheduled_down_time === 0 ? { name: this.$t("ore.report.unscheduled_down_time"), value: this.numberToFormatNumeral(0) } : []),
                    (summarySettings.bar.total === 0 ? { name: this.$t("ore.report.total_event_time"), value: this.secondsToHours(this.startStopEventsTotal()) } : [])
                  ]
                }
                break;
              case "MPD":
                summaryBar = [
                  (this.fastValidValue("total_of_men", 1, _module_barSettings) === 0 ? { name: this.$t("ore.report.total_of_men"), value: this.numberToFormatNumeral(this.productionTotalOfMen()) } : []),
                  (this.fastValidValue("summary_total", 1, _module_barSettings) === 0 ? { name: this.$t("ore.report.sum_of_total"), value: this.numberToFormatNumeral(this.productionSumTotal()) } : []),
                  (this.fastValidValue("num_production_hours_man", 1, _module_barSettings) === 0 ? { name: this.$t("ore.report.prod_num_man_hours"), value: this.numberToFormatNumeral(this.productionNumManHours()) } : []),
                ]
                break;
              case "MMT":
                let activities = this.fastValidValue('material_crushing_activities', [], this.storeReportSelected)
                let productItemsList = this.oreGetProductListByCampaign();
                let itemsFiltered = this.$_.filter(productItemsList, (x) => { return this.$_.find(activities, (y) =>  y.product == x.id )})
                let _items = [];
                this.$_.cloneDeep(itemsFiltered).forEach((_x) => {
                  _x["total_qty"] = this.numberToFormatNumeral(
                    this.materialActivitiesTotalProduced(undefined, _x)
                  );
                  _x["proportion"] = this.numberToPercent(
                    this.materialSummaryProportion(undefined, _x)
                  );
                  _items.push(_x);
                });
                if (_items.length > 0) {
                  _items.forEach(item => {
                    summaryCalc.push({
                      name: 'html',
                      value: "<tr class='html-custom'><td>" + this.$t("core.products_list." + item.product) + '</td>' +
                        '<td align="right"">' + item.total_qty + ' t' + '&nbsp;&nbsp;</td>' +
                        '<td align="right">' + item.proportion + '%' + '&nbsp;&nbsp;</td>'
                    })
                  })
                }
                break;
              case "MEG":
                if (summarySettings && this.isset(summarySettings.summary)) {
                  summaryCalc = [
                    (summarySettings.summary && summarySettings.summary.total_btu === 0 ? { field_name: "", name: this.$t("ore.report.total_btu") + ":", real_value: this.energyTotalBtu(), value: this.numberToFormatNumeral(this.energyTotalBtu()) } : []),
                    (summarySettings.summary && summarySettings.summary.total_kwh === 0 ? { field_name: "", name: this.$t("ore.report.total_kwh") + ":", real_value: this.energyTotalKwh(), value: this.numberToFormatNumeral(this.energyTotalKwh()) } : []),
                    (summarySettings.summary && summarySettings.summary.total_btu_t === 0 ? { field_name: "", name: this.$t("ore.report.total_btu_t") + ":", real_value: this.energyTotalBtu_T(), value: this.numberToFormatNumeral(this.energyTotalBtu_T()) } : []),
                    (summarySettings.summary && summarySettings.summary.total_kwh_t === 0 ? { field_name: "", name: this.$t("ore.report.total_kwh_t") + ":", real_value: this.energyTotalKwh_T(), value: this.numberToFormatNumeral(this.energyTotalKwh_T()) } : []),
                    (summarySettings.summary && summarySettings.summary.electricity_btu_t === 0 ? { field_name: "electricity", name: this.$t("ore.report.electricity_btu_t") + ":", real_value: this.energyElectricityBtu_T(), value: this.numberToFormatNumeral(this.energyElectricityBtu_T()) } : []),
                    (summarySettings.summary && summarySettings.summary.generator_btu_t === 0 ? { field_name: "generator", name: this.$t("ore.report.generator_btu_t") + ":", real_value: this.energyGeneratorBtu_T(), value: this.numberToFormatNumeral(this.energyGeneratorBtu_T()) } : []),
                  ]
                }
                if (summarySettings && this.isset(summarySettings.bar)) {
                  summaryBar = [
                    (summarySettings.bar.total_btu === 0 ? { name: this.$t("ore.report.total_btu"), value: this.numberToFormatNumeral(this.energyTotalBtu()) } : []),
                    (summarySettings.bar.total_kwh === 0 ? { name: this.$t("ore.report.total_kwh"), value: this.numberToFormatNumeral(this.energyTotalKwh()) } : []),
                    (summarySettings.bar.total_btu_t === 0 ? { name: this.$t("ore.report.total_btu_t"), value: this.numberToFormatNumeral(this.energyTotalBtu_T()) } : []),
                    (summarySettings.bar.total_kwh_t === 0 ? { name: this.$t("ore.report.total_kwh_t"), value: this.numberToFormatNumeral(this.energyTotalKwh_T()) } : []),
                  ]
                }
                break;
              case "MSL":
                break;
              case "MCM":
                break;
              case "MIV":
                break;
              case "MOE":
                if (_module_summarySettings) {
                  summaryCalc = [
                    (this.fastValidValue("availability", 1, _module_summarySettings) === 0 ? { name: this.$t("ore.report.availability") + ":", value: this.OEEAvailability() == Infinity ? 'ND' : this.numberToPercent(this.OEEAvailability()) + '%' } : 0 + '%'),
                    (this.fastValidValue("performance", 1, _module_summarySettings) === 0 ? { name: this.$t("ore.report.performance") + ":", value: this.OEEPerfomance() == Infinity ? 'ND' : this.numberToPercent(this.OEEPerfomance()) + '%' } : 0 + '%'),
                    (this.fastValidValue("quality", 1, _module_summarySettings) === 0 ? { name: this.$t("ore.report.quality") + ":", value: this.OEEQuality() == Infinity ? 'ND' : this.numberToPercent(this.OEEQuality()) + '%'} : 0 + '%'),
                    (this.fastValidValue("oee", 1, _module_summarySettings) === 0 ? { name: this.$t("ore.report.oee") + ":", value: this.OEE_OEE() == Infinity ? 'ND' : this.numberToPercent(this.OEE_OEE()) + '%' } : 0 + '%'),
                  ]
                }
                break;
              case "MAL":
                if (_module_summarySettings) {
                  let _runningTime = this.getRunningTime()
                  let _productionHours = this.getProductionHours()
                  summaryCalc = [
                    (this.fastValidValue("production_hours", 1, _module_summarySettings) === 0 ? { name: this.$t("ore.report.production_hours") + ":", value: this.secondsToHours(_productionHours, ' ') } : [] ),
                    (this.fastValidValue("running_time", 1, _module_summarySettings) === 0 ? { name: this.$t("ore.report.running_time") + ":", value:  this.secondsToHours(_runningTime, ' ') } : []),
                    (this.fastValidValue("efficiency", 1, _module_summarySettings) === 0 ? { name: this.$t("ore.report.efficiency") + ":", value: this.numberToPercent(this.efficiency()) + ' %' } : 0 + ' %'),
                    (this.fastValidValue("total_crushed_products", 1, _module_summarySettings) === 0 ? { name: this.$t("ore.report.total_crushed_products") + ":", value: this.numberToFormatNumeral(this.materialActivitiesTotalProduced()) } : 0 ),
                    (this.fastValidValue("total_material_produced", 1, _module_summarySettings) === 0 ? { name: this.$t("ore.report.total_material_produced") + ":", value: this.numberToFormatNumeral(this.materialActivitiesTotalProducedPositive()) + ' t' } : 0 + ' t' ),
                    (this.fastValidValue("waste", 1, _module_summarySettings) === 0 ? { name: this.$t("ore.report.waste") + ":", value: this.numberToFormatNumeral(this.wasteSummaryAll()) + ' t' } : 0),
                    (this.fastValidValue("tph_theoretical", 1, _module_summarySettings) === 0 ? { name: this.$t("ore.report.tph_theoretical") + ":", value: this.numberToFormatNumeral(this.tphTheoretical()) + ' t/h'} : 0 + ' t/h'),
                    (this.fastValidValue("planned_tph", 1, _module_summarySettings) === 0 ? { name: this.$t("ore.report.planned_tph") + ":", value: this.numberToFormatNumeral(this.tphPlanned()) + ' t/h'} : 0 + ' t/h'),
                    (this.fastValidValue("tph_real", 1, _module_summarySettings) === 0 ? { name: this.$t("ore.report.tph_real") + ":", value: this.numberToFormatNumeral(this.thpReal()) + ' t/h'} : 0 + ' t/h'),
                    (this.fastValidValue("tph_produced_real", 1, _module_summarySettings) === 0 ? { name: this.$t("ore.report.tph_produced_real") + ":", value: this.numberToFormatNumeral(this.thpProducedReal()) + ' t/h'} : 0 + ' t/h'),
                    (this.fastValidValue("tph_real_no_waste", 1, _module_summarySettings) === 0 ? { name: this.$t("ore.report.tph_real_no_waste") + ":", value: this.numberToFormatNumeral(this.thpRealNoWaste()) + ' t/h'} : 0 + ' t/h'),
                    (this.fastValidValue("production_man_hours", 1, _module_summarySettings) === 0 ? { name: this.$t("ore.report.production_man_hours") + ":", value: this.numberToFormatNumeral(this.productionManHoursSummary()) + ' t/wh'} : 0 + ' t/wh'),
                    (this.fastValidValue("tons_men_crushing", 1, _module_summarySettings) === 0 ? { name: this.$t("ore.report.tons_men_crushing") + ":", value: this.numberToFormatNumeral(this.tonsMenCrushing()) } : 0 ),
                  ]
                }
                break;
            }
          }
          moduleItem['summaryCalcs'] = this.$_.filter(summaryCalc, summaryCalcItem => this.isset(summaryCalcItem.name))
          moduleItem['summaryBar'] = this.$_.filter(summaryBar, summaryBarItem => this.isset(summaryBarItem.name))
          modulesReport.push(moduleItem)
        })
        modulesReport = modulesReport.map(item => {
          if (msmSettings[[item.name]] != undefined) {
            let _modulesSetting = msmSettings[[item.name]]

            let _order = 0
            try {
              _order = _modulesSetting.options.order
              if (_order == undefined)
                _order = 0
            } catch {}
            let _visible = false
            try { _visible = _modulesSetting.options.visible } catch {}
            item["order"] = _order
            item["showSummary"] = _visible
          } else {
            item["order"] = 0
            item["showSummary"] = false
          }
          return item
        })

        modulesReport = this.$_.orderBy(modulesReport, ['order'], ['asc'])
        this.$store.commit('ore/setSummaryReport', modulesReport)
      }
    },
    remainingTime(date, frecuency) {
      const DATETIME_NOW = new Date()
      const FRECUENCY_ARRAY = [
          { "id": 1, "name": "daily" },
          { "id": 2, "name": "weekly" },
          { "id": 3, "name": "biweekly" },
          { "id": 4, "name": "monthly" },
          { "id": 5, "name": "annually" },
      ]
      let date_update;
      try {
          date_update = new Date(date).toISOString()// datetime.strptime(date, "%Y-%m-%dT%H:%M:%S.%f")
      } catch (e) {
          console.log("errorRemainingTime", e);
          return 0//"NO VALID"
      }
      let frecuency_equipment_maintenance = frecuency ? FRECUENCY_ARRAY.find(x => x.id == frecuency) : null
      //next((x for x in FRECUENCY_ARRAY if x['id'] == frecuency), None)
      let date_update_frecuency = new Date(date_update); //date_update + frecuency_equipment_maintenance["time"]
      switch (frecuency_equipment_maintenance.name) {
          case "daily":
              date_update_frecuency.setDate(date_update_frecuency.getDate() + 1);
              break;
          case "weekly":
              date_update_frecuency.setDate(date_update_frecuency.getDate() + 7);
              break;
          case "biweekly":
              date_update_frecuency.setDate(date_update_frecuency.getDate() + 14);
              break;
          case "monthly":
              date_update_frecuency.setMonth(date_update_frecuency.getMonth() + 1);
              break;
          case "annually":
              date_update_frecuency.setFullYear(date_update_frecuency.getFullYear() + 1);
              break;
      }
      let result_frecuency = (DATETIME_NOW - date_update_frecuency) / 1000 //Se convierten a segundos
      return result_frecuency
    },
    async generateMaintenancePlanReport() {
      this.$store.commit("ore/setMaintenanceReport", [])
      let _storeReportSelected = this.$_.cloneDeep(this.storeReportSelected)
      let _report = this.storeReportSelected["report"].id
      // get lists of equipment and their maintenance
      let equipments_crusher = this.storeListEquipment.filter(_x => (!_x.deleted ||_x.deleted === "") && _x.crusher && _x.crusher === this.storeCrusherId)
      let equipment_maintenance = this.storeListEquipmentMaintenance.filter(_x => (!_x.deleted ||_x.deleted === "") && _x.equipment && equipments_crusher.find(_y => _y.id === _x.equipment))
      // get report information and other reports
      let maintenance_report = "maintenance" in _storeReportSelected ? _storeReportSelected["maintenance"] : []
      let maintenance_details_report = "maintenance_details" in _storeReportSelected ? _storeReportSelected["maintenance_details"] : []
      let maintenance_details_others = "maintenance_details_others" in _storeReportSelected ? _storeReportSelected["maintenance_details_others"] : []

      /*  zone se puede reemplazar por la lista global de listITem
          zone can be replaced by the global listITem
      */
      // let zone = "zone" in _storeReportSelected ? _storeReportSelected["zone"] : []
      let zone = await getListItemsByCode("EQZ") ;//"zone" in _storeReportSelected ? _storeReportSelected["zone"] : []
      let eqc = await getListItemsByCode("EQC") ;//"zone" in _storeReportSelected ? _storeReportSelected["zone"] : []
      zone = [].concat(zone, eqc)

      let _zones_data = []

      equipments_crusher.forEach(eq_crusher => {
        eq_crusher = this.$_.cloneDeep(eq_crusher)
        eq_crusher["_condition"] = 0
        eq_crusher["_last_date_condition"] = ""
        eq_crusher["_in_maintenance"] = false

        // find all maintenance for this equipment
        eq_crusher["equipment_maintenance"] = this.$_.filter(equipment_maintenance, ["equipment", eq_crusher["id"]])

        // find a maintenance for this equipment and this report
        let _item_data_parent = maintenance_report.find(_x => _x.equipment === eq_crusher["id"] && _x.report === _report)
        eq_crusher["_report_maintenance"] = _item_data_parent ? _item_data_parent : {}

        let exist_zone = null

        // Eq maintenance
        if (eq_crusher["equipment_maintenance"]) {
          // analyze maintenances for the current equipment
          eq_crusher["equipment_maintenance"].forEach(_equipment_maintenance => {
            _equipment_maintenance["_equipment"] = eq_crusher["id"]
            _equipment_maintenance["_equipment_maintenance"] = _equipment_maintenance["id"]
            _equipment_maintenance["_is_other_report"] = false
            _equipment_maintenance["_last_date"] = ""
            _equipment_maintenance["_maintenance_detail_id"] = null
            _equipment_maintenance["_maintenance_id"] = null
            _equipment_maintenance["_remainning_time"] = 0
            _equipment_maintenance["_report_id"] = null
            _equipment_maintenance["_value"] = 0

            // maintenance detail data
            let _equipment_maintenance_report_data = null
            // search maintenances already created in this report
            let filterItemsMaintenanceDetails = this.$_.filter(maintenance_details_report, { "report": _report, "type": _equipment_maintenance["type"], "equipment_maintenance": _equipment_maintenance["id"], "equipment": eq_crusher["id"] })

            // if no maintenance was found in the report, then look in other reports
            if (filterItemsMaintenanceDetails.length <= 0) {
              filterItemsMaintenanceDetails = this.$_.filter(maintenance_details_others, { "type": _equipment_maintenance["type"], "equipment_maintenance": _equipment_maintenance["id"], "equipment": eq_crusher["id"] })
            }

            if (filterItemsMaintenanceDetails.length >= 0) {
              let itemMaxDate = this.getMaxDateFromArray(filterItemsMaintenanceDetails, "update")
              // if found then get the index of the element
              if (itemMaxDate) {
                _equipment_maintenance_report_data = filterItemsMaintenanceDetails.find(_x => _x.id === itemMaxDate["id"])
              }
            }

            // a maintenance was found for the equipment
            if (_equipment_maintenance_report_data) {
              let _equipment_maintenance_report_data2 = this.$_.cloneDeep(_equipment_maintenance_report_data)
              // check if the maintenance found belongs to another report or not
              let _isOtherReport = !(_equipment_maintenance_report_data["report"] === _report)
              // get the remaining time based on the last update and the frequency.
              // if remaining time < 0; frequency time not met
              let _remainning_time = this.remainingTime(_equipment_maintenance_report_data2["update"], _equipment_maintenance["frecuency"])
              // Shows only report data or unfinished maintenance
              if (_remainning_time < 0 || !_isOtherReport) {
                _equipment_maintenance["_is_other_report"] = _isOtherReport
                _equipment_maintenance["_last_date"] = _equipment_maintenance_report_data["update"]
                _equipment_maintenance["_maintenance_detail_id"] = _equipment_maintenance_report_data["id"]
                _equipment_maintenance["_maintenance_id"] = _item_data_parent ? _item_data_parent["id"] : null
                _equipment_maintenance["_remainning_time"] = _remainning_time
                _equipment_maintenance["_report_id"] = _equipment_maintenance_report_data["report"]
                _equipment_maintenance["_value"] = _equipment_maintenance_report_data["value"]
              }
            }
          })
        }

        let maintenance_items = this.$_.cloneDeep(eq_crusher["equipment_maintenance"])
        // ONLY items to be verified
        // create an ordered array, where the first element has the highest priority
        // count number of items found for the given "type" and "_value(check status)"
        let conditions_eq_maintenance = [
          { "id": 3, "total": maintenance_items.filter(x => { return x.type == 1 && x._value == 3 }).length },
          { "id": 2, "total": maintenance_items.filter(x => { return x.type == 1 && x._value == 2 }).length },
          { "id": 1, "total": maintenance_items.filter(x => { return x.type == 1 && x._value == 1 }).length },
        ]
        let condition_setted = false
        // iterate through the previous array in order and look for holding items
        conditions_eq_maintenance.forEach(x_c => {
          let _condition = x_c.id
          let _condition_total = x_c.total
          /*  obtener items que no han cumplido la frecuencia
              get items that have not met the frequency
          */
          let items_frec = maintenance_items.filter((x) => { return x.type == 1 && x._value == _condition && x._remainning_time < 0 })
          // if there are no items, still look for the last data
          // sets if the equipment is in maintenance
          if (items_frec.length === 0) {
            items_frec = maintenance_items.filter((x) => { return x.type == 1 && x._value == _condition })
          } else {
            eq_crusher["_in_maintenance"] = true
          }
          // check if the variables are empty
          // fill the variables with the elements found from the first iteration that has elements and meets the conditions
          if ((!condition_setted) && items_frec.length > 0 && _condition_total > 0) {
            let maxdate = null
            maxdate = this.getMaxDateFromArray(items_frec, "_last_date")
            // set the condition for the equipment
            eq_crusher["_condition"] = _condition
            eq_crusher["_last_date_condition"] = maxdate ? maxdate["_last_date"] : ""
            condition_setted = true
          }
        })

        // ZONE
        // get the zone or the category of the team
        let eq_item_zone_id = this.fastValidValue("zone", null, eq_crusher)
        if (!eq_item_zone_id) {
          let eq_item_category_id = this.fastValidValue("category", null, eq_crusher)
          if (eq_item_category_id) {
            let _category_data = this.$_.find(zone, { 'id': eq_item_category_id })
            eq_item_zone_id = this.fastValidValue("parent_id", null, _category_data)
          }
        }
        // add the zone and equipment to an array
        // if the zone already exists in the array, push the equipment to equipments
        if (eq_item_zone_id) {
          let eq_zone_data = this.$_.find(zone, { 'id': eq_item_zone_id })
          exist_zone = this.$_.findIndex(_zones_data, ['id', eq_item_zone_id])
          if (exist_zone >= 0) {
            _zones_data[exist_zone]["_equipments"].push(eq_crusher)
          } else {
            if (eq_zone_data) {
              eq_zone_data["_in_maintenance"] = false
              eq_zone_data["_condition"] = 0
              eq_zone_data["_equipments"] = [eq_crusher]
              _zones_data.push(eq_zone_data)
            }
          }
        }
      })
      // set the condition for the zone
      _zones_data.forEach(equipment_zone => {
        let equipments_items = equipment_zone["_equipments"]
        let conditions_eq = [
          { "id": 3, "total": equipments_items.filter(x => { return x["_condition"] === 3 }).length },
          { "id": 2, "total": equipments_items.filter(x => { return x["_condition"] === 2 }).length },
          { "id": 1, "total": equipments_items.filter(x => { return x["_condition"] === 1 }).length },
        ]
        conditions_eq.forEach(item_condition_eq => {
          if (equipment_zone["_condition"] === 0 && item_condition_eq["total"] > 0) {
            equipment_zone["_condition"] = item_condition_eq["id"]
            // sets if the equipments in the zone have already complied with the maintenance
            let _c = 0
            equipments_items.filter(x => { return x["_condition"] === item_condition_eq.id }).forEach(_x => {
              if (_x._in_maintenance) _c = _c + 1
            })
            equipment_zone["_in_maintenance"] = _c > 0
          }
        })
      })
      this.$store.commit("ore/setMaintenanceReport", _zones_data)
      return _zones_data
    },
    setCampaignTarget() {
    /* busca el campaign_target, y la info del dia actual

       look for the campaign_target, and the information of the current day
    */
    let reportCampaignId = this.fastValidValue("report.campaign", undefined, this.storeReportSelected)
    let reportDate = this.fastValidValue("report.date", undefined, this.storeReportSelected)
    let reportShift = this.fastValidValue("report.shift", undefined, this.storeReportSelected)
    if (!reportCampaignId) return;
    let numberDayReport = this.$moment(reportDate).day()
    let numberDayNow = this.$moment().day()
    let dataStore = {
      data: {},
      day_now: {},
      day_report: {}
    }
    if (this.storeListCampaignTarget) {
      let _dataCampaignTarget = this.$_.cloneDeep(this.storeListCampaignTarget).find(_x => _x.campaign === reportCampaignId)
      if (_dataCampaignTarget) {
        let _data = this.customParseJSON(_dataCampaignTarget, ["schedule_day", "schedule_night"])
        let _scheduleShiftReport = reportShift === 0 ? _data.schedule_night : _data.schedule_day
        let dayScheduleReport = numberDayReport in _scheduleShiftReport ? _scheduleShiftReport[numberDayReport] : {}
        let dayScheduleNow = numberDayNow in _scheduleShiftReport ? _scheduleShiftReport[numberDayNow] : {}
        let _day = {
          active: false,
          planned_production_hours_start: "00:00",
          planned_production_hours_end: "00:00",
          planned_downtime: "00:00",
          planned_shift_hours: "00:00",
          planned_production_hours: "00:00",
          tonnes_hour: "0",
          tons_work_shift: "",
        };
        dataStore["data"] = _data
        dataStore["day_now"] = {..._day, ...dayScheduleNow}
        dataStore["day_report"] = {..._day, ...dayScheduleReport}
      }
    }
    this.$store.commit("ore/setReportCampaignTarget", dataStore)
    }
  }
}
export default oreMixin