<template>
  <div>
    <exp-alert-confirm
      ref="modalSaveChanges"
      message="ore.report.msg_data_no_save"
      :labelOk="'core.comun.yes'"
      :colorOk="'green'"
      :iconOk="'mdi-check-circle'"
      :fnOk="saveMaterialCrushing"
      :labelCancel="'core.comun.no'"
      :colorCancel="'red'"
      :iconCancel="'mdi-close-circle'"
      :fnCancel="noSaveChanges"
    />
    <exp-ore-modal
      ref="materialSummaryModal"
      :openModal="showDialog"
      @onCloseModal="closeModal"
      @onAcceptModal="saveMaterialCrushing"
      width="400px"
      :modalTitle="($t('ore.report.scenario') + ': ' + scenarioTitle)"
      :actionsConfig="modalActionsConfig"
    >
      <template v-slot:body>
        <ValidationObserver v-slot="{ dirty }" ref="observerMaterialCrushing">
          <form class="form-material" @submit.prevent="">
            <v-row dense>
              <v-col cols="12" v-if="configForms.source.visible" class="mb-2">
                <ValidationProvider
                  v-slot="{ errors }"
                  :name="$t('ore.report.source')"
                  rules="required"
                >
                  <v-select
                    v-model="data.source"
                    :items="sourceList"
                    item-value="id"
                    item-text="description"
                    outlined
                    dense
                    hide-details
                    :label="$t('ore.report.source')"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="6" v-if="configForms.primary_crushing_qty.visible">
                <ValidationProvider
                  v-slot="{ errors }"
                  :name="$t('ore.report.primary_crushing_qty')"
                  rules="required"
                >
                  <exp-mask
                    v-model="data.primary_crushing_qty"
                    :label="$t('ore.report.primary_crushing_qty')"
                    class="input-number"
                    outlined
                    dense
                    :error-messages="errors"
                    :mask="maskDecimalNumber"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="6" v-if="configForms.number_loads.visible">
                <ValidationProvider
                  v-slot="{ errors }"
                  :name="$t('ore.report.number_loads')"
                  rules="required"
                >
                  <exp-mask
                    v-model="data.number_of_loads"
                    :label="$t('ore.report.number_loads')"
                    class="input-number"
                    outlined
                    dense
                    :error-messages="errors"
                    :mask="maskDecimalNumber"
                  />
                </ValidationProvider>
              </v-col>
            </v-row>
          </form>
        </ValidationObserver>
      </template>
    </exp-ore-modal>
  </div>
</template>

<script>
import oreMixin from "@/mixins/ore";
import expOreModal from "@/components/expOreModal";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import {
  updateMaterialCrushing,
  createMaterialCrushing,
} from "@/gateway/material_crushing";

export default {
  name: "material-form",
  mixins: [oreMixin],
  components: { ValidationObserver, ValidationProvider, expOreModal },
  data() {
    return {
      // Modal vars
      loading: false,
      showDialog: false,
      originalData: {},
      // Custom vars
      data: {},
      default: {
        id: undefined,
        report: 0,
        source: undefined,
        primary_crushing_qty: 0,
        number_of_loads: 0,
        campaign: 0,
        scenario: 0,
      },
    };
  },
  props: {
    // Modal props
    configForms: {
      type: Object,
      default: () => {},
    },
    // Custom props
    sourceList: {
      type: Array,
      default: () => [],
    },
    scenarioTitle: {
      type: String,
      default: "",
    },
  },
  async mounted() {},
  methods: {
    // Modal methods
    initModal() {
      if ("observerMaterialCrushing" in this.$refs) {
        this.$refs.observerMaterialCrushing.reset();
      }
      this.data = this.$_.merge({ ...{}, ...this.default }, this.data);
      this.originalData = this.$_.cloneDeep(this.data);
      try {
        if (
          "number_of_loads" in this.data &&
          (this.data["number_of_loads"] || this.data["number_of_loads"] === 0)
        )
          this.originalData["number_of_loads"] = String(
            this.data.number_of_loads
          );
        if (
          "primary_crushing_qty" in this.data &&
          (this.data["primary_crushing_qty"] ||
            this.data["primary_crushing_qty"] === 0)
        )
          this.originalData["primary_crushing_qty"] = String(
            this.data.primary_crushing_qty
          );
      } catch (error) {}
    },
    noSaveChanges() {
      this.showDialog = false;
      this.clearModal();
    },
    async clearModal() {
      await this.$refs.observerMaterialCrushing.reset();
      this.data = { ...{}, ...this.default };
      this.originalData = this.$_.cloneDeep(this.data);
    },
    closeModal() {
      if (this.isDirty && this.storeOnlyReadReport == false) {
        this.$refs.modalSaveChanges.showDialog = true;
      } else {
        this.showDialog = false;
        this.clearModal();
      }
    },
    // Custom methods
    async saveMaterialCrushing() {
      if (this.loading) return;
      let frmValid = await this.$refs.observerMaterialCrushing.validate();
      this.$refs.modalSaveChanges.showDialog = false;
      if (frmValid) {
        this.loading = true;
        let _data = undefined;
        try {
          if ("id" in this.data && this.data["id"]) {
            _data = await updateMaterialCrushing(this.data.id, this.data);
          } else {
            this.data["scenario"] = this.fastValidValue(
              "report.scenario",
              null,
              this.storeReportSelected
            );
            this.data["campaign"] = this.fastValidValue(
              "report.campaign",
              null,
              this.storeReportSelected
            );
            _data = await createMaterialCrushing(this.data);
          }
          this.$emit("parentActions", "_set_materials_summary", _data);
          this.showDialog = false;
          this.clearModal();
        } catch (error) {
          this.logError(error)
        }
        this.loading = false;
      }
    },
  },
  computed: {
    // Modal computed
    isDirty() {
      // retorna true si la info ha cambiado
      // returns true if the info has changed
      return !this.$_.isEqual(this.data, this.originalData);
    },
    modalActionsConfig() {
      return {
        disabled_content: this.loading,
        action_accept_config: {
          loading: this.loading,
          disabled: this.storeOnlyReadReport
        },
        action_close_config: {
          disabled: this.loading,
          onlyEmmit: true,
        },
      };
    },
  },
  watch: {
    // Modal watch
    showDialog(value) {
      if (value) {
        this.initModal();
      } else {
        this.$emit("parentActions", "_clean_includes_id", {});
        this.clearModal();
      }
    },
  },
};
</script>
