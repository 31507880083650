<template>
  <v-app>
    <v-main>
      <div class="ore-template">
        <div class="ore-wrap-ipad">
          <div class="main-container">
            <slot />
          </div>
        </div>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import expHeaderBar from "@/components/expHeaderBar";
import expReportMenu from "@/components/reportMenu";

const defaultLayout = "AppLayoutDefault";
export default {
  name: "ReportLayout",
  components: {
    expHeaderBar,
    expReportMenu,
  },
  computed: {
    layout() {
      const layout = this.$route.meta.layout || defaultLayout;
      return () => import(`@/layouts/${layout}.vue`);
    }
  },
};
</script>