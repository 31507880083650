
export const storeAuth = {
  namespaced: true,
  state: {
    auth_customer: {},
    user_preferences: {
      last_language: { lang: '', messages: {} }
    }
  },
  getters: {
    getAuthCustomer(state) {
      return state.auth_customer
    },
    isAuthCustomer(state) {
      try {
        let _customerData = state.auth_customer
        if (JSON.parse(atob(_customerData.access.split(".")[1]))["user_id"] == _customerData.data.id) {
          return true
        } else {
          return false
        }
      } catch {
        return false
      }
    },
    getLastLanguage(state) {
      return state.user_preferences.last_language
    }
  },
  mutations: {
    setAuthCustomer(state, data) {
      state.auth_customer = data
    },
    setLastLanguage(state, data) {
      state.user_preferences.last_language = data
    }
  },
}