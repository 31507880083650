var EntitySchema = require("typeorm").EntitySchema;

module.exports = new EntitySchema({
    name: "oreConfigRockCharacteristic",
    tableName: "ore_config_rock_characteristic",
    columns: {
        id: {
            primary: true,
            type: "int",
            generated: false
        },
        name: {
            type: "varchar"
        },
        description: {
            type: "varchar",
            nullable: true
        },
        site: {
            type: "int"
        },
        extra_data: {
            type: "varchar",
            nullable: true
        },
        created: {
            type: "datetime",
            nullable: true
        },
        created_by: {
            type: "int",
            nullable: true
        },
        update: {
            type: "datetime",
            nullable: true
        },
        update_by: {
            type: "int",
            nullable: true
        },
        deleted: {
            type: "datetime",
            nullable: true
        },
        deleted_by: {
            type: "int",
            nullable: true
        },
        published: {
            type: "int",
            default: "1"
        }
    }
})