var EntitySchema = require("typeorm").EntitySchema;

module.exports = new EntitySchema({
    name: "oreConfigScenario",
    tableName: "ore_config_scenario",
    columns: {
        id: {
            primary: true,
            type: "int",
            generated: false
        },
        name: {
            type: "varchar"
        },
        description: {
            type: "varchar",
            nullable: true
        },
        theoretical_tph: {
            type: "float",
            nullable: true
        },
        target_tph: {
            type: "float",
            nullable: true
        },
        ton_per_day: {
            type: "float",
            nullable: true
        },
        flowsheet: {
            type: "int",
            default: "0"
        },
        campaign: {
            type: "int",
            nullable: true
        },
        created: {
            type: "datetime",
            nullable: true
        },
        created_by: {
            type: "int",
            nullable: true
        },
        update: {
            type: "datetime",
            nullable: true
        },
        update_by: {
            type: "int",
            nullable: true
        },
        deleted: {
            type: "datetime",
            nullable: true
        },
        deleted_by: {
            type: "int",
            nullable: true
        },
        published: {
            type: "int",
            default: "1"
        }
    }
})