<template>
  <div>
    <v-dialog v-model="dialog" persistent scrollable max-width="500px">
      <template v-slot:activator="{ on, attrs }">
        <v-list-item link v-bind="attrs" v-on="on">
          <v-list-item-icon>
            <v-icon class="ml-2" size="28" color="black">mdi-lock-reset</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              >{{ $t("core.user.restore_password") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <v-card>
        <v-card-title>{{ $t("core.user.change_password") }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 300px" class="py-3">
          <validation-observer
            ref="observer_change_password"
            v-slot="{ invalid }"
          >
            <v-form>
              <validation-provider
                v-slot="{ errors }"
                name="old_password"
                rules="required"
              >
                <v-text-field
                  v-model="old_password"
                  :error-messages="errors"
                  :label="$t('core.user.old_password')"
                  type="password"
                  required
                  :readonly="loading"
                ></v-text-field>
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                name="new_password"
                rules="required"
                vid="new_password"
              >
                <v-text-field
                  v-model="new_password"
                  :error-messages="errors"
                  :label="$t('core.user.new_password')"
                  type="password"
                  required
                  :readonly="loading"
                ></v-text-field>
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                name="confirm_new_password"
                rules="required|confirmed:new_password"
              >
                <v-text-field
                  v-model="confirm_new_password"
                  :error-messages="errors"
                  :label="$t('core.user.confirm_new_password')"
                  type="password"
                  required
                  :readonly="loading"
                ></v-text-field>
              </validation-provider>
            </v-form>
          </validation-observer>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn @click="changePassword" rounded color="green">
            <v-icon left v-if="!loading">mdi-content-save</v-icon>
            <v-progress-circular
              v-else
              indeterminate
              size="18"
              class="ore-progress-circular-loading"
            ></v-progress-circular>
            {{ $t("core.comun.save") }}&nbsp;
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn rounded @click="closeModal" color="red">
            <v-icon left>mdi-close-circle</v-icon>
            {{ $t("core.comun.cancel") }}&nbsp;
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  name: "exp-user-restore-password",
  mixins: [],
  components: { ValidationObserver, ValidationProvider },
  data() {
    return {
      dialog: false,
      old_password: "",
      new_password: "",
      confirm_new_password: "",
    };
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    async changePassword() {
      let valid = await this.$refs.observer_change_password.validate();
      if (this.loading) return;
      if (valid) {
        let data = {
          old_password: this.old_password,
          new_password: this.new_password,
          confirm_new_password: this.confirm_new_password,
        };
        this.$emit("onChangePassword", data);
      }
    },
    async closeModal() {
      await this.$refs.observer_change_password.reset();
      this.dialog = false;
      this.old_password = "";
      this.new_password = "";
      this.confirm_new_password = "";
    },
  },
};
</script>

