import configMixins from "@/mixins/config";
import { createQueryBuilder, getRepository } from "typeorm";
import { baseApi, getCustomerJWT } from '@/gateway/base'
import axios from 'axios'
import moment from "moment";

const syncbgMixin = {
    mixins: [configMixins],
    data() {
        return {

        }
    },
    methods: {
        /**
         * It is used to synchronize data from all reports that have the field called sync set to false.
         * It is send from mobile aplication to web aplication
         * @param {String} trigger - Message to know where the function is called.
         */
        async syncBgFn(trigger) {
            let _isSynchronizing = await this.$store.getters["comun/getSynchronizing"];
            let user_id = this.$store.getters["auth/getAuthCustomer"].data.id
            try{
                if(_isSynchronizing == false){
                    this.$store.commit("comun/setSynchronizing", true);
                    let _isOnline = await this.$store.getters["comun/getIsOnline"];
                    let _serverIsOnline;
                    let _reportIsReject = false; //To prevent error with the report Id of the store

                    if (_isOnline && this.$store.getters['ore/getConnection'] == true) {
                        const reportRepository = await getRepository("oreReport")
                        const startStopRepository = await getRepository("oreReportStartStop");
                        const startStopEventsRepository = await getRepository("oreReportStartStopEvents");
                        const productionHoursRepository = await getRepository("oreReportProductionHours");
                        const productionManHoursRepository = await getRepository("oreReportProductionManHours");
                        const materialCrushingRepository = await getRepository("oreReportMaterialCrushing");
                        const materialCrushingActivitiesRepository = await getRepository("oreReportMaterialCrushingActivities");
                        const energyRepository = await getRepository("oreReportEnergy");
                        const inventoryRepository = await getRepository("oreReportInventory");
                        const commentsRepository = await getRepository("oreReportComments");
                        const maintenanceRepository = await getRepository("oreReportMaintenance");
                        const maintenanceDetailsRepository = await getRepository("oreReportMaintenanceDetails");
                        const modulesStatusRepository = await getRepository("oreReportModuleStatus");
                        const reportStatusRepository = getRepository("ore_report_status");
                        const fileRepository = await getRepository("coreFile");

                        let item = {}
                        // The report table is searched for any record that remains to be synchronized, if it finds something, the data is sent.
                        await reportRepository.findOne({sync: false}).then(res => { if(typeof(res) == 'undefined'){ item.report = [] }else{ item.report = this.parseDate([res]); } })
                        if(item.report.length > 0 && typeof(item.report[0]) != 'undefined'){
                            await startStopEventsRepository.find({report: item.report[0].id, sync: false }).then(async (res) => { item.start_stop_events = this.parseDate(res); }).catch(e => console.log(e))
                            await startStopRepository.find({report: item.report[0].id, sync: false }).then(async (res) => {  item.start_stop = this.parseDate(res) }).catch(e => console.log(e))
                            await productionHoursRepository.find({report: item.report[0].id, sync: false }).then(async (res) => {  item.production_hours = this.parseDate(res) }).catch(e => console.log(e))
                            await productionManHoursRepository.find({report: item.report[0].id, sync: false }).then(async (res) => {  item.production_man_hours = this.parseDate(res) }).catch(e => console.log(e))
                            await materialCrushingRepository.find({report: item.report[0].id, sync: false }).then(async (res) => {  item.material_crushing = this.parseDate(res) }).catch(e => console.log(e))
                            await materialCrushingActivitiesRepository.find({report: item.report[0].id, sync: false }).then(async (res) => {  item.material_crushing_activities = this.parseDate(res) }).catch(e => console.log(e))
                            await energyRepository.find({report: item.report[0].id, sync: false }).then(async (res) => {  item.energy = this.parseDate(res) }).catch(e => console.log(e))
                            await inventoryRepository.find({report: item.report[0].id, sync: false }).then(async (res) => {  item.inventory = this.parseDate(res) }).catch(e => console.log(e))
                            await commentsRepository.find({report: item.report[0].id, sync: false }).then(async (res) => {  item.comments = this.parseDate(res) }).catch(e => console.log(e))
                            await maintenanceRepository.find({report: item.report[0].id, sync: false }).then(async (res) => {  item.maintenance = this.parseDate(res) }).catch(e => console.log(e))
                            await maintenanceDetailsRepository.find({report: item.report[0].id, sync: false }).then(async (res) => {  item.maintenance_details = this.parseDate(res) }).catch(e => console.log(e))
                            await modulesStatusRepository.find({report: item.report[0].id, sync: false }).then(async (res) => {  item.modules_status = this.parseDate(res) }).catch(e => console.log(e))
                            await reportStatusRepository.find({report: item.report[0].id, sync: false }).then(async (res) => {  item.report_status = this.parseDate(res) }).catch(e => console.log(e))
                            await fileRepository.find({ update_by: user_id, sync: false}).then(async (res) => {  item.core_file = this.parseDate(res) }).catch(e => console.log(e))

                            let objToSync = {
                                reports: []
                            }
                            item.report.forEach(_report => {
                        
                                let _obj = {
                                    report: _report,
                                    data: { 
                                        start_stop: typeof(item.start_stop[0]) != 'undefined' ? item.start_stop[0] : {},
                                        start_stop_events: item.start_stop_events,
                                        production_hours: typeof(item.production_hours[0]) != 'undefined' ? item.production_hours[0] : {},
                                        production_man_hours:  item.production_man_hours,
                                        material_crushing: item.material_crushing,
                                        material_crushing_activities: item.material_crushing_activities,
                                        energy: item.energy,
                                        inventory: item.inventory,
                                        comments: item.comments,
                                        maintenance: item.maintenance,
                                        maintenance_details: item.maintenance_details,
                                        modules_status: item.modules_status,
                                        report_status: item.report_status,
                                        core_file: item.core_file,
                                    }
                                }
                                objToSync.reports.push(_obj)
                            })
                            const self = this
                            const token = getCustomerJWT()
                            await axios.post(baseApi() + '1.0/ore/mobile/sync/', objToSync, { headers: { Authorization: `Bearer ${token}` } })
                                .then(async function (response) {
                                    await response.data.reports.forEach(async (_data) => {
                                        let reportRes = _data.report //report web
                                        let dataRes = _data.data
                                        let reportData = item.report //report local
                                        //If the report is rejected, everything is deleted to save what comes from the web application.
                                        if(reportRes.action_msg == "reject"){
                                            _reportIsReject = true
                                            await reportRepository.delete(reportData[0].id)
                                            reportRes.date = reportRes.date.slice(0,10)
                                            let newReporId = await reportRepository.save(reportRes).then((res) => { return res.id; }).catch(error => console.log(error));
                                            await startStopEventsRepository.delete({ report: reportData[0].id })
                                            await startStopRepository.delete({ report: reportData[0].id })
                                            await productionHoursRepository.delete({ report: reportData[0].id })
                                            await productionManHoursRepository.delete({ report: reportData[0].id })
                                            await materialCrushingRepository.delete({ report: reportData[0].id })
                                            await materialCrushingActivitiesRepository.delete({ report: reportData[0].id })
                                            await energyRepository.delete({ report: reportData[0].id })
                                            await inventoryRepository.delete({ report: reportData[0].id })
                                            await commentsRepository.delete({ report: reportData[0].id })
                                            await maintenanceRepository.delete({ report: reportData[0].id })
                                            await maintenanceDetailsRepository.delete({ report: reportData[0].id })
                                            await modulesStatusRepository.delete({ report: reportData[0].id })
                                            await reportStatusRepository.delete({ report: reportData[0].id })
                                            //Since the report was rejected, the id of the new report from the mobile application is needed to save it.
                                            dataRes.newReporId = newReporId;
                                        } else {
                                            dataRes.newReporId = reportData[0].id;
                                            // If the report is not rejected and the report was created in the web application, the audit fields are updated.
                                            if(reportRes.action_status == true && reportRes.web_id != null){
                                                await reportRepository.update(reportData[0].id,
                                                    {
                                                        web_id: reportRes.web_id,
                                                        update_web: new Date(reportRes.update_web),
                                                        sync: true,
                                                    }).then(res => console.log("updateReport"))
                                            }
                                        }
                                        if ("start_stop" in dataRes) {
                                            await self.syncModule([dataRes.start_stop], startStopRepository,dataRes.newReporId);
                                        }
                                        if("start_stop_events" in dataRes){
                                            await self.syncModule(dataRes.start_stop_events,startStopEventsRepository,dataRes.newReporId);
                                        }
                                        if("production_hours" in dataRes){
                                            await self.syncModule([dataRes.production_hours],productionHoursRepository,dataRes.newReporId);
                                        }
                                        if("production_man_hours" in dataRes){
                                            await self.syncModule(dataRes.production_man_hours,productionManHoursRepository,dataRes.newReporId);
                                        }
                                        if("material_crushing" in dataRes){
                                            await self.syncModule(dataRes.material_crushing,materialCrushingRepository,dataRes.newReporId);
                                        }
                                        if("material_crushing_activities" in dataRes){
                                            await self.syncModule(dataRes.material_crushing_activities,materialCrushingActivitiesRepository,dataRes.newReporId);
                                        }
                                        if("energy" in dataRes){
                                            await self.syncModule(dataRes.energy,energyRepository,dataRes.newReporId);
                                        }
                                        if("inventory" in dataRes){
                                            await self.syncModule(dataRes.inventory,inventoryRepository,dataRes.newReporId);
                                        }
                                        if("comments" in dataRes){
                                            await self.syncModule(dataRes.comments,commentsRepository,dataRes.newReporId);
                                        }
                                        if("maintenance" in dataRes){
                                            await self.syncModule(dataRes.maintenance,maintenanceRepository,dataRes.newReporId);
                                        }
                                        if("maintenance_details" in dataRes){
                                            await self.syncModule(dataRes.maintenance_details,maintenanceDetailsRepository,dataRes.newReporId);
                                        }
                                        if ("modules_status" in dataRes) {
                                            await self.syncModule(dataRes.modules_status, modulesStatusRepository,dataRes.newReporId);
                                        }
                                        if ("report_status" in dataRes) {
                                            await self.syncModule(dataRes.report_status, reportStatusRepository,dataRes.newReporId);
                                        }
                                        if ("core_file" in dataRes) {
                                            await self.syncModule(dataRes.core_file, fileRepository,dataRes.newReporId);
                                        }
                                    })
                                    _serverIsOnline = true;
                                }).catch(error => {console.log(error);_serverIsOnline = false; return true; })
                        }
                        /**
                         * If the report is rejected, it is necessary to update the report Id in the store, 
                         * for that the user is notified that the page will be reloaded. depending on where it is,
                         * it either reloads or  be redirected to the dashboard.
                         */
                        if(_reportIsReject){
                            setTimeout( () => {
                                alert(this.$t('ore.sync.report_reject'));
                                if(this.$router.history.current.fullPath === "/"){
                                    window.location.reload()
                                } else {
                                    this.$router.push("/")
                                }
                            },1000)
                        }
                        // Once this synchronization is finished, it is verified if there is another record to synchronize
                        let _otherReportToSync = await reportRepository.find({sync: false}).then(res => { return this.parseDate(res); })
                        // If another record exists, the function is called recursively.
                        if(_otherReportToSync.length > 0 && _serverIsOnline == true) {
                            setTimeout(() => { this.syncBgFn("Sync recursivo"), 1000 });
                        }
                    }
                }
                this.$store.commit("comun/setSynchronizing", false)
            } catch {
                this.$store.commit("comun/setSynchronizing", false);
            }
        },
        async syncModule(dataRes, repository, newReporId) {
            let _items = dataRes
            let _itemsTosave = []
            let _itemsToDelete = []
            await _items.forEach(async (item) => {
                //If the sync is rejected, the item is saved with the information that comes from web.
                if(item.action_msg == "reject"){
                    item.report = newReporId
                    item.sync = true
                    _itemsToDelete.push(item.id_mobile)
                    //To avoid problems in safari (IOS)
                    if ("date_time" in item)
                        item.date_time = moment(item.date_time).toISOString();

                    if("update_web" in item){
                        item.update_web = moment(item.update_web).toISOString();
                    }
                    if (("production_start" in item) && item.production_start != null)
                        item.production_start = moment(item.production_start).toISOString();

                    if (("production_end" in item) && item.production_end != null)
                        item.production_end = moment(item.production_end).toISOString();

                    if (("shift_start" in item) && item.shift_start != null)
                        item.shift_start = moment(item.shift_start).toISOString();

                    if (("shift_end" in item) && item.shift_end != null)
                        item.shift_end = moment(item.shift_end).toISOString();
                    
                    if (("stop_time" in item) && item.stop_time != null)
                        item.stop_time = moment(item.stop_time).toISOString();
    
                    if (("restart_time" in item) && item.restart_time != null)
                        item.restart_time = moment(item.restart_time).toISOString();

                    if (("start_time" in item) && item.start_time != null)
                        item.start_time = moment(item.start_time).toISOString();
        
                    if (("end_time" in item) && item.end_time != null)
                        item.end_time = moment(item.end_time).toISOString();

                    _itemsTosave.push(item)
                } else {
                    //If it is not rejected and the sync was successful, the variables are updated.
                    if(item.action_status){
                        await repository.update({uuid: item.uuid}, 
                            {
                                web_id: item.web_id,
                                update_web: new Date(item.update_web),
                                sync: true,
                            },
                        ).then((res) => { console.log("updateModuleSync"); }).catch(error => console.log(error))
                    }
                }
            })
            if(_itemsToDelete.length > 0){
                await repository.delete(_itemsToDelete)
            }
            if(_itemsTosave.length > 0){
                await repository.save(_itemsTosave).then((res) => { console.log("saveReject"); }).catch(error => console.log(error));
            }
        },
        // Convert date to string for sync
        parseDate(data) {
            let _array = data
            if (!Array.isArray(data)) {
                _array = [data]
            }
            if (typeof (data[0]) != 'undefined') {
                _array.forEach(element => {
                    if (element.created != null) {
                        element.created = moment(element.created).format("YYYY-MM-DD HH:mm")
                    }
                    
                    if(element.update != null){
                        element.update = moment(element.update).format("YYYY-MM-DD HH:mm")
                    }

                    if (element.deleted != null && element.deleted != "")
                        element.deleted = moment(element.deleted).format("YYYY-MM-DD HH:mm")

                    if ("date" in element)
                        element.date = new Date(element.date).toISOString();

                    if ("date_time" in element)
                        element.date_time = moment(element.date_time).format("YYYY-MM-DD HH:mm")

                    if ("status_date" in element)
                        element.status_date = moment(element.status_date).format("YYYY-MM-DD HH:mm")

                    if (("production_start" in element) && element.production_start != null)
                        element.production_start = moment(element.production_start).format("YYYY-MM-DD HH:mm")

                    if (("production_end" in element) && element.production_end != null)
                        element.production_end = moment(element.production_end).format("YYYY-MM-DD HH:mm")

                    if (("shift_start" in element) && element.shift_start != null)
                        element.shift_start = moment(element.shift_start).format("YYYY-MM-DD HH:mm")

                    if (("shift_end" in element) && element.shift_end != null)
                        element.shift_end = moment(element.shift_end).format("YYYY-MM-DD HH:mm")

                    if (("stop_time" in element) && element.stop_time != null)
                        element.stop_time = moment(element.stop_time).format("YYYY-MM-DD HH:mm");

                    if (("restart_time" in element) && element.restart_time != null)
                        element.restart_time = moment(element.restart_time).format("YYYY-MM-DD HH:mm");

                    if (("start_time" in element) && element.start_time != null)
                        element.start_time = moment(element.start_time).format("YYYY-MM-DD HH:mm")
        
                    if (("end_time" in element) && element.end_time != null)
                        element.end_time = moment(element.end_time).format("YYYY-MM-DD HH:mm")

                });
            }
            return _array;
        },
    }
}
export default syncbgMixin