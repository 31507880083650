<template>
  <div class="report-table">
    <div
      class="table-vertical white mt-2"
      style="
        display: flex;
        height: calc(100% - 15px);
        overflow: auto;
        width: 100%;
      "
      v-if="direction == 'v'"
    >
      <v-simple-table fixed-header :height="'100%'">
        <thead>
          <tr>
            <th
              v-for="(header, i) in headers"
              :key="i"
              :style="
                'width: ' +
                header.width +
                ';background-color:' +
                (header.value == 'options' ? '#feec00ff' : '')
              "
              :class="header.alignh"
              class="pa-1"
            >
              {{ $t(header.text) }}
              <span v-if="header.unit != ''">{{ header.unit }}</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, j) in items" :key="j">
            <td
              v-for="(header, k) in headers"
              :key="k"
              :style="`width: ${header.width}; background-color: ${
                header.value == 'options' ? '#feec00ff' : ''
              }; cursor: ${
                header.value == 'options'
                  ? ''
                  : clickableItem
                  ? 'pointer !important;'
                  : ''
              }`"
              :class="header.alignb"
              class="pa-1"
            >
              <slot :name="[header.value]" v-bind="item">
                <template v-if="header.value == 'options'">
                  <v-progress-circular
                    v-if="item.id === idItemLoading"
                    indeterminate
                    class="ore-progress-circular-loading"
                  ></v-progress-circular>
                  <template v-else>
                    <v-icon
                      v-if="optionsButtons.edit"
                      @click="funcEdit(item.id)"
                      class="icon-option"
                      :disabled="disableIcon(item, 'edit')"
                      >{{ readOnly == false ? "mdi-pencil" : "mdi-eye" }}</v-icon
                    >
                    <v-icon
                      v-if="optionsButtons.delete"
                      @click="funcDelete(item.id)"
                      class="icon-option"
                      :disabled="disableIcon(item, 'delete')"
                      >mdi-delete</v-icon
                    >
                  </template>
                </template>
                <template v-else>
                  <div @click="onClickItemDT(item)">
                    <span v-html="item[header.value] != '' ? item[header.value] : '&nbsp;'"></span>
                  </div>
                </template>
              </slot>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </div>
    <div
      class="table-horizontal white mt-2"
      style="height: calc(100% - 15px)"
      v-else
    >
      <div class="tableh-header" style="height: 100%">
        <div
          class="v-data-table table-body v-data-table--dense theme--light"
          style="height: 100%"
        >
          <div
            class="v-data-table__wrapper"
            style="height: 100%; overflow-x: hidden"
          >
            <table :style="'height: ' + heightTableHor">
              <tbody>
                <tr
                  v-for="(header, i) in headers"
                  :key="i"
                  :style="
                    (header.value == 'options' ? 'height: 36px' : '') +
                    ' !important; background-color:' +
                    colorBg +
                    ' !important'
                  "
                >
                  <th
                    :style="
                      'width: 100%; height: auto !important;color:' +
                      colorText +
                      ' !important;'
                    "
                    style="text-align: left !important"
                  >
                    <template v-if="header.value != 'options'">
                      {{ $t(header.text) }}
                      <span v-if="header.unit != ''">{{ header.unit }}</span>
                    </template>
                    <template v-else>
                      <div class="options-h">
                        <div style="height: 34px">&nbsp;</div>
                      </div>
                    </template>
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="tableh-body" style="height: 100%">
        <div
          class="v-data-table table-body v-data-table--dense theme--light"
          style="height: 100%"
        >
          <div class="v-data-table__wrapper" style="height: 100%">
            <table
              :style="
                'width: calc(150px * ' +
                items.length +
                ') !important; height: 100%'
              "
            >
              <tbody>
                <tr
                  v-for="(header, i) in headers"
                  :key="i"
                  :class="header.alignh"
                  :style="header.value == 'options' ? 'height: 36px' : ''"
                >
                  <td
                    v-for="(item, j) in items"
                    :key="j"
                    :style="`width: 150px; height: auto !important;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; cursor: ${
                      header.value == 'options'
                        ? ''
                        : clickableItem
                        ? 'pointer !important;'
                        : ''
                    };`"
                  >
                    <template v-if="header.value == 'options'">
                      <div
                        class="options-h"
                        :style="
                          'background-color:' +
                          (header.value == 'options' ? '#feec00ff' : '')
                        "
                      >
                        <v-icon
                          v-if="optionsButtons.edit"
                          @click="funcEdit(item.id)"
                          class="icon-option"
                          :disabled="disableIcon(item, 'edit')"
                          >{{ readOnly == false ? "mdi-pencil" : "mdi-eye" }}</v-icon
                        >
                        <v-icon
                          v-if="optionsButtons.delete"
                          @click="funcDelete(item.id)"
                          class="icon-option"
                          :disabled="disableIcon(item, 'delete')"
                          >mdi-delete</v-icon
                        >
                      </div>
                    </template>
                    <template v-else>
                      <div @click="onClickItemDT(item)">
                        {{
                          item[header.value] != ""
                            ? item[header.value]
                            : "&nbsp;"
                        }}
                      </div>
                    </template>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import configMixins from "@/mixins/config";

export default {
  name: "report-table",
  mixins: [configMixins],
  props: {
    direction: {
      type: String,
      default: "v",
    },
    tableHeight: {
      type: String,
      default: "100%",
    },
    headers: {
      type: Array,
      default: (value) => {
        return [];
      },
    },
    items: {
      type: Array,
      default: (value) => {
        return [];
      },
    },
    optionsButtons: {
      type: Object,
      default: (value) => {
        return {
          edit: true,
          delete: true,
        };
      },
    },
    funcEdit: {
      type: Function,
      default: (value) => {
        return value;
      },
    },
    funcDelete: {
      type: Function,
      default: (value) => {
        return value;
      },
    },
    idItemLoading: {
      type: Number,
      default: null,
    },
    colorBg: {
      type: String,
      default: "#ffffff",
    },
    colorText: {
      type: String,
      default: "#000000",
    },
    clickableItem: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    rowHeight() {
      return "calc(100% / " + this.headers.length + ")";
    },
    heightTableHor() {
      return this.isMobile ? "100%" : "calc(100% - 15px)";
    },
  },
  methods: {
    disableIcon(item, icon) {
      let stateIcon = true;
      try {
        if (icon == "edit") {
          stateIcon = !item.options.edit;
        } else if (icon == "delete") {
          stateIcon = !item.options.delete;
        }
        return stateIcon;
      } catch {
        return false;
      }
    },
    onClickItemDT(item) {
      if (this.clickableItem) {
        this.$emit("onClickItem", item);
      }
    },
  },
};
</script>

<style lang="scss">
.v-data-table.v-data-table--fixed-header.theme--light {
  width: 100%;
}
.table-vertical {
  .v-data-table__wrapper > table {
    border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;
  }
}
</style>

<style lang="scss" scoped>
.table-horizontal {
  .tableh-header {
    width: 220px;
    float: left;
  }
  .tableh-body {
    float: left;
    width: calc(100% - 220px);
    .v-data-table__wrapper {
      overflow-x: scroll !important;
    }
    .v-data-table__wrapper::-webkit-scrollbar {
      height: 15px !important;
      background-color: #f5f5f5 !important;
    }
    .v-data-table__wrapper::-webkit-scrollbar-thumb {
      width: 20px;
      border-radius: 20px;
      background-color: #c5c5c5;
    }
    .v-data-table__wrapper > table {
      border-right: thin solid rgba(0, 0, 0, 0.12);
    }
  }
}
</style>