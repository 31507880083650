<template>
  <div>
    <maintenanceForm
      ref="maintenanceForm"
      :idReport="storeReportId"
      :idCrusher="storeCrusherId"
      :equipmentSelected="equipmentSelectedData"
      :locked="lockTypeLocked"
      @onSavedData="onSavedDataForm"
      :disabledBtnReport="storeOnlyReadReport"
    />
    <v-card elevation="0" :color="'transparent'">
      <exp-report-header
        :color="color"
        :code="''"
        :colorText="colorText"
        :disabledBtnReport="storeOnlyReadReport"
        ref="expHeader"
        @onSave="saveModuleStatus()"
      />
      <v-card-text class="table_module">
        <v-row no-gutters class="my-1">
          <v-col>
            <v-sheet class="mx-auto">
              <v-slide-group multiple show-arrows>
                <v-slide-item
                  v-for="(itemZone, index) in maintenancePlan"
                  :key="index"
                  v-slot="{ active, toggle }"
                >
                  <v-btn
                    class="mx-1"
                    height="70"
                    width="154"
                    :color="
                      !itemZone._in_maintenance
                        ? getColorVerifyCondition(undefined, itemZone.id).color
                        : getColorVerifyCondition(
                            itemZone._condition,
                            itemZone.id
                          ).color
                    "
                    :key="index"
                    rounded
                    :depressed="actualZone != itemZone.id"
                    @click="changeZone(itemZone)"
                  >
                    <span>
                      {{ $t(`core.list_item.${itemZone.name}`) }}
                    </span>
                  </v-btn>
                </v-slide-item>
              </v-slide-group>
            </v-sheet>
          </v-col>
        </v-row>
        <div class="custom-v-data-table" style="width: 100%; height: 100%">
          <v-data-table
            height="496"
            fixed-header
            :headers="headers"
            :items="equipmentsZone"
            hide-default-footer
          >
            <template v-slot:item.options="{ item }">
              <div class="d-flex align-center justify-center body-option-table">
                <v-icon
                  large
                  @click="openModalVerifyEquip(item.id)"
                  class="body-icon-option-table">
                  {{ storeOnlyReadReport == false ? "mdi-pencil" : "mdi-eye" }}
                </v-icon>
              </div>
            </template>
            <template v-slot:item.condition="{ item }">
              <div class="text-center">
                <v-icon
                  x-large
                  :color="
                    !item._in_maintenance
                      ? getColorVerifyCondition().color
                      : getColorVerifyCondition(item._condition).color
                  "
                  >mdi-checkbox-blank-circle</v-icon
                >
              </div>
            </template>
            <template v-slot:item.last_checked="{ item }">
              <div>
                <span>{{
                  getDateTimeFormated(item._last_date_condition)
                }}</span>
              </div>
            </template>
          </v-data-table>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import basicMixin from "@/mixins/basic";
import oreMixin from "@/mixins/ore";
import expReportTable from "@/components/expReportTable";
import maintenanceForm from "./form";
export default {
  name: "page-reports-maintenance",
  mixins: [basicMixin, oreMixin],
  components: {
    expReportTable,
    maintenanceForm,
  },
  data() {
    return {
      color: "",
      colorText: "",
      equipmentsZone: [],
      equipmentSelected: 2,
      equipmentSelectedData: {
        equipment_maintenance: [],
        _report_maintenance: {},
      },
      actualZone: 0,
      listConditionsVerify: [
        {
          id: 1,
          name: this.$t("ore.report.ok"),
          color: "#4CAF50",
        },
        {
          id: 2,
          name: this.$t("ore.report.monitor"),
          color: "#ffeb3b",
        },
        {
          id: 3,
          name: this.$t("ore.report.repair"),
          color: "#F44336",
        },
      ],
      zone_name: "",
      configForms: {
        unlock_type: {
          locked: true,
          hidden: false,
        },
      },
      configTable: {
        direction: "v",
        headers: [],
      },
    };
  },
  async mounted() {
    let storeConfigModule = this.pageModuleConfig.settingsConfig;
    let localConfigForm = this.$_.clone(this.configForms);
    this.configForms = { ...localConfigForm, ...storeConfigModule.form };
    this.configTable = { ...localConfigForm, ...storeConfigModule.table };
    this.colorText = this.pageModuleConfig.color_text || "primary";
    await this.generateMaintenancePlanReport()
    if (this.maintenancePlan.length > 0)
      this.changeZone(this.maintenancePlan[0]);
    this.color = this.pageModuleConfig.color || "primary";
  },
  watch: {},
  methods: {
    getColorVerifyCondition(type, id = null) {
      let condition = this.$_.find(
        this.listConditionsVerify,
        (x) => x.id === type
      );
      if (!condition) condition = { color: "#ababab" };
      condition = this.$_.clone(condition);
      if (id && this.actualZone !== id)
        condition.color = condition.color += "66";
      return condition;
    },
    changeZone(zone) {
      if (zone) {
        this.actualZone = zone.id;
        this.equipmentsZone = zone._equipments;
        this.zone_name = this.$t(`core.list_item.${zone.name}`);
      }
    },
    openModalVerifyEquip(e) {
      this.$refs.maintenanceForm.colorTabs = this.color;
      this.equipmentSelected = e;
      this.equipmentSelectedData = this.$_.find(
        this.equipmentsZone,
        (x) => x.id === e
      );
      this.$refs.maintenanceForm.showDialog = true;
    },
    async onSavedDataForm() {
      await this.generateMaintenancePlanReport()
      this.changeZone(
        this.maintenancePlan.find((x) => x.id == this.actualZone)
      );
    },
    saveModuleStatus() {
      this.$refs.expHeader.isValidForm = true;
    },
  },
  computed: {
    lockTypeLocked() {
      // true locked, false hidden
      return this.configForms.unlock_type.locked;
    },
    maintenancePlan() {
      return this.sortList(this.storeMaintenanceReport);
    },
    headers() {
      return [
        {
          text: this.zone_name,
          value: "name",
          alignh: "text-center",
          alignb: "text-left",
        },
        {
          text: this.$t("ore.report.condition"),
          value: "condition",
          width: "55",
          alignh: "text-center",
          alignb: "text-center",
          sortable: false,
        },
        {
          text: this.$t("ore.report.last_checked"),
          width: "180",
          value: "last_checked",
          alignh: "text-center",
          alignb: "text-center",
        },
        {
          text: this.$t("core.comun.options"),
          value: "options",
          width: "55",
          alignh: "text-center",
          alignb: "text-center",
          sortable: false,
          class: "header-option-table",
        },
      ];
    },
  },
};
</script>

<style>
.v-btn__content {
  white-space: normal;
  flex: auto;
}
</style>