<template>
  <div class="report-menu">
    <v-navigation-drawer
      permanent
      expand-on-hover
      absolute
      mini-variant-width="80"
      width="300"
      style="top: 90px; z-index: 3"
    >
      <v-toolbar color="#024a64" dark>
        <v-app-bar-nav-icon></v-app-bar-nav-icon>
        <v-toolbar-title class="text-uppercase">{{
          $t("ore.report.main_menu")
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-list
        dense
        rounded
        color="white"
        :style="'height:' + listHeight + 'px;' + 'overflow:' + 'auto'"
      >
        <template v-for="item in itemsMenu">
          <v-list-item
            v-if="item.menuItem.showInMenu"
            :key="item.code"
            link
            @click="openRoute(item.menuItem.link)"
            class="px-2"
            :style="item.menuItem.background"
          >
            <v-list-item-icon>
              <v-badge
                :value="item.menuItem.showBadge"
                :left="item.menuItem.leftBadge"
                bordered
                :color="item.menuItem.colorBadge"
                :icon="item.menuItem.iconBadge"
                overlap
                :content="item.menuItem.totalBadge"
              >
                <span
                  v-if="item.menuItem.icon_svg"
                  class="menu-svg-icon"
                  :style="'fill:' + item.menuItem.color"
                  v-html="item.menuItem.icon"
                ></span>
                <v-icon v-else :color="item.menuItem.color">{{
                  item.menuItem.icon
                }}</v-icon>
              </v-badge>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ $t(item.menuItem.title) }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import basicMixin from "@/mixins/basic";

export default {
  name: "report-menu",
  mixins: [basicMixin],
  data() {
    return {};
  },
  props: {},
  computed: {
    itemsMenu() {
      let modulesReport = this.$store.getters["ore/getModulesReport"];
      return modulesReport.filter(x => x.status)
    },
    listHeight() {
      return this.heightScreen > 768 ? 614 : this.heightScreen - 216;
    },
  },
};
</script>

<style lang="scss" scoped>
.report-menu {
  .v-list-item {
    min-height: 52px !important;
    .v-list-item__icon {
      i {
        font-size: 40px;
        margin-left: 2px;
      }
      height: 35px;
    }
    .v-list-item__title {
      font-size: 22px;
      line-height: 30px;
    }
  }
  header {
    padding-left: 12px;
    button {
      i {
        font-size: 40px !important;
        width: 40px !important;
        height: 40px !important;
      }
    }
    .v-toolbar__title {
      font-size: 30px;
    }
  }
}
</style>
