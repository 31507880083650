<template>
  <div>
    <div class="v-application" v-if="!listLoaded">
      <div class="v-application--wrap">
        <div class="ore-template">
          <div class="ore-wrap-ipad">
            <div class="main-container">
              <exp-ore-loading>
                <template v-slot:title>{{ titleLoading }}</template>
                <template v-slot:message>{{ messageLoading }}</template>
              </exp-ore-loading>
            </div>
          </div>
        </div>
      </div>
    </div>
    <component :is="$route.meta.layout || 'div'" v-else>
      <router-view />
    </component>
  </div>
</template>

<script>
import { getSites } from "@/gateway/site"
import { getCampaigns } from "@/gateway/campaign"
import { getLtsCampaignTarget } from "@/gateway/campaign_target"
import { getSource } from "@/gateway/source"
import { getProducts } from "@/gateway/products"
import { getScenarios } from "@/gateway/scenario"
import { getCrushers } from "@/gateway/crusher"
import { getRegions } from "@/gateway/region";
import { getAllEquipment } from "@/gateway/equipment";
import { getAllEquipmentMaintenance } from "@/gateway/equipmentMaintenance";
import { getAllModule } from "@/gateway/module";
import { getListItemsByCodes, getListPivotByCodes } from "@/gateway/lists";
import { getCustomerJWT } from "@/gateway/base";
import { dataCustomer } from "@/gateway/customer";

import { getConnectionManager } from "typeorm";
import mobileMixins from "@/mixins/mobile";
import syncListsMixins from "@/mixins/sync_lists";
import syncReportsMixins from "@/mixins/sync_reports";
import syncFilesMixin from "@/mixins/sync_files";
import expOreLoading from "@/components/expOreLoading";
import configMixins from "@/mixins/config";

export default {
  name: "App",
  mixins: [
    syncListsMixins,
    syncReportsMixins,
    syncFilesMixin,
    configMixins,
    mobileMixins,
  ],
  components: {
    expOreLoading,
  },
  data: () => ({
    messageLoading: "",
    titleLoading: "",
    listLoaded: false,
  }),
  async mounted() {
    console.log("--- Start App ---");
    console.log(this);

    if(this.$route["name"] == "login_mobile") {
      this.listLoaded = true;
      return;
    }

    const self = this;
    self.titleLoading = self.$t("core.app.downloading");
    if (this.isMobile) {
      document.addEventListener(
        "deviceready",
        async () => {
          const connectionManager = getConnectionManager();
          if (!connectionManager.has("default")) {
            const connection = connectionManager.create({
              type: "cordova",
              database: "colas.db",
              location: "default",
              logging: false,
              synchronize: true,
              entities: [
                // Core tables
                require("./typeorm/entity/core/core_users.js"),
                require("./typeorm/entity/core/core_users_roles.js"),
                require("./typeorm/entity/core/core_module.js"),
                require("./typeorm/entity/core/core_file.js"),
                // Lists tables
                require("./typeorm/entity/core/core_list.js"),
                require("./typeorm/entity/core/core_listitem.js"),
                require("./typeorm/entity/core/core_list_pivot.js"),
                require("./typeorm/entity/core/core_list_pivotitem.js"),
                require("./typeorm/entity/core/core_translates.js"),
                require("./typeorm/entity/core/core_module.js"),
                //Config tables
                require("./typeorm/entity/ore/ore_config_address.js"),
                require("./typeorm/entity/ore/ore_config_campaign.js"),
                require("./typeorm/entity/ore/ore_config_campaign_target.js"),
                require("./typeorm/entity/ore/ore_config_company.js"),
                require("./typeorm/entity/ore/ore_config_country.js"),
                require("./typeorm/entity/ore/ore_config_crusher.js"),
                require("./typeorm/entity/ore/ore_config_equipment_maintenance.js"),
                require("./typeorm/entity/ore/ore_config_equipment.js"),
                require("./typeorm/entity/ore/ore_config_product_list_site.js"),
                require("./typeorm/entity/ore/ore_config_product_list.js"),
                require("./typeorm/entity/ore/ore_config_product.js"),
                require("./typeorm/entity/ore/ore_config_region.js"),
                require("./typeorm/entity/ore/ore_config_scenario.js"),
                require("./typeorm/entity/ore/ore_config_site.js"),
                require("./typeorm/entity/ore/ore_config_source.js"),
                require("./typeorm/entity/ore/ore_config_subsidiary.js"),
                require("./typeorm/entity/ore/ore_config_buid.js"),
                require("./typeorm/entity/ore/ore_config_rock_characteristic.js"),
                require("./typeorm/entity/ore/ore_config_rock_type.js"),
                // Report tables
                require("./typeorm/entity/ore/ore_report.js"),
                require("./typeorm/entity/ore/ore_report_status.js"),
                require("./typeorm/entity/ore/ore_report_module_status.js"),
                require("./typeorm/entity/ore/ore_report_comments.js"),
                require("./typeorm/entity/ore/ore_report_inventory.js"),
                require("./typeorm/entity/ore/ore_report_material_crushing_activities.js"),
                require("./typeorm/entity/ore/ore_report_material_crushing.js"),
                require("./typeorm/entity/ore/ore_report_energy.js"),
                require("./typeorm/entity/ore/ore_report_production_hours.js"),
                require("./typeorm/entity/ore/ore_report_production_man_hours.js"),
                require("./typeorm/entity/ore/ore_report_start_stop.js"),
                require("./typeorm/entity/ore/ore_report_start_stop_events.js"),
                require("./typeorm/entity/ore/ore_report_maintenance.js"),
                require("./typeorm/entity/ore/ore_report_maintenance_details.js"),
                // Sync Table
                require("./typeorm/entity/sync/sync_date.js"),
              ],
            });
            await connection.connect().then((response) => {
              self.$store.commit("ore/setConnection", response.isConnected);
              return response;
            });
          }

          // Get app version
          cordova.getAppVersion.getVersionNumber().then(function (version) {
            self.$store.commit("comun/setAppVersion", version);
          });

          if (this.isCustomersAuthenticated) {
            this.$store.commit("comun/setSynchronizing", true);
            await this.syncListsFn();
            await this.syncReportsFn();
            await this.syncFilesFn();
          } else {
            this.listLoaded = true;
          }

          this.titleLoading = this.$t("core.app.loading");
          if (this.isCustomersAuthenticated) {
            setTimeout(() => {
              this.loadLists();
            }, 50);
          } else {
            this.listLoaded = true;
            if (this.isMobile) {
              this.$router.push("/login");
            }
          }
        },
        false
      );
    } else {
      self.$store.commit("comun/setAppVersion", "2.79.4 2024-06-22 10:00");
      this.$store.commit(
        "comun/setLastSync",
        this.$moment().format("DD/MM/YYYY HH:mm")
      );
      this.titleLoading = this.$t("core.app.loading");
      if (this.isCustomersAuthenticated) {
        setTimeout(() => {
          this.loadLists();
        }, 50);
      } else {
        this.listLoaded = true;
      }
    }
  },
  methods: {
    async loadLists() {
      if (this.isOnline) {
        await dataCustomer(getCustomerJWT())
          .then((response) => {
            let _dataCustomer = this.$store.getters["auth/getAuthCustomer"];
            _dataCustomer.data = response.data;
            this.$store.commit("auth/setAuthCustomer", _dataCustomer);
          })
          .catch((error) => {
            if(this.isOnline == false) return
            if (error.response && error.response.status == 401) {
              this.$store.commit("auth/setAuthCustomer", {});
              window.location.reload();
            }
          });
      }
      await getAllModule();
      this.messageLoading =
        "(1/6) " +
        this.$t("ore.comun.loading_parameters") +
        " - " +
        this.$t("ore.region.regions");
      await getRegions();
      this.messageLoading =
        "(2/6) " +
        this.$t("ore.comun.loading_parameters") +
        " - " +
        this.$t("ore.site.sites");
      await getSites();
      this.messageLoading =
        "(3/6) " +
        this.$t("ore.comun.loading_parameters") +
        " - " +
        this.$t("ore.campaign.campaigns");
      await getCampaigns();
      await getLtsCampaignTarget()
      await getScenarios()
      await getSource()
      await getProducts()
      this.messageLoading =
        "(4/6) " +
        this.$t("ore.comun.loading_parameters") +
        " - " +
        this.$t("ore.crusher.crushers");
      await getCrushers();
      this.messageLoading =
        "(5/6) " +
        this.$t("ore.comun.loading_parameters") +
        " - " +
        this.$t("ore.equipment.maintenance_plan");
      await getAllEquipment();
      await getAllEquipmentMaintenance();
      this.messageLoading =
        "(6/6) " +
        this.$t("ore.comun.loading_parameters") +
        " - " +
        this.$t("ore.list.lists");
      await getListItemsByCodes([
        "ACT",
        "REL",
        "REC",
        "NPR",
        "EUS",
        "ETY",
        "EUN",
        "INV",
        "EQC",
        "EQZ",
        "MIT",
        "UNIT",
        "WRL",
        "ERN",
        "EBS",
        "ESC",]);
      await getListPivotByCodes(["EAS"]);
      this.$store.commit("comun/setSynchronizing", false);
      this.listLoaded = true;

      if (this.isMobile) {
        this.$router.push("/");
      }
    },
  },
  computed: {
    isCustomersAuthenticated() {
      return this.$store.getters["auth/isAuthCustomer"];
    },
  },
  watch: {
    isCustomersAuthenticated(state) {
      if (state === true) {
        this.loadLists();
      }
    },
  },
};
</script>