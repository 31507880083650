<template>
  <div>
    <exp-loading v-show="isLoading" />
    <comment-form
      ref="commentForm"
      @change="getAllData"
      :configForms="configForms"
      :disabledBtnReport="storeOnlyReadReport"
    />
    <exp-alert-confirm
      ref="deleteForm"
      :colorCancel="'green'"
      :message="msg_delete"
      :fnOk="deleteCommentFntModal"
    />
    <v-card elevation="0" :color="'transparent'">
      <exp-report-header :color="color" :code="''" :disabledBtnReport="storeOnlyReadReport" ref="expHeader" @onSave="saveModuleStatus()"/>
      <v-card-text class="table_module">
        <v-row>
          <v-col>
            <v-btn rounded :color="color" @click="clkFunction('_new', 0)" large :disabled="storeOnlyReadReport">
              <v-icon left>mdi-plus</v-icon>
              {{ $t("ore.report.add_new") }}
            </v-btn>
          </v-col>
        </v-row>
        <exp-report-table
          :direction="'v'"
          :headers="headers"
          :items="items"
          :tableHeight="'calc(100% - 15px)'"
          :funcEdit="editComment"
          :funcDelete="deleteCommentFntDT"
          :readOnly="storeOnlyReadReport"
          :optionsButtons="{ edit: true, delete: !storeOnlyReadReport }"
        />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import basicMixin from "@/mixins/basic";
import customerMixin from "@/mixins/customer";
import oreMixin from "@/mixins/ore";
import expReportTable from "@/components/expReportTable";
import commentForm from "./form";
import { getAllComments, getComment, deleteComment } from "@/gateway/comments";
export default {
  name: "page-reports-comments",
  layout: "colas",
  mixins: [basicMixin, customerMixin, oreMixin],
  components: {
    expReportTable,
    commentForm,
  },
  data() {
    return {
      color: "#1e9a92",
      isLoading: false,
      msg_delete: "",
      headers: [
        {
          text: "ore.report.user",
          value: "user_email",
          alignh: "text-center",
          alignb: "text-left",
          width: "100px",
        },
        {
          text: "ore.report.comment",
          value: "comments",
          alignh: "text-center",
          alignb: "text-center",
        },
        {
          text: "ore.report.date",
          value: "created",
          width: "90px",
          alignh: "text-center",
          alignb: "text-center",
        },
        {
          text: "core.comun.options",
          value: "options",
          width: "85px",
          alignh: "text-center",
          alignb: "text-center",
        },
      ],
      items: [],
      msg_delete: "",
      data: {},
      configForms: { comments: { visible: true, disabled: false } },
    };
  },
  async mounted() {
    this.isLoading = true;
    let configForms = this.pageModuleConfig.settingsConfig.form;
    let localConfig = this.$_.clone(this.configForms);
    this.configForms = { ...localConfig, ...configForms };
    this.color = this.pageModuleConfig.color || "primary";
    await this.getAllData();
    this.isLoading = false;
  },
  watch: {
    reportSelectedId() {
      this.getAllData();
    },
  },
  methods: {
    async getAllData(data = null) {
      this.isLoading = true;
      let allData = data
        ? data
        : this.$store.getters["ore/getReportSelected"].comments;
      try {
        if (!this.isset(allData)) {
          allData = await getAllComments(this.reportSelectedId);
        }
      } catch (error) {
        this.logError(error)
        allData = []
      }
      let _data = [];
      this.$_.forEach(allData, (item) => {
        _data.push({
          id: item.id,
          user_email: item.user_email,
          comments:
            item.comments.length > 170
              ? item.comments.substring(0, 170) + "..."
              : item.comments,
          created: this.$moment().format(
            this.crusherFormat.format_date + " HH:mm"
          ),
        });
      });
      this.items = _data;
      this.$store.commit("ore/setUpdateReportsSelected", { comments: allData });
      this.isLoading = false;
    },
    editComment(id) {
      this.clkFunction("_edit", id);
    },
    deleteCommentFntDT(id) {
      this.clkFunction("_delete", id);
    },
    async clkFunction(accion, id) {
      const self = this;
      switch (accion) {
        case "_edit":
          self.isLoading = true;
          try {
            let data = await getComment(id);
            self.$refs.commentForm.data = data;
            self.$refs.commentForm.showDialog = true;
          } catch (error) {
            this.logError(error)
          }
          self.isLoading = false;
          break;
        case "_new":
          self.$refs.commentForm.data = {};
          self.$refs.commentForm.showDialog = true;
          break;
        case "_delete":
          this.msg_delete = "ore.report.confirm_delete_comment";
          this.$refs.deleteForm.id = id;
          this.$refs.deleteForm.showDialog = true;
        default:
          break;
      }
    },
    async deleteCommentFntModal(id) {
      const self = this;
      self.isLoading = true;
      try {
        let _data = await deleteComment(id);
        await self.getAllData(_data);
      } catch (error) {
        this.logError(error)
      }
      self.isLoading = false;
    },
    saveModuleStatus(){
      this.$refs.expHeader.isValidForm = true;
    }
  },
  computed: {
    tableHeight() {
      return this.heightScreen > 768 ? 510 : this.heightScreen - 300;
    },
  },
};
</script>
