<template>
  <div>
    <exp-alert-confirm
      ref="modalSaveChanges"
      :message="msg_modal"
      :labelOk="'core.comun.yes'"
      :colorOk="'green'"
      :iconOk="'mdi-check-circle'"
      :fnOk="saveInventory"
      :labelCancel="'core.comun.no'"
      :colorCancel="'red'"
      :iconCancel="'mdi-close-circle'"
      :fnCancel="dontSaveChange"
    />
    <v-dialog v-model="showDialog" persistent max-width="700px">
      <v-card :disabled="isLoading">
        <v-card-title class="headline lighten-2 pb-0">
          {{ $t("ore.report.inventory") }}
        </v-card-title>
        <hr class="modal-title-hr" />
        <ValidationObserver v-slot="{ dirty }" ref="observerInventory">
          <form class="form-inventory" @submit.prevent="saveInventory()">
            <v-card-text>
              <v-row justify="space-between">
                <v-col
                  cols="4"
                  class="py-0"
                  v-if="configForms.inventory_item.visible"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    :name="$t('ore.report.inventory_item')"
                    rules="required"
                  >
                    <v-select
                      v-model="data.inventory_item"
                      :items="listInventoryType"
                      item-text="name"
                      item-value="id"
                      :label="$t('ore.report.inventory_item')"
                      :error-messages="errors"
                      outlined
                      dense
                      readonly
                    ></v-select>
                  </ValidationProvider>
                </v-col>
                <v-col cols="4" class="py-0" v-if="configForms.units.visible">
                  <ValidationProvider
                    v-slot="{ errors }"
                    :name="$t('ore.report.units')"
                    rules=""
                  >
                    <v-text-field
                      :value="unitInventoryType()"
                      :label="$t('ore.report.units')"
                      outlined
                      dense
                      readonly
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col
                  cols="4"
                  class="py-0"
                  v-if="configForms.starting_qty.visible"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    :name="$t('ore.report.starting_qty')"
                    rules="required"
                  >
                    <exp-mask
                      v-model="data.started"
                      :label="$t('ore.report.starting_qty')"
                      class="input-number"
                      outlined
                      dense
                      :error-messages="errors"
                      :mask="maskDecimalNumber"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col
                  cols="4"
                  class="py-0"
                  v-if="configForms.received_qty.visible"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    :name="$t('ore.report.received_qty')"
                    rules="required"
                  >
                    <exp-mask
                      v-model="data.received"
                      :label="$t('ore.report.received_qty')"
                      class="input-number"
                      outlined
                      dense
                      :error-messages="errors"
                      :mask="maskDecimalNumber"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col
                  cols="4"
                  class="py-0"
                  v-if="configForms.consumed_qty.visible"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    :name="$t('ore.report.consumed_qty')"
                    rules="required"
                  >
                    <exp-mask
                      v-model="data.consumed"
                      :label="$t('ore.report.consumed_qty')"
                      class="input-number"
                      outlined
                      dense
                      :error-messages="errors"
                      :mask="maskDecimalNumber"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col
                  cols="4"
                  class="py-0"
                  v-if="configForms.remaining_qty.visible"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    :name="$t('ore.report.remaining_qty')"
                    rules=""
                  >
                    <exp-mask
                      :value="remainingItem"
                      :label="$t('ore.report.remaining_qty')"
                      class="input-number"
                      outlined
                      dense
                      readonly
                      :error-messages="errors"
                      :mask="maskDecimalNumber"
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn
                rounded
                :disabled="!dirty || isLoading || disabledBtnReport"
                type="submit"
                color="green"
              >
                <v-icon left v-if="!isLoading">mdi-content-save</v-icon>
                <v-progress-circular
                  v-else
                  indeterminate
                  size="24"
                  class="ore-progress-circular-loading"
                ></v-progress-circular>
                {{ $t("core.comun.save") }}&nbsp;
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                :disabled="isLoading"
                rounded
                @click="cancelBtnForm()"
                color="red"
              >
                <v-icon left>mdi-close-circle</v-icon>
                {{ $t("core.comun.cancel") }}&nbsp;
              </v-btn>
            </v-card-actions>
          </form>
        </ValidationObserver>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import oreMixin from "@/mixins/ore";
import { getListItemsByCode } from "@/gateway/lists";
import basicMixin from "@/mixins/basic";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { updateInventory, createInventory } from "@/gateway/inventory";
export default {
  name: "modal-add-inventory",
  mixins: [basicMixin, oreMixin],
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      showDialog: false,
      isLoading: false,
      msg_modal: "",
      listInventoryType: [],
      listUnits: [],
      data: {
        id: 0,
        inventory_item: null,
        consumed: 0,
        started: 0,
        received: 0,
        remaining: 0,
        element_type: null,
      },
    };
  },
  props: {
    configForms: {
      type: Object,
      default: () => {},
    },
    disabledBtnReport: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    async saveInventory() {
      const self = this;
      let valid = await self.$refs.observerInventory.validate();
      let _data = {};
      if (valid) {
        self.isLoading = true;
        self.data.report = self.reportSelectedId;
        self.data.remaining = self.remainingItem;
        try {
          if (self.isset(self.data.id)) {
            _data = await updateInventory(self.data.id, self.data);
          } else {
            self.data.uuid = self.createUUID();
            _data = await createInventory(self.data);
          }
          self.$emit("change", _data);
          self.showDialog = false;
        } catch (error) {
          this.logError(error)
        }
        self.isLoading = false;
      }
    },
    async cancelBtnForm() {
      const self = this;
      if (self.isDirty && self.disabledBtnRepor) {
        self.msg_modal = "ore.report.msg_data_no_save";
        self.$refs.modalSaveChanges.showDialog = true;
      } else {
        self.showDialog = false;
      }
    },
    async dontSaveChange() {
      this.$refs.modalSaveChanges.showDialog = false;
      this.showDialog = false;
    },
    unitInventoryType() {
      let inventoryType = this.$_.filter(
        this.listInventoryType,
        (inventoryType) => inventoryType.id == this.data.inventory_item
      );
      let unitItem =
        inventoryType.length > 0
          ? this.$_.filter(
              this.listUnits,
              (unit) => unit.id == inventoryType[0].parent_id
            )[0]
          : {};
      if ("name" in unitItem) unitItem = unitItem.name;
      else unitItem = "-";
      return unitItem;
    },
    async getLists() {
      try {
        this.listInventoryType = this.listTranslate(
          await getListItemsByCode("INV")
        );
        this.listUnits = this.listTranslate(await getListItemsByCode("UNIT"));
      } catch (error) {
        this.logError(error)
      }
    },
  },
  async mounted() {
    this.getLists();
  },
  computed: {
    isDirty() {
      return this.$refs.observerInventory.ctx.dirty;
    },
    remainingItem() {
      return (
        parseFloat(this.data.started) +
        this.recievedQtyItemInventory(this.data.inventory_item) +
        parseFloat(this.data.received) -
        parseFloat(this.data.consumed)
      );
    },
  },
  watch: {
    showDialog(value) {
      const self = this;
      self.getLists();
      setTimeout(function () {
        self.$refs.observerInventory.reset();
      }, 100);
      if (!value) {
        self.data = {
          id: 0,
          consumed: 0,
          started: 0,
          received: 0,
          remaining: 0,
          element_type: 0,
          report: self.reportSelectedId,
        };
      }
    },
  },
};
</script>
