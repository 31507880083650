import Vue from "vue"
import Vuex from "vuex"
import VuexPersistence from "vuex-persist"

import { storeOre } from "./ore"
import { storeComun } from "./comun"
import { storeAuth } from "./auth"

import {expSnackBarStore} from "./components/expSnackBar"

Vue.use(Vuex)

const vuexPersist = new VuexPersistence({
  key: "persist",
  storage: window.localStorage,
  reducer: (state) => ({
    auth: state.auth,
    ore: {
      report_id: state.ore.report_id,
      plant_id: state.ore.plant_id,
    },
  }),
})

export default new Vuex.Store({
  modules: {
    comun: storeComun,
    ore: storeOre,
    auth: storeAuth,
    expSnackBarStore
  },
  plugins: [vuexPersist.plugin]
})