<template>
  <div>
    <exp-loading v-show="isLoading" />
    <inventory-form
      ref="inventoryForm"
      @change="getAllData"
      :configForms="configForms"
      :disabledBtnReport="storeOnlyReadReport"
    />
    <exp-alert-confirm
      ref="delInventory"
      :colorCancel="'green'"
      :message="msg_delete"
      :fnOK="deleteInventory"
    />
    <v-card elevation="0" :color="'transparent'">
      <exp-report-header
        :color="color"
        :code="''"
        :colorText="colorText"
        :disabledBtnReport="storeOnlyReadReport"
        ref="expHeader"
        @onSave="saveModuleStatus()"
      />
      <v-card-text class="table_module">
        <v-row>
        </v-row>
        <exp-report-table
          :direction="configTable.direction"
          :headers="headersTable"
          :items="items"
          :tableHeight="'calc(100% - 15px)'"
          :funcEdit="editInventory"
          :funcDelete="deleteInventory"
          :readOnly="storeOnlyReadReport"
          :optionsButtons="{ edit: true, delete: false }"
          :colorBg="color"
          :colorText="colorText"
        />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import basicMixin from "@/mixins/basic";
import customerMixin from "@/mixins/customer";
import oreMixin from "@/mixins/ore";
import expReportTable from "@/components/expReportTable";
import inventoryForm from "./form";
import { getInventory, getAllInventory } from "@/gateway/inventory";
import { getListItemsByCode } from "@/gateway/lists";
export default {
  name: "page-reports-inventory",
  layout: "colas",
  mixins: [basicMixin, customerMixin, oreMixin],
  components: {
    expReportTable,
    inventoryForm,
  },
  data() {
    return {
      color: "#1e9a92",
      colorText: "",
      headers: [
        {
          text: "ore.report.inventory_item",
          width: "120px",
          value: "inventory_item",
          class: "success--text title",
        },
        {
          text: "ore.report.units",
          value: "units",
        },
        {
          text: "ore.report.starting_qty",
          value: "started",
          alignh: "text-center",
          alignb: "text-right",
        },
        {
          text: "ore.report.received_qty",
          value: "received",
          alignh: "text-center",
          alignb: "text-right",
        },
        {
          text: "ore.report.consumed_qty",
          value: "consumed",
          alignh: "text-center",
          alignb: "text-right",
        },
        {
          text: "ore.report.remaining_qty",
          value: "remaining",
          alignh: "text-center",
          alignb: "text-right",
        },
        {
          text: "core.comun.options",
          value: "options",
          width: "45px",
          alignh: "text-center",
          alignb: "text-center",
        },
      ],
      items: [],
      listInventoryType: [],
      listUnits: [],
      msg_delete: "",
      data: {},
      isLoading: false,
      configForms: {
        inventory_item: {
          visible: true,
          disabled: false,
        },
        units: {
          visible: false,
          disabled: false,
        },
        starting_qty: {
          visible: true,
          disabled: false,
        },
        received_qty: {
          visible: true,
          disabled: false,
        },
        consumed_qty: {
          visible: true,
          disabled: false,
        },
        remaining_qty: {
          visible: true,
          disabled: false,
        },
      },
      configFormsReception: {
        no_certificat: { visible: true, disabled: false },
        date_time: { visible: true, disabled: false },
        inventory_item: { visible: true, disabled: false },
        no_livraison: { visible: true, disabled: false },
        taken_by: { visible: true, disabled: false },
        provider: { visible: true, disabled: false },
        element_type: { visible: true, disabled: false },
        conform: { visible: true, disabled: false },
        received: { visible: true, disabled: false },
        min_storage: { visible: true, disabled: false },
        max_storage: { visible: true, disabled: false },
        temperature: { visible: true, disabled: false },
        comments: { visible: true, disabled: false },
      },
      configTable: {
        direction: "v",
        headers: [],
      },
      configTableReception: {
        direction: "v",
        headers: [],
      },
    };
  },
  async mounted() {
    this.isLoading = true;
    let storeConfigModule = this.pageModuleConfig.settingsConfig;
    let localConfigForm = this.$_.clone(this.configForms);
    this.configForms = { ...localConfigForm, ...storeConfigModule.form };
    this.configFormsReception = {
      ...localConfigForm,
      ...storeConfigModule.formReception,
    };
    this.configTable = { ...localConfigForm, ...storeConfigModule.table };
    this.configTableReception = {
      ...localConfigForm,
      ...storeConfigModule.tableReception,
    };
    this.color = this.pageModuleConfig.color || "primary";
    this.colorText = this.pageModuleConfig.color_text || "primary";
    await this.getLists();
    if (this.isCustomersAuthenticated && this.reportSelectedId > 0)
      this.getAllData();
    this.isLoading = false;
  },
  methods: {
    async getAllData(data = null) {
      this.isLoading = true;
      let allData = data ? data : this.storeReportSelected.inventory;
      try {
        if (!this.isset(allData)) {
          allData = await getAllInventory(this.reportSelectedId);
        }
      } catch (error) {
        this.logError(error)
        allData = []
      }
      let _data = [];
      allData.forEach((item) => {
        let _unitItem = this.unitInventoryType(item);
        _data.push({
          id: item.id,
          units: _unitItem == "" ? "" : this.$t("core.list_item." + _unitItem),
          inventory_item:
            item.inventory_item >= 0
              ? this.getTranslation(item.inventory_item, this.listInventoryType)
              : "",
          started: this.numberToFormatNumeral(item.started),
          received: this.numberToFormatNumeral(
            this.recievedQtyItemInventory(item.inventory_item) + item.received
          ),
          consumed: this.numberToFormatNumeral(item.consumed),
          remaining: this.numberToFormatNumeral(
            item.started +
              this.recievedQtyItemInventory(item.inventory_item) +
              item.received -
              item.consumed
          ),
        });
      });
      this.items = _data;
      this.$store.commit("ore/setUpdateReportsSelected", {
        inventory: allData,
      });
      this.isLoading = false;
    },
    unitInventoryType(item) {
      let txtUnitItem = "";
      try {
        let inventoryType = this.listInventoryType.find(
          (inventoryType) => inventoryType.id == item.inventory_item
        );
        let unitItem =
          inventoryType != undefined
            ? this.listUnits.find((unit) => unit.id == inventoryType.parent_id)
            : undefined;
        if (unitItem != undefined) {
          if ("name" in unitItem) txtUnitItem = unitItem.name;
        }
      } catch {
        txtUnitItem = "";
      }
      return txtUnitItem;
    },
    editInventory(id) {
      this.clkFunction("_edit", id);
    },
    deleteInventory(id) {},
    async clkFunction(accion, id) {
      const self = this;
      switch (accion) {
        case "_edit":
          self.isLoading = true;
          try {
            let data;
            await getInventory(id).then((reponse) => {
              data = reponse;
            });
            self.$refs.inventoryForm.data = data;
            self.$refs.inventoryForm.showDialog = true;
          } catch (error) {
            this.logError(error)
          }
          self.isLoading = false;
          break;
        case "_new":
          self.$refs.inventoryForm.data = {
            started: 0,
            received: 0,
            consumed: 0,
            remaining: 0,
          };
          self.$refs.inventoryForm.showDialog = true;
          break;
        default:
          break;
      }
    },
    async getLists() {
      try {
        this.listInventoryType = await getListItemsByCode("INV");
        this.listUnits = await getListItemsByCode("UNIT");
      } catch (error) {
        this.logError(error)
      }
    },
    saveModuleStatus() {
      this.$refs.expHeader.isValidForm = true;
    },
  },
  computed: {
    isInventoryReception() {
      return this.storeGlobalSettingsgModules.inventory_reception;
    },
    tableHeight() {
      return this.heightScreen > 768 ? 510 : this.heightScreen - 300;
    },
    headersTable() {
      let headersConfig = this.configTable.headers;
      let headersTable = this.headers;
      let returnHeaders = [];
      for (let item in headersTable) {
        if (
          headersConfig[headersTable[item].value] &&
          headersConfig[headersTable[item].value].visible &&
          headersTable[item].value !== "options"
        ) {
          returnHeaders.push(headersTable[item]);
        }
      }
      let x = this.$_.find(headersTable, (x) => x.value === "options");
      if (x) returnHeaders.push(x);
      return returnHeaders;
    },
  },
  watch: {
    reportSelectedId() {
      this.getAllData();
    },
    "storeLang.lang"(value) {
      if (value) {
        this.getAllData();
        this.getLists();
      }
    },
  },
};
</script>
