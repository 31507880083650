import { createQueryBuilder, getRepository } from "typeorm";
import { baseApi, isConfigMobile, getCustomerJWT } from './base'
import axios from 'axios'
import store from "@/store"

/**
 * It is used to get all source records.
 * @returns All source available.
 */
export function getSource() {
    let itemsSource = store.getters['ore/getListSource']

    let isMobile = isConfigMobile()
    if (isMobile) {
        return new Promise(async function (resolve) {
            if (typeof (itemsSource) != 'undefined' && itemsSource.length != 0) {
                resolve(itemsSource)
            } else {
                const sourceRepository = await getRepository("oreConfigSource")
                let _source = await sourceRepository.find({ deleted: null, published: true }).then(res => { return res }).catch(e => console.log(e))
                _source.unshift({
                    blasting_number: "0",
                    blasting_tons: 0,
                    campaign: 0,
                    description: "ore.report.no_blasting",
                    id: 999999998,
                });
                _source.push({
                    blasting_number: "0",
                    blasting_tons: 0,
                    campaign: 0,
                    description: "ore.report.other",
                    id: 999999999,
                });
                store.commit('ore/setListSource', _source)
                resolve(_source)
            }
        })
    } else {
        return new Promise(function (resolve, reject) {
            if (typeof (itemsSource) != 'undefined' && itemsSource.length != 0) {
                resolve(itemsSource)
            } else {
                const token = getCustomerJWT()
                axios.get(baseApi() + '1.0/ore/crushing/source/lts/', { headers: { Authorization: `Bearer ${token}` } })
                    .then(response => {
                        let list = response.data
                        list.unshift({
                            blasting_number: "0",
                            blasting_tons: 0,
                            campaign: 0,
                            description: "ore.report.no_blasting",
                            id: 999999998,
                        });

                        list.push({
                            blasting_number: "0",
                            blasting_tons: 0,
                            campaign: 0,
                            description: "ore.report.other",
                            id: 999999999,
                        });
                        store.commit('ore/setListSource', list)
                        resolve(list)
                    })
                    .catch(error => reject(error))
            }
        })
    }
}

