<template>
  <v-app class="ore-login-mobile">
  </v-app>
</template>
<script>
import { msalMixin } from "vue-msal";

export default {
  auth: false,
  name: "page-login-mobile",
  mixins: [msalMixin],
  mounted() {
    let user = null;
    if (this.msal.isAuthenticated) {
      user = {
        ...this.msal.user,
        profile: {},
      };
      if (this.msal.graph && this.msal.graph.profile) {
        user.profile = this.msal.graph.profile;
      }
    }
    if (user != null) {
      user.token = localStorage.getItem(
        "msal." + user.idToken.aud + ".idtoken"
      )
      console.log(JSON.stringify({token: user.token}))
      webkit.messageHandlers.cordova_iab.postMessage(JSON.stringify({token: user.token}))
    } else {
      this.$msal.signIn();
    }
  }
};
</script>
