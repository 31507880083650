var EntitySchema = require("typeorm").EntitySchema;

module.exports = new EntitySchema({
    name: "oreConfigSource",
    tableName: "ore_config_source",
    columns: {
        id: {
            primary: true,
            type: "int",
            generated: false
        },
        blasting_number: {
            type: "varchar"
        },
        description: {
            type: "varchar",
            nullable: true
        },
        blasting_tons: {
            type: "float",
            nullable: true
        },
        location: {
            type: "float",
            nullable: true
        },
        compress_strength: {
            type: "float",
            nullable: true
        },
        hole_diameter: {
            type: "float",
            nullable: true
        },
        holes_drilled_number: {
            type: "float",
            nullable: true
        },
        blasting_side1: {
            type: "float",
            nullable: true
        },
        blasting_side2: {
            type: "float",
            nullable: true
        },
        detonator: {
            type: "int",
            nullable: true
        },
        power_factor: {
            type: "float",
            nullable: true
        },
        blasted_height: {
            type: "float",
            nullable: true
        },
        campaign: {
            type: "int",
            nullable: true
        },
        created: {
            type: "datetime",
            nullable: true
        },
        created_by: {
            type: "int",
            nullable: true
        },
        update: {
            type: "datetime",
            nullable: true
        },
        update_by: {
            type: "int",
            nullable: true
        },
        deleted: {
            type: "datetime",
            nullable: true
        },
        deleted_by: {
            type: "int",
            nullable: true
        },
        published: {
            type: "int",
            default: "1"
        }
    }
})