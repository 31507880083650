import { createQueryBuilder, getRepository } from "typeorm";
import { baseApi, isConfigMobile, getCustomerJWT, parseDate, updateReportAuditFields } from './base'
import axios from 'axios'
import store from "@/store"
import moment from "moment";

/**
 * It is used to get all records from inventory table
 * @param {Number} reportId - Report Id.
 * @returns {Array} All records available in the report.
 */
export function getAllInventory(reportId) {
    let isMobile = isConfigMobile()
    if (isMobile) {
        return new Promise(function (resolve, reject) {
            const inventory = getRepository("oreReportInventory")
                .find({ report: reportId, deleted: null })
                .then(response => { store.commit("ore/setUpdateReportsSelected", { 'inventory': response }); resolve(response); })
                .catch(error => console.log(error))
        })
    } else {
        return new Promise(function (resolve) {
            const token = getCustomerJWT()
            axios.get(baseApi() + '1.0/ore/report/inventory/all/' + reportId + "/", { headers: { Authorization: `Bearer ${token}` } })
                .then(response => resolve(response.data))
                .catch(error => reject(error))
        })
    }
}

/**
 * It gets a record from inventory to edit
 * @param {Number} id - Inventory Id.
 * @returns {Object} The record to edit
 */
export function getInventory(id) {
    let isMobile = isConfigMobile()
    if (isMobile) {
        return new Promise(function (resolve) {
            const inventory = getRepository("oreReportInventory")
                .findOne({ id: id, deleted: null, }).then(response => { resolve(response); })
                .catch(error => console.log(error))
        })
    } else {
        return new Promise(function (resolve, reject) {
            const token = getCustomerJWT()
            axios.get(baseApi() + '1.0/ore/report/inventory/' + id + "/", { headers: { Authorization: `Bearer ${token}` } })
                .then(response => resolve(response.data))
                .catch(error => reject(error))
        })
    }
}

/**
 * It makes a soft delete of a record from inventory table
 * @param {Number} id - Inventory Id.
 * @returns {Array} All records available in the report.
 */
export async function deleteInventory(id) {
    let isMobile = isConfigMobile()
    if (isMobile) {
        const inventory = getRepository("oreReportInventory");
        let user_id = store.getters["auth/getAuthCustomer"].data.id
        await inventory.update(id, {
            update: new Date(),
            update_by: user_id,
            deleted: new Date(),
            deleted_by: user_id,
            sync: false,
        })
        await inventory.find({ id: id }).then((res) => { return sync(res); });
    } else {
        return new Promise(function (resolve, reject) {
            const token = getCustomerJWT()
            axios.delete(baseApi() + '1.0/ore/report/inventory/' + id + "/", { headers: { Authorization: `Bearer ${token}` } })
                .then(response => resolve(response))
                .catch(error => reject(error))
        })
    }

}

/**
 * It creates a new record in the inventory table
 * @param {Object} data - Object with data to save
 * @returns {Array} All records available in the report.
 */
export async function createInventory(data) {
    let isMobile = isConfigMobile()
    if (isMobile) {
        const inventory = await getRepository("oreReportInventory");
        let now = new Date();
        let user_id = store.getters["auth/getAuthCustomer"].data.id
        data.update = now;
        data.update_by = user_id;
        data.created = now;
        data.created_by = user_id;

        await inventory.save(data)
            .then((response) => { return sync([response]); }).catch(error => console.log(error))
    } else {
        return new Promise(function (resolve, reject) {
            const token = getCustomerJWT()
            axios.post(baseApi() + '1.0/ore/report/inventory/', data, { headers: { Authorization: `Bearer ${token}` } })
                .then(response => resolve(response.data))
                .catch(error => reject(error))
        })
    }
}

/**
 * It updates a record in the inventory table
 * @param {Number} id - Inventory Id.
 * @param {Object} data - Object with data to update
 * @returns {Array} All records available in the report.
 */
export async function updateInventory(id, data) {
    let isMobile = isConfigMobile()
    if (isMobile) {
        const inventory = await getRepository("oreReportInventory");
        let user_id = store.getters["auth/getAuthCustomer"].data.id
        await inventory.update(id,
            {
                invetory_item: data.invetory_item,
                element_type: data.element_type,
                started: data.started,
                received: data.received,
                consumed: data.consumed,
                remaining: data.remaining,
                update: new Date(),
                update_by: user_id,
                sync: false
            },
        )
        await inventory.find({ id: id }).then((res) => { return sync(res); });
    } else {
        return new Promise(function (resolve, reject) {
            const token = getCustomerJWT()
            axios.put(baseApi() + '1.0/ore/report/inventory/' + id + "/", data, { headers: { Authorization: `Bearer ${token}` } })
                .then(response => resolve(response.data))
                .catch(error => reject(error))
        })
    }
}

/**
 * It is used to synchronize data from inventory module.
 * It is send from mobile aplication to web aplication
 * @param {Object} data - Object with data to send to web application
 * @returns {Array} All records available in the report.
 */
async function sync(data) {
    store.commit("comun/setSynchronizing", true);
    const reportRepository = getRepository("oreReport");
    const inventory = await getRepository("oreReportInventory");
    let data_inventory = parseDate(data);

    let reportData = await reportRepository.find({ id: data_inventory[0].report }).then(res => { return parseDate(res) })
    await reportRepository.update(reportData[0].id, { sync: false, }).then((res) => {}).catch(error => console.log(error))

    let checkOnLine =  store.getters["comun/getIsOnline"]
    if(checkOnLine){
        let objToSync = {
            reports: [
                {
                    report: reportData[0],
                    data: { inventory: data_inventory, }
                }
            ],
        }

        const token = getCustomerJWT()
        await axios.post(baseApi() + '1.0/ore/mobile/sync/', objToSync, { headers: { Authorization: `Bearer ${token}` } })
            .then(async function (response) {
                let id = data_inventory[0].id
                let reportRes = response.data.reports[0].report
                let dataRes = response.data.reports[0].data

                await updateReportAuditFields(reportRes, reportRepository, reportData[0].id)
                if ("inventory" in dataRes) {
                    let _items = dataRes.inventory
                    _items.forEach(async (item) => {
                        //If the sync is rejected, the item is updated with the information that comes from web.
                        if (item.action_msg == "reject") {
                            await inventory.update(id,
                                {
                                    invetory_item: item.invetory_item,
                                    element_type: item.element_type,
                                    started: item.started,
                                    received: item.received,
                                    consumed: item.consumed,
                                    remaining: item.remaining,
                                    created: new Date(item.created),
                                    created_by: item.created_by,
                                    update: new Date(item.update),
                                    update_by: item.update_by,
                                    web_id: item.web_id,
                                    update_web: new Date(item.update_web),
                                    sync: true,
                                },
                            ).then((res) => {}).catch(error => console.log(error))
                        } else {
                            //If it is not rejected and the sync was successful, the variables are updated.
                            if (item.action_status) {
                                await inventory.update(id,
                                    {
                                        web_id: item.web_id,
                                        update_web: new Date(item.update_web),
                                        sync: true,
                                    },
                                ).then((res) => {}).catch(error => console.log(error))
                            }
                        }
                    })
                }

                store.commit("comun/setSynchronizing", false);
                return getAllInventory(data_inventory[0].report)

            }).catch(error => {store.commit("comun/setSynchronizing", false); console.log(error); return getAllInventory(data_inventory[0].report)})
    } else {
        store.commit("comun/setSynchronizing", false);
        return getAllInventory(data_inventory[0].report)
    }
}
