import configMixins from "@/mixins/config";
import mobileMixins from "@/mixins/mobile";
import { getSync } from "@/gateway/sync";
import { createQueryBuilder, getRepository } from "typeorm";
import moment from "moment";
import store from "@/store";

const syncListsMixin = {
    mixins: [configMixins, mobileMixins],
    data() {
        return {
            total_options: 0,
            total_options_saved: 0,
            total_records: 0,
            total_records_saved: 0
        }
    },
    methods: {
        /**
         * It is used to get information from the web application about all the configuration tables.
         * If the response is valid, the synchronization date is updated and the data is processed and saved.
         * @returns true
         */
        async syncListsFn(dateSyncManual = null) {
            const self = this;
            return new Promise(async function (resolve) {
                if (self.checkOnLine())  {
                    if (self.isOnline == true) {
                        const sync = await getRepository("syncDate");
                        let lastSync = await sync.find().then(response => { console.log("DATE",response); return response; }).catch(error => { console.log("error", error) })

                        let _user_id = self.$store.getters["auth/getAuthCustomer"].data.id
                        let _lastSync = lastSync.filter(x => x.user_id == _user_id)

                        let dateSyncModule = null;
                        if (_lastSync.length > 0) {
                            if(dateSyncManual == null){
                                dateSyncModule = _lastSync[0].date_lists
                            } else {
                                dateSyncModule = dateSyncManual
                            }
                        }

                        let newDataToSync = {}
                        let modulesToSync = [
                            { "label": "Modules", "module": "sync", "url": "ore/sync" },
                            { "label": "Translations", "module": "translate", "url": "translate/all" },
                            { "label": "Users", "module": "users", "url": "user/all" },
                            { "label": "Roles", "module": "roles", "url": "userrole/all" },
                            { "label": "Equipments", "module": "equipment", "url": "ore/equipment/all" },
                            { "label": "Equipments", "module": "equipment_maintenance", "url": "ore/equipmentmaintenance/all" },
                            { "label": "Crusher", "module": "crusher", "url": "ore/crusher/all" },
                            { "label": "Sites", "module": "site", "url": "ore/crushing/site/all" },
                            { "label": "Regions", "module": "region", "url": "ore/crushing/region/all" },
                            { "label": "Campaigns", "module": "campaign", "url": "ore/crushing/campaign/all" },
                            { "label": "Targets", "module": "campaign_target", "url": "ore/crushing/campaign_target/all" },
                            { "label": "Scenarios", "module": "scenario", "url": "ore/crushing/scenario/all" },
                            { "label": "Subsidiaries", "module": "subsidiary", "url": "ore/crushing/subsidiary/all" },
                            { "label": "Buids", "module": "buid", "url": "ore/crushing/buid/all" },
                            { "label": "Sources", "module": "source", "url": "ore/crushing/source/all" },
                            //{ "label": "Product list sites", "module": "product_list_site", "url": "" },
                            //{ "label": "Addresses", "module": "address", "url": "" },
                            //{ "label": "Companies", "module": "company", "url": "" },
                            //{ "label": "Countries", "module": "country", "url": "" },
                            //{ "label": "Continents", "module": "continents", "url": "" },
                            { "label": "Products", "module": "products", "url": "ore/crushing/product/all" },
                            { "label": "Products lists", "module": "product_list", "url": "ore/products_list/all" },
                            { "label": "Rock types", "module": "rock_type", "url": "ore/list_rock_type/all" },
                            { "label": "Rock characteristics", "module": "rock_characteristic", "url": "ore/list_rock_charact/all" },
                            { "label": "Lists", "module": "list", "url": "list/all" },
                            { "label": "Items", "module": "list_item", "url": "list_item/all" },
                            { "label": "Lists", "module": "list_pivot", "url": "list_pivot/all" },
                            { "label": "Items", "module": "list_pivot_item", "url": "list_pivot_item/all" }
                        ]

                        for await (const _moduleSync of modulesToSync) {
                            self.messageLoading = _moduleSync.label

                            let _newDataToSync = await getSync(dateSyncModule, _moduleSync.url)
                            if (_moduleSync.module == "sync") {
                                newDataToSync['modules'] = _newDataToSync['modules']
                            } else {
                                newDataToSync[_moduleSync.module] = _newDataToSync
                            }

                            if (_lastSync.length > 0) {
                                if(Object.entries(newDataToSync).length > 0){
                                    await sync.update(_lastSync[0].id, { date_lists: newDataToSync.sync_date, user_id: _user_id });
                                    store.commit("comun/setLastSync",moment(newDataToSync.sync_date).format("DD/MM/YYYY HH:mm"));
                                }
                            } else {
                                if(Object.entries(newDataToSync).length > 0){
                                    await sync.save({ date_lists: newDataToSync.sync_date, user_id: _user_id });
                                }
                            }
                        }

                        newDataToSync = Object.entries(newDataToSync);
                        self.total_options = newDataToSync.length
                        self.total_records = newDataToSync.reduce((previousValue, currentValue) => {
                            return previousValue + ((currentValue[0] != "sync_date") ? currentValue[1].length : 0)
                        }, 0);

                        for await (const [key, value] of newDataToSync) {
                            switch (key) {
                                case "list":
                                    await self.saveUpdate(await getRepository("coreList"), value, key);
                                    break;
                                case "list_item":
                                    await self.saveUpdate(await getRepository("coreListItem"), value, key);
                                    break;
                                case "list_pivot":
                                    await self.saveUpdate(await getRepository("coreListPivot"), value, key);
                                    break;
                                case "list_pivot_item":
                                    await self.saveUpdate(await getRepository("coreListPivotItem"), value, key);
                                    break;
                                case "translate":
                                    value.forEach(item => {
                                        try {
                                            item.translates = btoa(item.translates || '{}');
                                        } catch { console.log(item.translates); item.translates = btoa("{}"); }
                                    });
                                    await self.saveUpdate(await getRepository("coreTranslate"), value, key);
                                    break;
                                case "modules":
                                    await self.saveUpdate(await getRepository("coreModule"), value, key);
                                    break;
                                case "users":
                                    await self.saveUpdate(await getRepository("coreUser"), value, key);
                                    break;
                                case "roles":
                                    await self.saveUpdate(await getRepository("coreUsersRoles"), value, key);
                                    break;
                                //case "address":
                                //    await self.saveUpdate(await getRepository("oreConfigAddress"), value, key);
                                //    break;
                                //case "company":
                                //    await self.saveUpdate(await getRepository("oreConfigCompany"), value, key);
                                //    break;
                                //case "country":
                                //    await self.saveUpdate(await getRepository("oreConfigCountry"), value, key);
                                //    break;
                                case "region":
                                    await self.saveUpdate(await getRepository("oreConfigRegion"), value, key);
                                    break;
                                case "crusher":
                                    value.forEach(item => {
                                        try {
                                            item.format = btoa(item.format);
                                        } catch { item.format = btoa("{}"); }
                                    });
                                    await self.saveUpdate(await getRepository("oreConfigCrusher"), value, key);
                                    break;
                                case "equipment":
                                    await self.saveUpdate(await getRepository("oreConfigEquipment"), value, key);
                                    break;
                                case "equipment_maintenance":
                                    await self.saveUpdate(await getRepository("oreConfigEquipmentMaintenance"), value, key);
                                    break;
                                case "site":
                                    await self.saveUpdate(await getRepository("oreConfigSite"), value, key);
                                    break;
                                case "campaign":
                                    self.saveUpdate(await getRepository("oreConfigCampaign"), value, key);
                                    break;
                                case "campaign_target":
                                    self.saveUpdate(await getRepository("oreConfigCampaignTarget"), value, key);
                                    break;
                                case "scenario":
                                    self.saveUpdate(await getRepository("oreConfigScenario"), value, key);
                                    break;
                                case "subsidiary":
                                    self.saveUpdate(await getRepository("oreConfigSubsidiary"), value, key);
                                    break;
                                case "buid":
                                    self.saveUpdate(await getRepository("oreConfigBuid"), value, key);
                                    break;
                                case "source":
                                    self.saveUpdate(await getRepository("oreConfigSource"), value, key);
                                    break;
                                case "products":
                                    self.saveUpdate(await getRepository("oreConfigProduct"), value, key);
                                    break;
                                case "product_list_site":
                                    self.saveUpdate(await getRepository("oreConfigProductListSite"), value, key);
                                    break;
                                case "product_list":
                                    self.saveUpdate(await getRepository("oreConfigProductList"), value, key);
                                    break;
                                case "rock_type":
                                    self.saveUpdate(await getRepository("oreConfigRockType"), value, key);
                                    break;
                                case "rock_characteristic":
                                    self.saveUpdate(await getRepository("oreConfigRockCharacteristic"), value, key);
                                    break;
                            }
                        }
                    };
                }
                setTimeout(() => {
                    resolve(true)
                }, 10);
            })
        },

        /**
         * It is used to page incoming data to avoid the maximum depth error.
         * @param {Array} data - Data to be saved.
         * @param {Class} repository - Entity to manipulate the model (entitie).
         */
        async saveUpdate(repository, data, table) {
            let self = this
            const numPerPage = 999;
            let numSteps = Math.ceil(data.length / numPerPage);
            let dataToInsert;
            let i = 0;
            for await (let i of Array(numSteps).keys()) {
                dataToInsert = data.slice(i * numPerPage, (i + 1) * numPerPage);
                await self.processSaveUpdate(
                    repository,
                    dataToInsert.map(item => { return item.id }),
                    self.createInsertSql(repository, dataToInsert)
                );
            }
            self.total_options_saved += 1;
        },

        /**
         * It is used to delete old data and insert the data with the updated information from the web.
         * @param {Class} repository - Entity to manipulate the model (entitie).
         * @param {Array} toDelete - Array with data to delete.
         * @param {Array} toInsert - Array with data to insert.
         * @returns {Boolean} - True when the proccess is finished.
         */
        async processSaveUpdate(repository, toDelete, toInsert) {
            let self = this
            return new Promise(async function (resolve) {
                await repository.delete(toDelete);
                await repository.query(toInsert);
                self.total_records_saved += toDelete.length;
                setTimeout(() => {
                    resolve(true)
                }, 10);
            })
        },

        /**
         * It is used to create the query to insert data into the mobile application,
         * taking the attributes of the models (entities) and the incoming values.
         * @param {Class} entity - Entity to manipulate the report model.
         * @param {Array} dataToInsert - Data to be saved in the mobile application.
         * @returns {String} - Query to be executed.
         */
        createInsertSql(entity, dataToInsert) {
            let strQuery = "INSERT INTO '" + entity.metadata.givenTableName + "' (";
            for (const [key, value] of Object.entries(entity.metadata.propertiesMap)) {
                strQuery += "'" + key + "',"
            }
            strQuery = strQuery.substr(0, strQuery.length - 1)
            strQuery += ') VALUES '
            for (let item of dataToInsert) {
                strQuery += "(";
                for (const [key, value] of Object.entries(entity.metadata.propertiesMap)) {
                    strQuery += this.fixValue(item[key], key, entity.metadata.ownColumns) + ","
                }
                strQuery = strQuery.substr(0, strQuery.length - 1)
                strQuery += "),";
            }
            strQuery = strQuery.substr(0, strQuery.length - 1)
            return strQuery;
        },

        /**
         * It is used to fix values and prevent error in the application mobile.
         * @param {Any} _val - The value to be fixed.
         * @param {String} key - The name of the column of the model (entitie).
         * @param {Array} columns - Array of the columns of the model (entitie).
         * @returns The value fixed to be saved in the mobile application.
         */
        fixValue(_val, key, columns) {
            let valReturn = null
            if (!(_val == null || _val == undefined)) {
                if (typeof (_val) == "number" || typeof (_val) == "boolean") {
                    valReturn = _val
                } else {
                    var regex = new RegExp("\"", "g");
                    try {
                        _val = _val.replace(regex, "\'")
                    } catch {
                        console.log(_val)
                    }
                    valReturn = `"${_val}"`
                }
            }

            if (valReturn == null) {
                let _default = columns.find(x => x.databaseName == key)["default"]
                if (_default != undefined) {
                    let _type = columns.find(x => x.databaseName == key)["type"]
                    if (_type == "int") {
                        return parseInt(_default)
                    }
                    else if (_type == "varchar") {
                        return `"${_default}"`
                    } else {
                        return _default
                    }
                } else {
                    return valReturn
                }
            } else {
                return valReturn
            }
        }
    }
}
export default syncListsMixin