import { createQueryBuilder, getRepository } from "typeorm";
import { baseApi, getCustomerJWT } from './base'
import axios from 'axios'
import store from "@/store"

/**
 * It is used to get all available module.
 * @returns {Array} All module available.
 */
export async function getAllModule() {
    let isMobile = store.getters['comun/getIsMobile']
    if (isMobile) {
        let itemsModule = await store.getters['ore/getListModule']
        return new Promise(async function (resolve) {
            if (itemsModule.length > 0) { resolve(itemsModule) }
            else {
                const moduleRepository = await getRepository("coreModule");
                let _modules = await moduleRepository
                    .find({ deleted: null })
                    .then(response => {return response; })
                    .catch(error => { console.log(error); })
                store.commit('ore/setListModule', _modules)
                resolve(_modules)
            }
        })
    } else {
        let itemsModule = await store.getters['ore/getListModule']
        return new Promise(function (resolve) {
            if (itemsModule.length > 0) { resolve(itemsModule) }
            else {
                const token = getCustomerJWT()
                axios.get(baseApi() + '1.0/module/lts/full/', { headers: { Authorization: `Bearer ${token}` } })
                    .then(response => {
                        store.commit('ore/setListModule', response.data)
                        resolve(response.data)
                    })
                    .catch(error => reject(error))
            }
        })
    }
}
