import Vue from "vue";
import numeral from "numeral";

Vue.filter("secondsToHours", function (value) {
  if (!value) return "00h 00m";
  value = Number(value);
  return Math.floor(value / 3600) + 'h' + ' ' + Math.floor(value % 3600 / 60) + 'm'
});

Vue.filter("numberToPercent", function (value) {
  if (!value) return "0.00 %"
  else return Number(value).toFixed(2) + ' %'
});

Vue.filter("numberToFormatNumeral", function (value) {
  if (!value) return "0.000"
  else return numeral(value).format("0,0.000")
});