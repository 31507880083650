import Vue from 'vue'
import VueAxios from 'vue-axios'
import axios from 'axios'
import baseApi from '../gateway/base'
import store from '../store'

Vue.use(VueAxios, axios);

axios.defaults.baseURL = baseApi

axios.interceptors.request.use((request) => {
  const _customer = store.getters["auth/getAuthCustomer"];
  const token = (_customer != null && _customer != undefined && _customer != "") ? _customer["access"] : "";
  if (token != "") {
    request.headers.common['Authorization'] = `Bearer ${token}`;
  }
  request.headers.common['Time-Request'] = Date.now();
  return request;
}, (error) => {
  return Promise.reject(error);
});

axios.interceptors.response.use((response) => {
  logRequest(response);
  return response;
}, (error) => {
  logRequest(error?.response);
  return Promise.reject(error);
});

function logRequest(data) {
  const statusCode = data ? data.status : null;
  const originalRequest = data ? data.config : null;
  if (originalRequest == null || statusCode ==  null) return;

  const elapsedTime = (Date.now() - originalRequest.headers['Time-Request']) / 1000;
  if ((statusCode !== 200 && statusCode !== 201) || elapsedTime > 25) {
    let url = originalRequest.url;
    if (url.lastIndexOf('api/1.0/auditrequest/') == -1) {
      let data = {
        "application": "web",
        "url": url,
        "method": originalRequest.method,
        "response_code": statusCode,
        "time_spent": elapsedTime,
        "published": true,
        "payload": btoa(JSON.stringify(originalRequest.data))
      }
      axios.post(url.substr(0, url.lastIndexOf('api')) + 'api/1.0/auditrequest/', data)
    }
  }
}