<template>
  <div>
    <v-card v-if="items.length > 0">
      <template v-for="(arrItems, index) in itemsCalculationBar">
        <v-card :key="index + 'Bar'" :color="color" class="ma-0 my-1">
          <v-card-text>
            <v-row
              no-gutters
              v-if="arrItems.length > 0"
              :style="`color: ${colorText != undefined ? colorText : 'black'};`"
            >
              <template v-for="item in arrItems">
                <v-col :key="item.id" class="text-center">
                  <strong class="bar-title">{{ item.name }}:</strong>
                  <span class="bar-value">{{ item.value }}</span>
                </v-col>
              </template>
            </v-row>
          </v-card-text>
        </v-card>
      </template>
    </v-card>
  </div>
</template>

<script>
import basicMixin from "@/mixins/basic";

export default {
  name: "exp-ore-calculation-bar",
  mixins: [basicMixin],
  data() {
    return {
      maxItems: 5,
    };
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    color: {
      type: String,
      default: "primary",
    },
    colorText: {
      type: String,
      default: "white",
    },
  },
  computed: {
    itemsCalculationBar() {
      let arrays = [];
      let list = this.$_.clone(this.items);
      if (list.length > this.maxItems) {
        // limitado solo a dos partes...
        // Limited to two parts only...
        let middleIndex = Math.ceil(list.length / 2);
        let firstHalf = list.splice(0, middleIndex);
        let secondHalf = list.splice(-middleIndex);
        if (firstHalf.length > 0) arrays.push(firstHalf);
        if (secondHalf.length > 0) arrays.push(secondHalf);
      } else {
        arrays.push(list);
      }
      return arrays;
    },
  },
};
</script>
