import { createQueryBuilder, getRepository } from "typeorm";
import { baseApi, isConfigMobile, getCustomerJWT, GetIdAccessRoles } from './base'
import axios from 'axios'
import store from "@/store"

/**
 * It is used to get all site records.
 * @returns All sites available.
 */
export function getSites() {
    let itemsSites = store.getters['ore/getListSites']

    let isMobile = isConfigMobile()
    if (isMobile) {
        return new Promise(async function (resolve) {
            if (typeof (itemsSites) != 'undefined' && itemsSites.length != 0) {
                resolve(itemsSites)
            } else {
                const siteRepository = await getRepository("oreConfigSite")
                    .createQueryBuilder("site")
                    .where("site.published = :published", { published: 1 })
                let _idsSites = await listIdSites()
                if (_idsSites != null) {
                    siteRepository.andWhere("site.id IN (:...idsSites)", { idsSites: _idsSites })
                }
                let _sites = siteRepository.getMany().then(response => { return response; }).catch(error => { console.log(error); })
                store.commit('ore/setListSites', _sites)
                resolve(_sites)
            }
        })
    } else {
        return new Promise(function (resolve, reject) {
            if (typeof (itemsSites) != 'undefined' && itemsSites.length != 0) {
                resolve(itemsSites)
            } else {
                const token = getCustomerJWT()
                axios.get(baseApi() + '1.0/ore/crushing/site/lts/', { headers: { Authorization: `Bearer ${token}` } })
                    .then(response => {
                        store.commit('ore/setListSites', response.data)
                        resolve(response.data)
                    })
                    .catch(error => reject(error))
            }
        })
    }
}

/**
 * It is used to get an array of regions ids filtered by user.
 * @returns {Array} - Array of regions ids.
 */
async function listIdSites() {
    let user_id = store.getters["auth/getAuthCustomer"].data.id
    let _idAccessRoles = await GetIdAccessRoles(user_id);
    return _idAccessRoles.sites
}