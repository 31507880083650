<template>
  <div class="box-summary">
    <div
      class="box-content"
      :style="haveZoom ? 'cursor: pointer' : ''"
      ref="boxRef"
      @click="openZoom()"
    >
      <v-card elevation="2" class="box-item mx-1 pa-0" :color="bgcolor">
        <v-card-title
          class="d-flex align-center justify-center py-0 box-title"
          :style="'background:' + bgcolorTitle + ';' + 'height: 45px;'"
        >
          <div class="box-title-content">{{ title }}</div>
          <v-spacer></v-spacer>
          <v-icon x-large v-if="iconType == 'icon'" class="summary-icon">{{
            icon
          }}</v-icon>
          <span
            style="height: 100%; fill: #fff"
            v-if="iconType == 'svg'"
            class="d-flex align-center justify-center summary-svg-icon"
            v-html="icon"
          ></span>
        </v-card-title>
        <v-card-text class="px-0" style="color: #024a64">
          <slot>
            <v-card-text v-if="html != ''" v-html="html"></v-card-text>
            <table v-else style="width: 100%">
              <template v-for="(moduleCalc, index) in items">
                <template v-if="moduleCalc.name == 'html'">
                  <tr v-html="moduleCalc.value" :key="index"></tr>
                </template>
                <template v-else>
                  <tr :key="index" :style="`background-color:${'row_color' in moduleCalc ? moduleCalc.row_color : ''};`">
                    <td colspan="3" >{{ moduleCalc.name }}</td>
                    <td align="right">{{ moduleCalc.value }}</td>
                  </tr>
                </template>
              </template>
            </table>
          </slot>
        </v-card-text>
      </v-card>
    </div>
    <v-dialog
      v-model="zoom"
      :width="items.length > columnItems ? '800px' : '420px'"
      class="box-dialog"
    >
      <v-card class="rounded-t-xl huge-sumary">
        <v-card-title
          :style="'background:' + bgcolorTitle + ';' + 'color: #fff;'"
        >
          <div class="text-h3 font-weight-regular box-title-content">
            {{ title }}
          </div>
          <v-spacer></v-spacer>
          <v-icon
            color="#fff"
            size="50px"
            v-if="iconType == 'icon'"
            class="summary-dialog-icon"
            >{{ icon }}</v-icon
          >
          <span
            style="height: 100%"
            v-if="iconType == 'svg'"
            class="text-rigth summary-dialog-svg-icon"
            v-html="icon"
          ></span>
        </v-card-title>
        <v-card-text class="pa-0" style="color: #024a64">
          <slot name="contentbox">
            <v-card-text v-if="html != ''" v-html="html"></v-card-text>
            <div v-else>
              <v-row
                v-if="itemsSummary.length > 0"
                no-gutters
                class="d-flex justify-center"
              >
                <template v-for="(cool, indexCool) in itemsSummary">
                  <v-col :key="indexCool + 100" class="pa-4" :cols="sizeCol">
                    <table style="width: 100%">
                      <template v-for="(moduleCalc, index) in cool.items">
                        <template v-if="moduleCalc.name == 'html'">
                          <tr v-html="moduleCalc.value" :key="index"></tr>
                        </template>
                        <template v-else>
                          <tr :key="index" :style="`background-color:${'row_color' in moduleCalc ? moduleCalc.row_color : ''};`">
                            <td colspan="3" >{{ moduleCalc.name }}</td>
                            <td align="right" style="white-space: nowrap;">
                              {{ moduleCalc.value }}
                            </td>
                          </tr>
                        </template>
                      </template>
                    </table>
                  </v-col>
                </template>
              </v-row>
              <v-row
                v-else
                no-gutters
                class="d-flex align-center justify-center"
              >
                <v-card-text>
                  <p class="text-h4 ma-0 pa-0">
                    {{ $t("core.datatable.no_data") }}
                  </p></v-card-text
                >
              </v-row>
            </div>
          </slot>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "exp-state",
  data() {
    return {
      zoom: false,
      boxHtml: "",
      boxWidth: 0,
      boxHeight: 0,
      minSizeCol: 6,
      sizeCol: 2,
    };
  },
  props: {
    bgcolor: {
      type: String,
      default: "#FFFFFF",
    },
    bgcolorTitle: {
      type: String,
      default: "#FFFFFF",
    },
    textcolor: {
      type: String,
      default: "#000000",
    },
    html: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "Box",
    },
    iconType: {
      type: String,
      default: "icon",
    },
    icon: {
      type: String,
      default: "mdi-twitter",
    },
    items: {
      type: Array,
      default: [],
    },
    columnItems: {
      type: Number,
      default: 10,
    },
    haveZoom: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    openZoom(e) {
      if (this.haveZoom) {
        this.zoom = true;
      }
    },
    closeZoom() {
      this.zoom = false;
    },
  },
  computed: {
    itemsSummary() {
      let columnItems = this.columnItems;
      let items = this.items;
      let returnItems = [];
      let toalColsHtml = 12;
      if (columnItems > 0 && items.length > 0) {
        let totalColumns = Math.floor(Number(items.length) / columnItems);
        var totalColumnsRemainder = Number(items.length) % columnItems;
        if (totalColumnsRemainder > 0) totalColumns += 1;
        this.sizeCol = Math.floor(toalColsHtml / totalColumns);
        for (let n = 1; n <= totalColumns; n++) {
          let lastCol = n > 0 ? n - 1 : 0;
          let itemsS = n * columnItems;
          let init = lastCol > 0 ? lastCol * columnItems : 0;
          let fin = itemsS;
          returnItems.push({
            items: items.slice(init, fin),
          });
        }
      }
      return returnItems;
    },
  },
};
</script>

<style lang="scss" scoped>
.box-summary {
  height: 100%;
}

.box-content {
  height: 100%;
}

.box-title {
  color: #fff;
}

.box-item {
  height: 100%;
  table {
    border-spacing: 0;
  }
  tr:nth-child(even) {
    background: rgba(0, 0, 0, 0.05);
  }
  td {
    font-size: 12px;
    line-height: 16px;
    padding: 2px;
  }
  .summary-icon,
  .summary-svg-icon svg {
    color: #fff;
    fill: #fff;
  }
}

.box-title-content {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100% - 40px);
}

.huge-sumary {
  table {
    border-spacing: 0;
  }
  tr:nth-child(even) {
    background: rgba(0, 0, 0, 0.05);
  }
  td {
    font-size: 22px;
    line-height: 35px;
    padding: 5px;
  }
  .text-h3 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 55px);
    float: left;
    font-size: 50px;
  }
  .summary-dialog-icon,
  .summary-dialog-svg-icon svg {
    color: #fff;
    fill: #fff;
  }
}

.box-dialog {
  box-shadow: initial !important;
}
</style>
<style lang="scss" >
.box-item {
  table {
    td {
      font-size: 10px;
    }
  }
}
.huge-sumary {
  table {
    td {
      font-size: 18px;
      line-height: 35px;
      padding: 5px;
    }
  }
}
</style>