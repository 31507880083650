// It should be imported on module pages only!
const oreModulesHelperMixin = {
  data () {
    return {
        moduleConfigData: {
            code: "",
            name: "",
            color: "",
            color_soft: "",
            color_text: "",
            icon: "",
            icon_svg: "",
            order: 999
        }
    }
  },
  mounted () {},
  computed: {
    oreModuleConfig () {
      let configModule = this.$store.getters['ore/getModuleConfig']('')
      if (this.isset(configModule.code)) return configModule
      else return undefined
    }
  },
  methods: {
    initModuleConfig () {
        let moduleConfig = this.oreModuleConfig
        this.moduleConfigData = {
            code: moduleConfig.code,
            name: moduleConfig.name,
            color: moduleConfig.color,
            color_soft: moduleConfig.color_soft,
            color_text: moduleConfig.color_text,
            icon: moduleConfig.icon,
            icon_svg: moduleConfig.icon_svg,
            order: moduleConfig.order
        }
    },
    getModuleConfigTable (headers) {
      let _headers = this.$_.cloneDeep(headers)
      let configTable = {
        direction: 'v',
        headers: []
      }
      let moduleConfig = this.oreModuleConfig
      let moduleConfigTable = this.fastValidValue(
        'settingsConfig.table',
        undefined,
        moduleConfig
      )
      if (moduleConfigTable) {
        configTable['direction'] = this.fastValidValue(
          'direction',
          'v',
          moduleConfigTable
        )
        let headersConfig = this.fastValidValue(
          'headers',
          {},
          moduleConfigTable
        )
        for (let item in _headers) {
          let searchWord = _headers[item].value
          switch (moduleConfig.code) {
            case 'MMT':
              switch (searchWord) {
                case 'product_type':
                  searchWord = 'description'
                  break
                case 'started':
                  searchWord = 'starting_qty'
                  break
                case 'received':
                  searchWord = 'received_qty'
                  break
                case 'consumed':
                  searchWord = 'consumed_qty'
                  break
                case 'sold':
                  searchWord = 'sold_qty'
                case 'remaining':
                  searchWord = 'remaining_qty'
                  break
              }
              break
          }
          if (
            headersConfig[searchWord] &&
            headersConfig[searchWord].visible &&
            searchWord !== 'options'
          ) {
            configTable['headers'].push(_headers[item])
          }
        }
      }
      let x = this.$_.find(_headers, (x) => x.value === "options");
      if (x) configTable['headers'].push(x);
      return configTable
    },
    getModuleConfigForm (configForm) {
        let _configForm = this.$_.cloneDeep(configForm)
        let moduleConfig = this.oreModuleConfig
        let moduleConfigForm = this.fastValidValue(
          'settingsConfig.form',
          {},
          moduleConfig
        )
        let config = { ..._configForm, ...moduleConfigForm };
        return config
      },
  },
//   watch: {
//    // para reemplazar el llamado auto de initModuleConfig en cada modulo
      // to override the auto call of initModuleConfig in each module
//     'ore/getModuleCode'(value) {
//       if (value) {
//         this.initModuleConfig();
//       }
//     },
//   },
}
export default oreModulesHelperMixin
