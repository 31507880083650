import { createQueryBuilder, getRepository } from "typeorm";
import { baseApi, isConfigMobile, getCustomerJWT, parseDate, updateReportAuditFields } from './base'
import axios from 'axios'
import store from "@/store"
import moment from "moment";

/**
 * It is used to get the record from start stop table
 * @param {Number} reportId - Report Id.
 * @returns {Object} The respective record.
 */
export function getStartStopReport(report) {
    let isMobile = isConfigMobile()
    if (isMobile) {
        return new Promise(async function (resolve) {
            const startStop = await getRepository("oreReportStartStop");
            await startStop.findOne({ report: report, delete: null })
                .then(response => {
                    if (response.production_no == false) {
                        response.production_start = moment(response.production_start).format("YYYY-MM-DD HH:mm")
                        response.production_end = moment(response.production_end).format("YYYY-MM-DD HH:mm")
                        response.shift_start = moment(response.shift_start).format("YYYY-MM-DD HH:mm")
                        response.shift_end = moment(response.shift_end).format("YYYY-MM-DD HH:mm")
                    }
                    if (typeof (response) == 'undefined') response = {}; resolve(response)
                })
                .catch(error => { })
        })
    } else {
        return new Promise(function (resolve, reject) {
            const token = getCustomerJWT()
            axios.get(baseApi() + '1.0/ore/report/startstop/report/' + report + "/", { headers: { Authorization: `Bearer ${token}` } })
                .then(response => resolve(response.data))
                .catch(error => reject(error))
        })
    }
}

/**
 * It creates a new record in the start stop table
 * @param {Object} data - Object with data to save
 * @returns {Object} The respective start stop record.
 */
export async function createStartStop(data) {
    let isMobile = isConfigMobile()
    if (isMobile) {
        const startStop = await getRepository("oreReportStartStop")
        let now = new Date();
        let user_id = store.getters["auth/getAuthCustomer"].data.id
        data.update = now;
        data.update_by = user_id;
        data.created = now;
        data.created_by = user_id;
        if (data.production_no == false) {
            data.production_start = new Date(data.production_start).toISOString();
            data.production_end = new Date(data.production_end).toISOString();
            data.shift_start = new Date(data.shift_start).toISOString();
            data.shift_end = new Date(data.shift_end).toISOString();
        } else {
            data.production_start = null;
            data.production_end = null;
            data.shift_start = null;
            data.shift_end = null;
        }
        await startStop.save(data)
            .then(response => { store.commit("ore/setUpdateReportsSelected", { 'start_stop': response }); return sync([response]); })
            .catch(error => reject(error))
    } else {
        return new Promise(function (resolve, reject) {
            const token = getCustomerJWT()
            axios.post(baseApi() + '1.0/ore/report/startstop/', data, { headers: { Authorization: `Bearer ${token}` } })
                .then(response => resolve(response.data))
                .catch(error => reject(error))
        })
    }
}

/**
 * It updates a record in the start stop table
 * @param {Number} id - Start stop Id.
 * @param {Object} data - Object with data to update
 * @returns {Object} The respective start stop record.
 */
export async function updateStartStop(id, data) {
    let isMobile = isConfigMobile()
    let user_id = store.getters["auth/getAuthCustomer"].data.id
    if (isMobile) {
        if (data.production_no == false) {
            data.production_no_razon = null;
            data.production_start = moment(data.production_start).toISOString()
            data.production_end = moment(data.production_end).toISOString()
            data.shift_start = moment(data.shift_start).toISOString()
            data.shift_end = moment(data.shift_end).toISOString()
        } else {
            data.production_start = null;
            data.production_end = null;
            data.running_time = null;
            data.shift_start = moment(data.shift_start).toISOString()
            data.shift_end = moment(data.shift_end).toISOString()
        }
        const startStop = await getRepository("oreReportStartStop");
        await startStop.update(id,
            {
                ...data,
                update: new Date(),
                sync: false
            },
        ).then(response => { return response; })
            .catch(error => { })

        await startStop.findOne({ id: id })
            .then(response => {
                if (response.production_no == false) {
                    response.production_start = moment(response.production_start).format("YYYY-MM-DD HH:mm")
                    response.production_end = moment(response.production_end).format("YYYY-MM-DD HH:mm")
                    response.shift_start = moment(response.shift_start).format("YYYY-MM-DD HH:mm")
                    response.shift_end = moment(response.shift_end).format("YYYY-MM-DD HH:mm")
                } else {
                    response.production_start = null
                    response.production_end = null
                    response.shift_start = moment(response.shift_start).format("YYYY-MM-DD HH:mm")
                    response.shift_end = moment(response.shift_end).format("YYYY-MM-DD HH:mm")
                }
                store.commit("ore/setUpdateReportsSelected", { 'start_stop': response });
                return sync([response]);
            }).catch(error => { })
    } else {
        return new Promise(function (resolve, reject) {
            const token = getCustomerJWT()
            axios.put(baseApi() + '1.0/ore/report/startstop/' + id + "/", data, { headers: { Authorization: `Bearer ${token}` } })
                .then(response => resolve(response.data))
                .catch(error => reject(error))
        })
    }
}

/**
 * It is used to synchronize data from start stop module.
 * It is send from mobile aplication to web aplication
 * @param {Object} data - Object with data to send to web application
 * @returns {Object} The respective start stop record.
 */
async function sync(data) {
    store.commit("comun/setSynchronizing", true);
    let checkOnLine = await store.getters["comun/getIsOnline"];

    const reportRepository = getRepository("oreReport");
    const startStop = await getRepository("oreReportStartStop");
    let data_start_stop = parseDate(data);

    let reportData = await reportRepository.find({ id: data_start_stop[0].report }).then(res => { return parseDate(res) })
    await reportRepository.update(reportData[0].id, { sync: false, }).then((res) => { }).catch(error => { })

    if (checkOnLine) {
        let objToSync = {
            reports: [
                {
                    report: reportData[0],
                    data: { start_stop: data_start_stop[0] }
                }
            ],
        }

        const token = getCustomerJWT()
        await axios.post(baseApi() + '1.0/ore/mobile/sync/', objToSync, { headers: { Authorization: `Bearer ${token}` } })
            .then(async function (response) {
                let id = data_start_stop[0].id
                let reportRes = response.data.reports[0].report
                let dataRes = response.data.reports[0].data

                await updateReportAuditFields(reportRes, reportRepository, reportData[0].id)
                if ("start_stop" in dataRes) {
                    let _items = [dataRes.start_stop]
                    _items.forEach(async (item) => {
                        //If the sync is rejected, the item is updated with the information that comes from web.
                        if (item.action_msg == "reject") {
                            await startStop.update(id,
                                {
                                    ...item,
                                    created: new Date(item.created),
                                    update: new Date(item.update),
                                    update_web: new Date(item.update_web),
                                    sync: true,
                                },
                            ).then((res) => { }).catch(error => { })
                        } else {
                            //If it is not rejected and the sync was successful, the variables are updated.
                            if (item.action_status) {
                                await startStop.update(id,
                                    {
                                        web_id: item.web_id,
                                        update_web: new Date(item.update_web),
                                        sync: true,
                                    },
                                ).then((res) => { }).catch(error => { })
                            }
                        }
                    })
                }

                store.commit("comun/setSynchronizing", false);
                return new Promise(async function (resolve) {
                    await startStop.findOne({ id: data_start_stop[0].id }).then(response => { resolve(response) })
                })

            }).catch(error => {
                store.commit("comun/setSynchronizing", false);
                return new Promise(async function (resolve) {
                    await startStop.findOne({ id: data_start_stop[0].id }).then(response => { resolve(response) })
                })
            })
    } else {
        store.commit("comun/setSynchronizing", false);
        return new Promise(async function (resolve) {
            await startStop.findOne({ id: data_start_stop[0].id }).then(response => { resolve(response) })
        })
    }
}
