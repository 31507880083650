<template>
  <div>
    <exp-alert-confirm
      ref="deleteSummaryMaterial"
      :colorCancel="'green'"
      :message="msg_delete"
      :fnOk="(_id) => clkFunction('_dt_confirm_delete', _id)"
    />
    <exp-ore-modal
      ref="materialSummaryModal"
      :openModal="showDialog"
      @onCloseModal="onCloseModal"
      width="1000px"
      height="650px"
      :actionsConfig="{ align_actions: 'center', action_accept: false }"
    >
      <template v-slot:title>
        <v-card-title
          class="justify-center"
          :style="`color: ${parentModuleConfig.color};`"
        >
          {{ `${$t("ore.report.materials_summary")}` }}
        </v-card-title>
      </template>
      <template v-slot:body>
        <v-card
          flat
          class="card-bitumenreception"
          height="500px"
          :style="`border: 1px solid ${parentModuleConfig.color}; display: flex;`"
        >
          <exp-report-table
            class="material-table"
            :style="`width: 100%; margin-top: -8px;`"
            :direction="'v'"
            :headers="materialsSummaryDtHeaders"
            :items="materialsSummaryItems"
            :funcEdit="(_id) => clkFunction('_dt_action_edit', _id)"
            :funcDelete="(_id) => clkFunction('_dt_action_delete', _id)"
            :readOnly="storeOnlyReadReport"
            :optionsButtons="{ edit: true, delete: (storeOnlyReadReport == false) }"
            clickableItem
            :tableHeight="'calc(100%)'"
            @onClickItem="(item) => clkFunction('_select_material', item.id)"
          />
        </v-card>
        <v-card flat>
          <v-row no-gutters>
            <v-col cols="6"></v-col>
            <v-col cols="6">
              <v-row no-gutters class="text-right">
                <v-col cols="6">
                  <span class="font-weight-bold">
                    {{ `Total` }}
                  </span>
                </v-col>
                <v-col cols="3">
                  <span class="font-weight-bold">
                    {{ `${numberToFormatNumeral(summaryActivitiesTotalQTYPositive())}` }}
                  </span>
                </v-col>
                <v-col cols="3">
                  <span class="font-weight-bold">100 %</span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </template>
    </exp-ore-modal>
  </div>
</template>

<script>
import basicMixin from "@/mixins/basic";
import oreMixin from "@/mixins/ore";
import customersMixin from "@/mixins/customer";
import datatableMixin from "@/mixins/datatable";

import expOreModal from "@/components/expOreModal";
import expReportTable from "@/components/expReportTable";

export default {
  name: "material-summary-modal",
  mixins: [basicMixin, customersMixin, oreMixin, datatableMixin],
  components: { expOreModal, expReportTable },
  data() {
    return {
      showDialog: false,
      msg_delete: "ore.report.confirm_delete_material",
      materialsSummaryDtHeaders: [
        {
          text: "ore.report.scenario",
          value: "scenario",
          alignh: "text-left",
          alignb: "text-left",
        },
        {
          text: "ore.report.source",
          value: "source",
          alignh: "text-left",
          alignb: "text-left",
        },
        {
          text: "ore.report.created_date",
          value: "created",
          width: "120px",
          alignh: "text-center",
          alignb: "text-center",
        },
        {
          text: "ore.report.total_materials_produced",
          value: "total_materials_produced",
          width: "120px",
          alignh: "text-center",
          alignb: "text-center",
        },
        {
          text: "ore.report.proportion",
          value: "proportion",
          width: "120px",
          alignh: "text-center",
          alignb: "text-center",
        },
        {
          text: "core.comun.options",
          value: "options",
          width: "70px",
          alignh: "text-center",
          alignb: "text-center",
        },
      ],
    };
  },
  props: {
    parentModuleConfig: {
      type: Object,
      default: () => ({
        color: "",
        color_text: "",
      }),
    },
    materialsSummaryItems: {
      type: Array,
      default: () => [],
    },
    sourceList: {
      type: Array,
      default: () => [],
    },
    config: {
      type: Object,
      default: () => ({
        color: "",
        color_text: "",
      }),
    },
  },
  methods: {
    onCloseModal() {
      this.showDialog = false;
    },
    async clkFunction(accion, payload) {
      const self = this;
      let _validForm = false;
      switch (accion) {
        case "_dt_action_edit":
          self.$emit("parentActions", "_edit_material", payload);
          break;
        case "_select_material":
          self.$emit("parentActions", "_select_material", payload);
          self.showDialog = false; // test on select item
          break;
        case "_dt_action_delete":
          self.$refs.deleteSummaryMaterial.id = payload;
          self.$refs.deleteSummaryMaterial.showDialog = true;
          break;
        case "_dt_confirm_delete":
          self.$emit("parentActions", "_delete_material", payload);
          break;
        default:
          break;
      }
    },
  }
};
</script>
