<template>
  <div class="colas-centercontainer">
    <v-card elevation="0" :color="'transparent'">
      <v-card-text>
        <div
          :style="'height:' + tableHeight + 'px'"
          class="white dash-table"
        >
          <v-simple-table :height="tableHeight - 55">
            <thead>
              <tr>
                <th
                  v-for="(header, i) in headers"
                  :key="i"
                  :style="
                    'width: ' +
                    header.width +
                    ';background-color:' +
                    (header.value == 'options' ? '#feec00ff' : '')
                  "
                  :class="header.alignh"
                  class="pa-1"
                >
                  {{ $t(header.text) }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, j) in itemsTable"
                :key="j"
                :class="'color_bg color_bg_' + item.status"
                :style="rowColorStatus(item)"
              >
                <td
                  v-for="(header, k) in headers"
                  :key="k"
                  :style="
                    'width:' +
                    header.width +
                    ';background-color:' +
                    (header.value == 'options' ? '#feec00ff' : '')
                  "
                  :class="header.alignb"
                  class="pa-1"
                >
                  <template v-if="header.value == 'options'">
                    <v-icon
                      @click="editReport(item)"
                      class="icon-option"
                      v-if="reportOnlyRead(item.status)"
                      >mdi-eye</v-icon
                    >
                    <v-icon
                      @click="editReport(item)"
                      class="icon-option"
                      :disabled="statusEditBtn(item)"
                      v-else
                      >mdi-pencil
                    </v-icon>
                    <v-icon
                      @click="confirmDeleteReport(item.id)"
                      class="icon-option"
                      :disabled="statusDeleteBtn(item) || reportOnlyRead(item.status)"
                      >mdi-delete</v-icon
                    >
                  </template>
                  <template v-else-if="header.value == 'date'">
                    <div :style="'width:' + header.width">
                      {{ $moment(item.date).utc().format(formatDate) }}
                    </div>
                    {{ showId ? '(' + item.id + ')' : '' }}
                  </template>
                  <template v-else-if="header.value == 'site_crush_camp'">
                    <div
                      v-for="value, index in item.site_crush_camp" :key="index"
                      :style="'padding-left:10px;' +
                      'border-top:' + (index==1 ? '1px solid white;' : 'none;') +
                      'border-bottom:' + (index==1 ? '1px solid white;' : 'none;') +
                      'width:' + header.width"
                    >
                      {{ value }}
                    </div>
                  </template>
                  <template v-else-if="header.value == 'scenario'">
                    <div v-show="showFlowSheet" :style="'width:' + header.width + '; position: relative; top: -3px;'">
                      <v-btn
                        v-show="item.flowsheet"
                        rounded
                        @click="openModalImages(item.scenario_id)"
                        x-small
                        style="min-width: 0; float: right"
                        color="info"
                      >
                        <v-icon small>mdi-image</v-icon>
                      </v-btn>
                    </div>
                    <div :style="'white-space: normal;width:' + header.width + '; height:' + (item.flowsheet ? '53%;' : 'auto;')" >
                      {{ item.scenario }}
                    </div>
                  </template>
                  <template v-else-if="header.value == 'shift'">
                    <v-icon
                      large
                      color="orange"
                      v-if="item[header.value] == 1"
                      >mdi-weather-sunny</v-icon
                    >
                    <v-icon large color="purple" v-else
                      >mdi-weather-night</v-icon
                    >
                  </template>
                  <template v-else-if="header.value == 'status'">
                    <div :style="'width:' + header.width">
                      <v-btn
                        v-if="item.status == 1"
                        rounded
                        @click="openModalStatus(item.id)"
                        x-small
                        style="min-width: 0; float: left"
                        color="success"
                      >
                        <v-icon x-small>mdi-lock-open</v-icon>
                      </v-btn>
                      <span @click="openModalStatusLog(item.id)">
                        {{ $t(rowNameStatus(item)) }}
                      </span>
                      <v-btn
                        v-if="item.status == 2"
                        rounded
                        @click="openModalStatus(item.id)"
                        x-small
                        style="min-width: 0; float: right"
                        color="error"
                      >
                        <v-icon x-small>mdi-lock</v-icon>
                      </v-btn>
                    </div>
                  </template>
                  <template v-else-if="header.value == 'responsible'">
                    <div
                      :style="'width:' + header.width"
                      :title="item.responsible"
                    >
                      {{ item.responsible }}
                    </div>
                  </template>
                  <template v-else>
                    <div :style="'width:' + header.width">
                      {{ item[header.value] }}
                    </div>
                  </template>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
          <div class="paginator">
            <v-row>
              <v-col cols="8" class="pt-4">
                <div style="float: left">
                  <v-pagination
                    v-if="infoPagination.page > 0"
                    v-model="infoPagination.page"
                    :length="infoPagination.pagesPagination"
                    :total-visible="7"
                    @input="filterReport"
                    class="btnPagination"
                  />
                  <div class="infoPagination">{{ dataControlPagination }}</div>
                </div>
              </v-col>              <slot></slot>
            </v-row>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import basicMixin from "@/mixins/basic";
import customersMixin from "@/mixins/customer";
import datatableMixin from "@/mixins/datatable";
import oreMixin from "@/mixins/ore";

export default {
  auth: false,
  name: "page-index-table",
  layout: "colas",
  mixins: [basicMixin, customersMixin, datatableMixin, oreMixin],
  components: {
  },
  data() {
    return {
      headers: [
        {
          text: "ore.report.site_crush_camp",
          value: "site_crush_camp",
          alignh: "text-center",
          alignb: "text-left",
        },
        {
          text: "ore.report.scenario",
          value: "scenario",
          alignh: "text-center",
          alignb: "text-left",
        },
        {
          text: "ore.report.date",
          value: "date",
          width: "90px",
          alignh: "text-center",
          alignb: "text-center",
        },
        {
          text: "ore.report.shift",
          value: "shift",
          width: "70px",
          alignh: "text-center",
          alignb: "text-center",
        },
        {
          text: "ore.report.status",
          value: "status",
          width: "100px",
          alignh: "text-center",
          alignb: "text-center",
        },
        {
          text: "ore.report.created_by",
          value: "responsible",
          width: "180px",
          alignh: "text-center",
          alignb: "text-right",
        },
        {
          text: "core.comun.options",
          value: "options",
          width: "85px",
          alignh: "text-center",
          alignb: "text-center",
        },
      ]
    };
  },
  props: {
    formatDate: {
      type: String,
      default: "YYYY-MM-DD",
    },
    itemsTable: {
      type: Array,
      default: (value) => {
        return [];
      },
    },
    infoPagination: {
      type: Object,
      default: (value) => {
        return {};
      },
    },
    optionsPagination: {
      type: Object,
      default: (value) => {
        return {};
      },
    },
    itemsStatusReports: {
      type: Array,
      default: (value) => {
        return [];
      },
    },
    showId: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    filterReport(page = 1) {
      this.$emit("filtersReport", page);
    },
    statusEditBtn(item) {
      if (this.userType >= 5) {
        return !(item.status == 2 || item.status == 3);
      } else if (this.userType == 4) {
        return !(item.status == 2 || item.status == 3 || item.status == 4);
      } else {
        return false;
      }
    },
    statusDeleteBtn(item) {
      if (this.userType >= 5) {
        return !(item.status == 2 || item.status == 3);
      } else if (this.userType == 4) {
        return !(item.status == 2 || item.status == 3);
      } else {
        return false;
      }
    },
    rowColorStatus(item) {
      let _status = this.itemsStatusReports.find((x) => x.id == item.status);
      return "background-color: " + (_status != undefined ? _status.color : '#FFFFFF') + "44";
    },
    rowNameStatus(item) {
      let _status = this.itemsStatusReports.find((x) => x.id == item.status);
      return _status != undefined ? _status.name : '';
    },
    showFlowSheet() {
      return this.isset(this.filters.states.find(x => x === 101))
    },
    editReport(item) {
      this.$emit("editReport", item);
    },
    openModalStatus(id) {
      this.$emit("openModalStatus", id);
    },
    openModalImages(id) {
      this.$emit("openModalImages", id);
    },
    openModalStatusLog(id) {
      this.$emit("openModalStatusLog", id);
    },
    confirmDeleteReport(id) {
      this.$emit("confirmDeleteReport", id);
    },
  },
  computed: {
    tableHeight() {
      return this.heightScreen > 768 ? 500 : this.heightScreen - 340;
    },
    dataControlPagination() {
      let _showning = 0;
      let _start = 0;
      let _end = 0;
      let _total = 0;
      let _page = 0;
      try {
        _total = this.infoPagination.totalRecords || 0;
        _page = this.infoPagination.page > 0 ? this.infoPagination.page : 1;
      } catch (error) {}
      if (this.isLoading == false) {
        try {
          _start =
            this.itemsTable && this.itemsTable.length > 0
              ? _page === 1
                ? _page
                : 1 + (_page - 1) * this.optionsPagination.length
              : 0;
        } catch (e) {
          _start = 0;
        }
        try {
          _showning = this.itemsTable.length;
        } catch (e) {
          _showning = 0;
        }
        _end = _start > 0 ? _start + _showning - 1 : 0;
      }
      if (_total > 0)
        return `${this.$t("core.comun.showing")} ${_start} ${this.$t(
          "core.comun.to"
        )} ${_end} ${this.$t("core.comun.of")} ${_total}`;
      else return `${this.$t("core.comun.dt_no_data_show")}`;
    },
  }
};
</script>