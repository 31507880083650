<template>
  <v-dialog v-model="modalDateTime" :width="dialogWidth">
    <template v-slot:activator="{ on }">
      <v-text-field
        :disabled="disabled"
        :label="label"
        :value="dateTimeInputFormatted"
        v-on="on"
        readonly
        dense
        outlined
        hide-details
        :backgroundColor="backgroundColor"
        :class="hiddeTextDisabled ? 'hidde-text-disabled' : ''"
        :error-messages="errorMessages"
      />
      <span style="color: red">{{ errorMessages[0] }}</span>
    </template>
    <v-card class="expDateTime" :class="type">
      <v-toolbar color="primary" dark class="text-h6">
        {{ dateTimeSelectedFormatted }}
      </v-toolbar>
      <v-card-text class="pa-2">
        <template v-if="!visibleTime">
          <v-skeleton-loader
            v-if="type === 'date' || type === 'datetime'"
            boilerplate
            type="date-picker-options, date-picker-days"
          ></v-skeleton-loader>
          <v-skeleton-loader
            v-if="type === 'time'"
            boilerplate
            type="sentences, sentences, sentences, sentences"
          ></v-skeleton-loader>
        </template>
        <VueCtkDateTimePicker
          v-else
          :id="name + 'VueCtkDateTimePicker'"
          :behaviour="{
            time: {
              nearestIfDisabled: false,
            },
          }"
          inline
          no-keyboard
          no-header
          v-model="dateTimeSelected"
          :format="formatDateTimeSelected"
          :onlyDate="showDate"
          :onlyTime="showTime"
          :minDate="minDate"
          :maxDate="maxDate"
          @input="inputDateTime"
        />
      </v-card-text>
      <v-card-actions class="pa-0 ma-0">
        <slot name="actions">
          <v-row class="ma-0 pa-0">
            <v-col
              :cols="type == 'time' ? 12 : 6"
              class="pa-2"
              :class="type == 'time' ? 'text-center' : 'text-left'"
            >
              <v-btn rounded color="green" @click="okEvent()">
                <v-icon left>mdi-check</v-icon>
                {{ $t(okText) }}
              </v-btn>
            </v-col>
            <v-col
              :cols="type == 'time' ? 12 : 6"
              class="ma-0 pa-2"
              :class="type == 'time' ? 'text-center' : 'text-right'"
            >
              <v-btn rounded color="red" @click="cancelEvent()">
                <v-icon left>mdi-close-circle</v-icon>
                {{ $t(cancelText) }}
              </v-btn>
            </v-col>
          </v-row>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
export default {
  name: "exp-datetime",
  components: {
    VueCtkDateTimePicker,
  },
  data() {
    return {
      modalDateTime: false,
      visibleTime: false,
      dateTimeSelected: null,
      dateTimeClicked: false,
    };
  },
  props: {
    value: {
      type: [Date, String],
      default: null,
    },
    type: {
      type: String,
      default: "date",
    },
    backgroundColor: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hiddeTextDisabled: {
      type: Boolean,
      default: false,
    },
    formatDate: {
      type: String,
      default: "YYYY-MM-DD",
    },
    formatTime: {
      type: String,
      default: "HH:mm",
    },
    formatDateTime: {
      type: String,
      default: "YYYY-MM-DD HH:mm",
    },
    okText: {
      type: String,
      default: "core.comun.accept",
    },
    cancelText: {
      type: String,
      default: "core.comun.cancel",
    },
    name: {
      type: String,
      default: "VueCtkDateTimePicker",
    },
    errorMessages: {
      type: Array,
      default() {
        return [];
      },
    },
    maxDate: null,
    minDate: null,
  },
  computed: {
    dateTimeInputFormatted() {
      let _toFormatDate;
      if (this.type === "time") {
        if (this.value != null && this.value != undefined && this.value != "") {
          _toFormatDate = "1903-01-01 " + this.value;
        } else {
          _toFormatDate = this.$moment();
        }
      } else {
        _toFormatDate = this.value;
      }
      var _formatDate = this.$moment(_toFormatDate);
      if (!_formatDate.isValid()) {
        _formatDate = this.$moment();
      }
      if (this.value)
        return this.$moment(_formatDate).format(this.formatDateTimeOutput);
      else return "";
    },
    dateTimeSelectedFormatted() {
      if (this.type === "time") {
        if (this.dateTimeSelected == null) {
          return this.$moment().format(this.formatDateTimeOutput);
        } else {
          return this.$moment("1901-01-01 " + this.dateTimeSelected).format(
            this.formatDateTimeOutput
          );
        }
      } else {
        return this.$moment(this.dateTimeSelected).format(
          this.formatDateTimeOutput
        );
      }
    },
    showDate() {
      return this.type === "date";
    },
    showTime() {
      return this.type === "time";
    },
    formatDateTimeSelected() {
      if (this.type === "datetime") return "YYYY-MM-DD HH:mm:ss";
      else if (this.type === "date") return "YYYY-MM-DD";
      else if (this.type === "time") return "HH:mm:ss";
    },
    formatDateTimeOutput() {
      if (this.type === "datetime") return this.formatDateTime;
      else if (this.type === "date") return this.formatDate;
      else if (this.type === "time") return this.formatTime;
    },
    dialogWidth() {
      if (this.type === "time") return 180;
      else if (this.type === "datetime") return 450;
      else return 320;
    },
  },
  methods: {
    inputDateTime(e) {
      if (e) this.dateTimeClicked = true;
    },
    processSettings() {
      let _value = this.$_.clone(this.value);
      if (this.type === "time") {
        if (_value == null) {
          this.dateTimeSelected = this.$moment().format(this.formatTime);
        } else {
          _value = "1902-01-01 " + _value;
        }
        _value = this.$moment().format(this.formatDateTimeSelected);
      } else {
        if (!this.$moment(_value).isValid) {
          _value = this.$moment().format(this.formatDateTimeSelected);
        }
        if (this.minDate != null) {
          if (_value < this.minDate) {
            _value = this.minDate;
          }
        }
        if (this.maxDate != null) {
          if (_value > this.maxDate) {
            _value = this.maxDate;
          }
        }
        if (this.dateTimeSelected != _value) {
          this.dateTimeSelected = _value;
          this.okEvent();
        }
      }
    },
    okEvent() {
      let sendDateTime = "";
      if (!this.value && !this.dateTimeClicked)
        sendDateTime = this.$moment().format("HH:mm");
      else sendDateTime = this.dateTimeSelected;
      this.$emit("input", sendDateTime);
      this.$emit("change", sendDateTime);
      this.modalDateTime = false;
    },
    cancelEvent() {
      this.modalDateTime = false;
    },
  },
  watch: {
    value(newVal, oldVal) {
      if (newVal === oldVal) {
        return;
      }
      if (!newVal || (newVal != oldVal && !this.modalDateTime)) {
        this.visibleTime = false;
      }
      this.processSettings();
    },
    maxDate(newVal, oldVal) {
      if (newVal === oldVal) {
        return;
      }
      this.processSettings();
    },
    minDate(newVal, oldVal) {
      if (newVal === oldVal) {
        return;
      }
      this.processSettings();
    },
    modalDateTime(newVal, oldVal) {
      if (newVal === oldVal) {
        return;
      }
      this.dateTimeSelected = this.value;
      this.processSettings();
      if (!newVal) {
        this.dateTimeClicked = false;
      } else {
        setTimeout(() => {
          this.visibleTime = true;
        }, 300);
      }
    },
    displayDateTimeModal() {
      if (this.type === "date") this.isOnlyDate = true;
      if (this.type === "time") this.isOnlyTime = true;
      if (this.type === "datetime") this.isDataTime = true;
      this.init();
    },
  },
};
</script>
