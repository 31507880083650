<template>
  <div>
    <exp-ore-modal
      ref="materialSummaryModal"
      :openModal="showDialog"
      @onCloseModal="onCloseModal"
      width="1000px"
      height="650px"
      :noTitle="true"
      :actionsConfig="{ align_actions: 'center', action_accept: false }"
    >
      <template v-slot:body>
        <v-card height="100%" tile flat class="d-flex flex-column">
          <v-card
            flat
            height="350px"
            class="d-flex card-bitumenreception"
            :style="`border: 1px solid ${parentModuleConfig.color};`"
          >
            <exp-report-table
              :style="`width: 100%; margin-top: -8px;`"
              :direction="'v'"
              :headers="materialsSummaryActivitiesDtHeaders"
              :items="activitiesItemsDT"
              :funcEdit="(_id) => clkFunction('_dt_action_edit', _id)"
              :funcDelete="(_id) => clkFunction('_dt_action_delete', _id)"
              :readOnly="storeOnlyReadReport"
              :optionsButtons="{ edit: true, delete: true }"
            />
          </v-card>
          <v-card flat class="mt-1" :style="`overflow: auto;`">
            <v-card-text>
              <v-row dense class="d-flex align-center">
                <v-col cols="4"></v-col>
                <v-col cols="4">
                  <v-card
                    class="pa-1"
                    :style="`background: ${parentModuleConfig.color}; color: ${parentModuleConfig.color_text};`"
                    flat
                    tile
                  >
                    <span> {{ $t("ore.report.total_crushed_products") }}</span>
                  </v-card>
                </v-col>
                <v-col cols="4">
                  <v-row dense class="d-flex align-center">
                    <v-col cols="6">
                      <v-card
                        class="pa-1 text-right"
                        :style="`background: ${parentModuleConfig.color}; color: ${parentModuleConfig.color_text};`"
                        flat
                        tile
                      >
                        <span>{{
                          `${numberToFormatNumeral(
                            summaryActivitiesTotalQTY()
                          )}`
                        }}</span>
                      </v-card>
                    </v-col>
                    <v-col cols="6">
                      <v-card
                        class="pa-1 text-right"
                        :style="`background: ${parentModuleConfig.color}; color: ${parentModuleConfig.color_text};`"
                        flat
                        tile
                      >
                        <span>{{
                          `${numberToPercent(
                            summaryActivitiesTotalProportion()
                          )} %`
                        }}</span>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row dense class="d-flex align-center">
                <v-col cols="4"></v-col>
                <v-col cols="4">
                  <v-card
                    class="pa-1"
                    :style="`background: ${parentModuleConfig.color}; color: ${parentModuleConfig.color_text};`"
                    flat
                    tile
                  >
                    <span>{{ $t("ore.report.total_material_produced") }} </span>
                  </v-card>
                </v-col>
                <v-col cols="4">
                  <v-row dense class="d-flex align-center">
                    <v-col cols="6">
                      <v-card
                        class="pa-1 text-right"
                        :style="`background: ${parentModuleConfig.color}; color: ${parentModuleConfig.color_text};`"
                        flat
                        tile
                      >
                        <span>{{
                          `${numberToFormatNumeral(
                            summaryActivitiesTotalQTYPositive()
                          )}`
                        }}</span>
                      </v-card>
                    </v-col>
                    <v-col cols="6">
                      <v-card
                        class="pa-1 text-right"
                        :style="`background: ${parentModuleConfig.color}; color: ${parentModuleConfig.color_text};`"
                        flat
                        tile
                      >
                        <span>&nbsp;</span>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row dense class="d-flex align-center">
                <v-col cols="4">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <div class="text_ellipsis" v-on="on">
                        {{ $t("ore.report.ttl_crsh_prd_pmy_crsh_qty") }}
                      </div>
                    </template>
                    <span>{{
                      $t("ore.report.ttl_crsh_prd_pmy_crsh_qty")
                    }}</span>
                  </v-tooltip>
                </v-col>
                <v-col cols="4">
                  <v-card
                    class="pa-1 text-right"
                    :style="`background: ${parentModuleConfig.color}; color: ${parentModuleConfig.color_text};`"
                    flat
                    tile
                  >
                    <span>{{
                      numberToFormatNumeral(
                        summaryActivitiesTotalCrusherProductsPrimaryQty()
                      )
                    }}</span>
                  </v-card>
                </v-col>
                <v-col cols="4"> </v-col>
              </v-row>
              <v-row dense class="d-flex align-center" v-if="false">
                <v-col cols="4" class="pb-0">{{
                  $t("ore.report.surge_pile_variation")
                }}</v-col>
                <v-col cols="4" class="pb-0">
                  <v-card
                    class="pa-1 text-right"
                    :style="`background: ${parentModuleConfig.color}; color: ${parentModuleConfig.color_text};`"
                    flat
                    tile
                  >
                    <span>{{
                      numberToFormatNumeral(
                        summaryActivitiesTotalCrusherProductsPrimaryQty()
                      )
                    }}</span>
                  </v-card>
                </v-col>
                <v-col cols="4"></v-col>
              </v-row>
              <template v-for="_item in Object.keys(recrushinGlobalData)">
                <v-row
                  :key="recrushinGlobalData[_item].id"
                  v-if="recrushinGlobalData[_item].activity_verification"
                  dense
                  class="d-flex align-center"
                >
                  <v-col cols="4"
                    >{{ $t("ore.report.verfi_act") }}
                    {{ recrushinGlobalData[_item].name }}</v-col
                  >
                  <v-col cols="4">
                    <v-card
                      class="pa-1 text-right"
                      :style="`background: ${
                        recrushinGlobalData[_item].alert_color &&
                        recrushinGlobalData[_item].value > 0
                          ? 'red'
                          : parentModuleConfig.color
                      }; color: ${parentModuleConfig.color_text};`"
                      flat
                      tile
                    >
                      <span>{{
                        `${numberToFormatNumeral(
                          recrushinGlobalData[_item].value
                        )}`
                      }}</span>
                    </v-card></v-col
                  >
                  <v-col cols="4"> </v-col>
                </v-row>
              </template>
            </v-card-text>
          </v-card>
        </v-card>
      </template>
    </exp-ore-modal>
  </div>
</template>

<script>
import oreMixin from "@/mixins/ore";
import expOreModal from "@/components/expOreModal";
import expReportTable from "@/components/expReportTable";

export default {
  name: "material-summary-activities-modal",
  mixins: [oreMixin],
  components: { expOreModal, expReportTable },
  data() {
    return {
      showDialog: false,
      materialsSummaryActivitiesDtHeaders: [
        {
          text: "ore.report.source",
          value: "source",
          alignh: "text-center",
          alignb: "text-left",
        },
        {
          text: "ore.report.activities",
          value: "activity",
          alignh: "text-center",
          alignb: "text-center",
        },
        {
          text: "ore.report.code",
          value: "code",
          width: "140px",
          alignh: "text-center",
          alignb: "text-center",
        },
        {
          text: "ore.report.products",
          value: "product",
          alignh: "text-center",
          alignb: "text-center",
        },
        {
          text: "ore.report.quantity",
          value: "qty",
          width: "110px",
          alignh: "text-center",
          alignb: "text-center",
        },
        {
          text: "ore.report.total_products",
          value: "total_materials",
          width: "110px",
          alignh: "text-center",
          alignb: "text-center",
        },
      ],
    };
  },
  props: {
    parentModuleConfig: {
      type: Object,
      default: () => ({
        color: "",
        color_text: "",
      }),
    },
    activitiesItemsDT: {
      type: Array,
      default: () => [],
    },
    recrushinGlobalData: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    onCloseModal() {
      this.showDialog = false;
    },
  },
  computed: {},
  watch: {},
};
</script>
