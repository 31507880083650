import { getRepository } from "typeorm";
import { baseApi, isConfigMobile, getCustomerJWT, parseDate, updateReportAuditFields } from "./base";
import basicMixin from "@/mixins/basic";
import axios from "axios";
import store from "@/store";
import { deleteAllMaterialCrushingActivitiesBymaterialUUID } from "@/gateway/material_crushing_activities"
/**
 * It is used to get all records from materials crushing table.
 * @param {Number} reportId - Report Id.
 * @returns {Array} All records available in the report.
 */
export async function getAllMaterialCrushing(reportId) {
    let isMobile = isConfigMobile();
    if (isMobile) {
      return new Promise(async function (resolve) {
        return await getRepository(
          "oreReportMaterialCrushing"
        )
          .find({ report: reportId, deleted: null })
          .then((response) => {
            store.commit("ore/setUpdateReportsSelected", {
                material_crushing: response,
            });
            resolve(response);
          })
          .catch((error) => console.log(error));
      });
    } else {
        return new Promise(function (resolve, reject) {
            const token = getCustomerJWT();
            axios
              .get(
                baseApi() + "1.0/ore/report/material-crushing/all/" + reportId + "/",
                { headers: { Authorization: `Bearer ${token}` } }
              )
              .then((response) => resolve(response.data))
              .catch((error) => {
                reject(error);
              });
          });
    }
  }

/**
 * It gets a record from materials crushing to edit
 * @param {Number} id - Material Id.
 * @returns {Object} The record to edit
 */
export function getMaterialCrushing(id) {
  let isMobile = isConfigMobile();
  if (isMobile) {
    return new Promise(function (resolve) {
      const material = getRepository("oreReportMaterialCrushing")
        .findOne({ id: id, deleted: null })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  } else {
    return new Promise(function (resolve, reject) {
      const token = getCustomerJWT();
      axios
        .get(baseApi() + "1.0/ore/report/material-crushing/" + id + "/", {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => resolve(response.data))
        .catch((error) => {
          reject(error);
        });
    });
  }
}

/**
 * It makes a soft delete of a record from materials crushing table
 * @param {Number} id - Material Id.
 * @returns {Array} All records available in the report.
 */
export async function deleteMaterialCrushing(id) {
  let isMobile = isConfigMobile();
  if (isMobile) {
    const material = getRepository("oreReportMaterialCrushing");
    const materialParent = await material.findOne({ id: id, deleted: null })
    let user_id = store.getters["auth/getAuthCustomer"].data.id;

    await material
      .update(id, {
        update: new Date(),
        update_by: user_id,
        deleted: new Date(),
        deleted_by: user_id,
        sync: false,
      })
      .then(() => {
        deleteAllMaterialCrushingActivitiesBymaterialUUID(materialParent.uuid).then(() => {
        }).catch(e => {
          console.log("error deleted childs", e);
        })
      })
      .catch((error) => console.log(error));
    await material.find({ id: id }).then((res) => {
      return sync(res);
    });
  } else {
    return new Promise(function (resolve, reject) {
      const token = getCustomerJWT();
      axios
        .delete(baseApi() + "1.0/ore/report/material-crushing/" + id + "/", {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => resolve(response.data))
        .catch((error) => {
          reject(error);
        });
    });
  }
}

/**
 * It creates a new record in the materials crushing table
 * @param {Object} data - Object with data to save
 * @returns {Array} All records available in the report.
 */
export async function createMaterialCrushing(data) {
  data["uuid"] = basicMixin.methods.createUUID();
  data["report"] = store.getters["ore/getReportId"];
  let isMobile = isConfigMobile();
  if (isMobile) {
    const material = getRepository("oreReportMaterialCrushing");
    let now = new Date();
    let user_id = store.getters["auth/getAuthCustomer"].data.id;
    data.update = now;
    data.update_by = user_id;
    data.created = now;
    data.created_by = user_id;
    await material
      .save(data)
      .then((res) => {
        return sync([res]);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  } else {
    return new Promise(function (resolve, reject) {
      const token = getCustomerJWT();
      axios
        .post(baseApi() + "1.0/ore/report/material-crushing/", data, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => resolve(response.data))
        .catch((error) => {
          reject(error);
        });
    });
  }
}

/**
 * It updates a record in the materials crushing table
 * @param {Number} id - Material Id.
 * @param {Object} data - Object with data to update
 * @returns {Array} All records available in the report.
 */
export async function updateMaterialCrushing(id, data) {
  let isMobile = isConfigMobile();
  if (isMobile) {
    const material = await getRepository("oreReportMaterialCrushing");
    let user_id = store.getters["auth/getAuthCustomer"].data.id;
    await material.update(id, {
      source: data.source,
      primary_crushing_qty: data.primary_crushing_qty,
      number_of_loads: data.number_of_loads,
      campaign: data.campaign,
      scenario: data.scenario,
      started: data.started,
      update: new Date(),
      update_by: user_id,
      sync: false,
    });
    await material.find({ id: id, deleted: null }).then((res) => {
      return sync(res);
    });
  } else {
    return new Promise(function (resolve, reject) {
      const token = getCustomerJWT();
      axios
        .put(baseApi() + "1.0/ore/report/material-crushing/" + id + "/", data, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => resolve(response.data))
        .catch((error) => {
          reject(error);
        });
    });
  }
}

/**
 * It is used to synchronize data from materials module.
 * It is send from mobile aplication to web aplication
 * @param {Object} data - Object with data to send to web application
 * @returns {Array} All records available in the report.
 */
async function sync(data) {
  store.commit("comun/setSynchronizing", true);
  const reportRepository = getRepository("oreReport");
  const material = await getRepository("oreReportMaterialCrushing");
  let data_material = parseDate(data);

  let reportData = await reportRepository
    .find({ id: data_material[0].report })
    .then((res) => {
      return parseDate(res);
    });
  await reportRepository
    .update(reportData[0].id, { sync: false })
    .then((res) => {})
    .catch((error) => console.log(error));

  let checkOnLine = store.getters["comun/getIsOnline"];
  if (checkOnLine) {
    let objToSync = {
      reports: [
        {
          report: reportData[0],
          data: { material_crushing: data_material },
        },
      ],
    };
    const token = getCustomerJWT();
    await axios
      .post(baseApi() + "1.0/ore/mobile/sync/", objToSync, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(async function (response) {
        let id = data_material[0].id;
        let reportRes = response.data.reports[0].report;
        let dataRes = response.data.reports[0].data;

        await updateReportAuditFields(
          reportRes,
          reportRepository,
          reportData[0].id
        );
        if ("material_crushing" in dataRes) {
          let _items = [dataRes.material_crushing];
          //If the sync is rejected, the item is updated with the information that comes from web.
          _items.forEach(async (item) => {
            if (item.action_msg == "reject") {
              await material
                .update(id, {
                  source: item.source,
                  primary_crushing_qty: item.primary_crushing_qty,
                  number_of_loads: item.number_of_loads,
                  campaign: item.campaign,
                  scenario: item.scenario,
                  uuid: item.uuid,
                  created: new Date(item.created),
                  created_by: item.created_by,
                  update: new Date(item.update),
                  update_by: item.update_by,
                  web_id: item.web_id,
                  update_web: new Date(item.update_web),
                  sync: true,
                })
                .then((res) => {})
                .catch((error) => console.log(error));
            } else {
              //If it is not rejected and the sync was successful, the variables are updated.
              if (item.action_status) {
                await material
                  .update(id, {
                    web_id: item.web_id,
                    update_web: new Date(item.update_web),
                    sync: true,
                  })
                  .then((res) => {})
                  .catch((error) => console.log(error));
              }
            }
          });
        }
        store.commit("comun/setSynchronizing", false);
        return getAllMaterialCrushing(data_material[0].report);
      })
      .catch((error) => {
        store.commit("comun/setSynchronizing", false);
        console.log(error);
        return getAllMaterialCrushing(data_material[0].report);
      });
  } else {
    store.commit("comun/setSynchronizing", false);
    return getAllMaterialCrushing(data_material[0].report);
  }
}
