import { createQueryBuilder, getRepository } from "typeorm";
import { baseApi, isConfigMobile, getCustomerJWT, parseDate, updateReportAuditFields } from './base'
import axios from 'axios'
import store from "@/store"
import moment from "moment";

/**
 * It is used to get all records from start stop events table.
 * @param {Number} reportId - Report Id.
 * @returns {Array} All records available in the report.
 */
export function getAllEventsLists(reportId) {
    let isMobile = isConfigMobile()
    if (isMobile) {
        return new Promise(async function (resolve) {
            const startStopEvents = await getRepository("oreReportStartStopEvents")
                .find({ report: reportId, deleted: null })
                .then(response => {
                    response.forEach((startStopEvent) => {
                        startStopEvent.stop_time = moment(startStopEvent.stop_time).format("YYYY-MM-DD HH:mm")
                        if (startStopEvent.restart_time != null && startStopEvent.restart_time != undefined) {
                            startStopEvent.restart_time = moment(startStopEvent.restart_time).format("YYYY-MM-DD HH:mm")
                        }
                    })
                    store.commit("ore/setUpdateReportsSelected", { 'start_stop_events': response });
                    resolve(response)
                })
                .catch(error => console.log(error))
        })
    } else {
        return new Promise(function (resolve, reject) {
            const token = getCustomerJWT()
            axios.get(baseApi() + '1.0/ore/report/startstopevents/all/' + reportId + "/", { headers: { Authorization: `Bearer ${token}` } })
                .then(response => resolve(response.data))
                .catch(error => reject(error))
        })
    }
}

/**
 * It gets a record from start stop events to edit
 * @param {Number} id - Start stop event Id.
 * @returns {Object} The record to edit
 */
export function getStartStopEvent(id) {
    let isMobile = isConfigMobile()
    if (isMobile) {
        return new Promise(async function (resolve) {
            const startStopEvents = await getRepository("oreReportStartStopEvents");
            await startStopEvents.findOne({ id: id, deleted: null, })
                .then(response => {
                    response.stop_time = moment(response.stop_time).format("YYYY-MM-DD HH:mm")
                    if (response.unresolved == true) {
                        response.restart_time = null
                    } else {
                        if (response.restart_time != null && response.restart_time != undefined) {
                            response.restart_time = moment(response.restart_time).format("YYYY-MM-DD HH:mm")
                        }
                    }
                    resolve(response);
                })
                .catch(error => console.log(error))
        })
    } else {
        return new Promise(function (resolve, reject) {
            const token = getCustomerJWT()
            axios.get(baseApi() + '1.0/ore/report/startstopevents/' + id + "/", { headers: { Authorization: `Bearer ${token}` } })
                .then(response => resolve(response.data))
                .catch(error => reject(error))
        })
    }
}

/**
 * It creates a new record in the start stop events table
 * @param {Object} data - Object with data to save
 * @returns {Array} All records available in the report.
 */
export async function createStartStopEvent(data) {
    let isMobile = isConfigMobile()
    if (isMobile) {
        const startStopEvents = getRepository("oreReportStartStopEvents");
        let now = new Date();
        let user_id = store.getters["auth/getAuthCustomer"].data.id
        data.stop_time = moment(data.stop_time).toISOString()
        if (data.unresolved == true) {
            data.restart_time = null
        } else {
            data.restart_time = moment(data.restart_time).toISOString()
        }
        data.update = now;
        data.update_by = user_id;
        data.created = now;
        data.created_by = user_id;

        await startStopEvents.save(data)
            .then((res) => { return sync([res]); }).catch(error => console.log(error))
    } else {
        return new Promise(function (resolve, reject) {
            const token = getCustomerJWT()
            axios.post(baseApi() + '1.0/ore/report/startstopevents/', data, { headers: { Authorization: `Bearer ${token}` } })
                .then(response => resolve(response.data))
                .catch(error => reject(error))
        })
    }
}

/**
 * It updates a record in the start stop events table
 * @param {Number} id - Start stop event Id.
 * @param {Object} data - Object with data to update
 * @returns {Array} All records available in the report.
 */
export async function updateStartStopEvent(id, data) {
    let isMobile = isConfigMobile()
    if (isMobile) {
        const startStopEvents = getRepository("oreReportStartStopEvents");
        let user_id = store.getters["auth/getAuthCustomer"].data.id
        if (data.unresolved == true) {
            data.restart_time = null
        } else {
            data.restart_time = moment(data.restart_time).toISOString()
        }
        await startStopEvents.update(id,
            {
                stop_time: moment(data.stop_time).toISOString(),
                unresolved: data.unresolved,
                restart_time: data.restart_time,
                razon: data.razon,
                category: data.category,
                equipment: data.equipment,
                comments: data.comments,
                update: new Date(),
                update_by: user_id,
                sync: false
            },
        )
        await startStopEvents.find({ id: id, deleted: null }).then(res => { return sync(res) });
    } else {
        return new Promise(function (resolve, reject) {
            const token = getCustomerJWT()
            axios.put(baseApi() + '1.0/ore/report/startstopevents/' + id + "/", data, { headers: { Authorization: `Bearer ${token}` } })
                .then(response => resolve(response.data))
                .catch(error => reject(error))
        })
    }
}

/**
 * It makes a soft delete of a record from start stop events table
 * @param {Number} id - Start stop event Id.
 * @returns {Array} All records available in the report.
 */
export async function deleteStartStopEvent(id) {
    let isMobile = isConfigMobile()
    if (isMobile) {
        const startStopEvents = await getRepository("oreReportStartStopEvents");
        let user_id = store.getters["auth/getAuthCustomer"].data.id
        await startStopEvents.update(id, {
            update: new Date(),
            update_by: user_id,
            deleted: new Date(),
            deleted_by: user_id,
            sync: false,
        }).then(() => {}).catch(error => console.log(error))
        await startStopEvents.find({ id: id }).then((res) => { return sync(res); });
    } else {
        return new Promise(function (resolve, reject) {
            const token = getCustomerJWT()
            axios.delete(baseApi() + '1.0/ore/report/startstopevents/' + id + "/", { headers: { Authorization: `Bearer ${token}` } })
                .then(response => resolve(response.data))
                .catch(error => reject(error))
        })
    }
}

/**
 * It is used to synchronize data from start stop events module.
 * It is send from mobile aplication to web aplication
 * @param {Object} data - Object with data to send to web application
 * @returns {Array} All records available in the report.
 */
async function sync(data) {
    store.commit("comun/setSynchronizing", true);
    const reportRepository = getRepository("oreReport");
    const startStopEvents = await getRepository("oreReportStartStopEvents");
    let data_start_stop_events = parseDate(data);

    let reportData = await reportRepository.find({ id: data_start_stop_events[0].report }).then(res => { return parseDate(res) })
    await reportRepository.update(reportData[0].id, { sync: false, }).then((res) => {}).catch(error => console.log(error))

    let checkOnLine = store.getters["comun/getIsOnline"]
    if (checkOnLine) {
        let objToSync = {
            reports: [
                {
                    report: reportData[0],
                    data: { start_stop_events: data_start_stop_events }
                }
            ],
        }
        const token = getCustomerJWT()
        await axios.post(baseApi() + '1.0/ore/mobile/sync/', objToSync, { headers: { Authorization: `Bearer ${token}` } })
            .then(async function (response) {
                let id = data_start_stop_events[0].id
                let reportRes = response.data.reports[0].report
                let dataRes = response.data.reports[0].data

                await updateReportAuditFields(reportRes, reportRepository, reportData[0].id)
                if ("start_stop_events" in dataRes) {
                    let _items = dataRes.start_stop_events
                    _items.forEach(async (item) => {
                        //If the sync is rejected, the item is updated with the information that comes from web.
                        if (item.action_msg == "reject") {
                            await startStopEvents.update(id,
                                {
                                    stop_time: item.stop_time,
                                    unresolved: item.unresolved,
                                    restart_time: item.restart_time,
                                    razon: item.razon,
                                    category: item.category,
                                    equipment: item.equipment,
                                    comments: item.comments,
                                    created: new Date(item.created),
                                    created_by: item.created_by,
                                    update: new Date(item.update),
                                    update_by: item.update_by,
                                    web_id: item.web_id,
                                    update_web: new Date(item.update_web),
                                    sync: true,
                                },
                            ).then((res) => {}).catch(error => console.log(error))
                        } else {
                            //If it is not rejected and the sync was successful, the variables are updated.
                            if (item.action_status) {
                                await startStopEvents.update(id,
                                    {
                                        web_id: item.web_id,
                                        update_web: new Date(item.update_web),
                                        sync: true,
                                    },
                                ).then((res) => {}).catch(error => console.log(error))

                            }
                        }
                    })
                }

                store.commit("comun/setSynchronizing", false);
                return getAllEventsLists(data_start_stop_events[0].report)

            }).catch(error => { store.commit("comun/setSynchronizing", false); console.log(error); return getAllEventsLists(data_start_stop_events[0].report) })
    } else {
        store.commit("comun/setSynchronizing", false);
        return getAllEventsLists(data_start_stop_events[0].report)
    }
}
