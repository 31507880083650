import { createQueryBuilder, getRepository, IsNull } from "typeorm";
import { baseApi, isConfigMobile, getCustomerJWT } from './base'
import axios from 'axios'
import store from "@/store"

/**
 * It is used to get all scenario records.
 * @returns All scenarios available.
 */
export function getScenarios() {
    let itemsScenario = store.getters['ore/getListScenario']

    let isMobile = isConfigMobile()
    if (isMobile) {
        return new Promise(async function (resolve) {
            if (typeof (itemsScenario) != 'undefined' && itemsScenario.length != 0) {
                resolve(itemsScenario)
            } else {
                const scenarioRepository = await getRepository("oreConfigScenario")
                let _scenarios = await scenarioRepository.find({ deleted: null, published: true }).then(res => { return res }).catch(e => console.log(e))
                store.commit('ore/setListScenario', _scenarios)
                resolve(_scenarios)
            }
        })
    } else {
        return new Promise(function (resolve, reject) {
            if (typeof (itemsScenario) != 'undefined' && itemsScenario.length != 0) {
                resolve(itemsScenario)
            } else {
                const token = getCustomerJWT()
                axios.get(baseApi() + '1.0/ore/crushing/scenario/lts/', { headers: { Authorization: `Bearer ${token}` } })
                    .then(response => {
                        store.commit('ore/setListScenario', response.data)
                        resolve(response.data)
                    })
                    .catch(error => reject(error))
            }
        })
    }
}

/**
 * It is used to get all the images information for the scenario flowsheet
 * and show the images in the dashboard.
 * @param {Number} attachment_id - Scenario Id.
 * @returns {Array} - An array with the images information.
 */
export async function getImagesScenario(attachment_id) {
    let table = "ore_config_scenario"
    let isMobile = isConfigMobile()
    if (isMobile) {
        return new Promise( async function (resolve) {
            const fileRepository = await getRepository("coreFile");
            let allImages = await fileRepository.find({ attachment_type: table, attachment_id: attachment_id, deleted: IsNull(), published: true }).then(response => { return response }).catch(e => console.log(e))
            resolve(allImages)
        })
    } else {
        return new Promise(function (resolve, reject) {
            const token = getCustomerJWT()
            axios.get(baseApi() + '1.0/ore/report/images/att_id/' + table + "/" + attachment_id + "/", { headers: { Authorization: `Bearer ${token}` } })
                .then(response => {
                    store.commit('ore/setListScenario', response.data)
                    resolve(response.data)
                }).catch(error => reject(error))
        })
    }
}

