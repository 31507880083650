var EntitySchema = require("typeorm").EntitySchema;

module.exports = new EntitySchema({
    name: "coreUser",
    tableName: "core_users",
    columns: {
        id: {
            primary: true,
            type: "int",
            generated: false
        },
        type_user: {
            type: "int",
            default: "3"
        },
        username: {
            type: "varchar",
            unique: true
        },
        email: {
            type: "varchar",
            nullable: true,
            unique: true
        },
        password: {
            type: "varchar",
            nullable: true
        },
        first_name: {
            type: "varchar",
            nullable: true
        },
        last_name: {
            type: "varchar",
            nullable: true
        },
        reset_password: {
            type: "varchar",
            nullable: true
        },
        registered_on: {
            type: "datetime",
            nullable: true
        },
        code_activate: {
            type: "varchar",
            nullable: true
        },
        code_time: {
            type: "datetime",
            nullable: true
        },
        picture: {
            type: "varchar",
            nullable: true
        },
        extra_data: {
            type: "varchar",
            nullable: true
        },
        type_user: {
            type: "int",
            default: 4
        },
        access_config: {
            type: "int",
            default: "0"
        },
        created: {
            type: "datetime",
            nullable: true
        },
        created_by: {
            type: "int",
            nullable: true
        },
        update: {
            type: "datetime",
            nullable: true
        },
        update_by: {
            type: "int",
            nullable: true
        },
        deleted: {
            type: "datetime",
            nullable: true
        },
        deleted_by: {
            type: "int",
            nullable: true
        },
        published: {
            type: "int",
            default: "1"
        }
    }
})