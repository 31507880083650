const configMixin = {
    data() {
        return {
            baseApi: '/api/',
            isMobile: false,
            isProduction: false,
        }
    },
    mounted() {
        this.$store.commit('comun/setIsMobile', this.isMobile);
    },
}
export default configMixin