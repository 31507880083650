import { createQueryBuilder, getRepository, Brackets } from "typeorm";
import { baseApi, isConfigMobile, getCustomerJWT } from './base'
import axios from 'axios'
import store from "@/store"

/**
 * It is used to get lists filtering by code from table named list,
 * then it is join with the table named listitem filtering by the field named list_type.
 * @param {String} code - List code.
 * @returns {Array} All lists available.
 */
export async function getListItemsByCode(code) {
    const self = this
    let itemsList = await store.getters['comun/getLists']
    let isMobile = isConfigMobile()

    if (isMobile) {
        let itemsByCode = ((itemsList != undefined) ? itemsList.find(x => x.code == code) : null)
        return new Promise(async function (resolve) {
            if (itemsByCode != undefined && itemsByCode != null && itemsByCode.items.length > 0) {
                resolve(filterListItemByCrusher(itemsByCode.items))
            } else {
                const listRepository = await getRepository("coreList");
                const listItemRepository = await getRepository("coreListItem");

                let itemType = await listRepository.findOne({ code: code }).then(response => { return response; }).catch(error => { console.log(error); })
                if (typeof (itemType) != 'undefined') {
                    let item = await listItemRepository.find({ list_type: itemType.id }).then(response => { return response; }).catch(error => { console.log(error); })
                    store.commit('comun/setLists', { code: code, items: item })
                    resolve(filterListItemByCrusher(item))
                } else {
                    resolve({})
                }
            }
        })
    } else {
        let itemsByCode = ((itemsList != undefined) ? itemsList.find(x => x.code == code) : null)
        return new Promise(function (resolve, reject) {
            if (itemsByCode != undefined && itemsByCode != null && itemsByCode.items.length > 0) {
                resolve(filterListItemByCrusher(itemsByCode.items))
            } else {
                const token = getCustomerJWT()
                axios.get(baseApi() + '1.0/list_item/lts/type_code/' + code + "/", { headers: { Authorization: `Bearer ${token}` } })
                    .then(function (response) {
                        store.commit('comun/setLists', { code: code, items: response.data })
                        resolve(filterListItemByCrusher(response.data))
                    })
                    .catch(error => reject(error))
            }
        })
    }
}

/**
 * It is used to get lists filtering by code from table named listPivot,
 * then it is join with the table named listPivotItem filtering by the field named list_type.
 * @param {String} code - List code.
 * @returns {Array} All lists available.
 */
export async function getListPivotByCode(code) {
    let itemsList = await store.getters['comun/getListsPivot']
    let isMobile = isConfigMobile()

    if (isMobile) {
        let itemsPivotByCode = ((itemsList != undefined) ? itemsList.find(x => x.code == code) : null)
        return new Promise(async function (resolve) {
            if (itemsPivotByCode != undefined && itemsPivotByCode != null && itemsPivotByCode.items.length > 0) {
                resolve(filterListItemByCrusher(itemsPivotByCode.items))
            } else {
                const listPivotRepository = await getRepository("coreListPivot");
                const listPivotItemRepository = await getRepository("coreListPivotItem");

                let itemType = await listPivotRepository.findOne({ code: code }).then(response => { return response; }).catch(error => { console.log(error); })
                if (typeof (itemType) != 'undefined') {
                    let item = await listPivotItemRepository.find({ list_type: itemType.id }).then(response => { return response; }).catch(error => { console.log(error); })
                    store.commit('comun/setListsPivot', { code: code, items: item })
                    resolve(filterListItemByCrusher(item))
                }
            }
        })
    } else {
        let itemsPivotByCode = ((itemsList != undefined) ? itemsList.find(x => x.code == code) : null)
        return new Promise(function (resolve, reject) {
            if (itemsPivotByCode != undefined && itemsPivotByCode != null && itemsPivotByCode.items.length > 0) {
                resolve(filterListItemByCrusher(itemsPivotByCode.items))
            } else {
                const token = getCustomerJWT()
                axios.get(baseApi() + '1.0/list_pivot_item/lts/type_code/' + code + "/", { headers: { Authorization: `Bearer ${token}` } })
                    .then(function (response) {
                        store.commit('comun/setListsPivot', { code: code, items: response.data })
                        resolve(filterListItemByCrusher(response.data))
                    })
                    .catch(error => reject(error))
            }
        })
    }
}

/**
 * It is used to get lists filtering by an array of codes from table named list,
 * then it is join with the table named listitem filtering by the field named list_type,
 * then it validates if the list is multi-plant or if it belongs to the user's plants.
 * @param {Array} codes - Array with lists codes.
 * @returns {Array} All lists available
 */
export async function getListItemsByCodes(codes) {
    let listLoaded = []
    let noCode = []
    let itemsList = await store.getters['comun/getLists']

    let isMobile = store.getters['comun/getIsMobile']
    if (isMobile) {
        codes.forEach(code => {
            let listByCode = itemsList.find(x => x.code == code)
            if (listByCode && listByCode.code)
                listLoaded.push(listByCode)
            else
                noCode.push(code)
        });
        return new Promise(async function (resolve) {
            if (noCode.length == 0) {
                resolve(listLoaded)
            } else {
                const listRepository = await getRepository("coreList");
                const listItemRepository = await getRepository("coreListItem");
                if (noCode.length > 0) {
                    let resReturn = []

                    let listByCodes = await listRepository
                        .createQueryBuilder("list")
                        .where("list.code IN (:...codes)", { codes: noCode })
                        .getMany()

                    let listItems = await listItemRepository
                        .createQueryBuilder("listItem")
                        .where("listItem.list_type IN (:...codes)", { codes: listByCodes.map((x) => x.id) })
                        .getMany()

                    await listByCodes.forEach(async item => {
                        let listItemsChildren = listItems.filter(x => x.list_type == item.id)
                        let _multi_plant = item.multi_plants
                        await listItemsChildren.map(x => {
                            if (_multi_plant == false || _multi_plant == 0) {
                                delete x.multi_plant
                            }
                        })
                        let res = {
                            code: item.code,
                            items: listItemsChildren
                        }
                        resReturn.push(res)
                    })
                    let _data = listLoaded.concat(resReturn)

                    store.commit('comun/setAllLists', _data)
                    resolve(_data)
                } else {
                    resolve([])
                }
            }
        })
    } else {
        codes.forEach(code => {
            let listByCode = itemsList.find(x => x.code == code)
            if (listByCode && listByCode.code)
                listLoaded.push(listByCode)
            else
                noCode.push(code)
        });
        return new Promise(function (resolve, reject) {
            if (noCode.length === 0) {
                resolve(listLoaded);
            } else {
                const token = getCustomerJWT();
                const promises = noCode.map(code => {
                return axios.get(baseApi() + `1.0/list_item/lts/type_code/${code}/`, { headers: { Authorization: `Bearer ${token}` } })
                    .then(response => {
                        return {
                            code: code,
                            items: response.data
                        }
                    })
                    .catch(error => {
                        console.error(`Error fetching data for code ${code}:`, error);
                        throw error; // Propaga el error para que Promise.all lo capture
                    });
                });
                Promise.all(promises)
                    .then(responses => {
                        const allData = responses.reduce((acc, data) => acc.concat(data), listLoaded);
                        store.commit('comun/setAllLists', allData);
                        resolve(allData);
                    })
                    .catch(error => reject(error));
            }
        });
    }
}

/**
 * It is used to get lists filtering by an array of codes from table named listPivot,
 * then it is join with the table named listPivotItem filtering by the field named list_type,
 * @param {Array} codes - Array with lists codes.
 * @returns {Array} All lists available
 */
export async function getListPivotByCodes(codes) {
    let listLoaded = []
    let noCode = []
    let itemsList = await store.getters['comun/getListsPivot']

    let isMobile = store.getters['comun/getIsMobile']
    if (isMobile) {
        codes.forEach(code => {
            let listByCode = itemsList.find(x => x.code == code)
            if (listByCode && listByCode.code)
                listLoaded.push(listByCode)
            else
                noCode.push(code)
        });
        return new Promise(async function (resolve) {
            if (noCode.length == 0) {
                resolve(listLoaded)
            } else {
                const listPivotRepository = await getRepository("coreListPivot");
                const listPivotItemRepository = await getRepository("coreListPivotItem");

                let itemCode = await listPivotRepository.createQueryBuilder("listPivot").where("listPivot.code = :code", { code: noCode[0] }).getOne()
                let itemCodeChildren = await listPivotItemRepository.createQueryBuilder("pivotItem")
                    .select("pivotItem.id", "pivotItem.list_itema", "pivotItem.list_itemb")
                    .where("pivotItem.list_pivot = :pivotId", { pivotId: itemCode.id })
                    .getMany()
                    .then(response => { return response; }).catch(error => { console.log(error); })

                let _data = listLoaded.concat(itemCodeChildren)
                store.commit('comun/setAllListsPivot', _data)
                resolve(_data)
            }
        })
    } else {
        codes.forEach(code => {
            let listByCode = itemsList.find(x => x.code == code)
            if (listByCode && listByCode.code)
                listLoaded.push(listByCode)
            else
                noCode.push(code)
        });
        return new Promise(function (resolve) {
            if (noCode.length == 0) {
                resolve(listLoaded)
            } else {
                const token = getCustomerJWT()
                axios.post(baseApi() + '1.0/multi/list_pivot_item/lts/type_code/', { "names": noCode }, { headers: { Authorization: `Bearer ${token}` } })
                    .then(response => {
                        let _data = listLoaded.concat(response.data)
                        store.commit('comun/setAllListsPivot', _data)
                        resolve(_data)
                    })
                    .catch(error => reject(error))
            }
        })
    }
}

/**
 * It is used to populate the energy module when a new report is being created,
 * this filter records from the table named listPivot and it join with the table named listPivotItem,
 * then it validates if the list is multi-crusher or if it belongs to the user's crushers.
 * @param {String} code - List code
 * @param {Number} crusher - crusher Id
 * @returns {Array} All records available for the crusher.
 */
export async function getPivoteItems(code, crusher) {
    const listPivotRepository = await getRepository("coreListPivot");
    const listPivotItemRepository = await getRepository("coreListPivotItem");

    let _code = code
    let itemCode = await listPivotRepository.findOne({ code: _code, deleted: null }).then(response => { return response; }).catch(error => { console.log("error", error) })

    let itemCodeChildren = []
    if (typeof (itemCode) != 'undefined') {
        itemCodeChildren = await listPivotItemRepository.createQueryBuilder("listItem")
            .where("listItem.list_pivot = :listPivotId", { listPivotId: itemCode.id })
            .andWhere("listItem.deleted IS NULL")
            .andWhere(
                new Brackets((qb) => {
                    qb.where("listItem.multi_crusher = 'ALL' ")
                        .orWhere("listItem.multi_crusher IS NULL ")
                        .orWhere("listItem.multi_crusher like :crusher ", { crusher: `%#${crusher}#%` })
                }),
            ).andWhere("listItem.extra_data like :crusher ", { crusher: `%#${crusher}#%` }).getMany();
    }
    return itemCodeChildren;
}

/**
 * It is used to populate the inventory module when a new report is being created,
 * this filter records from the table named list and it join with the table named listItem,
 * then it validates if the list is multi-crusher or if it belongs to the user's crushers.
 * @param {String} code - List code
 * @param {Number} crusher - crusher Id
 * @returns {Array} All records available for the crusher.
 */
 export async function getListItems(code, crusher) {
    const listRepository = await getRepository("coreList");
    const listItemRepository = await getRepository("coreListItem");

    let _code = code
    let itemParent = await listRepository.findOne({ code: _code, deleted: null }).then(response => { return response; }).catch(error => { console.log("error", error) })

    let itemsChildren = []
    if (typeof (itemParent) != 'undefined') {
        itemsChildren = await listItemRepository.createQueryBuilder("listItem")
            .where("listItem.list_type = :listId", { listId: itemParent.id })
            .andWhere("listItem.deleted IS NULL")
            .andWhere(
                new Brackets((qb) => {
                    qb.where("listItem.multi_crusher = 'ALL' ")
                        .orWhere("listItem.multi_crusher IS NULL ")
                        .orWhere("listItem.multi_crusher like :crusher ", { crusher: `%#${crusher}#%` })
                }),
            ).getMany()
    }
    return itemsChildren;
}

/**
 * It is used to validate the lists available from the local storage.
 * @param {Array} items - Lists array.
 * @returns {Array} All records available for the crusher.
 */
export async function filterListItemByCrusher(items) {
    let crusherId = await store.getters['ore/getCrusherId']
    let _listItems = []

    items.forEach(item => {
        if (!("published" in item) || ("published" in item && item.published)) {
            if ("multi_crusher" in item) {
                if (item.multi_crusher && (item.multi_crusher == "ALL" || item.multi_crusher.indexOf("#" + crusherId + "#")) >= 0) {
                    _listItems.push(item)
                }
            } else {
                _listItems.push(item)
            }
        }
    })
    return _listItems
}